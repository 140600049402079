import Api from 'core/api/clients/api/Api';

const getCatalog = async filters => {
  const body = {
    page: filters.page,
    itemsPerPage: filters.itemsPerPage,
    orderDesc: filters.orderDesc,
    orderColum: filters.orderColumn,
    brand: filters.brand,
    culture: filters.culture,
    technology: filters.technology,
    genotype: filters.genotype,
  };
  return Api.post({
    url: '/Catalog/ListGenotypes',
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const updateSellingTip = async ({
  varietyId,
  sellingTip,
}: {
  varietyId: string;
  sellingTip: string;
}) => {
  return Api.put({
    url: `/Catalog/${varietyId}`,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body: JSON.stringify({ sellingTip }),
  }).then(response => response);
};

export const CatalogService = {
  getCatalog,
  updateSellingTip,
};
