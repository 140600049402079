import {
  DynamicFormData,
  GDMConditionalRender,
  GDMDynamicFormFields,
  GDMPageCard,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dynamicFormValidateForm } from 'utils';
import { errorHandler } from 'utils/form/errorHandler';
import { AdminRegisterPageComponent } from '../AdminRegisterPage.type';
import { createAdmin } from '../utils';

const DEFAULT_ADMIN: Partial<DynamicFormData> = {
  name: '',
  contactEmail: '',
  phoneNumber: '',
  documentNumber: '',
};

const AdminRegisterPage: AdminRegisterPageComponent = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const {
    user: { countryName },
  } = useStore(UserProfileStore);

  const [admin, setAdmin] = useState<Partial<DynamicFormData>>(DEFAULT_ADMIN);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [entityType, setEntityType] = useState('Administrador');
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    setEntityType('Administrador');
  }, [currentLanguage]);

  useEffect(() => {
    if (admin.documentNumber) {
      setDocumentTypeId();
    }

    if (admin.countryId) {
      setCountryId();
    }
  }, [admin.documentNumber, admin.countryId]);

  useEffect(() => {
    if (countryName) {
      setCountryId();
    }
  }, [countryName]);

  const handleValidate = adminFormData =>
    dynamicFormValidateForm({
      formData: adminFormData,
      countryName,
      entityType,
      getLabelTranslation,
    });

  const onValidate = errors => {
    if (Object.keys(errors)?.length) {
      setIsSubmiting(false);
    }
    setErrors(errors);
  };

  const handleChange = (e: any) => {
    const {
      target: { value, name },
    } = e;

    setAdmin(adminData => ({ ...adminData, [name]: value }));
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      onClose: closeModal,
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const onError = errors => {
    errorHandler(errors, setErrors);

    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_register_error'),
      onClose: closeModal,
      message: getLabelTranslation('modal_message_register_error'),
    });
  };

  const onInit = () => {
    setIsSubmiting(true);
  };

  const onSuccess = () => {
    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_register_success'),
      onClose: () => navigate('/admin-list'),
      primaryAction: {
        label: 'Ok',
        action: () => {
          closeModal();
          navigate('/admin-list');
        },
      },
    });
  };

  const onFinally = () => {
    setIsSubmiting(false);
  };

  const handleSubmit = async () => {
    console.log(admin);
    await createAdmin({
      admin,
      onError,
      onInit,
      onFinally,
      onSuccess,
      handleValidate,
      onValidate,
    });
  };

  const setDocumentTypeId = () => {
    if (countryName === 'Argentina') {
      setAdmin(prev => ({
        ...prev,
        documentTypeId: 'b85d6c71-e469-4268-a813-1bac98f596ae',
      }));
    } else {
      setAdmin(prev => ({
        ...prev,
        documentTypeId: 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d',
      }));
    }
  };

  const setCountryId = () => {
    if (countryName === 'Argentina') {
      setAdmin(prev => ({
        ...prev,
        countryId: '628ff4ec-0435-460a-b4ca-bcea09bf9f5b',
      }));
    }

    if (countryName === 'Brasil') {
      setAdmin(prev => ({
        ...prev,
        countryId: '41981f5c-0e28-44f5-ab84-d6259659abee',
      }));
    }
  };

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'cadastrar_administrador',
      )}`}
      title={getLabelTranslation('cadastrar_administrador')}
    >
      <GDMConditionalRender canRender>
        <GDMDynamicFormFields
          {...{
            entityType,
            viewType: 'create',
            formData: admin,
            isSubmiting,
            errors,
            formDataEvents: {
              onCancel: handleCancel,
              onChange: handleChange,
              onSubmit: handleSubmit,
            },
            formDataOptions: {
              roles: [],
              brands: [],
              cities: [],
              states: [],
              regions: [],
              gdmSellers: [],
              multipliers: [],
            },
          }}
        />
      </GDMConditionalRender>
    </GDMPageCard>
  );
};

export default AdminRegisterPage;
