import { useNavigate } from 'react-router-dom';

import { msalInstance } from 'authConfig';
import SelectBrand from 'core/components/SelectBrand/SelectBrand';
import { acceptTermsUser } from 'core/store/stores';

export default function SelectBrandPage({ termsWasOpen, setTermsWasOpened }) {
  const navigate = useNavigate();
  const handleSelectBrand = () => {
    navigate('/');
  };
  const backNavigate = () => {
    acceptTermsUser(false);
    setTermsWasOpened();
    navigate('/term-user');
  };
  const handleExit = () => {
    navigate('/');
    logout();
  };

  return (
    <div>
      <SelectBrand
        handleChose={handleSelectBrand}
        handleBack={backNavigate}
        handleExit={handleExit}
        termWasOpen={termsWasOpen}
      />
    </div>
  );
}

export const logout = async () => {
  sessionStorage.removeItem('msalToken');
  return msalInstance.logoutRedirect();
};
