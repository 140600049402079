import { CloseOutlined, GpsFixed } from '@mui/icons-material';
import { CircularProgress, Drawer, IconButton, Stack } from '@mui/material';
import { GDMContainer } from 'components/gdm-container';
import { GDMMaps } from 'components/gdm-maps';
import { GDMTypography } from 'components/gdm-typography';
import { useState } from 'react';
import { GDMDrawerMapComponent } from './GDMDrawerMap.type';

const sleep = async (milliseconds: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
};

const GDMDrawerMap: GDMDrawerMapComponent = ({
  isOpen,
  onClose,
  title,
  subtitle,
  actions,
  setInitialCoordinates,
  InitialCoordinates,
  type,
  handleMarkerDelete,
  handleMapClick = () => {},
  handleClear,
  mapPinListLot,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reset, setReset] = useState(true);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        async position => {
          setReset(false);
          const { latitude, longitude } = position.coords;
          handleMapClick({ latitude: latitude.toString(), longitude: longitude.toString() });
          if (setInitialCoordinates) {
            setInitialCoordinates({ lat: latitude, lng: longitude });
          }
          await sleep(1);
          setIsLoading(false);
          setReset(true);
        },
        () => {
          setIsLoading(false);
        },
      );
    } else {
      // eslint-disable-next-line no-alert
      window.alert('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Drawer open={isOpen} anchor="right" onClose={() => onClose && onClose()}>
      <GDMContainer
        sx={{
          minWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '600px',
            xl: '1200px',
          },
        }}
      >
        <GDMContainer sx={{ p: 2 }}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <GDMTypography variant="h4" sx={{ fontWeight: 700, marginBottom: '8px' }}>
                {title}
              </GDMTypography>
              {!!subtitle && subtitle}
            </div>
            <IconButton onClick={() => onClose && onClose()}>
              <CloseOutlined />
            </IconButton>
          </Stack>
        </GDMContainer>
        <GDMContainer
          sx={{
            position: 'relative',
            '& .map': {
              height: '78vh',
            },
          }}
        >
          <IconButton
            onClick={() => {
              if (!isLoading) getUserLocation();
            }}
            sx={{
              position: 'absolute',
              top: '62px',
              width: '40px',
              height: '40px',
              right: '10px',
              bgcolor: '#fff',
              borderRadius: '2px',
              zIndex: 9999,
              ':hover': {
                bgcolor: '#fff',
              },
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : <GpsFixed />}
          </IconButton>
          {reset && (
            <GDMMaps
              {...{
                InitialCoordinates,
                type,
                handleMarkerDelete,
                handleClear,
                mapPinListLot,
                handleMapClick,
                ...rest,
              }}
            />
          )}
        </GDMContainer>
        <GDMContainer sx={{ p: 2 }}>{!!actions && actions}</GDMContainer>
      </GDMContainer>
    </Drawer>
  );
};

export default GDMDrawerMap;
