/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import { BrandsServices } from 'core/api/services/brands';
import { LocationsService } from 'core/api/services/locations';
import { MultiplierService } from 'core/api/services/multiplier';
import { RegionsServices } from 'core/api/services/regions';
import { RolesServices } from 'core/api/services/roles';
import { SellerGDMService } from 'core/api/services/sellerGDM';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import {
  CountriesDomainEnum,
  CountriesPhoneCodeDomainEnum,
} from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { validateDocument } from 'core/utils/validators/forms';
import { useStore } from 'effector-react';
import validator from 'validator';

import { SelectChangeEvent } from '@mui/material/Select';
import { SxProps, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LuNewspaper } from 'react-icons/lu';
import { LiaChartBarSolid } from 'react-icons/lia';
import { PiBriefcaseMetal } from 'react-icons/pi';
import {
  GDMBox,
  GDMButton,
  GDMContainer,
  GDMFormFields,
  GDMLoading,
  GDMTypography,
} from 'components';
import useModal from 'hooks/useModal/useModal';
import { GDMDynamicFormComponent, IDynamicFormData } from './GDMDynamicForm.type';
import { GDMCard } from './components/gdm-card/GDMCard';
import { GDMTableAccounts } from './components/gdm-table/GDMTable';

const flapStyleActive: SxProps = {
  display: 'flex',
  alignItems: 'center',
  background: 'white',
  gap: '8px',
  padding: '8px 24px',
  borderRadius: '8px 8px 0 0',
  border: '1px solid #CBCDD9',
  borderBottom: '1px solid white',
  cursor: 'pointer',
  '@media only screen and (max-width: 600px)': {
    padding: '8px 4px',
    justifyContent: 'center',
  },
};

const flapStyleDisabled: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '12px 32px',
  borderBottom: '1px solid white',
  cursor: 'pointer',
  '@media only screen and (max-width: 600px)': {
    padding: '8px 4px',
    justifyContent: 'center',
  },
};

const GDMDynamicForm: GDMDynamicFormComponent = ({
  viewType,
  onSubmit,
  initialData,
  entityType,
  breadcrumb,
  fieldErrors,
  accountsList = undefined,
  statistics,
  filters,
  setFilters,
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [country, setCountry] = useState(CountriesDomainEnum.Brasil as string);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [disableInputs, setDisableInputs] = useState(false);
  const [regions, setRegions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [states, setStates] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [multipliers, setMultipliers] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [errors, setErrors] = useState<ObjectLiteral<string>>({});
  const [cityInputValue, setCityInputValue] = useState('');
  const [loadingForm, setLoadingForm] = useState(false);
  const [breadcrumbByViewType, setBreadcrumbByViewType] = useState('');
  const [activeFlap, setActiveFlap] = useState('register');

  const { user } = useStore(UserProfileStore);
  const { openModal, closeModal } = useModal();

  const countryOfUser = user.countryName;

  const [formData, setFormData] = useState<IDynamicFormData>({
    name: '',
    contactEmail: '',
    phoneNumber: '',
    documentNumber: '',
    documentTypeId: '',
    countryId: '',
    roleId: undefined,
    regionId: undefined,
    brandIds: undefined,
    fantasyName: '',
    personInChargeName: '',
    sellerId: undefined,
    multiplierId: undefined,
    address: '',
    ufId: undefined,
    cityId: '',
    logoFile: null,
  });

  const breadcrumbs = {
    view: `${getLabelTranslation('ReusableFormKey_Administrative')} > ${getLabelTranslation(
      'see_registrations',
    )} > ${getLabelTranslation('ReusableFormKey_Administrator')}`,
    register: `${getLabelTranslation('ReusableFormKey_Administrative')} > ${getLabelTranslation(
      'ReusableFormKey_RegisterAdministrator',
    )}`,
    edit: `${getLabelTranslation('ReusableFormKey_Administrative')} > ${getLabelTranslation(
      'see_registrations',
    )} > ${getLabelTranslation('ReusableFormKey_RegisterAdministrator')} > ${getLabelTranslation(
      'Edit',
    )}`,
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = (
    formData: IDynamicFormData,
    country: string,
    entityType = 'Administrador',
  ) => {
    const errors: ObjectLiteral<string> = {};

    if (!formData.name.trim()) {
      errors.name = getLabelTranslation('validatorForm_full_name_is_required');
    }

    if (!formData.contactEmail.trim()) {
      errors.contactEmail = getLabelTranslation('validatorForm_email_is_required');
    } else if (!/\S+@\S+\.\S+/.test(formData.contactEmail)) {
      errors.contactEmail = getLabelTranslation('validatorForm_invalid_email');
    }

    const cleanedPhoneNumber = formData.phoneNumber.replace(/[ \-+_]/g, '');
    const qtyOfNumbers = cleanedPhoneNumber.length;
    const onlyDdiNumbers = 2;
    const fixedLinePhoneWithPrefixNine = cleanedPhoneNumber[4] === '9';
    const isABrasilFixedLinePhone = qtyOfNumbers === 12;
    const isABrasilCellPhone = qtyOfNumbers > 12;
    const isBrasil = country === 'Brasil';
    const isArgentina = country === 'Argentina';

    const phoneValidation = {
      BrasilFixedLinePhone:
        /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{3})$/,
      BrasilCellPhone:
        /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
      Argentina: /^[0-9()\s\-+]+$/,
    };

    if (qtyOfNumbers === onlyDdiNumbers) {
      errors.phoneNumber = getLabelTranslation('validatorForm_phone_is_required');
    }

    if (qtyOfNumbers === 0) {
      errors.phoneNumber = getLabelTranslation('validatorForm_phone_is_required');
    }

    if (isArgentina) {
      if (!phoneValidation.Argentina.test(formData.phoneNumber)) {
        errors.phoneNumber = getLabelTranslation('validatorForm_invalid_phone');
      }
    }

    if (isABrasilFixedLinePhone && isBrasil && fixedLinePhoneWithPrefixNine) {
      if (!phoneValidation.BrasilFixedLinePhone.test(formData.phoneNumber)) {
        errors.phoneNumber = 'Telefone fixo inválido';
      }
    }

    if (isABrasilCellPhone && isBrasil) {
      if (!phoneValidation.BrasilCellPhone.test(formData.phoneNumber)) {
        errors.phoneNumber = 'Celular inválido';
      }
    }

    const cleanedDocument = formData.documentNumber.replace(/\D/g, '');
    const documentCountry = country === 'Argentina' ? 'CUIT' : 'CPF/CNPJ';

    if (entityType === 'Distribuidor' || entityType === 'Multiplicador') {
      if (!formData.address || !formData.address.trim()) {
        errors.address = getLabelTranslation('validatorForm_address_is_required');
      }

      if (!formData.ufId) {
        errors.ufId = getLabelTranslation('validatorForm_state_is_required');
      }

      if (!formData.cityId) {
        errors.cityId = getLabelTranslation('validatorForm_city_is_required');
      }

      if (!formData.brandIds || !formData.brandIds.length) {
        errors.brandIds = getLabelTranslation('validatorForm_at_least_one_brand_is_required');
      }

      if (!formData.logoFile) {
        errors.logoFile = getLabelTranslation('validatorForm_logo_is_required');
      }
      if (!formData.personInChargeName || !formData.personInChargeName.trim()) {
        errors.personInChargeName = getLabelTranslation('validatorForm_responsible_is_required');
      }
      if (cleanedDocument.length > 11 && !formData.fantasyName) {
        errors.fantasyName = getLabelTranslation('validatorForm_fantasy_name_is_required');
      }
      if (cleanedDocument.length === 0 && !formData.fantasyName) {
        errors.fantasyName = getLabelTranslation('validatorForm_fantasy_name_is_required');
      }
    }

    if (!cleanedDocument || cleanedDocument.length === 0) {
      errors.documentNumber = `${documentCountry} ${getLabelTranslation(
        'validatorForm_is_required',
      )}`;
    } else if (!cleanedDocument.trim() || cleanedDocument.length < 11) {
      errors.documentNumber = `${getLabelTranslation('validatorForm_invalid_document_number')}`;
    } else if (cleanedDocument.length > 11 && cleanedDocument.length !== 14) {
      errors.documentNumber = getLabelTranslation('validatorForm_invalid_document_number');
    } else if (
      formData.documentNumber !== '' &&
      !validateDocument(formData.documentNumber, country)
    ) {
      errors.documentNumber = getLabelTranslation('validatorForm_invalid_document_number');
    }

    if (entityType === 'Vendedor GDM') {
      if (cleanedDocument.length > 11 && !formData.fantasyName) {
        errors.fantasyName = getLabelTranslation('validatorForm_fantasy_name_is_required');
      }
      if (cleanedDocument.length === 0 && !formData.fantasyName) {
        errors.fantasyName = getLabelTranslation('validatorForm_fantasy_name_is_required');
      }
      if (!formData.brandIds || !formData.brandIds.length) {
        errors.brandIds = getLabelTranslation(
          'validatorForm_gdm_seller_must_have_at_least_one_brand',
        );
      }
      if (!formData.regionId || !formData.regionId.trim()) {
        errors.regionId = getLabelTranslation('validatorForm_region_is_required');
      }
      if (!formData.personInChargeName || !formData.personInChargeName.trim()) {
        errors.personInChargeName = getLabelTranslation('validatorForm_responsible_is_required');
      }
    }

    return errors;
  };

  useEffect(() => {
    setErrors(fieldErrors);
  }, [fieldErrors]);

  useEffect(() => {
    if (!entityType) return;
    const haveToDisableForm = entityType === 'Multiplicador' || entityType === 'Distribuidor';
    if (!formData.sellerId && !formData.multiplierId && haveToDisableForm) {
      setDisableInputs(true);
      return;
    }
    setDisableInputs(false);
  }, [formData.sellerId, formData.multiplierId, entityType]);

  useEffect(() => {
    if (initialData && viewType !== 'create') {
      setFormData({
        ...initialData,
        personInChargeName: isEmpty(initialData.personInChargeName)
          ? ''
          : initialData.personInChargeName,
        address: isEmpty(initialData.address) ? '' : initialData.address,
        fantasyName: isEmpty(initialData.fantasyName) ? '' : initialData.fantasyName,
        contactEmail: initialData.email ?? initialData.contactEmail,
        brandIds: initialData?.brands?.map(brand => brand.id) || undefined,
        sellerId: initialData?.sellerGdmUserId || undefined,
        ufId: states?.find(state => state.name === initialData.stateName)?.id || undefined,
        cityId: initialData.cityId || '',
      });
      setCityInputValue(initialData.cityName || '');
      RegionsServices.getRegions(initialData.countryId).then(response => {
        setRegions(response);
      });
    }
    if (viewType === 'view') {
      setBreadcrumbByViewType(breadcrumbs.view);
    } else if (viewType === 'edit') {
      setBreadcrumbByViewType(breadcrumbs.edit);
    } else {
      setBreadcrumbByViewType(breadcrumbs.register);
    }
  }, [initialData, viewType, states, loadingForm]);

  const isEmpty = (value: any) => value === undefined || value === 'undefined' || value === null;

  const getDocumentMask = (entityType, documentNumber) => {
    if (entityType === getLabelTranslation('ReusableFormKey_Administrator')) {
      return '999.999.999-99';
    }
    return documentNumber.replace(/\D/g, '').length > 11 ? '99.999.999/9999-99' : '999.999.999-999';
  };

  const fieldsConfigPerCountry = useMemo(() => {
    const documentMask = getDocumentMask(entityType, formData.documentNumber);
    // const brPhoneMask = getDocumentMask(entityType, formData.documentNumber);
    // const arPhoneMask = getDocumentMask(entityType, formData.documentNumber);

    return {
      Brasil: {
        document: {
          label:
            entityType === 'Administrador'
              ? `${getLabelTranslation('ReusableFormKey_CPF')}`
              : `${getLabelTranslation('ReusableFormKey_CNPJ')}`,
          mask: documentMask,
        },
        phoneNumber: {
          mask: '+99 99 99999-9999',
        },
      },
      Argentina: {
        document: {
          label: getLabelTranslation('ReusableFormKey_CPF'),
          mask: '999.999.999-99',
        },
        phoneNumber: {
          mask: '+99 9 999 999-9999',
        },
      },
    };
  }, [formData.documentNumber, entityType]);

  const checkViewType = viewType === 'create' ? user?.countryId : initialData?.countryId;

  useEffect(() => {
    setLoadingForm(true);
    const fetchData = async () => {
      try {
        const [countries, roles, brands, regions, sellers, multipliers] = await Promise.all([
          LocationsService.getCountries(),
          RolesServices.getRoles(),
          BrandsServices.getBrands(),
          RegionsServices.getRegions(checkViewType),
          SellerGDMService.getSellerGDM({ currentPage: 1, itemsPerPage: 200 }).then(
            response => response.items,
          ),
          MultiplierService.getMultipliers({ currentPage: 1, itemsPerPage: 100 }).then(
            response => response.items,
          ),
        ]);

        setCountries(countries);
        setRoles(roles);
        setBrands(brands);
        setRegions(regions);
        setSellers(sellers);
        setMultipliers(multipliers);

        const selectedCountryId =
          countryOfUser === CountriesDomainEnum.Brasil ? countries[0].id : countries[1].id;
        setFormData(prev => ({ ...prev, countryId: selectedCountryId }));
        setCountry(countryOfUser);

        const states = await LocationsService.getStates(selectedCountryId);
        setStates(states);
        const selectedUfId = undefined;

        const cities = await LocationsService.getCities(selectedUfId);
        setCities(cities);
        const selectedCities = '';

        const docTypes = await LocationsService.getDocumentTypes();
        setDocumentTypes(docTypes);
        setFormData(prev => ({
          ...prev,
          countryId: selectedCountryId,
          ufId: selectedUfId,
          cityId: selectedCities,
          documentTypeId:
            formData.documentTypeId ||
            docTypes.find(doc => doc.countryId === selectedCountryId || countries[0].id).id,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => setLoadingForm(false), 300);
      }
    };

    if (viewType === 'create' || (initialData !== null && user)) {
      fetchData();
    }
  }, [checkViewType]);

  useEffect(() => {
    if (viewType === 'create') {
      setFormData(prev => ({
        ...prev,
        phoneNumber:
          user.countryName === CountriesDomainEnum.Brasil
            ? CountriesPhoneCodeDomainEnum.Brasil
            : CountriesPhoneCodeDomainEnum.Argentina,
      }));
    }
  }, [user.countryName]);

  const config = useMemo(() => {
    return {
      formData,
      viewType,
      roles,
      disableInputs,
      sellers,
      multipliers,
      fieldsConfigPerCountry,
      countries,
      country,
      states,
      cities,
      cityInputValue,
      brands,
      regions,
      loading,
    };
  }, [
    formData,
    viewType,
    roles,
    disableInputs,
    sellers,
    multipliers,
    fieldsConfigPerCountry,
    countries,
    country,
    states,
    cities,
    cityInputValue,
    brands,
    regions,
    loading,
  ]);

  // const getDocumentType = (name, value, country) => {
  //   if (name !== 'documentNumber') return { detail: null };

  //   if (country === CountriesDomainEnum.Brasil) {
  //     return value.replace(/\D/g, '').length <= 11
  //       ? { detail: getLabelTranslation('ReusableFormKey_CPF') }
  //       : { detail: getLabelTranslation('ReusableFormKey_CNPJ') };
  //   }
  //   if (country === CountriesDomainEnum.Argentina) {
  //     return { detail: getLabelTranslation('ReusableFormKey_CPF') };
  //   }

  //   return { detail: null };
  // };

  const validateField = (name, value, country) => {
    if (name === 'contactEmail') {
      return validator.isEmail(value) ? '' : getLabelTranslation('ReusableFormKey_InvalidEmail');
    }
    if (name === 'documentNumber') {
      return validateDocument(value, country)
        ? ''
        : getLabelTranslation('ReusableFormKey_InvalidDocumentNumber');
    }

    return '';
  };

  const handleChangeValues = useCallback(
    event => {
      const { name: field, value } = event.target;
      const valueOfField = value;
      const numericValue = value.replace(/[^\d/_.-]/g, '');

      if (field === 'documentNumber') {
        if (numericValue.length < valueOfField.length) {
          return;
        }
      }

      setFormData(prev => ({
        ...prev,
        [field]: value,
        documentTypeId: user.documentTypeId,
      }));

      const qtyOfNumbersTyped = numericValue.length;
      if (
        field === 'documentNumber' &&
        qtyOfNumbersTyped > 11 &&
        qtyOfNumbersTyped < 14 &&
        country === 'Brasil'
      ) {
        setErrors(errors => ({ ...errors, documentNumber: 'Documento inválido' }));
        return;
      }

      const errorMessage = validateField(field, value, country);

      if (!errorMessage) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: errorMessage }));
      }
    },
    [country, documentTypes],
  );

  const handleChangeSeller = useCallback((_, newValue) => {
    if (newValue) {
      setFormData(prev => ({
        ...prev,
        sellerId: newValue.id,
        countryId: newValue.countryId,
      }));
      const listBrandIds = formData.brandIds ? formData.brandIds : [];
      handleChangeBrand(null, [...listBrandIds, ...newValue.brands]);
      setDisableInputs(false);
    } else {
      setFormData(prev => ({
        ...prev,
        sellerId: '',
        countryId: '',
      }));
      setDisableInputs(true);
    }
  }, []);

  const changeInputValue = e => {
    setCityInputValue(e.target.value);
  };

  const handleChangeMultiplier = (_: any, newValue: any) => {
    if (newValue) {
      setFormData(prev => ({
        ...prev,
        multiplierId: newValue.id,
        countryId: newValue.countryId,
      }));
      const listBrandIds = formData.brandIds ? formData.brandIds : [];
      handleChangeBrand(null, [...listBrandIds, ...newValue.brands]);
      setDisableInputs(false);
    } else {
      setFormData(prev => ({
        ...prev,
        multiplierId: '',
        countryId: '',
      }));
      setDisableInputs(true);
    }
  };

  const handleChangeRoleId = useCallback(e => {
    setFormData(prev => ({
      ...prev,
      roleId: e.target.value,
    }));
    setDisableInputs(false);
  }, []);

  useEffect(() => {
    if (initialData) setCityInputValue(initialData.cityName);
  }, [initialData]);

  const handleSubmit = async event => {
    event.preventDefault();
    const formErrors = validateForm(formData, country, entityType);
    setErrors(formErrors);

    const setDocId = async () => {
      const docTypes = await LocationsService.getDocumentTypes();
      const cleanedNumber = formData.documentNumber.replace(/\D/g, '').length;
      if (countryOfUser === CountriesDomainEnum.Argentina) {
        const cuit = docTypes.find(doc => doc.detail === 'CUIT').id;
        return cuit;
      }
      if (cleanedNumber === 14) {
        const cnpj = docTypes.find(doc => doc.detail === 'CNPJ').id;
        return cnpj;
      }
      const cpf = docTypes.find(doc => doc.detail === 'CPF').id;
      return cpf;
    };

    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      try {
        await onSubmit({
          ...formData,
          documentTypeId: await setDocId(),
        });
      } catch (error) {
        console.error('Error in onSubmit:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const handleChangeCountry = useCallback(
    (event: SelectChangeEvent) => {
      const selectedCountry = countries.find(ctry => ctry.id === event.target.value).name;
      let documentType;
      documentType = documentTypes.find(
        document => document.detail === fieldsConfigPerCountry[selectedCountry].document.label,
      );
      if (
        fieldsConfigPerCountry[selectedCountry].document.label ===
        getLabelTranslation('ReusableFormKey_CPF_CNPJ')
      )
        documentType =
          formData.documentNumber.replace(/\D/g, '').length <= 11
            ? { detail: getLabelTranslation('ReusableFormKey_CPF') }
            : { detail: getLabelTranslation('ReusableFormKey_CNPJ') };

      setCountry(countryOfUser);
      setFormData(prev => ({
        ...prev,
        countryId: event.target.value,
        documentTypeId: documentTypes.find(docType => docType.detail === documentType.detail).id,
      }));
      LocationsService.getStates(event.target.value).then(stateResp => {
        setStates(stateResp);
        setFormData(prev => ({ ...prev, ufId: formData.ufId || stateResp[0].id }));
        LocationsService.getCities(formData.ufId || stateResp[0].id).then(cityResp => {
          setCities(cityResp);
          setFormData(prev => ({ ...prev, cityId: formData.cityId || cityResp[0].id }));
        });
      });
    },
    [
      countries,
      documentTypes,
      fieldsConfigPerCountry,
      formData,
      setCountry,
      setFormData,
      setStates,
      setCities,
    ],
  );

  const handleChangeBrand = (_event: SelectChangeEvent, selectedValues) => {
    const selectedIds = selectedValues.map(brand => brand.id || brand);

    setFormData(prev => ({
      ...prev,
      brandIds: selectedIds,
    }));
  };

  const handleChangeStates = useCallback(
    (event: SelectChangeEvent) => {
      setFormData(prev => ({ ...prev, ufId: event.target.value }));
      LocationsService.getCities(event.target.value).then(cityResp => {
        setCities(cityResp);
        setFormData(prev => ({ ...prev, cityId: '' }));
      });
      setErrors(errors => {
        delete errors.ufId;
        return { ...errors };
      });
    },
    [formData.cityId],
  );

  const handleChangeCities = useCallback((_event: any, newValue: string | null | any) => {
    setFormData(prev => ({ ...prev, cityId: newValue?.id || undefined }));
    setCityInputValue(newValue?.name || '');
    setErrors(errors => {
      delete errors.cityId;
      return { ...errors };
    });
  }, []);

  const handleChangeRegion = useCallback((event: SelectChangeEvent) => {
    setFormData(prev => ({
      ...prev,
      regionId: event.target.value,
    }));
  }, []);

  const handleFileChange = useCallback((file: File) => {
    setFormData(prev => ({ ...prev, logoFile: file }));
    setErrors(errors => {
      delete errors.logoFile;
      return { ...errors };
    });
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handlers = {
    roleId: handleChangeRoleId,
    sellerId: handleChangeSeller,
    multiplierId: handleChangeMultiplier,
    submit: handleSubmit,
    file: handleFileChange,
    changeBrand: handleChangeBrand,
    changeRegion: handleChangeRegion,
    changeCities: handleChangeCities,
    changeStates: handleChangeStates,
    changeCountry: handleChangeCountry,
    changeValues: handleChangeValues,
  };

  const viewTypeTranslation = {
    view: getLabelTranslation('reusableForm_ViewRegistrationOf_'),
    edit: getLabelTranslation('reusableForm_EditRegistrationOf_'),
    create: getLabelTranslation('ReusableFormKey_Register'),
  };
  if (loadingForm) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  const checkEntity = entityType === 'administrator' ? 'Administrador' : entityType;

  return (
    <GDMContainer
      component="div"
      sx={{
        flexGrow: 1,
        p: 2,
        borderRadius: 2,
        padding: isDesktop ? '64px' : '0px',
        boxShadow: isDesktop ? '0 2px 8px rgba(0,0,0,0.1)' : undefined,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: isDesktop ? theme.palette.background.paper : 'transparent',
      }}
    >
      <GDMContainer
        sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
      >
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: isDesktop ? 'initial' : 'center',
            marginBottom: isDesktop ? '32px' : '20px',
            marginTop: isDesktop ? '0px' : '32px',
          }}
        >
          <GDMTypography sx={{ color: '#A1A9B8', fontSize: '14px' }} display="block" gutterBottom>
            {breadcrumb || breadcrumbByViewType}
          </GDMTypography>
        </GDMContainer>
        <GDMContainer>
          <GDMTypography
            variant="h4"
            textAlign={isDesktop ? 'left' : 'center'}
            mb={isDesktop ? 6 : 3}
            fontWeight={700}
            gutterBottom
            component="div"
            color="#222222"
          >
            {viewTypeTranslation[viewType] || viewTypeTranslation.create}{' '}
            {entityType === 'administrator' ? 'Administrador' : entityType}
          </GDMTypography>
        </GDMContainer>

        {viewType === 'view' &&
          (entityType === 'Distribuidor' || entityType === 'Multiplicador') && (
            <GDMContainer
              sx={{
                display: isDesktop ? 'flex' : 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                width: '100%',
                background: '#F2F4F9',
                borderBottom: '1px solid #D9DBE4',
                borderRadius: '12px 12px 0 0',
                marginBottom: '24px',
              }}
            >
              <GDMBox
                sx={activeFlap === 'register' ? flapStyleActive : flapStyleDisabled}
                onClick={() => {
                  setActiveFlap('register');
                }}
              >
                <LuNewspaper color={theme.palette.primary.main} />
                <GDMTypography sx={{ fontSize: '18px', color: '#222', fontWeight: 500 }}>
                  {getLabelTranslation('register')}
                </GDMTypography>
              </GDMBox>
              <GDMBox
                sx={activeFlap === 'accounts' ? flapStyleActive : flapStyleDisabled}
                onClick={() => {
                  setActiveFlap('accounts');
                }}
              >
                <PiBriefcaseMetal color={theme.palette.primary.main} />
                <GDMTypography sx={{ fontSize: '18px', color: '#222', fontWeight: 500 }}>
                  {getLabelTranslation('accounts')}
                </GDMTypography>
              </GDMBox>
              <GDMBox
                sx={activeFlap === 'statistics' ? flapStyleActive : flapStyleDisabled}
                onClick={() => {
                  setActiveFlap('statistics');
                }}
              >
                <LiaChartBarSolid color={theme.palette.primary.main} />
                <GDMTypography sx={{ fontSize: '18px', color: '#222', fontWeight: 500 }}>
                  {getLabelTranslation('statistics')}
                </GDMTypography>
              </GDMBox>
            </GDMContainer>
          )}
        {activeFlap === 'register' && (
          <GDMFormFields
            disableForm={viewType === 'view'}
            entityType={checkEntity}
            handlers={handlers}
            config={config}
            errors={errors}
            changeInputValue={changeInputValue}
            cancelForm={handleCancel}
          />
        )}
        {activeFlap === 'accounts' && accountsList.items.length !== 0 && (
          <GDMTableAccounts rows={accountsList.items} filters={filters} setFilters={setFilters} />
        )}

        {activeFlap === 'accounts' && accountsList.items.length === 0 && (
          <GDMTypography sx={{ textAlign: 'center', paddingTop: '24px' }}>
            {getLabelTranslation('this_profile_dont_have_accounts')}
          </GDMTypography>
        )}

        {activeFlap === 'statistics' && (
          <GDMContainer
            sx={{
              display: 'grid',
              gridTemplateColumns: isDesktop ? 'repeat(5, 1fr)' : '1fr',
              gap: '16px',
            }}
          >
            <GDMCard
              title={getLabelTranslation('recommendations_made')}
              percent={false}
              value={statistics.recommendationsMade}
            />
            <GDMCard
              title={getLabelTranslation('recommendations_sale')}
              percent={false}
              value={statistics.sales}
            />
            <GDMCard
              title={getLabelTranslation('recommendations_implemented')}
              value={statistics.recommendationsImplemented}
              percent
            />
            <GDMCard
              title={getLabelTranslation('recommendation_lost')}
              percent
              value={statistics.recommendationsLost}
            />
            <GDMCard
              title={getLabelTranslation('lot_history')}
              percent={false}
              value={statistics.lotHistory}
            />
            <GDMCard
              title={getLabelTranslation('planting')}
              percent={false}
              value={statistics.planting}
            />
            <GDMCard
              title={getLabelTranslation('planted')}
              percent={false}
              value={statistics.planted}
            />
            <GDMCard
              title={getLabelTranslation('in_harvest')}
              percent={false}
              value={statistics.inHarvest}
            />
            <GDMCard
              title={getLabelTranslation('harvested')}
              percent={false}
              value={statistics.harvested}
            />
            <GDMCard
              title={getLabelTranslation('interrupted')}
              percent={false}
              value={statistics.interrupted}
            />
          </GDMContainer>
        )}
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          marginTop: '2rem',
          justifyContent: viewType !== 'view' ? 'flex-end' : 'space-between',
          button: {
            width: '187px',
          },
        }}
      >
        {viewType === 'view' && activeFlap === 'register' && (
          <GDMBox
            sx={{
              display:
                !isDesktop && !(entityType === 'Distribuidor' || entityType === 'Multiplicador')
                  ? 'flex'
                  : 'none',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <GDMButton
              variant="outlined"
              onClick={handleGoBack}
              disabled={loading}
              sx={{ color: theme.palette.secondary.dark }}
            >
              <FaArrowLeftLong style={{ marginRight: '8px' }} />
              {getLabelTranslation('ReusableFormKey_Back')}
            </GDMButton>
            <GDMButton
              variant="outlined"
              onClick={() => {
                setActiveFlap('accounts');
              }}
              disabled={loading}
              sx={{
                color: theme.palette.secondary.dark,
                display:
                  entityType === 'Distribuidor' || entityType === 'Multiplicador' ? 'flex' : 'none',
              }}
            >
              {getLabelTranslation('next_flap')}
              <FaArrowRightLong style={{ marginLeft: '8px' }} />
            </GDMButton>
          </GDMBox>
        )}

        {viewType === 'view' && activeFlap !== 'register' && (
          <GDMBox sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
            <GDMButton
              variant="outlined"
              onClick={handleGoBack}
              disabled={loading}
              sx={{
                color: theme.palette.secondary.dark,
                width: isDesktop ? '187px' : '100% !important',
              }}
            >
              <FaArrowLeftLong style={{ marginRight: '8px' }} />
              {getLabelTranslation('ReusableFormKey_Back')}
            </GDMButton>
          </GDMBox>
        )}
        {viewType === 'view' && activeFlap === 'register' && isDesktop && (
          <GDMBox sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
            <GDMButton
              variant="outlined"
              onClick={handleGoBack}
              disabled={loading}
              sx={{
                color: theme.palette.secondary.dark,
                width: isDesktop ? '187px' : '100% !important',
              }}
            >
              <FaArrowLeftLong style={{ marginRight: '8px' }} />
              {getLabelTranslation('ReusableFormKey_Back')}
            </GDMButton>
          </GDMBox>
        )}
        {isDesktop && viewType !== 'view' && (
          <GDMContainer display="flex" justifyContent="space-around">
            <GDMButton
              sx={{
                mr: 4,
                height: '51px',
                width: isDesktop ? '187px' : '100%',
                color: theme.palette.secondary.dark,
                background: theme.palette.secondary.main,
                boxShadow: 'none',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              disabled={loading}
              onClick={handleCancel}
              variant="contained"
            >
              {getLabelTranslation('ReusableFormKey_Cancel')}
            </GDMButton>
            <GDMButton
              variant="contained"
              onClick={handleSubmit}
              sx={{
                height: '51px',
                width: isDesktop ? '187px' : '100%',
                boxShadow: 'none',
                ':hover': {
                  boxShadow: 'none',
                  background: theme.palette.primary.light,
                },
              }}
              disabled={loading}
            >
              {loading ? (
                <GDMLoading type="circular" {...{ size: 24 }} />
              ) : (
                getLabelTranslation('ReusableFormKey_Save')
              )}
            </GDMButton>
          </GDMContainer>
        )}
      </GDMContainer>
    </GDMContainer>
  );
};

export default GDMDynamicForm;
