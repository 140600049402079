/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { GDMButton, GDMContainer, GDMTypography } from 'components';

import { useMediaQuery } from '@mui/material';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GDMSlider } from 'components/gdm-slider';
import { CiCalendarDate } from 'react-icons/ci';
import { PiCirclesFourLight } from 'react-icons/pi';
import colors from '../../../core/design-system/style-guide/colors.json';

export default function RecommendationChart({
  varietyValue,
  recommendationData,
  chartDateData,
  chartDensityData,
}: any) {
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();

  const displayInterval = () => {
    if (isDesktop) {
      if (chartDateData && chartDateData.length > 200) {
        return 20;
      }
      return 7;
    }
    if (!isDesktop) {
      if (chartDateData && chartDateData.length > 200) {
        return 50;
      }
    }
    return 20;
  };

  const CustomLegendRender = props => {
    const { payload } = props;
    return (
      <GDMContainer
        sx={{
          width: '100%',
          display: 'flex',
          flexWrap: { xs: 'wrap', md: 'unset' },
          rowGap: { xs: '8px', md: '0' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: isDesktop ? '12px' : 0,
          marginTop: '12px',
        }}
      >
        {payload?.map((entry, idx) => {
          const { dataKey } = entry;
          const color = props?.colors[idx];
          return (
            recommendationData?.recommendationVariety[idx]?.genotype && (
              <GDMContainer
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: isDesktop ? 0 : idx < payload.length - 1 ? '6px' : 0,
                }}
              >
                <Surface
                  width={17}
                  height={17}
                  viewBox={{ x: 0, y: 0, height: 10, width: 10 }}
                  style={{ marginRight: isDesktop ? '6px' : '2px' }}
                >
                  <Symbols cx={5} cy={5} type="square" fill={color || '#ccc'} />
                </Surface>
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    textWrap: 'nowrap',
                    color: '#464E5F',
                    overflow: 'hidden',
                  }}
                >
                  {recommendationData?.recommendationVariety[idx]?.genotype}
                </span>
              </GDMContainer>
            )
          );
        })}
      </GDMContainer>
    );
  };

  return (
    <>
      {isDesktop ? (
        <GDMContainer
          id="graph-container"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: 'auto',
            gap: '16px',
          }}
        >
          {recommendationData?.userSowingDateIsDate && (
            <GDMContainer
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '400px',
                margin: '32px 0',
                border: `2px solid #D9DBE4`,
                borderRadius: '24px',
                padding: '16px',
                gap: '16px',
              }}
            >
              <GDMContainer
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: '16px',
                  alignItems: 'center',
                }}
              >
                <CiCalendarDate color={colors.dark2} size={32} />
                <GDMTypography
                  sx={{
                    color: '#222222',
                    fontSize: { xs: '18px !important', md: '18px !important' },
                    fontWeight: '700',
                  }}
                  variant="inherit"
                >
                  {getLabelTranslation('recommendation_graph_planting_date')}
                </GDMTypography>
              </GDMContainer>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={400}
                  height={200}
                  data={chartDateData}
                  margin={{
                    top: 15,
                    right: 15,
                    left: 15,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 0" stroke="#E7E9F1" vertical={false} />
                  <XAxis
                    dataKey="variableT"
                    tickFormatter={(tick, index) => {
                      const item = chartDateData.find(data => data.variableT === tick);
                      return index % displayInterval() === 0
                        ? item
                          ? item.exibitionDate
                          : tick
                        : '';
                    }}
                    interval={0}
                    fontSize={14}
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={tick => {
                      return `${tick}%`;
                    }}
                  />
                  <Tooltip />
                  <Legend
                    iconType="square"
                    content={params => (
                      <CustomLegendRender
                        colors={[
                          theme.palette.primary.main,
                          theme.palette.primary.light,
                          theme.palette.primary[100],
                        ]}
                        {...params}
                      />
                    )}
                  />

                  <Line
                    type="monotone"
                    dataKey="Produtividade_1"
                    name={recommendationData?.recommendationVariety[0]?.genotype}
                    stroke={theme.palette.primary.main}
                    strokeWidth={
                      recommendationData?.recommendationVariety[0]?.id === varietyValue ? 2 : 1
                    }
                    dot={false}
                    activeDot={{ r: 8 }}
                    strokeDasharray={
                      recommendationData?.recommendationVariety[0]?.id === varietyValue ? '' : '5 5'
                    }
                    connectNulls
                  />
                  <Line
                    type="monotone"
                    dataKey="Produtividade_2"
                    name={recommendationData?.recommendationVariety[1]?.genotype}
                    stroke={theme.palette.primary.light}
                    strokeWidth={
                      recommendationData?.recommendationVariety[1]?.id === varietyValue ? 2 : 1
                    }
                    activeDot={{ r: 8 }}
                    dot={false}
                    strokeDasharray={
                      recommendationData?.recommendationVariety[1]?.id === varietyValue ? '' : '5 5'
                    }
                    connectNulls
                  />
                  <Line
                    type="monotone"
                    dataKey="Produtividade_3"
                    name={recommendationData?.recommendationVariety[2]?.genotype}
                    stroke={theme.palette.primary[100]}
                    strokeWidth={
                      recommendationData?.recommendationVariety[2]?.id === varietyValue ? 2 : 1
                    }
                    activeDot={{ r: 8 }}
                    dot={false}
                    strokeDasharray={
                      recommendationData?.recommendationVariety[2]?.id === varietyValue ? '' : '5 5'
                    }
                    connectNulls
                  />
                </LineChart>
              </ResponsiveContainer>
            </GDMContainer>
          )}

          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '400px',
              margin: '32px 0',
              border: `2px solid #D9DBE4`,
              borderRadius: '24px',
              padding: '16px',
              gap: '16px',
            }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                width: '100%',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <PiCirclesFourLight color={colors.dark2} size={32} />
              <GDMTypography
                style={{
                  fontSize: '18px !important',
                  color: '#222222',
                  fontWeight: '700',
                }}
                variant="inherit"
              >
                {getLabelTranslation('planting_density')}
              </GDMTypography>
            </GDMContainer>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={400}
                height={200}
                data={chartDensityData}
                margin={{
                  top: 15,
                  right: 15,
                  left: 15,
                  bottom: 15,
                }}
              >
                <CartesianGrid strokeDasharray="3 0" stroke="#E7E9F1" vertical={false} />
                <XAxis dataKey="density" axisLine={false} tickLine={false} />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={tick => {
                    return `${tick}%`;
                  }}
                />
                <Tooltip />
                <Legend
                  iconType="square"
                  content={params => (
                    <CustomLegendRender
                      colors={[
                        theme.palette.primary.main,
                        theme.palette.primary.light,
                        theme.palette.primary[100],
                      ]}
                      {...params}
                    />
                  )}
                />
                <Line
                  type="monotone"
                  dataKey="Recomendação_1"
                  name={recommendationData?.recommendationVariety[0]?.genotype}
                  strokeWidth={
                    recommendationData?.recommendationVariety[0]?.id === varietyValue ? 2 : 1
                  }
                  stroke={theme.palette.primary.main}
                  activeDot={{ r: 8 }}
                  dot={false}
                  strokeDasharray={
                    recommendationData?.recommendationVariety[0]?.id === varietyValue ? '' : '5 5'
                  }
                  connectNulls
                />
                <Line
                  type="monotone"
                  dataKey="Recomendação_2"
                  name={recommendationData?.recommendationVariety[1]?.genotype}
                  stroke={theme.palette.primary.light}
                  strokeWidth={
                    recommendationData?.recommendationVariety[1]?.id === varietyValue ? 2 : 1
                  }
                  activeDot={{ r: 8 }}
                  dot={false}
                  strokeDasharray={
                    recommendationData?.recommendationVariety[1]?.id === varietyValue ? '' : '5 5'
                  }
                  connectNulls
                />
                <Line
                  type="monotone"
                  dataKey="Recomendação_3"
                  name={recommendationData?.recommendationVariety[2]?.genotype}
                  stroke={theme.palette.primary[100]}
                  strokeWidth={
                    recommendationData?.recommendationVariety[2]?.id === varietyValue ? 2 : 1
                  }
                  activeDot={{ r: 8 }}
                  dot={false}
                  strokeDasharray={
                    recommendationData?.recommendationVariety[2]?.id === varietyValue ? '' : '5 5'
                  }
                  connectNulls
                />
              </LineChart>
            </ResponsiveContainer>
          </GDMContainer>
        </GDMContainer>
      ) : (
        <GDMContainer id="graph-container" sx={{ width: '100%', mt: 6, pb: 8 }}>
          {recommendationData && (
            <GDMSlider>
              {recommendationData?.userSowingDateIsDate && (
                <div className="keen-slider__slide">
                  <GDMContainer
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '400px',
                      border: `2px solid #D9DBE4`,
                      borderRadius: '24px',
                      padding: '6px 16px',
                      gap: '16px',
                    }}
                  >
                    <GDMContainer
                      sx={{
                        display: 'flex',
                        width: '100%',
                        gap: '16px',
                        alignItems: 'center',
                      }}
                    >
                      <CiCalendarDate color={colors.dark2} size={32} />
                      <GDMTypography
                        sx={{
                          color: '#222222',
                          fontSize: '18px!important',
                          fontWeight: '700',
                        }}
                      >
                        {getLabelTranslation('recommendation_graph_planting_date')}
                      </GDMTypography>
                    </GDMContainer>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        width={400}
                        height={200}
                        data={chartDateData}
                        margin={{
                          top: 2,
                          right: 2,
                          left: 2,
                          bottom: 15,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 0" stroke="#E7E9F1" vertical={false} />
                        <XAxis
                          dataKey="variableT"
                          tickFormatter={(tick, index) => {
                            const item = chartDateData.find(data => data.variableT === tick);
                            return index % displayInterval() === 0
                              ? item
                                ? item.exibitionDate
                                : tick
                              : '';
                          }}
                          interval={0}
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={tick => {
                            return `${tick}%`;
                          }}
                        />
                        <Tooltip />
                        <Legend
                          iconType="square"
                          content={params => (
                            <CustomLegendRender
                              colors={[
                                theme.palette.primary.main,
                                theme.palette.primary.light,
                                theme.palette.primary[100],
                              ]}
                              {...params}
                            />
                          )}
                        />

                        <Line
                          type="monotone"
                          dataKey="Produtividade_1"
                          name={recommendationData?.recommendationVariety[0]?.genotype}
                          stroke={theme.palette.primary.main}
                          strokeWidth={
                            recommendationData?.recommendationVariety[0]?.id === varietyValue
                              ? 2
                              : 1
                          }
                          activeDot={{ r: 8 }}
                          dot={false}
                          strokeDasharray={
                            recommendationData?.recommendationVariety[0]?.id === varietyValue
                              ? ''
                              : '5 5'
                          }
                          connectNulls
                        />
                        <Line
                          type="monotone"
                          dataKey="Produtividade_2"
                          name={recommendationData?.recommendationVariety[1]?.genotype}
                          stroke={theme.palette.primary.light}
                          strokeWidth={
                            recommendationData?.recommendationVariety[1]?.id === varietyValue
                              ? 2
                              : 1
                          }
                          activeDot={{ r: 8 }}
                          dot={false}
                          strokeDasharray={
                            recommendationData?.recommendationVariety[1]?.id === varietyValue
                              ? ''
                              : '5 5'
                          }
                          connectNulls
                        />
                        <Line
                          type="monotone"
                          dataKey="Produtividade_3"
                          name={recommendationData?.recommendationVariety[2]?.genotype}
                          stroke={theme.palette.primary[100]}
                          strokeWidth={
                            recommendationData?.recommendationVariety[2]?.id === varietyValue
                              ? 2
                              : 1
                          }
                          activeDot={{ r: 8 }}
                          dot={false}
                          strokeDasharray={
                            recommendationData?.recommendationVariety[2]?.id === varietyValue
                              ? ''
                              : '5 5'
                          }
                          connectNulls
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </GDMContainer>
                </div>
              )}
              <div className="keen-slider__slide">
                <GDMContainer
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '400px',
                    border: `2px solid #D9DBE4`,
                    borderRadius: '24px',
                    padding: '6px 16px',
                    gap: '16px',
                  }}
                >
                  <GDMContainer
                    sx={{
                      display: 'flex',
                      width: '100%',
                      gap: '16px',
                      alignItems: 'center',
                    }}
                  >
                    <PiCirclesFourLight color={colors.dark2} size={32} />
                    <GDMTypography
                      sx={{
                        color: '#222222',
                        fontSize: '18px!important',
                        fontWeight: '700',
                      }}
                    >
                      {getLabelTranslation('planting_density')}
                    </GDMTypography>
                  </GDMContainer>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      width={400}
                      height={200}
                      data={chartDensityData}
                      margin={{
                        top: 2,
                        right: 2,
                        left: 2,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 0" stroke="#E7E9F1" vertical={false} />
                      <XAxis dataKey="density" axisLine={false} tickLine={false} />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={tick => {
                          return `${tick}%`;
                        }}
                      />
                      <Tooltip />
                      <Legend
                        iconType="square"
                        content={params => (
                          <CustomLegendRender
                            colors={[
                              theme.palette.primary.main,
                              theme.palette.primary.light,
                              theme.palette.primary[100],
                            ]}
                            {...params}
                          />
                        )}
                      />
                      <Line
                        type="monotone"
                        dataKey="Recomendação_1"
                        name={recommendationData?.recommendationVariety[0]?.genotype}
                        strokeWidth={
                          recommendationData?.recommendationVariety[0]?.id === varietyValue ? 2 : 1
                        }
                        stroke={theme.palette.primary.main}
                        activeDot={{ r: 8 }}
                        dot={false}
                        strokeDasharray={
                          recommendationData?.recommendationVariety[0]?.id === varietyValue
                            ? ''
                            : '5 5'
                        }
                        connectNulls
                      />
                      <Line
                        type="monotone"
                        dataKey="Recomendação_2"
                        name={recommendationData?.recommendationVariety[1]?.genotype}
                        stroke={theme.palette.primary.light}
                        strokeWidth={
                          recommendationData?.recommendationVariety[1]?.id === varietyValue ? 2 : 1
                        }
                        activeDot={{ r: 8 }}
                        dot={false}
                        strokeDasharray={
                          recommendationData?.recommendationVariety[1]?.id === varietyValue
                            ? ''
                            : '5 5'
                        }
                        connectNulls
                      />
                      <Line
                        type="monotone"
                        dataKey="Recomendação_3"
                        name={recommendationData?.recommendationVariety[2]?.genotype}
                        stroke={theme.palette.primary[100]}
                        strokeWidth={
                          recommendationData?.recommendationVariety[2]?.id === varietyValue ? 2 : 1
                        }
                        activeDot={{ r: 8 }}
                        dot={false}
                        strokeDasharray={
                          recommendationData?.recommendationVariety[2]?.id === varietyValue
                            ? ''
                            : '5 5'
                        }
                        connectNulls
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </GDMContainer>
              </div>
            </GDMSlider>
          )}
        </GDMContainer>
      )}
    </>
  );
}
