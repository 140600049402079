import { GDMContainer } from 'components';
import { Skeleton } from '@mui/material';

export default function SkeletonParams() {
  const skeletonCount = 4;
  const Skeletons = Array.from({ length: skeletonCount }, (_, index) => (
    <GDMContainer
      key={`skeleton-${index}`}
      component="div"
      sx={{
        gap: 1,
        marginTop: 1,
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #A1A9B8',
        padding: 2,
        width: '100%',
        height: '191px',
      }}
    >
      <GDMContainer
        component="div"
        sx={{
          gap: 1,
          display: 'flex',
          alignItems: 'center',
          borderRadius: '24px',
        }}
      >
        <Skeleton
          sx={{
            width: '100%',
            height: '36px',
          }}
        />
      </GDMContainer>
      <GDMContainer
        component="div"
        sx={{
          gap: 1,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Skeleton
          sx={{
            width: '100%',
            height: '60px',
          }}
        />

        <Skeleton
          sx={{
            width: '50%',
            height: '18px',
          }}
        />
      </GDMContainer>
    </GDMContainer>
  ));

  return Skeletons;
}
