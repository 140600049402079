import { UsersAdministratorService } from 'core/api/services/administrator';

import { AdminListLoadAdminsFunction } from './adminListLoadAdmins.type';

const adminListLoadAdmins: AdminListLoadAdminsFunction = async ({
  setIsLoading,
  adminListConfig,
  setAdminListTableData,
  setHasError,
}) => {
  try {
    const response = await UsersAdministratorService.getAdmins(adminListConfig);

    setHasError(false);
    setAdminListTableData({
      items: response.items,
      totalPages: response.totalPages,
    });
  } catch (error) {
    console.error(error);
    setHasError(true);
  } finally {
    setIsLoading(false);
  }
};

export default adminListLoadAdmins;
