import { useMediaQuery, useTheme } from '@mui/material';
import {
  GDMButton,
  GDMContainer,
  GDMGrid,
  GDMLoading,
  GDMLotList,
  GDMTypography,
} from 'components';
import { GDMFarmerRegisterForm } from 'components/gdm-farmer-register-form';
import { FarmService } from 'core/api/services/farm';
import { FarmerService } from 'core/api/services/farmer';
import { LocationsService } from 'core/api/services/locations';
import { LotsService } from 'core/api/services/lot';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { IFarmerFormData, IFormData } from 'pages/farmer-register/FarmerRegister.type';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { IoAdd, IoArrowBack } from 'react-icons/io5';
import { PiFarm } from 'react-icons/pi';
import { RiPlantLine } from 'react-icons/ri';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { containerStyle, flapStyleActive, flapStyleDisabled } from './FarmForm.style';
import { GDMFarmList } from './components/GDMFarmList/GDMFarmList';
import { validateDocument, validateEmail, validatePhone } from './utils';

const initialStateFormDataFarmer = {
  name: '',
  contactEmail: '',
  phoneNumber: '',
  documentNumber: '',
  documentTypeId: '',
  countryId: '',
  address: '',
  stateId: '0',
  cityId: '0',
  totalPlantedArea: '',
};

export default function FarmerForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState({ title: '', subtitle: '' });
  const [activeFlap, setActiveFtep] = useState('farmer');
  const [countries, setCountries] = useState([]);
  const [viewType, setViewType] = useState('view');
  const [country, setCountry] = useState(CountriesDomainEnum.Brasil as string);
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState<ObjectLiteral<string>>({});
  const { getLabelTranslation, translationsDictionary } = useLanguageSwitcher();
  const [orderColumn, setOrderColumn] = useState({
    flap: '',
    orderDesc: false,
    column: '',
    entityName: '',
  });
  const { openModal, closeModal } = useModal();
  const { user } = useStore(UserProfileStore);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  let submit;
  const [formDataFarmer, setFormDataFarmer] = useState<IFarmerFormData>(initialStateFormDataFarmer);

  const { farmerId } = useParams();

  const handleChangeFlap = flap => {
    setOrderColumn({ column: '', flap, orderDesc: false, entityName: '' });
    setActiveFtep(flap);
  };
  const getFarmer = ({
    farmerId,
    entityName,
    columnName,
    orderDesc,
  }: {
    farmerId: string;
    entityName?: string;
    columnName?: string;
    orderDesc?: boolean;
  }) => {
    setIsLoading(true);
    FarmerService.getFarmerById({ id: farmerId, columnName, entityName, orderDesc })
      .then(response => {
        const ufId = { target: { value: response.stateId } };
        const cityId = { target: { value: response.cityId } };
        setFormDataFarmer({
          address: response.address,
          cityId: response.cityId,
          contactEmail: response.contactEmail,
          countryId: response.countryId,
          documentNumber: response.documentNumber,
          documentTypeId: response.documentTypeId,
          name: response.name,
          phoneNumber: response.phoneNumber,
          stateId: response.stateId,
          totalPlantedArea: response.totalPlantedArea,
          stateName: response.stateName,
          cityName: response.cityName,
          countryName: response.countryName,
          lots: response.farmerLots.lots,
          farms: response.farmerFarms.farms,
        });
        handleStateChangeFarmer(ufId);
        handleCityChangeFarmer(cityId);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleUpdateCloseModal = () => {
    navigate(-1);
    closeModal();
  };

  const exit = () => {
    navigate(-1);
    closeModal();
  };

  useEffect(() => {
    if (viewType === 'edit') {
      setTitle(() => ({
        subtitle: '',
        title: getLabelTranslation('edit_farmer'),
      }));
    } else if (activeFlap === 'farmer') {
      setTitle(() => ({
        title: getLabelTranslation('see_farmer'),
        subtitle: 'Cadastro do',
      }));
    } else if (activeFlap === 'farm') {
      setTitle(() => ({
        title: 'Ver Cadastro de Fazenda',
        subtitle: 'Fazendas do',
      }));
    } else if (activeFlap === 'lot') {
      setTitle(() => ({
        title: 'Ver Cadastro de Lote',
        subtitle: 'Lotes das Fazendas do',
      }));
    }
  }, [activeFlap, translationsDictionary]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesList = await LocationsService.getCountries();
        setCountries(countriesList);

        const selectedCountryId =
          user.countryName === CountriesDomainEnum.Brasil
            ? countriesList[0].id
            : countriesList[1].id;
        setFormDataFarmer(prev => ({ ...prev, countryId: selectedCountryId }));
        setCountry(user.countryName);

        const liststates = await LocationsService.getStates(selectedCountryId);
        setStates(liststates);

        setFormDataFarmer(prev => ({
          ...prev,
          countryId: selectedCountryId,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [cities, user]);

  useEffect(() => {
    getFarmer({ farmerId });
  }, [farmerId]);

  useEffect(() => {
    getFarmer({
      farmerId,
      columnName: orderColumn.column,
      entityName: orderColumn.entityName,
      orderDesc: orderColumn.orderDesc,
    });
  }, [orderColumn]);

  const handleFarmerFormSubmit = async () => {
    validateEntireForm(formDataFarmer).then(async hasError => {
      if (!hasError) {
        try {
          const formDataToSend = new FormData();
          formDataToSend.append('name', formDataFarmer.name);
          formDataToSend.append('contactEmail', formDataFarmer.contactEmail);
          formDataToSend.append('phoneNumber', formDataFarmer.phoneNumber);
          formDataToSend.append('documentTypeId', await setDocId());
          formDataToSend.append('address', formDataFarmer.address);
          formDataToSend.append('countryId', formDataFarmer.countryId);
          formDataToSend.append('stateId', formDataFarmer.stateId);
          formDataToSend.append('cityId', formDataFarmer.cityId);
          formDataToSend.append('totalPlantedArea', `${formDataFarmer.totalPlantedArea}`);
          formDataToSend.append('documentNumber', formDataFarmer.documentNumber.replace(/\D/g, ''));

          const id = farmerId as any;
          await FarmerService.updateFarmer(formDataToSend, { id });
          openModal({
            type: 'success',
            title: getLabelTranslation('updated_success'),
            onClose: handleUpdateCloseModal,
          });
        } catch (error) {
          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
        }
      }
    });
  };

  const handleStateChangeFarmer = event => {
    const stateId = event.target.value;
    setFormDataFarmer(prev => ({ ...prev, stateId }));
    if (stateId !== '0') {
      const fetchData = async () => {
        try {
          const citiesList = await LocationsService.getCities(stateId);
          const error = validateFields('stateId', stateId);
          if (error.length === 0) {
            setErrors(e => {
              delete e.ufId;
              return { ...errors };
            });
          } else {
            setErrors(e => ({ ...e, ufId: error }));
          }

          setCities(citiesList);
        } catch (error) {
          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
        }
      };
      fetchData();
    } else {
      setCities([]);
    }
  };

  const handleCityChangeFarmer = event => {
    const cityId = event.target.value;
    const error = validateFields('cityId', cityId);
    if (error.length === 0) {
      setErrors(errors => {
        delete errors.cityId;
        return { ...errors };
      });
    } else {
      setErrors(errors => ({ ...errors, cityId: error }));
    }

    setFormDataFarmer(prev => ({ ...prev, cityId }));
  };

  const validateFields = (fieldName, fieldValue) => {
    if (!fieldValue && fieldName !== 'totalArea') return getLabelTranslation('required_field');

    if (fieldName === 'documentNumber') {
      if (!validateDocument(fieldValue, user.countryName)) {
        return getLabelTranslation('register_farmer_document_error');
      }
    }

    if (fieldName === 'phoneNumber') {
      if (!validatePhone(fieldValue, user.countryName)) {
        return getLabelTranslation('register_farmer_phone_error');
      }
    }

    if (fieldName === 'contactEmail') {
      if (!validateEmail(fieldValue)) {
        return getLabelTranslation('register_farmer_email_error');
      }
    }

    if (fieldName === 'totalPlantedArea') {
      if (parseInt(fieldValue, 10) <= 0) {
        return getLabelTranslation('register_farmer_area_error');
      }
    }

    if ((fieldName === 'stateId' || fieldName === 'cityId') && fieldValue === '0') {
      return getLabelTranslation('required_field');
    }

    return '';
  };

  const validateEntireForm = form => {
    return new Promise<boolean>(resolve => {
      const keys = Object.keys(form) as (keyof IFormData)[];
      let hasError = false;
      keys.forEach(key => {
        const error = validateFields(key, form[key]);

        if (error.length === 0) {
          setErrors(errors => {
            delete errors[key];
            return { ...errors };
          });
        } else {
          setErrors(errors => ({ ...errors, [key]: error }));
          hasError = true;
        }
      });
      resolve(hasError);
    });
  };

  const setDocId = async () => {
    const docTypes = await LocationsService.getDocumentTypes();
    const cleanedNumber = formDataFarmer.documentNumber.replace(/\D/g, '').length;
    if (user.countryName === CountriesDomainEnum.Argentina) {
      const cuit = docTypes.find(doc => doc.detail === 'CUIT').id;
      return cuit;
    }
    if (cleanedNumber === 14) {
      const cnpj = docTypes.find(doc => doc.detail === 'CNPJ').id;
      return cnpj;
    }
    const cpf = docTypes.find(doc => doc.detail === 'CPF').id;
    return cpf;
  };

  const handleChangeValuesFarmer = useCallback(
    event => {
      const { name: field, value } = event.target;

      const error = validateFields(field, value);

      if (error.length === 0) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: error }));
      }

      setFormDataFarmer(prev => ({
        ...prev,
        [field]: value,
        documentTypeId: user.documentTypeId,
      }));
    },
    [country],
  );

  const handleGetFarmerAfterDeleteFarm = () => {
    getFarmer({ farmerId });
  };

  const openDeleteModalFarm = (id, canBeDeleted) => {
    const handleDelete = () => {
      closeModal();
      if (canBeDeleted) {
        FarmService.deleteFarm({ id }).then(() => {
          openModal({
            type: 'success',
            title: getLabelTranslation('modal_tittle_delete_success'),
            onClose: handleGetFarmerAfterDeleteFarm,
          });
        });
      } else {
        openModal({
          type: 'error',
          title: getLabelTranslation('error_on_delete_register'),
          message: getLabelTranslation('error_on_delete'),
          onClose: closeModal,
        });
      }
    };
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_confirm_question'),
      message: getLabelTranslation('modal_alert'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: handleDelete,
      },
      secondAction: {
        label: getLabelTranslation('cancel'),
        action: closeModal,
      },
    });
  };

  const openDeleteModalLot = (id, canBeDeleted) => {
    const handleDelete = () => {
      closeModal();
      if (canBeDeleted) {
        LotsService.deleteLot({ id }).then(() => {
          openModal({
            type: 'success',
            title: getLabelTranslation('modal_tittle_delete_success'),
            onClose: handleGetFarmerAfterDeleteFarm,
          });
        });
      } else {
        openModal({
          type: 'error',
          title: getLabelTranslation('error_on_delete_register'),
          message: getLabelTranslation('error_on_delete'),
          onClose: closeModal,
        });
      }
    };
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_confirm_question'),
      message: getLabelTranslation('modal_alert'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: handleDelete,
      },
      secondAction: {
        label: getLabelTranslation('cancel'),
        action: closeModal,
      },
    });
  };
  useEffect(() => {
    if (location.pathname.includes('farm-flap')) {
      setActiveFtep('farm');
      setViewType('view');
    }
    if (location.pathname.includes('edit')) {
      setViewType('edit');
    } else {
      setViewType('view');
    }
  }, []);
  return (
    <GDMContainer
      component="form"
      onSubmit={submit}
      sx={containerStyle}
      {...{ noValidate: true, autoComplete: 'off' }}
    >
      <GDMTypography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: '#A1A9B8', fontSize: '14px' }}
      >
        {viewType === 'edit' &&
          `${getLabelTranslation('see_register')} > ${getLabelTranslation(
            'farmer',
          )} > ${getLabelTranslation('to_edit')}`}
        {viewType === 'view' &&
          `${getLabelTranslation('see_register')} > ${getLabelTranslation('farmer')}`}
      </GDMTypography>

      <GDMTypography
        variant="h4"
        sx={{
          fontWeight: '700',
          color: '#222',
          marginBottom: 0,
          fontSize: isDesktop ? '32px' : '28px',
          textAlign: 'start',
        }}
        gutterBottom
      >
        {activeFlap === 'farmer' && viewType === 'view' && getLabelTranslation('see_farmer')}
        {(activeFlap === 'farm' || activeFlap === 'lot') &&
          viewType === 'view' &&
          getLabelTranslation('view_farm_registration')}
        {viewType === 'edit' && getLabelTranslation('edit_farmer')}
      </GDMTypography>

      {viewType !== 'edit' && (
        <GDMTypography sx={{ marginBottom: '32px', textAlign: 'start' }}>
          {activeFlap === 'farmer' &&
            `${getLabelTranslation('register')} ${getLabelTranslation('of_the')} `}
          {activeFlap === 'farm' &&
            `${getLabelTranslation('farms')}  ${getLabelTranslation('of_the')} `}
          {activeFlap === 'lot' &&
            `${getLabelTranslation('lot_of_farms')}  ${getLabelTranslation('of_the')} `}
          <strong>{formDataFarmer.name}</strong>
        </GDMTypography>
      )}

      {viewType === 'view' && (
        <GDMContainer
          sx={{
            width: '100%',
            background: '#F2F4F9',
            display: isDesktop ? 'flex' : 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            borderRadius: '8px 8px 0 0',
            marginBottom: '36px',
          }}
        >
          <GDMGrid
            item
            xs={6}
            md={8}
            sx={activeFlap === 'farmer' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              handleChangeFlap('farmer');
            }}
          >
            <RiPlantLine color="#222" fontSize={24} />
            <GDMTypography
              sx={{ fontSize: '16px', fontWeight: isDesktop ? 500 : 700, color: '#222' }}
            >
              {getLabelTranslation('agricultor')}
            </GDMTypography>
          </GDMGrid>{' '}
          <GDMGrid
            item
            xs={6}
            md={8}
            sx={activeFlap === 'farm' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              handleChangeFlap('farm');
            }}
          >
            <PiFarm color="#222" fontSize={24} />
            <GDMTypography sx={{ fontSize: '16px', color: '#222' }}>
              {getLabelTranslation('farm')}
            </GDMTypography>
          </GDMGrid>{' '}
          <GDMGrid
            item
            xs={6}
            md={8}
            sx={activeFlap === 'lot' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              handleChangeFlap('lot');
            }}
          >
            <HiOutlineSquares2X2 color="#222" fontSize={24} />
            <GDMTypography sx={{ fontSize: '16px', color: '#222' }}>
              {getLabelTranslation('lot')}
            </GDMTypography>
          </GDMGrid>
        </GDMContainer>
      )}

      <GDMGrid
        container
        spacing={2}
        sx={{
          flex: 1,
          flexWrap: 'wrap',
          flexDirection: activeFlap === 'farmer' && !isLoading && isDesktop ? 'row' : 'column',
        }}
      >
        {activeFlap === 'farmer' && !isLoading && (
          <GDMFarmerRegisterForm
            user={user}
            errors={errors}
            states={states}
            cities={cities}
            viewType={viewType}
            countries={countries}
            formData={formDataFarmer}
            handleChangeValues={handleChangeValuesFarmer}
            handleStateChange={handleStateChangeFarmer}
            handleCityChange={handleCityChangeFarmer}
          />
        )}

        {activeFlap === 'farm' && !isLoading && (
          <GDMFarmList
            farms={formDataFarmer.farms}
            openModal={openDeleteModalFarm}
            orderColumn={orderColumn}
            setOrderColumn={setOrderColumn}
          />
        )}
        {activeFlap === 'lot' && !isLoading && (
          <GDMLotList
            lots={formDataFarmer.lots}
            type="farmer"
            openDeleteModal={openDeleteModalLot}
            orderColumn={orderColumn}
            setOrderColumn={setOrderColumn}
          />
        )}
        {isLoading && (
          <GDMContainer
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '12%',
              paddingBottom: '12%',
            }}
          >
            <GDMLoading />
          </GDMContainer>
        )}
        {viewType !== 'view' ? (
          <GDMGrid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-end"
            sx={{
              marginTop: '22px',
              marginBottom: '0',
              flex: 1,
              width: '95%',
              flexWrap: 'wrap',
              padding: !isDesktop && 0,
              marginLeft: !isDesktop ? '18px' : '16px',
              gap: !isDesktop ? '12px' : '27px',
              flexDirection: isDesktop ? 'row' : 'column',
            }}
          >
            <GDMButton
              onClick={() => {
                openModal({
                  type: 'warning',
                  title: getLabelTranslation('warning_exit'),
                  message: getLabelTranslation('modal_message_exit_warning'),
                  primaryAction: {
                    label: getLabelTranslation('exit'),
                    action: exit,
                  },
                  secondAction: {
                    label: getLabelTranslation('cancel'),
                    action: closeModal,
                  },
                });
              }}
              sx={{
                mr: 4,
                height: '51px',
                flex: !isDesktop && 1,
                width: isDesktop ? '187px' : '100%',
                color: theme.palette.secondary.dark,
                background: theme.palette.secondary.main,
                boxShadow: 'none',
                marginRight: !isDesktop && 0,
                ':hover': {
                  background: theme.palette.primary.light,
                  boxShadow: 'none',
                },
                '&.Mui-disabled': {
                  background: theme.palette.action.disabled,
                  color: 'white',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('ReusableFormKey_Cancel')}
            </GDMButton>
            <GDMButton
              variant="contained"
              onClick={() => {
                submit = handleFarmerFormSubmit();
              }}
              sx={{
                height: '51px',
                flex: !isDesktop && 1,
                width: isDesktop ? '187px' : '100%',
                background: theme.palette.primary.main,
                boxShadow: 'none',
                ':hover': {
                  boxShadow: 'none',
                  background: theme.palette.primary.dark,
                },
                '&.Mui-disabled': {
                  background: theme.palette.action.disabled,
                  color: 'white',
                },
              }}
            >
              {getLabelTranslation('ReusableFormKey_Save')}
            </GDMButton>
          </GDMGrid>
        ) : (
          <GDMGrid
            container
            spacing={2}
            alignItems="end"
            justifyContent="space-between"
            display="flex"
            sx={{
              marginTop: isDesktop ? '50px' : '32px',
              marginBottom: '0',
              marginLeft: 0,
              paddingLeft: '16px',
              flex: 1,
              width: '100%',
              flexWrap: 'wrap',
              flexDirection: isDesktop ? 'row' : 'column-reverse',
              gap: isDesktop ? '0' : '32px',
            }}
          >
            <GDMButton
              onClick={() => {
                navigate('/farmer-list');
              }}
              sx={{
                mr: 4,
                height: '51px',
                width: isDesktop ? '187px' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                display: 'flex',
                marginRight: 0,
                alignItems: 'center',
                gap: '16px',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="outlined"
            >
              <IoArrowBack />
              {getLabelTranslation('back')}
            </GDMButton>
            {activeFlap !== 'lot' && (
              <GDMButton
                onClick={() => {
                  navigate(`/farmer-register/${farmerId}/farm-step`);
                }}
                sx={{
                  mr: 4,
                  height: '51px',
                  width: isDesktop ? '200px' : '100%',
                  color: theme.palette.secondary.dark,
                  background: 'transparent',
                  boxShadow: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  gap: '8px',
                  marginRight: 0,
                  ':hover': {
                    background: theme.palette.secondary.light,
                    boxShadow: 'none',
                  },
                }}
                variant="outlined"
              >
                <IoAdd style={{ fontSize: '24px' }} color="#222" />
                {getLabelTranslation('register_farm')}
              </GDMButton>
            )}
          </GDMGrid>
        )}
      </GDMGrid>
    </GDMContainer>
  );
}
