import { Navigate } from 'react-router-dom';

import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';

import { useIsAuthenticated } from '@azure/msal-react';
import { ReactElement } from 'react';

type ProtectedRouteProps = {
  children: JSX.Element;
  requiredPermissions?: string[];
};

const permissionsMap = {
  ADMINISTRATOR: ['admin', 'seller-gdm', 'multiplier', 'distributor', 'account', 'list', 'farmer'],
  SELLER_GDM: ['seller-gdm', 'list', 'multiplier', 'distributor', 'account', 'farmer'],
  MULTIPLIER_MANAGER: ['multiplier', 'list'],
  DISTRIBUTOR_MANAGER: ['distributor', 'list'],
  MULTIPLIER_SELLER: ['seller-gdm', 'multiplier', 'list'],
  DISTRIBUTOR_SELLER: ['seller-gdm', 'distributor', 'list'],
  Agricultor: ['farmer', 'list'],
};

export function ProtectedRoute({
  children,
  requiredPermissions,
}: ProtectedRouteProps): ReactElement {
  const isAuthenticated = useIsAuthenticated();
  const { user } = useStore(UserProfileStore);

  if (!isAuthenticated) {
    return null;
  }

  if (requiredPermissions) {
    const userPermissions = permissionsMap[user.role] || [];
    const hasPermission = requiredPermissions.filter(permission =>
      userPermissions.includes(permission),
    );

    if (!hasPermission) {
      return <Navigate to="/" />;
    }
  }

  return children;
}
