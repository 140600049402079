import { Box, Button, Typography } from '@mui/material';
import { UserProfileService } from 'core/api/services/user';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { acceptTermsUser } from 'core/store/stores';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import termImage from '../../assets/images/termofuser.png';
import { brandContainer, textStyles } from './TermOfUser.styles';

export type Language = 'pt-BR' | 'es-AR';

export default function TermOfUser() {
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();
  const { user } = useStore(UserProfileStore);
  const [urlPDF, setUrlPDF] = useState();
  const countryId = user.countryId;
  const { getLabelTranslation, switchLanguage } = useLanguageSwitcher();

  const handleAccept = () => {
    UserProfileService.termsAcceptance().then(() => {
      acceptTermsUser(true);
      navigate('/');
    });
  };

  useEffect(() => {
    UserProfileService.userTerms(user.countryId).then(response => {
      if (response) {
        setUrlPDF(response.cdnUrl);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  return (
    <Box sx={brandContainer}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1.3fr 1fr',
          padding: '30px 40px 30px 40px',
          height: '100%',
          '@media (max-width: 1000px)': { gridTemplateColumns: '1fr' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
            paddingRight: '40px',
            textAlign: 'justify',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: '700' }}>
            {getLabelTranslation('welcome')}
          </Typography>

          <Typography sx={textStyles}>{getLabelTranslation('welcome_seed_guide')}</Typography>

          <Typography sx={textStyles}>{getLabelTranslation('explore_features')}</Typography>

          <Typography sx={textStyles}>
            {getLabelTranslation('use_we_service')}{' '}
            <strong>{getLabelTranslation('get_and_use_data')}</strong>
            <br />
            {getLabelTranslation('like_we')} <strong>{getLabelTranslation('privity_poli')}</strong>,{' '}
            {getLabelTranslation('include')}{' '}
            <strong>{getLabelTranslation('cookies_to_improve')}</strong>
          </Typography>

          <Typography sx={textStyles}>
            {getLabelTranslation('see_more_details')}{' '}
            <strong>{`"${getLabelTranslation('user_manual')}"`}</strong>
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center',
              input: { width: '30px', ':checked': { color: '#1FCA6E' } },
              paddingTop: '32px',
            }}
          >
            <input
              type="checkbox"
              value="terms"
              checked={accepted}
              onChange={({ target }) => setAccepted(target.checked)}
              style={{ width: '20px', height: '20px' }}
            />
            <Typography sx={{ fontSize: '20px', textAlign: 'justify' }}>
              {getLabelTranslation('i_agree')}{' '}
              <a href={urlPDF} target="_blank" rel="noopener noreferrer">
                {getLabelTranslation('policies')}
                {getLabelTranslation('of_privity')}
              </a>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box
            sx={{
              borderLeft: '1px solid #CBCDD9',
              paddingLeft: '40px',
              marginTop: '120px',
              img: {
                width: '600px',
              },
              '@media (max-width: 1000px)': { display: 'none' },
            }}
          >
            <img src={termImage} alt="Term Of User" />
          </Box>

          <Box sx={{ alignSelf: 'end', display: 'flex', gap: '20px' }}>
            <Button
              onClick={handleAccept}
              disabled={!accepted}
              variant="contained"
              sx={{
                padding: '12px 32px',
                background: '#06C270',
                display: 'flex',
                alignItems: 'center',
                color: '#fff',
                gap: '20px',
                ':hover': {
                  background: '#0Fb270',
                },
              }}
            >
              <Typography sx={{ fontSize: '18px' }}>{getLabelTranslation('next')}</Typography>
              <FaArrowRight />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
