import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';

import { Collapse, Divider, Skeleton, Typography, useMediaQuery } from '@mui/material';

import {
  GDMBox,
  GDMButton,
  GDMContainer,
  GDMDivider,
  GDMLink,
  GDMLoading,
  GDMSecureLink,
  GDMTypography,
} from 'components';
import {
  TbArrowLeft,
  TbCloudDown,
  TbCloudUpload,
  TbDownload,
  TbFileText,
  TbTrash,
} from 'react-icons/tb';

import { RecommendationService } from 'core/api/services/recommendation';

import useModal from 'hooks/useModal/useModal';
import { FiUploadCloud } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import generatePDF from 'react-to-pdf';
import colors from '../../../core/design-system/style-guide/colors.json';
import ParamsForm from '../ParamsForm/ParamsForm';
import RecommendationChart from '../RecommendationChart/RecommendationChart';
import RecommendationPageForm from '../RecommendationForm/RecommendationPageForm';
import RecommendationToDownload from '../RecommendationToDownload/RecommendationToDownload';
import RecommendationVarieties from '../RecommendationVarieties/RecommendationVarieties';

export default function RecommendationViewPage(): JSX.Element {
  const { theme } = useThemeSwitcher();
  const { recId } = useParams();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const [recommendationData, setRecommendationData] = useState<any>();
  const { openModal, closeModal } = useModal();
  const [chartDateData, setChartDateData] = useState([]);
  const [chartDensityData, setChartDensityData] = useState([]);
  const [varietyValue, setVarietyValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [editedFields] = useState({});
  const [fieldsValue] = useState([]);
  const [saveRecommendationData, setSaveRecommendationData] = useState({
    fixedVariables: [],
    environmentalVariables: [],
  });
  const [updateData, setUpdateData] = useState({
    brandId: '',
    cultureId: '',
    lotId: '',
    parameters: {},
  });
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleScroll = () => {
    document.getElementById('file-container')?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDeleteFile = id => {
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_title_delete_file'),
      message: getLabelTranslation('modal_message_irreversible'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: () => {
          handleDeleteFileService(id);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('button_cancel'),
        action: closeModal,
      },
    });
  };

  const handleDeleteRecommendation = id => {
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_title_delete_recommendation'),
      message: getLabelTranslation('modal_message_irreversible'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: () => {
          handleDeleteRecommendationService(id);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('button_cancel'),
        action: closeModal,
      },
    });
  };

  const handleDeleteFileService = async id => {
    try {
      await RecommendationService.deleteRecommendationFile(id);

      const newData = {
        ...recommendationData,
        recommendationFile: recommendationData.recommendationFile.filter(x => x.id !== id),
      };
      setRecommendationData(newData);
      openModal({
        type: 'success',
        title: getLabelTranslation('modal_title_delete_file_success'),
      });
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_title_delete_file_error'),
        message: getLabelTranslation('modal_message_delete_file_error'),
        onClose: closeModal,
      });
    }
  };

  const handleDeleteRecommendationService = async id => {
    try {
      await RecommendationService.deleteRecommendation(id);

      openModal({
        type: 'success',
        title: getLabelTranslation('modal_title_delete_recommendation_success'),
      });
      navigate('/recommendation-list');
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_title_delete_recommendation_error'),
        message: getLabelTranslation('modal_message_delete_recommendation_error'),
        onClose: closeModal,
      });
    }
  };

  const handleDownloadRecommendation = async () => {
    try {
      setIsLoading(true);
      await RecommendationService.getRecommendationToDownload(recommendationData?.id).then(() => {
        const containerToDownload = () => document.getElementById('recommendation-to-download');

        generatePDF(containerToDownload, {
          method: 'open',
          filename: `${recommendationData.name}Gld.pdf`,
        });
        setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = async event => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const filesToSend = Array.from(event.target.files) as File[];

        const formData = new FormData();
        formData.append('RecommendationId', recommendationData?.id);
        filesToSend.forEach(file => {
          formData.append('files', file);
        });

        await RecommendationService.addFileRecommendation(formData);

        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Limpa o campo de seleção de arquivos
        }

        await fetchData();

        openModal({
          type: 'success',
          title: getLabelTranslation('modal_title_add_file_success'),
        });
      }
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_title_add_file_error'),
        message: getLabelTranslation('modal_message_add_file_error'),
        onClose: closeModal,
      });
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await RecommendationService.getRecommendationById(recId);
      setRecommendationData(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [recId]);

  const convertToSaveInitialData = () => {
    setSaveRecommendationData({
      fixedVariables: [],
      environmentalVariables: [],
    });
    if (recommendationData?.technologies?.length > 0) {
      if (!recommendationData?.technology) {
        const tech = {
          id: 'tech',
          name: 'tech',
          type: 'string',
          value: '',
        };
        setSaveRecommendationData(prev => ({
          ...prev,
          fixedVariables: [...prev.fixedVariables, tech],
        }));
      } else {
        const tech = {
          id: 'tech',
          name: 'tech',
          type: 'string',
          value: recommendationData?.technology,
        };
        setSaveRecommendationData(prev => ({
          ...prev,
          fixedVariables: [...prev.fixedVariables, tech],
        }));
      }
    }

    if (recommendationData?.productivity) {
      const productivity = {
        id: 'productivity',
        name: 'productivity',
        type: 'number',
        value: recommendationData?.productivity,
        legend: 'kg/ha',
      };

      setSaveRecommendationData(prev => ({
        ...prev,
        environmentalVariables: [...prev.environmentalVariables, productivity],
      }));
    }

    if (recommendationData?.plantingDensity) {
      const plantingDensity = {
        id: 'plantingDensity',
        name: 'plantingDensity',
        type: 'number',
        value: recommendationData?.plantingDensity,
        legend: 'pl/m²',
      };
      setSaveRecommendationData(prev => ({
        ...prev,
        environmentalVariables: [...prev.environmentalVariables, plantingDensity],
      }));
    }

    if (recommendationData?.userSowingDate) {
      const userSowingDate = {
        id: 'userSowingDate',
        name: 'userSowingDate',
        type: 'string',
        value: recommendationData?.userSowingDate,
      };
      setSaveRecommendationData(prev => ({
        ...prev,
        fixedVariables: [...prev.fixedVariables, userSowingDate],
      }));
    }
    const environmentalVariables = recommendationData?.environmentalVariables
      ? Object.keys(recommendationData?.environmentalVariables).forEach(key => {
          const variable = recommendationData?.environmentalVariables[key];
          const varData = {
            id: key,
            name: variable.name,
            type: variable.feature === 'slider' ? 'number' : 'string',
            value: variable.defaultValue,
          };
          setSaveRecommendationData(prev => ({
            ...prev,
            environmentalVariables: [...prev.environmentalVariables, varData],
          }));
        })
      : [];
  };

  useEffect(() => {
    if (Object.keys(editedFields).length > 0) {
      setUpdateData(prevState => ({
        ...prevState,
        parameters: editedFields,
      }));
    }

    const keys = Object.keys(editedFields);
    const updatedFixedVariables = saveRecommendationData.fixedVariables?.map(variable => {
      const key = keys.find(keyToFind => keyToFind.toLowerCase() === variable.id);
      if (key) {
        return {
          ...variable,
          value: editedFields[key],
        };
      }
      return variable; // Retornar o objeto original se não houver alteração
    });

    const updatedEnvironmentalVariables = saveRecommendationData?.environmentalVariables?.map(
      variable => {
        const key = keys.find(k => k === variable.id);
        if (key) {
          return {
            ...variable,
            value: editedFields[key],
          };
        }
        return variable; // Retornar o objeto original se não houver alteração
      },
    );

    // Criar um novo estado com os valores atualizados
    const updatedState = {
      fixedVariables: updatedFixedVariables,
      environmentalVariables: updatedEnvironmentalVariables,
    };
    setSaveRecommendationData(updatedState);
  }, [editedFields, fieldsValue]);

  useEffect(() => {
    const initialDate = recommendationData?.recommendationVariety
      ?.map(item => {
        return item.plantingDate.map(planting => {
          const order = item.order;
          return {
            variableT: planting.variableT,
            exibitionDate: planting.exibitionDate,
            [`Produtividade_${order}`]: planting.yield,
          };
        });
      })
      .flat();

    const resultDate = initialDate?.sort((a, b) => {
      const dateA = new Date(a.variableT.split('/').reverse().join('/')).getTime();
      const dateB = new Date(b.variableT.split('/').reverse().join('/')).getTime();
      return dateA - dateB;
    });

    const resultDensity = recommendationData?.recommendationVariety
      ?.map(item => {
        return item.plantingDensity.map(planting => {
          const order = item.order;
          return {
            density: planting.density,
            [`Recomendação_${order}`]: planting.yield,
          };
        });
      })
      .flat();

    const combinedDateData = resultDate?.reduce((acc, curr) => {
      const dateIndex = acc.findIndex(item => item.variableT === curr.variableT);
      if (dateIndex === -1) {
        acc.push({ ...curr });
      } else {
        Object.keys(curr).forEach(key => {
          if (key !== 'variableT') {
            acc[dateIndex][key] = curr[key];
          }
        });
      }
      return acc;
    }, []);

    const combinedDensityData = resultDensity?.reduce((acc, curr) => {
      const dateIndex = acc.findIndex(item => item.density === curr.density);
      if (dateIndex === -1) {
        acc.push({ ...curr });
      } else {
        Object.keys(curr).forEach(key => {
          if (key !== 'density') {
            acc[dateIndex][key] = curr[key];
          }
        });
      }
      return acc;
    }, []);

    const orderedDensityData =
      combinedDensityData && combinedDensityData?.length > 0
        ? combinedDensityData?.sort((a, b) => a.density - b.density)
        : combinedDensityData;

    setChartDateData(combinedDateData);
    setChartDensityData(orderedDensityData);
    setUpdateData({
      ...updateData,
      brandId: recommendationData?.brandId,
      cultureId: recommendationData?.cultureId,
      lotId: recommendationData?.lotId,
    });

    convertToSaveInitialData();
    setVarietyValue(recommendationData?.recommendationVariety?.find(x => x.selectedVariety)?.id);
  }, [recommendationData]);

  const [openFirst, setOpenFirst] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const handleToggleFirst = () => {
    setOpenFirst(!openFirst);
  };

  const handleToggleSecond = () => {
    setOpenSecond(!openSecond);
  };

  return (
    <>
      {isLoading && (
        <GDMContainer
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100%',
            position: 'absolute',
            paddingTop: '1px',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.2)',
            zIndex: 1299,
          }}
        >
          <GDMBox sx={{ paddingTop: recommendationData ? '25%' : '0' }}>
            <GDMLoading />
          </GDMBox>
        </GDMContainer>
      )}
      <GDMContainer
        component="div"
        sx={{
          borderRadius: 2,
          boxShadow: isDesktop && '0 2px 8px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderTopRightRadius: '24px',
          borderTopLeftRadius: '24px',
          backgroundColor: isDesktop && theme.palette.background.paper,
        }}
      >
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: { xs: '24px 4px', md: '64px 64px 32px 64px' },
          }}
        >
          <GDMContainer
            sx={{ marginBottom: '24px', alignSelf: { xs: 'center', md: 'flex-start' } }}
          >
            <GDMTypography
              sx={{
                color: '#A1A9B8',
                fontSize: '14px',
              }}
              display="block"
              gutterBottom
            >
              {'Recomendação > Ver Recomendação'}
            </GDMTypography>
          </GDMContainer>

          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <GDMContainer>
              {isLoading ? (
                <Skeleton variant="text" height={56} />
              ) : (
                <GDMTypography
                  variant="h4"
                  textAlign={isDesktop ? 'left' : 'center'}
                  fontWeight={700}
                  color="#222222"
                >
                  {recommendationData?.name}
                </GDMTypography>
              )}
              <GDMContainer
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: { xs: '0px', md: '5px' },
                  marginBottom: { xs: '24px', md: '0px' },
                }}
              >
                <GDMTypography
                  lineHeight="35.2px"
                  textAlign={isDesktop ? 'left' : 'center'}
                  fontWeight={400}
                  color="#222222"
                  sx={{
                    fontSize: { xs: '16px', md: '16px' },
                  }}
                >
                  {`${getLabelTranslation('recommendation_view_subtitle_first')} `}
                  <GDMButton
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      textAlign: isDesktop ? 'left' : 'center',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#222222',
                      padding: 0,
                      m: 0,
                      height: 'auto',
                      marginTop: '-2px',
                    }}
                    onClick={() => {
                      handleScroll();
                      setOpenSecond(true);
                    }}
                  >
                    {getLabelTranslation('recommendation_view_subtitle_second')}
                  </GDMButton>
                </GDMTypography>
              </GDMContainer>
            </GDMContainer>
            <GDMContainer
              sx={{
                height: '48px',
                width: { xs: '100%', md: '211px' },
                border: `1px solid ${theme.palette.primary.main}`,
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '6px',
              }}
            >
              {isLoading ? (
                <Skeleton variant="text" height={56} width="100%" />
              ) : (
                <>
                  <GDMTypography
                    variant="body1"
                    textAlign={isDesktop ? 'left' : 'center'}
                    fontWeight={400}
                    fontSize="14px"
                    color={theme.palette.primary.main}
                  >
                    {getLabelTranslation('recommendation_view_status')}
                  </GDMTypography>
                  <GDMTypography
                    textAlign={isDesktop ? 'left' : 'center'}
                    fontSize="14px"
                    fontWeight={700}
                    color={theme.palette.primary.main}
                  >
                    {recommendationData?.recommendationStatusName &&
                      getLabelTranslation(recommendationData?.recommendationStatusName)}
                  </GDMTypography>
                </>
              )}
            </GDMContainer>
          </GDMContainer>

          <Divider sx={{ my: 2, width: { xs: '0', md: '2px' } }} />

          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '62px',
                borderRadius: '4px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                padding: '0 14px',
                background: theme.palette.primary.light,
                color: theme.palette.common.white,
              }}
              onClick={handleToggleFirst}
            >
              <GDMTypography
                fontWeight={700}
                color={colors.white}
                fontSize={16}
                lineHeight="22.4px"
              >
                {getLabelTranslation('recommendation_view_suggestion')}
              </GDMTypography>
              {openFirst ? <IoIosArrowUp width={16} /> : <IoIosArrowDown width={16} />}
            </GDMContainer>
            <Collapse
              in={openFirst}
              sx={{
                background: { xs: 'transparent', md: colors.light3 },
                padding: '0 16px',
              }}
            >
              <GDMTypography
                fontSize="16px"
                fontWeight={400}
                color="#222222"
                fontStyle="italic"
                textAlign="right"
                width="100%"
                padding="16px 0"
              >
                {getLabelTranslation('recommendation_suggestion_date')}
                <br />
                {recommendationData?.createdAt}
              </GDMTypography>
              <RecommendationPageForm
                isFormLoaded
                recommendation={recommendationData}
                setIsLoading={setIsLoading}
                isReadOnly
              />
              <GDMDivider />

              <ParamsForm recommendation={recommendationData} isReadOnly />

              {isDesktop && <GDMDivider />}

              <RecommendationVarieties
                setVarietyValue={setVarietyValue}
                varietyValue={varietyValue}
                recommendationData={recommendationData}
                isReadOnly
              />

              <RecommendationChart
                recommendationData={recommendationData}
                chartDateData={chartDateData}
                chartDensityData={chartDensityData}
                varietyValue={varietyValue}
              />
              <GDMContainer
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column-reverse', md: 'row' },
                  gap: { xs: '24px', md: '0' },
                  justifyContent: 'space-between',
                  paddingBottom: { xs: '16px', md: '64px' },
                }}
              >
                <GDMButton
                  sx={{
                    height: '51px',
                    width: { xs: '100%', md: '220px' },
                    float: 'left',
                    fontWeigt: 700,
                    fontSize: '14px',
                  }}
                  color="primary"
                  variant="outlined"
                  fullWidth={false}
                  startIcon={<TbTrash />}
                  onClick={() => {
                    handleDeleteRecommendation(recommendationData?.id);
                  }}
                >
                  {getLabelTranslation('recommendation_delete')}
                </GDMButton>
                <GDMButton
                  sx={{
                    height: '51px',
                    width: { xs: '100%', md: '220px' },
                    float: 'left',
                    fontWeigt: 700,
                    fontSize: '14px',
                    color: '#222',
                    background: theme.palette.secondary.main,
                  }}
                  color="primary"
                  variant="contained"
                  fullWidth={false}
                  startIcon={<TbDownload />}
                  onClick={handleDownloadRecommendation}
                >
                  {getLabelTranslation('recommendation_download')}
                </GDMButton>
              </GDMContainer>
            </Collapse>

            <GDMContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '62px',
                borderRadius: '4px',
                padding: '0 14px',
                marginTop: '24px',
                background: theme.palette.primary.light,
                color: theme.palette.common.white,
              }}
              onClick={handleToggleSecond}
              id="file-container"
            >
              <GDMTypography
                fontWeight={700}
                color={colors.white}
                fontSize={16}
                lineHeight="22.4px"
              >
                {getLabelTranslation('recommendation_attached_files_title')}
              </GDMTypography>
              {openSecond ? <IoIosArrowUp width={16} /> : <IoIosArrowDown width={16} />}
            </GDMContainer>
            <Collapse in={openSecond}>
              <GDMContainer
                sx={{
                  background: { xs: 'transparent', md: colors.light3 },
                  padding: { xs: '0', md: '0 16px' },
                }}
              >
                <GDMContainer
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '24px 0',
                  }}
                >
                  <GDMContainer
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'center', md: 'unset' },
                      gap: { xs: '16px', md: 'unset' },
                      justifyContent: 'space-between',
                    }}
                  >
                    <GDMContainer
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      <Typography
                        fontSize="16px"
                        lineHeight="22.4px"
                        fontWeight={400}
                        color={colors.black}
                      >
                        {getLabelTranslation('recommendation_files_label_name')}{' '}
                        <strong>{recommendationData?.name}</strong>
                      </Typography>
                    </GDMContainer>
                    <GDMContainer
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: { xs: 'center', md: 'flex-end' },
                        gap: { xs: '8px', md: '0' },
                        justifyContent: 'space-between',
                      }}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        multiple
                      />
                      <GDMButton
                        sx={{
                          height: '51px',
                          width: { xs: '100%', md: '220px' },
                          float: 'left',
                          fontWeigt: 700,
                          fontSize: '14px',
                        }}
                        color="primary"
                        variant="outlined"
                        fullWidth={false}
                        startIcon={<FiUploadCloud />}
                        onClick={handleButtonClick}
                      >
                        {getLabelTranslation('recommendation_upload')}
                      </GDMButton>
                      <GDMTypography
                        fontWeight={400}
                        color={colors.dark1}
                        sx={{
                          textAlign: { xs: 'center', md: 'right' },
                          fontSize: { xs: '12px', md: '16px' },
                        }}
                      >
                        {getLabelTranslation('recommendation_attach_files_label')}
                      </GDMTypography>
                    </GDMContainer>
                  </GDMContainer>
                  {recommendationData?.recommendationFile?.map(file => (
                    <GDMContainer
                      key={file.id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '12px 0',
                        gap: '26px',
                        wdith: '100%',
                      }}
                    >
                      <GDMContainer
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        <TbFileText size={24} color={theme.palette.primary.dark} />
                        <Typography
                          fontSize="18px"
                          lineHeight="25.2px"
                          fontWeight={400}
                          color={colors.black}
                          sx={{ flex: 1 }}
                        >
                          {file.fileName}
                        </Typography>
                        <GDMContainer
                          sx={{
                            display: 'flex',
                            gap: '4px',
                            width: '20%',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: { xs: 'flex-end', md: 'center' },
                          }}
                        >
                          {isDesktop && (
                            <GDMSecureLink
                              fontSize="14px"
                              lineHeight="14.4px"
                              fontWeight={600}
                              color={theme.palette.info.main}
                              href={file.cdnUrl}
                              target="_blank"
                              rel="noreferrer noopener"
                              download={file.fileName}
                              sx={{ textDecoration: 'none' }}
                            >
                              {getLabelTranslation('recommendation_file_download')}
                            </GDMSecureLink>
                          )}
                          <GDMLink
                            href={file.cdnUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                            download={file.fileName}
                            sx={{ textDecoration: 'none' }}
                          >
                            <TbCloudDown
                              size={24}
                              color={theme.palette.info.main}
                              style={{ marginBottom: isDesktop ? '-6px' : 0 }}
                            />
                          </GDMLink>
                        </GDMContainer>
                        <GDMContainer
                          sx={{
                            display: 'flex',
                            gap: '4px',
                            width: '15%',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {isDesktop && (
                            <Typography
                              fontSize="14px"
                              lineHeight="14.4px"
                              fontWeight={600}
                              color={colors.dark2}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleDeleteFile(file.id)}
                            >
                              {getLabelTranslation('recommendation_file_delete')}
                            </Typography>
                          )}
                          <TbTrash
                            size={24}
                            color={colors.dark2}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteFile(file.id)}
                          />
                        </GDMContainer>
                      </GDMContainer>
                    </GDMContainer>
                  ))}
                  {recommendationData?.recommendationFile.length === 0 && (
                    <GDMContainer
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        paddingTop: '24px',
                      }}
                    >
                      <TbCloudUpload size={36} color={colors.dark1} />
                      <Typography
                        fontSize="20px"
                        fontWeight={600}
                        color={colors.dark1}
                        sx={{ textAlign: 'center' }}
                      >
                        {getLabelTranslation('recommendation_files_empty_title')}
                      </Typography>
                      <Typography
                        fontSize="18px"
                        fontWeight={400}
                        color={colors.dark1}
                        sx={{ textAlign: 'center' }}
                      >
                        {getLabelTranslation('recommendation_files_empty_description')}
                      </Typography>
                    </GDMContainer>
                  )}
                </GDMContainer>
              </GDMContainer>
            </Collapse>
          </GDMContainer>

          <GDMContainer
            sx={{
              display: 'flex',
              marginTop: 4,
              width: '100%',
              paddingBottom: 4,
              alignItems: 'flex-start',
            }}
          >
            <GDMButton
              sx={{
                height: '51px',
                width: { xs: '100%', md: '220px' },
                float: 'left',
                fontWeigt: 700,
                fontSize: '14px',
                borderColor: theme.palette.primary.main,
              }}
              color="primary"
              variant="outlined"
              fullWidth={false}
              startIcon={<TbArrowLeft />}
              onClick={() => {
                navigate('/recommendation-list');
              }}
            >
              {getLabelTranslation('back')}
            </GDMButton>
          </GDMContainer>
        </GDMContainer>
      </GDMContainer>
      <RecommendationToDownload
        recommendationId={recommendationData?.id}
        chartDateData={chartDateData}
        chartDensityData={chartDensityData}
        varietyValue={
          recommendationData?.recommendationVariety?.find(x => x.id === varietyValue)?.order
        }
        cultureName={recommendationData?.cultureName}
      />
    </>
  );
}
