import React, { useState } from 'react';
import { PiExportLight } from 'react-icons/pi';

import { useMediaQuery, useTheme } from '@mui/material';

import { GDMContainer, GDMSecureImage, GDMTypography } from 'components';

import { GDMFileUploadComponent } from './GDMFileUpload.type';

function isBlobOrMediaOrFile(value: any): value is Blob | MediaSource | File {
  return value instanceof Blob || value instanceof MediaSource || value instanceof File;
}

const GDMFileUpload: GDMFileUploadComponent = ({
  label,
  required = false,
  onChange,
  defaultValue,
  value = null,
  disabled = false,
}) => {
  const getDefaultValue = () => {
    if (isBlobOrMediaOrFile(value)) {
      return URL.createObjectURL(value);
    }
    return value || defaultValue;
  };

  const [fileName, setFileName] = useState(value?.name || '');
  const [filePreview, setFilePreview] = useState<string | null>(getDefaultValue() || null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFileName(file.name);
      setFilePreview(URL.createObjectURL(file));
      onChange(file);
    }
  };

  return (
    <GDMContainer
      sx={{
        border: '1px dashed #A1A9B8',
        borderRadius: 1,
        width: '100%',
        p: 2,
        position: 'relative', // Added position relative for label positioning
        textAlign: 'center',
        height: '155px', // Added height for mobile view
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: isDesktop ? 0 : 2,
        '&:hover': {
          backgroundColor: disabled ? 'none' : '#fff',
        },
        background: disabled ? '#F2F4F9' : '#fff',
      }}
      onClick={() => document.getElementById('file-upload')?.click()}
    >
      {filePreview ? (
        <GDMSecureImage
          src={filePreview}
          alt="Preview"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <GDMTypography
          variant="body2"
          sx={{
            color: '#A1A9B8',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '19px',
          }}
        >
          {fileName || (
            <>
              <PiExportLight size={22} style={{ marginRight: '8px', marginBottom: '-3px' }} />
              <span>{label}</span> {required && <span style={{ color: 'red' }}>*</span>}
            </>
          )}
        </GDMTypography>
      )}
      {!disabled && (
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="file-upload"
          disabled={disabled}
          type="file"
          onChange={handleFileChange}
        />
      )}
    </GDMContainer>
  );
};

export default GDMFileUpload;
