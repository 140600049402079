/* eslint-disable react/jsx-no-undef */
import { Avatar, useMediaQuery } from '@mui/material';
import recom1 from 'assets/images/recomm_1_white.svg';
import house from 'assets/images/house-icon.svg';
import { GDMBox, GDMContainer, GDMSecureImage, GDMTypography } from 'components';
import { RecommendationService } from 'core/api/services/recommendation';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { useEffect, useState } from 'react';
import { BsCloudRain } from 'react-icons/bs';
import { CiCalendarDate } from 'react-icons/ci';
import { PiCirclesFourLight } from 'react-icons/pi';
import { RiHome2Line } from 'react-icons/ri';
import {
  TbChartBar,
  TbChartPie2,
  TbDna2,
  TbDroplet,
  TbLetterN,
  TbPlant2,
  TbTemperature,
} from 'react-icons/tb';
import { stringTruncate } from 'utils/formaters';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { containerDashboard } from './RecommendationToDownload.styles';
import { IRecommendationDownloadData } from './RecommendationToDownload.type';

const CustomLegendRender = ({ payload = [], recommendation, ...props }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        marginTop: '12px',
      }}
    >
      {payload.map((__, idx) => {
        const color = props?.colors[idx];

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <Surface width={17} height={17} viewBox={{ x: 0, y: 0, height: 10, width: 10 }}>
              <Symbols cx={5} cy={5} type="square" fill={color || '#ccc'} />
            </Surface>
            <span
              style={{
                fontSize: '22px',
                fontWeight: 700,
                color: '#464E5F',
              }}
            >
              {recommendation?.genotype}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const CustomLegend = ({ theme, recommendation, ...props }) => (
  <CustomLegendRender
    colors={[theme.palette.primary.main, theme.palette.primary.light, theme.palette.primary.dark]}
    recommendation={recommendation}
    {...props}
  />
);

export default function RecommendationToDownload({
  recommendationId,
  chartDensityData,
  chartDateData,
  varietyValue,
  cultureName,
}: any): JSX.Element {
  const { theme } = useThemeSwitcher();
  const { getLabelTranslation } = useLanguageSwitcher();
  const [brandImageUrl, setBrandImageUrl] = useState('');
  const [lotImageUrl, setLotImageUrl] = useState('');
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [recommendation, setRecommendation] = useState<IRecommendationDownloadData>();

  const displayInterval = () => {
    if (chartDateData && chartDateData.length > 200) {
      return 20;
    }
    return 7;
  };

  useEffect(() => {
    setBrandImageUrl(recommendation?.brandImageUrl);
    setLotImageUrl(recommendation?.lotImageUrl);
  }, [recommendation]);

  const loadRecommendation = async () => {
    try {
      const data = await RecommendationService.getRecommendationToDownload(recommendationId);
      setRecommendation(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (recommendationId) loadRecommendation();
  }, [recommendationId]);

  const formatDate = () => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Os meses são indexados de 0 a 11
    const year = now.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <GDMContainer sx={containerDashboard} id="recommendation-to-download">
      <GDMContainer sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '52px' }}>
        <GDMContainer
          sx={{
            padding: '64px 69px 0 69px  ',
            minHeight: '542px',
            border: '6px solid #D9DBE4',
            borderRadius: '32px',
            width: '100%',
          }}
        >
          <GDMContainer
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '8px' }}
          >
            <GDMBox>
              <GDMTypography
                fontSize={50}
                fontWeight={400}
                color="#8F90A6"
                variant="inherit"
                lineHeight={1.2}
              >
                {stringTruncate(recommendation?.name, 25)}
              </GDMTypography>
            </GDMBox>

            <GDMTypography fontSize={50} fontWeight={400} color="#3a3a3c" variant="inherit">
              {formatDate()}
            </GDMTypography>
          </GDMContainer>
          <GDMContainer
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '240px',
              width: '100%',
              padding: '32px 34px 0 34px',
            }}
          >
            <GDMContainer sx={{ width: '280px', height: '280px' }}>
              <GDMSecureImage
                useAvatar
                src={brandImageUrl}
                alt="brand image"
                sx={{
                  borderRadius: 0,
                  width: '100%',
                  height: 'auto',
                }}
              />
            </GDMContainer>
            {recommendation?.multiplierImageUrl && (
              <GDMContainer
                sx={{
                  width: '280px',
                  height: '280px',
                  backgroundImage: `url(${recommendation?.multiplierImageUrl})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            {recommendation?.distributorImageUrl && (
              <GDMContainer
                sx={{
                  width: '280px',
                  height: '280px',
                  backgroundImage: `url(${recommendation?.distributorImageUrl})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
          </GDMContainer>
        </GDMContainer>
        <GDMContainer
          sx={{
            padding: '66px 66px 85px 66px',
            minHeight: '542px',
            border: '6px solid #D9DBE4',
            borderRadius: '32px',
            width: '100%',
          }}
        >
          <GDMContainer
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '17px 0 17px 60px',
              gap: '30px',
              width: '100%',
              backgroundColor: '#F4F4F4',
              borderRadius: '16px',
            }}
          >
            <Avatar
              src={house}
              sx={{
                background: 'none',
                width: '53px',
                height: 'auto',
                color: '#8F90A6',
                svg: {
                  color: '#8F90A6',
                },
                borderRadius: 0,
              }}
            />
            <GDMTypography fontSize={50} fontWeight={700} color="#3A3A3C" variant="inherit">
              {getLabelTranslation('farmer_data')}
            </GDMTypography>
          </GDMContainer>
          <GDMContainer
            sx={{
              display: 'grid',
              marginTop: '20px',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: 'min-content min-content min-content',
              columnGap: '16px',
              rowGap: '16px',
            }}
          >
            <GDMTypography
              fontSize={36}
              fontWeight={400}
              lineHeight="42px"
              variant="inherit"
              sx={{
                gridRow: '1 / 2',
              }}
            >
              <b>{getLabelTranslation('name')}: </b> {recommendation?.farmerName}
            </GDMTypography>
            <GDMTypography fontSize={36} lineHeight="42px" fontWeight={400} variant="inherit">
              <b>{getLabelTranslation('address')}: </b>
              {recommendation?.farmerAddress}
            </GDMTypography>
            <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
              <b>{getLabelTranslation('document')}: </b> {recommendation?.farmerDocument}
            </GDMTypography>
            <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
              <b>{getLabelTranslation('city')}: </b>
              {recommendation?.farmerCity}
            </GDMTypography>
            <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
              <b>{getLabelTranslation('phone')}: </b>
              {recommendation?.farmerPhone}
            </GDMTypography>
            <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
              <b>{getLabelTranslation('state')}: </b>
              {recommendation?.farmerState}
            </GDMTypography>
            <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
              <b>{getLabelTranslation('email')}: </b>
              {recommendation?.farmerEmail}
            </GDMTypography>
          </GDMContainer>
        </GDMContainer>
      </GDMContainer>
      <GDMContainer sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '64px',
            padding: '66px',
            border: '6px solid #D9DBE4',
            borderRadius: '32px',
            width: '100%',
            minHeight: '250px',
            marginTop: '32px',
          }}
        >
          <GDMContainer
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '64px' }}
          >
            <GDMContainer
              sx={{
                width: '1012px',
              }}
            >
              <GDMContainer
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '17px 0 17px 60px',
                  gap: '30px',
                  width: '100%',
                  backgroundColor: '#F4F4F4',
                  borderRadius: '16px',
                }}
              >
                <Avatar
                  src={house}
                  sx={{
                    background: 'none',
                    width: '53px',
                    height: 'auto',
                    color: '#8F90A6',
                    svg: {
                      color: '#8F90A6',
                    },
                    borderRadius: 0,
                  }}
                />
                <GDMTypography fontSize={50} fontWeight={700} color="#3A3A3C" variant="inherit">
                  {getLabelTranslation('farm_data')}
                </GDMTypography>
              </GDMContainer>
              <GDMContainer
                sx={{
                  display: 'grid',
                  marginTop: '32px',
                  gridTemplateColumns: '1fr',
                  gridTemplateRows: 'min-content min-content min-content',
                  rowGap: '16px',
                }}
              >
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('farm_name')}: </b>
                  {recommendation?.farmName}
                </GDMTypography>
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('city')}: </b>
                  {recommendation?.farmCity}
                </GDMTypography>
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('state')}: </b>
                  {recommendation?.farmState}
                </GDMTypography>
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('total_area')}: </b>
                  {recommendation?.farmTotalArea}
                </GDMTypography>
              </GDMContainer>
            </GDMContainer>
            <GDMContainer
              sx={{
                width: '1012px',
              }}
            >
              <GDMContainer
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '17px 0 17px 60px',
                  gap: '30px',
                  width: '100%',
                  backgroundColor: '#F4F4F4',
                  borderRadius: '16px',
                }}
              >
                <Avatar
                  src={house}
                  sx={{
                    background: 'none',
                    width: '53px',
                    height: 'auto',
                    color: '#8F90A6',
                    svg: {
                      color: '#8F90A6',
                    },
                    borderRadius: 0,
                  }}
                />
                <GDMTypography fontSize={50} fontWeight={700} color="#3A3A3C" variant="inherit">
                  {getLabelTranslation('plot_data')}
                </GDMTypography>
              </GDMContainer>
              <GDMContainer
                sx={{
                  display: 'grid',
                  marginTop: '32px',
                  gridTemplateColumns: '1fr',
                  gridTemplateRows: 'min-content min-content min-content',
                  rowGap: '16px',
                }}
              >
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('plot_name')}: </b>
                  {recommendation?.lotName}
                </GDMTypography>
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('total_area')}: </b>
                  {recommendation?.lotTotalArea}
                </GDMTypography>
                <GDMTypography fontSize={36} fontWeight={400} lineHeight="42px" variant="inherit">
                  <b>{getLabelTranslation('latitude_longitude')}: </b>
                  {recommendation?.lotLatitude} / {recommendation?.lotLongitude}
                </GDMTypography>
              </GDMContainer>
            </GDMContainer>
          </GDMContainer>
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
              width: '100%',
              borderRadius: '32px',
            }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '17px 0 17px 60px',
                gap: '30px',
                width: '100%',
                backgroundColor: '#F4F4F4',
                borderRadius: '16px',
              }}
            >
              <Avatar
                src={house}
                sx={{
                  background: 'none',
                  width: '53px',
                  height: 'auto',
                  color: '#8F90A6',
                  svg: {
                    color: '#8F90A6',
                  },
                  borderRadius: 0,
                }}
              />
              <GDMTypography fontSize={50} fontWeight={700} color="#3A3A3C" variant="inherit">
                {getLabelTranslation('recommendation_lot_map_label')}
              </GDMTypography>
            </GDMContainer>
            <GDMSecureImage
              useAvatar
              src={lotImageUrl}
              alt="lot map image"
              sx={{
                width: '1124px',
                height: '719px',
                borderRadius: '32px',
              }}
            />
          </GDMContainer>
        </GDMContainer>
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '32px',
          padding: '61px 66px',
          width: '100%',
          height: '220px',
          backgroundColor: '#A1A9B8',
          borderRadius: '16px',
        }}
      >
        <GDMContainer
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Avatar
            src={recom1}
            sx={{
              background: 'none',
              width: '75px',
              height: '75px',
              svg: {
                color: '#FAFAFA',
              },
              borderRadius: 0,
            }}
          />
          <GDMTypography
            fontSize={50}
            fontWeight={700}
            color="#FAFAFA"
            lineHeight="70px"
            variant="inherit"
          >
            {getLabelTranslation('Recommended_Variety_Of')} {` ${cultureName}`}
          </GDMTypography>
        </GDMContainer>
        <GDMTypography
          fontSize={56}
          fontWeight={700}
          color="#222222"
          lineHeight="48px"
          variant="inherit"
          sx={{
            backgroundColor: '#fafafa',
            padding: '24px 132px',
            borderRadius: '70px',
            height: '97px',
          }}
        >
          {recommendation?.genotype}
        </GDMTypography>
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '27px',
          marginTop: '32px',
        }}
      >
        {recommendation?.technology && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              padding: '32px 66px',
              width: '100%',
              border: '6px solid #D9DBE4',
              borderRadius: '32px',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '23px' }}>
              <TbPlant2 color="#A1A9B8" size={55} />
              <GDMTypography fontSize={32} fontWeight={700} color="#222222" variant="inherit">
                {getLabelTranslation('recommendation_params_field_tech')}
              </GDMTypography>
            </GDMContainer>
            <GDMTypography fontSize={38} fontWeight={700} color="#222222" variant="inherit">
              {recommendation?.technology}
            </GDMTypography>
          </GDMContainer>
        )}
        {recommendation?.productivity && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              padding: '32px 66px',
              width: '100%',
              border: '6px solid #D9DBE4',
              borderRadius: '32px',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '23px' }}>
              <TbChartBar color="#A1A9B8" size={55} />
              <GDMTypography fontSize={32} fontWeight={700} color="#222222" variant="inherit">
                {getLabelTranslation('recommendation_params_field_productivity')}
              </GDMTypography>
            </GDMContainer>
            <GDMTypography fontSize={38} fontWeight={700} color="#222222" variant="inherit">
              {recommendation?.productivity} kg/ha
            </GDMTypography>
          </GDMContainer>
        )}
        {recommendation?.nitrogen && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              padding: '32px 66px',
              width: '100%',
              border: '6px solid #D9DBE4',
              borderRadius: '32px',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '23px' }}>
              <TbLetterN color="#A1A9B8" size={55} />
              <GDMTypography fontSize={32} fontWeight={700} color="#222222" variant="inherit">
                {getLabelTranslation('recommendation_params_field_nitrogen')}
              </GDMTypography>
            </GDMContainer>
            <GDMTypography fontSize={38} fontWeight={700} color="#222222" variant="inherit">
              {recommendation?.nitrogen} kg/ha
            </GDMTypography>
          </GDMContainer>
        )}
        {recommendation?.plantingDensity && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              padding: '32px 66px',
              width: '100%',
              border: '6px solid #D9DBE4',
              borderRadius: '32px',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '23px' }}>
              <PiCirclesFourLight color="#A1A9B8" size={55} />
              <GDMTypography fontSize={32} fontWeight={700} color="#222222" variant="inherit">
                {getLabelTranslation('recommendation_params_field_plantingDensity')}
              </GDMTypography>
            </GDMContainer>
            <GDMTypography fontSize={38} fontWeight={700} color="#222222" variant="inherit">
              {recommendation?.plantingDensity} pl/m²
            </GDMTypography>
          </GDMContainer>
        )}
        {recommendation?.plantingDate && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              padding: '32px 66px',
              width: '100%',
              border: '6px solid #D9DBE4',
              borderRadius: '32px',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '23px' }}>
              <CiCalendarDate color={theme.palette.primary.main} size={55} />
              <GDMTypography fontSize={32} fontWeight={700} color="#222222" variant="inherit">
                {getLabelTranslation('recommendation_params_field_planting_date')}
              </GDMTypography>
            </GDMContainer>
            <GDMTypography fontSize={38} fontWeight={700} color="#222222" variant="inherit">
              {recommendation?.plantingDate === 'Early' && getLabelTranslation('early')}
              {recommendation?.plantingDate === 'Normal' && getLabelTranslation('normal')}
              {recommendation?.plantingDate === 'Late' && getLabelTranslation('late')}
              {recommendation?.plantingDate !== 'Late' &&
                recommendation?.plantingDate !== 'Early' &&
                recommendation?.plantingDate !== 'Normal' &&
                recommendation?.plantingDate}
            </GDMTypography>
          </GDMContainer>
        )}
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '27px',
          marginTop: '17px',
        }}
      >
        {recommendation?.environmentalVariables.map(variable => (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              padding: '32px 66px',
              width: '100%',
              border: '6px solid #D9DBE4',
              borderRadius: '32px',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '23px' }}>
              {(variable.variableName === 'clay' && (
                <TbChartPie2 color={theme.palette.grey[600]} size={55} />
              )) ||
                (variable.variableName === 'prec' && <BsCloudRain color="#A1A9B8" size={55} />) ||
                (variable.variableName === 'temp' && <TbTemperature color="#A1A9B8" size={55} />) ||
                (variable.variableName === 'water' && <TbDroplet color="#A1A9B8" size={55} />) ||
                (variable.variableName === 'melo' && <TbDna2 color="#A1A9B8" size={55} />) ||
                (variable.variableName === 'heterodera' && <TbDna2 color="#A1A9B8" size={55} />) ||
                (variable.variableName === 'om' && <TbDna2 color="#A1A9B8" size={55} />) ||
                (!['prec', 'temp', 'water', 'melo', 'heterodera', 'om'].includes(
                  variable.variableName,
                ) && <TbDna2 color="#A1A9B8" size={55} />)}
              <GDMTypography fontSize={32} fontWeight={700} color="#222222" variant="inherit">
                {getLabelTranslation(`recommendation_params_field_${variable.name}`)}
              </GDMTypography>
            </GDMContainer>
            <GDMContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: '4px',
              }}
            >
              <GDMTypography fontSize={54} fontWeight={700} color="#222222" variant="inherit">
                {variable.variableName === 'heterodera' ||
                variable.variableName === 'prati' ||
                variable.variableName === 'irrigated' ||
                variable.variableName === 'lowlands' ||
                variable.variableName === 'groundwater'
                  ? getLabelTranslation(variable.defaultValue)
                  : variable.defaultValue}
              </GDMTypography>
              <GDMTypography fontWeight={700} fontSize={54} variant="inherit">
                {variable.variableName === 'prec'
                  ? 'mm'
                  : variable.variableName === 'temp'
                  ? '°C'
                  : variable.variableName === 'water'
                  ? '%'
                  : variable.variableName === 'clay'
                  ? '%'
                  : variable.variableName === 'om'
                  ? '%'
                  : ''}
              </GDMTypography>
            </GDMContainer>
          </GDMContainer>
        ))}
      </GDMContainer>
      <GDMContainer
        id="graph-container"
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          minHeight: '300px',
          gap: '54px',
        }}
      >
        {chartDateData?.length > 0 && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: '718px',
              marginTop: '32px',
              border: `6px solid #D9DBE4`,
              borderRadius: '32px',
              padding: '44px 77px',
              gap: '16px',
            }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                width: '100%',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CiCalendarDate color="#A1A9B8" size={57} />
              <GDMTypography
                sx={{
                  color: '#222222',
                  fontSize: '32px',
                  fontWeight: '700',
                }}
                variant="inherit"
              >
                {getLabelTranslation('recommendation_graph_planting_date')}
              </GDMTypography>
            </GDMContainer>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={400}
                height={200}
                data={chartDateData}
                margin={{
                  top: 15,
                  right: 15,
                  left: 15,
                  bottom: 15,
                }}
              >
                <CartesianGrid strokeDasharray="3 0" stroke="#E7E9F1" vertical={false} />
                <XAxis
                  fontSize={30}
                  dataKey="variableT"
                  tickFormatter={(tick, index) => {
                    const item = chartDateData.find(data => data.variableT === tick);
                    return index % displayInterval() === 0
                      ? item
                        ? item.exibitionDate
                        : tick
                      : '';
                  }}
                  interval={0}
                  axisLine={false}
                  tickLine={false}
                  style={{ fontSize: '18px' }}
                />
                <YAxis
                  fontSize={30}
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={tick => {
                    return `${tick}%`;
                  }}
                  style={{ fontSize: '24px' }}
                />
                <Tooltip />
                <Legend
                  iconType="square"
                  content={<CustomLegend recommendation={recommendation} theme={theme} />}
                />

                <Line
                  type="monotone"
                  dataKey={`Produtividade_${varietyValue}`}
                  name={recommendation?.genotype}
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                  strokeDasharray=""
                  connectNulls
                />

                <ReferenceLine
                  x={recommendation?.plantingDate}
                  stroke="#CBCDD9"
                  strokeDasharray="5 5"
                />

                <ReferenceDot
                  x={recommendation?.plantingDate}
                  y={
                    recommendation?.plantingDateGraph.find(
                      x => x.variableT === recommendation?.plantingDate,
                    )?.yield
                  }
                  r={6}
                  fill={theme.palette.primary.main}
                  stroke="none"
                />
              </LineChart>
            </ResponsiveContainer>
          </GDMContainer>
        )}

        <GDMContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '718px',
            marginTop: '32px',
            border: `6px solid #D9DBE4`,
            borderRadius: '32px',
            padding: '44px 77px',
            gap: '16px',
          }}
        >
          <GDMContainer
            sx={{
              display: 'flex',
              width: '100%',
              gap: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PiCirclesFourLight color="#A1A9B8" size={57} />
            <GDMTypography
              sx={{
                color: '#222222',
                fontSize: '32px',
                fontWeight: '700',
              }}
              variant="inherit"
            >
              {getLabelTranslation('planting_density')}
            </GDMTypography>
          </GDMContainer>
          <ResponsiveContainer width="100%" height="100%" minHeight="300px">
            <LineChart
              width={400}
              height={200}
              data={chartDensityData}
              margin={{
                top: 15,
                right: 15,
                left: 15,
                bottom: 15,
              }}
            >
              <CartesianGrid strokeDasharray="3 0" stroke="#E7E9F1" vertical={false} />
              <XAxis
                dataKey="density"
                axisLine={false}
                tickLine={false}
                style={{ fontSize: '18px' }}
                tickFormatter={(tick, index) => {
                  const item = chartDensityData.find(data => data.density === tick);
                  return index % displayInterval() === 0 ? (item ? item.density : tick) : '';
                }}
              />
              <YAxis
                style={{ fontSize: '24px' }}
                axisLine={false}
                tickLine={false}
                tickFormatter={tick => {
                  return `${tick}%`;
                }}
              />
              <Tooltip />
              <Legend
                iconType="square"
                content={<CustomLegend theme={theme} recommendation={recommendation} />}
              />
              <Line
                type="monotone"
                dataKey={`Recomendação_${varietyValue}`}
                name={recommendation?.genotype}
                strokeWidth={2}
                stroke={theme.palette.primary.main}
                dot={false}
                strokeDasharray=""
                connectNulls
              />
              <ReferenceLine
                x={recommendation?.plantingDensity}
                stroke="#CBCDD9"
                strokeDasharray="5 5"
              />
              <ReferenceDot
                x={recommendation?.plantingDensity}
                y={
                  recommendation?.plantingDensityGraph.find(
                    x => x.density === recommendation?.plantingDensity,
                  )?.yield
                }
                r={6}
                fill={theme.palette.primary.main}
                stroke="none"
              />
            </LineChart>
          </ResponsiveContainer>
        </GDMContainer>
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          marginTop: '32px',
          padding: '66px',
          width: '100%',
          border: '6px solid #D9DBE4',
          borderRadius: '32px',
        }}
      >
        <GDMTypography
          fontSize={50}
          fontWeight={700}
          color="#222222"
          lineHeight="70px"
          variant="inherit"
        >
          {getLabelTranslation('seller_data')}
        </GDMTypography>
        <GDMTypography
          fontSize={36}
          fontWeight={400}
          color="#222222"
          lineHeight="42px"
          variant="inherit"
        >
          <b>{getLabelTranslation('name')}: </b>
          {recommendation?.userName}&nbsp;&nbsp;|&nbsp;&nbsp;<b>{getLabelTranslation('phone')}: </b>
          {recommendation?.userPhone}&nbsp;&nbsp;|&nbsp;&nbsp;
          <b>{getLabelTranslation('email')}: </b>
          {recommendation?.userEmail}
        </GDMTypography>
      </GDMContainer>
    </GDMContainer>
  );
}
