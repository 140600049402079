import { SxProps, Theme } from '@mui/material';

export const containerStyle: SxProps = {
  display: 'flex',
  gap: {
    xs: '0px',
    sm: '0px',
    md: '38px',
    lg: '38px',
    xl: '38px',
  },
  height: {
    xs: 'auto',
    sm: 'auto',
    md: '100%',
    lg: '100%',
    xl: '100%',
  },
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
};

export const boxStyle: SxProps = {
  backgroundColor: '#FAFAFA',
  borderRadius: '24px',
  boxShadow: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const formCard: SxProps = {
  ...boxStyle,
  flex: 1,
  boxShadow: {
    xs: 'none',
    sm: 'none',
    md: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
    lg: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
    xl: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
  },
};

export const avatarCard: SxProps<Theme> = theme => ({
  ...boxStyle,
  width: {
    xs: '100%',
    sm: '100%',
    md: '380px',
    lg: '380px',
    xl: '380px',
  },
  maxWidth: '100%',
  boxShadow: {
    xs: 'none',
    sm: 'none',
    md: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
    lg: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
    xl: '0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
  },
  gap: {
    xs: '24px',
    sm: '24px',
    md: '32px',
    lg: '32px',
    xl: '32px',
  },
  position: 'relative',

  '&:before': {
    content: '""',
    display: {
      xs: 'flex',
      sm: 'flex',
      md: 'none',
      lg: 'none',
      xl: 'none',
    },
    position: 'absolute',
    top: '-20px',
    left: '-12px',
    background: theme.palette.secondary.main,
    width: '100vw',
    height: '107px',
    zIndex: 10,
  },
});

export const formFlexRowStyle: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '24px',
  maxWidth: '100%',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  div: {
    width: '100%',
  },
};

export const roleCard: SxProps = {
  borderRadius: '6px',
  background: '#CFEFFF',
  height: '50px',
  textAlign: 'center',
  padding: '14px 32px',
  fontSize: '16px',
  fontWeight: 400,
  color: '#222222',
  lineHeight: 'inherit',
  width: {
    xs: '100%',
    sm: '100%',
    md: '276px',
    lg: '276px',
    xl: '276px',
  },
};

export const profileContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  gap: {
    xs: '24px',
    sm: '24px',
    md: '32px',
    lg: '32px',
    xl: '32px',
  },
  flexDirection: 'column',
  padding: {
    xs: '12px 4px 24px',
    sm: '12px 12px 24px',
    md: '64px 76px',
    lg: '64px 76px',
    xl: '64px 76px',
  },
};

export const pageNameStyle: SxProps = {
  fontSize: '32px!important',
  lineHeight: '35.2px!important',
  color: '#222',
  fontWeight: '700',
  textAlign: {
    xs: 'center',
    sm: 'center',
    md: 'left',
    lg: 'left',
    xl: 'left',
  },
};

export const avatarActionsContainer: SxProps = {
  display: 'flex',
  marginTop: 'auto',
  gap: {
    xs: '24px',
    sm: '24px',
    md: '32px',
    lg: '32px',
    xl: '32px',
  },
  flexDirection: 'column',
  button: {
    width: '100%',
    textWrap: 'nowrap',
    lineHeight: 'inherit',
  },
  label: {
    width: '100%',
    textWrap: 'nowrap',
    lineHeight: 'inherit',
  },
  padding: {
    xs: '0px 4px 32px',
    sm: '0px 12px 32px',
    md: '0px 64px 64px',
    lg: '0px 64px 64px',
    xl: '0px 64px 64px',
  },
};

export const formContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  padding: {
    xs: '24px 4px',
    sm: '24px 12px',
    md: '64px 64px',
    lg: '64px 64px',
    xl: '64px 64px',
  },
  gap: {
    xs: '24px',
    sm: '24px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
  height: '100%',
  'input:disabled': {
    background: '#F2F4F9',
  },
  '.MuiInputBase-root': {
    height: '48px',
  },
  'div.bottom-form-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    div: {
      width: '100%',
    },
  },
};

export const actionsContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: {
    xs: '24px',
    sm: '24px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  button: {
    width: {
      xs: '100%',
      sm: '100%',
      md: '187px',
      lg: '187px',
      xl: '187px',
    },
    textWrap: 'nowrap',
  },
};

export const internalActionsContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',

  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  gap: {
    xs: '24px',
    sm: '24px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
};

export const inputMaskStyle = {
  padding: '14px 24px 14px 14px',
  borderRadius: '6px',
  height: '48px',
  fontSize: '16px',
  color: '#222222',
  border: '1px solid #A1A9B8',
  background: '#FAFAFA',
};

export const avatarStyle: SxProps = {
  width: '150px',
  height: '150px',
  fontSize: '62px',
  fontWeight: '700',
  color: '#FAFAFA',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  zIndex: 40,
};

export const userNameStyle: SxProps = {
  fontSize: '24px!important',
  color: '#222',
  fontWeight: '700',
  textAlign: 'center',
};

export const labelFileInputStyle: React.CSSProperties = {
  padding: '14px 20px',
  borderRadius: '6px',
  height: '50px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  fontWeight: 700,
  cursor: 'pointer',
  textAlign: 'center' as React.CSSProperties['textAlign'],
};

export const checkboxButtonsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'flex-end',
  gap: '32px',
};
