import { AssignmentOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { GDMContainer, GDMTypography } from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import {
  dashboardDataContainerStyles,
  dashboardKPIContainerStyles,
  dashboardKPIContentStyles,
  dashboardKPITitleStyles,
  dashboardKPIValueStyles,
  dashboardProfileContainerStyles,
  dashboardProfileTitleContainerStyles,
  dashboardProfileTitleIconStyles,
  dashboardProfileTitleStyles,
  implementedRecommendationsIndicatorStyles,
  implementedRecommendationsLabelStyles,
  implementedRecommendationsValueContainerStyles,
  implementedRecommendationsValueStyles,
} from './DashboardProfileData.style';

export default function DashboardProfileData({ dashboardData }) {
  const { theme } = useThemeSwitcher();

  const { getLabelTranslation } = useLanguageSwitcher();

  const implementedRecommendationsIndicatorColor =
    dashboardData?.recommendationsImplemented < 50
      ? theme.palette.error.main
      : dashboardData?.recommendationsImplemented < 50
      ? theme.palette.warning.main
      : theme.palette.success.main;

  return (
    <GDMContainer sx={dashboardProfileContainerStyles}>
      <GDMContainer sx={dashboardProfileTitleContainerStyles}>
        <AssignmentOutlined sx={dashboardProfileTitleIconStyles(theme)} />

        <GDMTypography sx={dashboardProfileTitleStyles}>
          {getLabelTranslation('dash_profile_label')}
        </GDMTypography>
      </GDMContainer>

      <GDMContainer sx={dashboardDataContainerStyles}>
        <GDMContainer sx={dashboardKPIContainerStyles}>
          <GDMContainer sx={dashboardKPIContentStyles}>
            <Tooltip
              title={`${dashboardData?.recommendationsMade} ${getLabelTranslation(
                `dash_recommendationsMade_title`,
              )} ${getLabelTranslation(`dash_recommendationsMade_complement_2`)}`}
              arrow
              placement="top"
            >
              <span>
                <GDMTypography sx={dashboardKPIValueStyles}>
                  {dashboardData?.recommendationsMade}
                </GDMTypography>
              </span>
            </Tooltip>

            <GDMTypography sx={dashboardKPITitleStyles}>
              {getLabelTranslation(`dash_recommendationsMade_title`)} <br />
              {getLabelTranslation(`dash_recommendationsMade_complement_2`)}
            </GDMTypography>
          </GDMContainer>

          <GDMContainer sx={dashboardKPIContentStyles}>
            <Tooltip
              title={`${dashboardData?.farmersRegistered} ${getLabelTranslation(
                `dash_farmersRegistered_title`,
              )} ${getLabelTranslation(`dash_farmersRegistered_title_2`)}`}
              arrow
              placement="top"
            >
              <span>
                <GDMTypography sx={dashboardKPIValueStyles}>
                  {dashboardData?.farmersRegistered}
                </GDMTypography>
              </span>
            </Tooltip>
            <GDMTypography sx={dashboardKPITitleStyles}>
              {getLabelTranslation(`dash_farmersRegistered_title`)} <br />
              {getLabelTranslation(`dash_farmersRegistered_title_2`)}
            </GDMTypography>
          </GDMContainer>

          <GDMContainer sx={dashboardKPIContentStyles}>
            <Tooltip
              title={`${dashboardData?.sales} ${getLabelTranslation(
                `dash_sales_title`,
              )} ${getLabelTranslation(`dash_sales_title_2`)}`}
              arrow
              placement="top"
            >
              <span>
                <GDMTypography sx={dashboardKPIValueStyles}>{dashboardData?.sales}</GDMTypography>
              </span>
            </Tooltip>
            <GDMTypography sx={dashboardKPITitleStyles}>
              {getLabelTranslation(`dash_sales_title`)} <br />
              {getLabelTranslation(`dash_sales_title_2`)}
            </GDMTypography>
          </GDMContainer>
        </GDMContainer>

        <GDMContainer sx={implementedRecommendationsValueContainerStyles}>
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <GDMContainer
              sx={{
                ...implementedRecommendationsIndicatorStyles,
                background: implementedRecommendationsIndicatorColor,
              }}
            />
            <GDMTypography sx={implementedRecommendationsValueStyles}>
              {`${
                !!dashboardData?.recommendationsImplemented
                  ? Math.trunc(dashboardData?.recommendationsImplemented)
                  : '0'
              }%`}
            </GDMTypography>
          </GDMContainer>

          <GDMTypography sx={implementedRecommendationsLabelStyles}>
            {getLabelTranslation(`dash_implemented_text`)}
          </GDMTypography>
        </GDMContainer>
      </GDMContainer>
    </GDMContainer>
  );
}
