/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useNavigate } from 'react-router-dom';

import { GDMButton, GDMContainer, GDMFormControl, GDMTypography } from 'components';

import { RadioGroup, useMediaQuery } from '@mui/material';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { TbExchange } from 'react-icons/tb';
import { GDMSlider } from 'components/gdm-slider';
import GDMVarietyPanel from '../components/gdm-variety-card/GDMVarietyPanel';
import { IRecommendationVarietiesProps } from './RecommendationVarieties.type';

export default function RecommendationVarieties({
  setVarietyValue,
  varietyValue,
  recommendationData,
  disabled,
  isReadOnly,
}: IRecommendationVarietiesProps) {
  const navigate = useNavigate();
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();

  const handleCompareVarieties = async () => {
    const genotypeIds = [];

    recommendationData.recommendationVariety.forEach(variety => {
      genotypeIds.push(variety.genotypeId);
    });

    localStorage.setItem('varietiesIdsToCompare', JSON.stringify(genotypeIds));
    navigate('/varieties-to-compare');
  };

  return (
    <GDMContainer
      sx={{
        marginTop: isDesktop ? '32px' : '8px',
      }}
    >
      <GDMContainer
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <GDMTypography
          sx={{
            fontSize: {
              xs: '20px',
              sm: '20px',
              md: '24px',
              lg: '24px',
              xl: '24px',
            },
          }}
          mb={1}
          fontWeight={700}
          color="#222222"
        >
          {getLabelTranslation('recommended_variable')} de {recommendationData?.cultureName}
        </GDMTypography>
        {!isDesktop && (
          <GDMButton
            sx={{
              height: '44px',
              minWidth: '144px',
              width: '144px',
              maxWidth: '144px',
              fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
              fontWeight: 700,
              color: isReadOnly ? '#222' : '#fafafa',
              background: isReadOnly ? theme.palette.secondary.main : theme.palette.primary.main,
              ':hover': {
                borderStyle: 'none',
                background: theme.palette.primary.light,
              },
            }}
            variant="contained"
            fullWidth={false}
            startIcon={<TbExchange />}
            disabled={disabled}
            onClick={handleCompareVarieties}
          >
            {getLabelTranslation('recommendation_params_compare')}
          </GDMButton>
        )}
      </GDMContainer>
      <GDMContainer
        component="div"
        sx={{
          gap: 1,
          marginTop: 1,
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <GDMContainer
          component="div"
          sx={{
            gap: 1,
            marginTop: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <GDMTypography
            fontWeight={300}
            color="#222222"
            sx={{ fontSize: { xs: '16px', sm: '16px', md: '18px', lg: '18px', xl: '18px' } }}
          >
            {getLabelTranslation('ranked_variables')}
            <br />
            {getLabelTranslation('select_one_variable')}
          </GDMTypography>
        </GDMContainer>
        {isDesktop && (
          <GDMButton
            sx={{
              height: '51px',
              width: '220px',
              fontSize: { xs: '11px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
              fontWeight: 700,
              color: isReadOnly ? '#222' : '#fafafa',
              background: isReadOnly ? theme.palette.secondary.main : theme.palette.primary.main,
              ':hover': {
                borderStyle: 'none',
                background: isReadOnly ? theme.palette.primary.light : theme.palette.primary.dark,
              },
            }}
            variant="contained"
            fullWidth={false}
            startIcon={<TbExchange />}
            disabled={disabled}
            onClick={handleCompareVarieties}
          >
            {getLabelTranslation('recommendation_params_compare')}
          </GDMButton>
        )}
      </GDMContainer>

      {isDesktop ? (
        <GDMContainer
          sx={{
            marginTop: '16px',
            width: '100%',
          }}
        >
          <GDMFormControl
            sx={{
              width: '100%',
            }}
          >
            <RadioGroup
              aria-labelledby="variety-group-label"
              name="variety-buttons-group"
              onChange={e => {
                setVarietyValue(e.target.value.toString());
                sessionStorage.setItem('variety', e.target.value);
              }}
              sx={{
                '& .MuiFormControlLabel-root': {
                  display: 'flex',
                  alignItems: 'flex-start',
                },
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  width: '100%',
                },
                '&.MuiFormGroup-root': {
                  flexDirection: 'row',
                  gap: 2,
                  width: '100%',
                },
              }}
            >
              {recommendationData &&
                recommendationData?.recommendationVariety
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <GDMVarietyPanel
                      item={item}
                      index={index}
                      varietyValue={varietyValue}
                      disabled={disabled || isReadOnly}
                    />
                  ))}
            </RadioGroup>
          </GDMFormControl>
        </GDMContainer>
      ) : (
        <GDMFormControl
          sx={{
            width: '100%',
            mt: 2,
          }}
        >
          <RadioGroup
            aria-labelledby="variety-group-label"
            name="variety-buttons-group"
            onChange={e => {
              setVarietyValue(e.target.value.toString());
              sessionStorage.setItem('variety', e.target.value);
            }}
            sx={{
              '& .MuiFormControlLabel-root': {
                display: 'flex',
                alignItems: 'flex-start',
              },
              '& .MuiFormControlLabel-label': {
                fontSize: '14px',
                width: '100%',
              },
              '&.MuiFormGroup-root': {
                flexDirection: 'row',
                gap: 2,
                width: '100%',
              },
            }}
          >
            {recommendationData?.recommendationVariety && (
              <GDMSlider>
                {recommendationData.recommendationVariety
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <div className="keen-slider__slide" key={index}>
                      <GDMVarietyPanel
                        item={item}
                        index={index}
                        varietyValue={varietyValue}
                        disabled={disabled || isReadOnly}
                      />
                    </div>
                  ))}
              </GDMSlider>
            )}
          </RadioGroup>
        </GDMFormControl>
      )}
    </GDMContainer>
  );
}
