import { GDMContainer, GDMInputCheckbox, GDMMenuItem, GDMSelect, GDMTypography } from 'components';
import GDMDatePicker from 'components/gdm-date-picker/GDMDatePicker';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import dayjs from 'dayjs';
import { IoIosArrowDown } from 'react-icons/io';

import { stringTruncate } from 'utils/formaters';
import {
  dateFilterContainerStyles,
  datePickerStyles,
  filterFormFieldContainerStyles,
  filterFormFieldContentStyles,
  filterFormFieldStyles,
  labelDateFilterStyles,
  labelFilterStyles,
  selectFilterStyles,
} from './DashboardFiltersDynamicRender.styles';

export type Filter = {
  [key: string]: FilterOption[];
};

export type FilterOption = {
  id: string;
  name: string;
};

export type Keys = {
  name: string;
  admin: boolean;
  hide: boolean;
};

export default function DashboardFiltersDynamicRender({
  dashboardFilters,
  filtersData,
  setDashboardFilters,
  activeFilterFields,
  isAdminFilters = false,
}) {
  const { getLabelTranslation } = useLanguageSwitcher();

  const handleChangeSelect = ({ target: { value } }, filterFieldName) => {
    setDashboardFilters(prev => {
      const newValue: string[] = Array.isArray(value) ? [...value] : [];

      if (newValue.includes('0')) {
        if (newValue.length > filtersData[filterFieldName]?.length)
          return {
            ...prev,
            [filterFieldName]: [],
          };
        return {
          ...prev,
          [filterFieldName]: filtersData[filterFieldName]?.map(x => x.id),
        };
      }
      const updatedFilters = {
        ...prev,
        [filterFieldName]: newValue.length > 0 ? newValue : undefined,
      };

      return Object.fromEntries(Object.entries(updatedFilters).filter(([_, v]) => v !== undefined));
    });
  };

  const handleChangeDatePicker = (value, field) => {
    setDashboardFilters(prev => {
      const updatedFilters = {
        ...prev,
        [field]: value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ') : undefined,
      };

      return updatedFilters;
    });
  };

  return activeFilterFields.map(filterField => {
    const currentFilterData = filtersData[filterField?.name];

    const isDateFields = filterField?.name === 'startDate';

    const hasDateFields = activeFilterFields.find(
      activeFilterField => activeFilterField?.name === 'startDate',
    );

    const elementsNumber = hasDateFields
      ? activeFilterFields.length + 1
      : activeFilterFields.length;

    const containerDesktopMaxWidth = isDateFields
      ? `calc((2 * (100% - ${elementsNumber - 1} * 8px) / ${elementsNumber}) + 8px)`
      : `calc((100% - ${elementsNumber - 1} * 8px) / ${elementsNumber} )`;

    if (!currentFilterData && !isDateFields) return <></>;

    return (
      <GDMContainer
        key={filterField?.name}
        sx={{
          ...filterFormFieldContainerStyles,
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: containerDesktopMaxWidth,
            lg: containerDesktopMaxWidth,
            xl: containerDesktopMaxWidth,
          },
          ...(isDateFields ? { flex: 2 } : {}),
        }}
      >
        <GDMContainer sx={filterFormFieldStyles}>
          <GDMTypography
            sx={{
              ...labelFilterStyles,
              paddingBottom: isAdminFilters
                ? '10px'
                : {
                    xs: isDateFields ? '8px' : '10px',
                    sm: isDateFields ? '8px' : '10px',
                    md: isDateFields ? '5px' : '22px',
                    lg: isDateFields ? '5px' : '22px',
                    xl: isDateFields ? '5px' : '22px',
                  },
            }}
          >
            {getLabelTranslation(isDateFields ? 'filter_period' : `filter_${filterField?.name}`)}
          </GDMTypography>
          <GDMContainer sx={filterFormFieldContentStyles}>
            {!!currentFilterData && typeof currentFilterData === 'object' && (
              <GDMSelect
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                name={filterField?.name}
                fullWidth
                sx={{ ...selectFilterStyles }}
                variant="outlined"
                value={dashboardFilters[filterField?.name] ?? []}
                IconComponent={IoIosArrowDown}
                onChange={e => handleChangeSelect(e, filterField?.name)}
                multiple
                displayEmpty
                renderValue={(selected: string[]) => {
                  if (selected.length === 0 || selected.length === currentFilterData.length) {
                    return <span>{getLabelTranslation('dash_all')}</span>;
                  }
                  return stringTruncate(
                    selected
                      .map(val => currentFilterData?.find(option => option.id === val)?.name)
                      .join(', '),
                    40,
                  );
                }}
              >
                <GDMMenuItem key="select-item-all" value="0">
                  <GDMInputCheckbox
                    checked={
                      dashboardFilters[filterField?.name]?.length === currentFilterData?.length
                    }
                  />
                  {getLabelTranslation('dash_all')}
                </GDMMenuItem>
                {currentFilterData?.map(item => (
                  <GDMMenuItem key={`select-item-${item.id}`} value={item.id}>
                    <GDMInputCheckbox
                      checked={dashboardFilters[filterField?.name]?.indexOf(item.id) > -1}
                    />
                    {filterField?.name === 'role' ? getLabelTranslation(item.name) : item.name}
                  </GDMMenuItem>
                ))}
              </GDMSelect>
            )}

            {isDateFields && (
              <>
                <GDMContainer sx={dateFilterContainerStyles}>
                  <GDMTypography sx={labelDateFilterStyles}>
                    {getLabelTranslation('filter_startDate')}
                  </GDMTypography>
                  <GDMDatePicker
                    name="startDate"
                    minDate={dayjs('01/01/2023').startOf('year')}
                    defaultValue={
                      dashboardFilters.startDate || dayjs().startOf('year').toISOString()
                    }
                    sx={datePickerStyles}
                    onChange={value => handleChangeDatePicker(value, 'startDate')}
                    textField
                  />
                </GDMContainer>
                <GDMContainer sx={dateFilterContainerStyles}>
                  <GDMTypography sx={labelDateFilterStyles}>
                    {getLabelTranslation('filter_endDate')}
                  </GDMTypography>
                  <GDMDatePicker
                    name="endDate"
                    defaultValue={dashboardFilters.endDate || dayjs().endOf('year').toISOString()}
                    sx={datePickerStyles}
                    onChange={value => handleChangeDatePicker(value, 'endDate')}
                    textField
                  />
                </GDMContainer>
              </>
            )}
          </GDMContainer>
        </GDMContainer>
      </GDMContainer>
    );
  });
}
