import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { GDMIconButton } from 'components/gdm-icon-button';
import { GDMListItem } from 'components/gdm-list-item';
import { GDMListItemButton } from 'components/gdm-list-item-button';
import { GDMTypography } from 'components/gdm-typography';
import colors from 'core/design-system/style-guide/colors.json';
import { AdminListListItemHeadMobileComponent } from './AdminListListItemHeadMobile.type';

const AdminListListItemHeadMobile: AdminListListItemHeadMobileComponent = ({
  adminId,
  adminName,
  nameLabel,
  openedAdminId,
  adminIdSelectedToDelete,
  handleCollapse,
}) => {
  const isOpened: boolean = openedAdminId === adminId;
  const isSelectedToDelete = adminIdSelectedToDelete === adminId;

  return (
    <GDMListItemButton
      sx={{
        ...(isSelectedToDelete ? { backgroundColor: '#CFEFFF' } : {}),
        padding: isOpened ? '24px 0px 0px' : '24px 0px',
      }}
      onClick={() => handleCollapse(adminId)}
    >
      <GDMListItem sx={{ padding: 0 }}>
        <GDMTypography variant="body2">{nameLabel} </GDMTypography>
        <GDMTypography
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '1',
          }}
          variant="body1"
          ml={1}
        >{` ${adminName}`}</GDMTypography>
      </GDMListItem>
      <GDMIconButton
        sx={{
          color: colors.black,
          width: '32px',
          height: '32px',
          backgroundColor: isOpened ? '#43ACE3' : '#CFEFFF',
          '&:hover': {
            backgroundColor: '#43ACE3',
          },
          borderRadius: '50%',
        }}
      >
        {isOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </GDMIconButton>
    </GDMListItemButton>
  );
};

export default AdminListListItemHeadMobile;
