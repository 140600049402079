import { GDMTypography } from 'components';
import { GDMContainer } from 'components/gdm-container';
import { GDMTable } from 'components/gdm-table';
import { GDMTableBody } from 'components/gdm-table-body';
import { GDMTableContainer } from 'components/gdm-table-container';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { AdminListTableHeadDesktop } from '../admin-list-table-head-desktop';
import { AdminListTableItemDesktop } from '../admin-list-table-item-desktop';
import { AdminListTableDesktopComponent } from './AdminListTableDesktop.type';

const AdminListTableDesktop: AdminListTableDesktopComponent = ({
  adminHeaderLabels,
  items,
  changeOrder,
  adminIdSelectedToDelete,
  handleOpenDeleteAdminModal,
  emptyMessage,
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();

  return (
    <GDMTableContainer component={GDMContainer}>
      <GDMTable aria-label="simple table">
        <AdminListTableHeadDesktop
          adminHeaderLabels={adminHeaderLabels}
          changeOrder={changeOrder}
        />

        <GDMTableBody>
          {items.map(admin => (
            <AdminListTableItemDesktop
              handleOpenDeleteAdminModal={handleOpenDeleteAdminModal}
              adminIdSelectedToDelete={adminIdSelectedToDelete}
              key={`admin-table-desktop-${admin.id}`}
              admin={admin}
            />
          ))}
        </GDMTableBody>
      </GDMTable>

      {items.length === 0 && !!emptyMessage && (
        <GDMTypography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}
    </GDMTableContainer>
  );
};

export default AdminListTableDesktop;
