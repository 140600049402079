import Api from 'core/api/clients/api/Api';

const upSertRecommendation = async (body: any): Promise<any> => {
  const url = `/Recommendation`;
  return Api.post({
    url,
    body: JSON.stringify(body),
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const saveRecommendation = async ({
  recommendationId,
  recommendationName,
  recommendationVarietyId,
}: {
  recommendationId: string;
  recommendationName: string;
  recommendationVarietyId: string;
}) => {
  return Api.put({
    url: '/Recommendation',
    body: {
      recommendationId,
      recommendationName,
      recommendationVarietyId,
    },
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

const rattingRecomendation = async ({
  recommendationId,
  rattingId,
  userEvaluation,
}: {
  recommendationId: string;
  rattingId: string;
  userEvaluation: string;
}) => {
  return Api.post({
    url: '/RecommendationRatting',
    body: {
      recommendationId,
      rattingId,
      userEvaluation,
    },
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

const getRattingParameters = async () => {
  return Api.get({
    url: `/Ratting`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getRecommendationToDownload = async id => {
  return Api.get({
    url: `/Recommendation/GetPdfData/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getListRecommendation = async filters => {
  const body = {
    page: filters.page,
    itemsPerPage: filters.itemsPerPage,
    orderDesc: filters.orderDesc,
    orderColum: filters.orderColumn,
    search: filters.search,
    brand: filters.brand,
    culture: filters.culture,
    technology: filters.technology,
    genotype: filters.genotype,
    status: filters.status,
    state: filters.state,
    city: filters.city,
    role: filters.role,
    multiplier: filters.multiplier,
    distributor: filters.distributor,
    user: filters.user,
    periodFrom: filters.periodFrom,
    periodTo: filters.periodTo,
  };
  return Api.post({
    url: '/Recommendation/ListRecommendations',
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const updateRecommendationStatus = async ({
  recommendationId,
  statusId,
}: {
  recommendationId: string;
  statusId: string;
}) => {
  return Api.put({
    url: '/Recommendation/UpdateRecommendationStatus',
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body: { recommendationId, statusId },
  }).then(response => response);
};
const getRecommendationStatus = async () => {
  return Api.get({
    url: '/RecommendationStatus',
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const getVarietiesToCompare = async (ids: string[]) => {
  return Api.post({
    url: `/Catalog/CompareGenotype`,
    body: {
      genotypeIds: [...ids],
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getRecommendationById = async id => {
  return Api.get({
    url: `/Recommendation/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const deleteRecommendationFile = async id => {
  return Api.delete({
    url: `/RecommendationFile/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const deleteRecommendation = async id => {
  return Api.delete({
    url: `/Recommendation/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const addFileRecommendation = async formData => {
  return Api.post({
    url: `/RecommendationFile`,
    body: formData,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getPendingRecommendations = async ({
  columnName,
  orderDesc,
}: {
  columnName?: string;
  orderDesc?: boolean;
}) => {
  let paramsStr = '';

  const params = [
    { value: columnName, key: 'OrderColumn' },
    { value: orderDesc, key: 'OrderDesc' },
  ];
  params.forEach(param => {
    if (param.value) {
      paramsStr += !paramsStr ? '?' : '&';
      paramsStr += `${param.key}=${param.value}`;
    }
  });

  return Api.get({
    url: `/Recommendation/ListPendingRecommendations${paramsStr}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const updatePendingRecommendationStatus = async ({
  data,
}: {
  data: { recommendationId: string; statusId: string }[];
}) => {
  return Api.put({
    url: '/Recommendation/UpdatePendingRecommendationStatus',
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body: data,
  }).then(response => response);
};

export const RecommendationService = {
  upSertRecommendation,
  saveRecommendation,
  rattingRecomendation,
  getRattingParameters,
  getRecommendationToDownload,
  getListRecommendation,
  updateRecommendationStatus,
  getRecommendationStatus,
  getVarietiesToCompare,
  getRecommendationById,
  deleteRecommendationFile,
  deleteRecommendation,
  addFileRecommendation,
  getPendingRecommendations,
  updatePendingRecommendationStatus,
};
