import { Check } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import { GDMButton } from 'components';
import { useEffect, useState } from 'react';
import {
  recommendationButtonCheckIconStyles,
  recommendationButtonsGridStyles,
  recommendationButtonsGroupStyles,
  recommendationButtonStyles,
} from './DashboardRecommendationButtons.styles';

const DashboardRecommendationButtons = ({
  dashboardFilters,
  setDashboardFilters,
  handleUpdateDashboard,
  statusData,
  getLabelTranslation,
  theme,
  mode = 'button-group',
}) => {
  const [selected, setSelected] = useState('all');
  const [statusButtonsToRender, setStatusButtonsToRender] = useState([]);

  const isGroup = mode === 'button-group';

  useEffect(() => {
    setStatusButtonsToRender(statusData?.filter(s => !!s.id) || []);
  }, [statusData]);

  useEffect(() => {
    if (!dashboardFilters.status) {
      setSelected('all');
    } else if (Array.isArray(dashboardFilters.status) && dashboardFilters.status?.length) {
      setSelected(dashboardFilters.status?.[0] || 'all');
    } else if (
      !Array.isArray(dashboardFilters.status) &&
      typeof dashboardFilters.status === 'string' &&
      !!dashboardFilters.status
    ) {
      setSelected(dashboardFilters.status);
    }
  }, [dashboardFilters.status]);

  const handleClickAllButton = () => {
    const updatedFilters = {
      ...dashboardFilters,
      status: undefined,
    };

    const newData = Object.fromEntries(
      Object.entries(updatedFilters).filter(([_, v]) => v !== undefined),
    );
    setSelected('all');
    setDashboardFilters(newData);
    handleUpdateDashboard(newData);
  };

  const handleClickButton = status => {
    const newData = {
      ...dashboardFilters,
      status: [status.id.toString()],
    };

    setSelected(status.id);

    setDashboardFilters(newData);
    handleUpdateDashboard(newData);
  };

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="group filter status"
      sx={
        isGroup
          ? {
              ...recommendationButtonsGroupStyles(theme),
              minWidth: `calc(100px * ${(statusButtonsToRender?.length || 0) + 1})`,
            }
          : recommendationButtonsGridStyles(theme)
      }
    >
      <GDMButton
        variant={!dashboardFilters?.status ? 'contained' : 'outlined'}
        onClick={handleClickAllButton}
      >
        {selected === 'all' && <Check sx={recommendationButtonCheckIconStyles} />}
        {getLabelTranslation('dashboard_filter_status_all')}
      </GDMButton>
      {statusButtonsToRender.map(status => (
        <GDMButton
          variant={
            dashboardFilters?.status?.length > 0 && dashboardFilters?.status[0] === status?.id
              ? 'contained'
              : 'outlined'
          }
          key={status.id}
          sx={{
            ...recommendationButtonStyles,
            ...(isGroup && selected === status.id ? { flex: '1.3!important' } : {}),
          }}
          onClick={() => handleClickButton(status)}
        >
          {selected === status.id && <Check sx={recommendationButtonCheckIconStyles} />}

          {getLabelTranslation(`status_reduced_${status?.name.toLowerCase()}`)}
        </GDMButton>
      ))}
    </ButtonGroup>
  );
};

export default DashboardRecommendationButtons;
