import { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import CustomFormLabel from '../../../gdm-custom-input-label/GDMCustomInputLabel';
import { GDMCustomRoleFieldsComponent } from './GDMCustomRoleFields.type';

const GDMCustomRoleFields: GDMCustomRoleFieldsComponent = ({
  handlers,
  config,
  entityType,
  viewType,
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const location = useLocation();
  const type = new URLSearchParams(location.search).get('type');
  const [sellerGdmRoleId, setSellerGdmRoleId] = useState<string>('');

  useEffect(() => {
    if (!config.roles.length) return;
    if (type === 'seller-gdm') {
      const sellerGdmRole = config.roles.filter(role => role.name === 'SELLER_GDM')[0];
      handlers.roleId({ target: { value: sellerGdmRole.id } });
      setSellerGdmRoleId(sellerGdmRole.id);
    }
  }, [config.roles, type, handlers.roleId]);

  const cssResetTextfield = {
    input: {
      background: 'initial',
      border: 'none',
    },
  };
  const fieldConfig = {
    'Vendedor GDM': (
      <>
        <Grid item xs={12} sm={12} md={3}>
          <CustomFormLabel
            label={
              viewType === 'view'
                ? getLabelTranslation('customRolesInputs_profile')
                : getLabelTranslation('customRolesInputs_select_profile')
            }
            required={viewType !== 'view'}
            fontSize="16px"
            color="#222222"
          />
          <FormControl fullWidth>
            <InputLabel sx={{ fontSize: '16px' }}>
              {getLabelTranslation('customRolesInputs_gdm_seller')}
            </InputLabel>
            <Select
              IconComponent={IoIosArrowDown}
              value={sellerGdmRoleId ?? config.formData.roleId}
              disabled
              onChange={handlers.roleId}
              variant="outlined"
              label={getLabelTranslation('customRolesInputs_profile')}
              fullWidth
              sx={{
                background: '#fff',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                color: '#222222',
                '.MuiOutlinedInput-notchedOutline	': {
                  legend: {
                    display: 'none',
                  },
                },
                '&.Mui-disabled': {
                  backgroundColor: '#F2F4F9',
                  color: '#A1A9B8',
                },
              }}
            >
              <MenuItem key="seller_gdm">
                {getLabelTranslation('customRolesInputs_gdm_seller')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9} />
      </>
    ),
    Distribuidor: (
      <>
        <Grid item xs={12} sm={12} md={3}>
          <CustomFormLabel
            label={getLabelTranslation('customRolesInputs_select_gdm_seller')}
            fontSize="16px"
            color="#222222"
          />
          <Autocomplete
            popupIcon={<IoIosArrowDown />}
            sx={{
              input: {
                background: '#fff',
                border: 'none',
                '&::placeholder': {
                  fontSize: '14px',
                },
              },
              background: config.viewType === 'view' ? '#F2F4F9' : '#fff',
            }}
            options={config.sellers}
            getOptionLabel={option => option.name}
            value={config.sellers.find(seller => seller.id === config.formData.sellerId) || null}
            onChange={handlers.sellerId}
            disabled={config.viewType === 'view'}
            renderInput={params => (
              <TextField
                {...params}
                focused={false}
                sx={cssResetTextfield}
                placeholder={getLabelTranslation('customRolesInputs_gdm_seller')}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <CustomFormLabel
            label={getLabelTranslation('customRolesInputs_select_multiplier')}
            fontSize="16px"
            color="#222222"
          />
          <Autocomplete
            popupIcon={<IoIosArrowDown />}
            options={config.multipliers}
            getOptionLabel={option => option.name}
            sx={{
              background: config.viewType === 'view' ? '#F2F4F9' : '#fff',
              input: {
                background: '#fff',
                border: 'none',
              },
            }}
            value={
              config.multipliers.find(
                multiplier => multiplier.id === config.formData.multiplierId,
              ) || null
            }
            onChange={handlers.multiplierId}
            disabled={config.viewType === 'view'}
            renderInput={params => (
              <TextField
                {...params}
                focused={false}
                sx={cssResetTextfield}
                placeholder={getLabelTranslation('customRolesInputs_select_multiplier')}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} />
      </>
    ),
    Multiplicador: (
      <>
        <Grid item xs={12} sm={12} md={3}>
          <CustomFormLabel
            label={getLabelTranslation('customRolesInputs_select_gdm_seller')}
            required
            fontSize="16px"
            color="#222222"
          />
          <Autocomplete
            popupIcon={<IoIosArrowDown />}
            options={config.sellers}
            getOptionLabel={option => option.name}
            value={config.sellers.find(seller => seller.id === config.formData.sellerId) || null}
            onChange={handlers.sellerId}
            disabled={config.viewType === 'view'}
            sx={{
              background: config.viewType === 'view' ? '#F2F4F9' : '#fff',
              input: {
                border: 'none',
              },
              '.Mui-disabled': {
                background: '#F2F4F9',
              },
            }}
            renderInput={params => (
              <TextField
                {...params}
                focused={false}
                sx={cssResetTextfield}
                placeholder={getLabelTranslation('customRolesInputs_select_gdm_seller')}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} />
      </>
    ),
  };

  return fieldConfig[entityType] || null;
};

export default GDMCustomRoleFields;
