import { IconButton, InputAdornment, TextField } from '@mui/material';
import { GDMContainer } from 'components/gdm-container';
import { GDMMenuItem } from 'components/gdm-menu-item';
import { GDMSelect } from 'components/gdm-select';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useEffect, useState } from 'react';
import { IoClose, IoSearchOutline } from 'react-icons/io5';
import { TableFilterColumn } from './GDMTableFilter.type';

const GDMTableFilter = ({
  handleSearch,
  columns,
}: {
  handleSearch: any;
  columns: Array<TableFilterColumn>;
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();

  const [selectedColumn, setSelectedColumn] = useState(null);
  const [query, setQuery] = useState<any>('');
  const [columnsFilter, setColumnsFilter] = useState(columns);

  useEffect(() => {
    if (columnsFilter === columns) setColumnsFilter(columns);
  }, [columns]);

  useEffect(() => {
    handleSearch(query, selectedColumn);
  }, [selectedColumn, query]);

  return (
    <GDMContainer
      sx={{
        display: 'grid',
        gridTemplateColumns: '0.5fr 1fr',
        gap: '16px',
        '@media (max-width: 800px)': {
          gridTemplateColumns: '1fr',
          gap: '5px',
          marginBottom: '24px',
          width: '100%',
        },
      }}
    >
      <GDMSelect
        onChange={event => {
          setSelectedColumn(event.target.value);
        }}
        defaultValue="none"
        variant="outlined"
        size="small"
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '22.4px',
          color: '#222',
          textAlign: 'start',
        }}
      >
        {!selectedColumn && (
          <GDMMenuItem key={0} value="none" selected>
            {getLabelTranslation('search_for')}
          </GDMMenuItem>
        )}

        {columns.map(column => (
          <GDMMenuItem key={column.column_name} value={column.column_name}>
            {column.column_title}
          </GDMMenuItem>
        ))}
      </GDMSelect>
      <TextField
        variant="outlined"
        size="small"
        onChange={query => {
          setQuery(query.target.value);
          handleSearch(query, selectedColumn);
        }}
        value={query}
        placeholder={getLabelTranslation('search')}
        InputProps={
          query
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setQuery('');
                        handleSearch('', '');
                      }}
                    >
                      <IoClose />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <IoSearchOutline />
                  </InputAdornment>
                ),
              }
        }
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '22.4px',
          color: '#222',
        }}
      />
    </GDMContainer>
  );
};

export default GDMTableFilter;
