/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const getLanguageKeys = async (iso: string, route?: string): Promise<any> => {
  return Api.get({
    url: `/LanguageKeys/?iso=${iso}${!!route ? `&group=${route}` : ''}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getAllLanguageKeys = async (language: string): Promise<any> => {
  return Api.get({
    url: `/LanguageKeys/ListAllLanguageKeysByIso?iso=${language}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const LanguageKeysService = {
  getLanguageKeys,
  getAllLanguageKeys,
};
