/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const getCultures = async (): Promise<any> => {
  return Api.get({
    url: `/Culture`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const CulturesServices = {
  getCultures,
};
