import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { IoNotificationsOutline } from 'react-icons/io5';

import { Badge, Popover } from '@mui/material';

import { GDMButton, GDMContainer, GDMTypography, GDMDivider } from 'components';

import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { TbBuildingCottage, TbNotes } from 'react-icons/tb';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { GDMNotificationsComponent } from './GDMNotifications.type';

const GDMNotifications: GDMNotificationsComponent = () => {
  const { user } = useStore(UserProfileStore);
  const { getLabelTranslation } = useLanguageSwitcher();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setNotifications(user?.hasNotifications);
  }, [user]);

  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /** TODO: Remove icon coupling */
  return (
    <GDMContainer>
      <GDMButton
        disableRipple
        sx={{ height: '100%', ':hover': { background: 'none' } }}
        onClick={handleAvatarClick}
      >
        <Badge
          color="error"
          overlap="circular"
          badgeContent={user ? user.totalPendingHarvest + user.totalPendingPlanting : '0'}
          invisible={!notifications}
          showZero
          max={99}
        >
          <IoNotificationsOutline size={24} color="#777D88" />
        </Badge>
      </GDMButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {notifications ? (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '320px',
              height: '288px',
              padding: '24px',
              gap: '32px',
            }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <GDMTypography
                sx={{
                  fontSize: '14px',
                  lineHeight: '19.6px',
                  fontWeight: 700,
                  color: 'black',
                  width: '70%',
                  textAlign: 'center',
                }}
              >
                {getLabelTranslation('pending_actions_to_view')}
              </GDMTypography>
              <GDMTypography
                sx={{
                  fontSize: '14px',
                  lineHeight: '19.6px',
                  fontWeight: 400,
                  color: 'black',
                  width: '70%',
                  textAlign: 'center',
                }}
              >
                {getLabelTranslation('notifications_click_to_view')}
              </GDMTypography>
            </GDMContainer>
            <GDMContainer
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              {user.totalPendingPlanting > 0 && (
                <GDMContainer
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <TbNotes size={25} />
                  <GDMContainer>{getLabelTranslation('planting_pending')}</GDMContainer>
                  <GDMTypography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '18px',
                      width: '18px',
                      background: 'red',
                      borderRadius: '50%',
                      fontSize: '10px',
                      fontWeight: '700',
                      color: '#fff',
                    }}
                  >
                    {user.totalPendingPlanting}
                  </GDMTypography>
                </GDMContainer>
              )}

              {user.totalPendingPlanting > 0 && user.totalPendingHarvest > 0 && <GDMDivider />}

              {user.totalPendingHarvest > 0 && (
                <GDMContainer
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <TbBuildingCottage size={25} />
                  <GDMContainer>{getLabelTranslation('harvest_pending')}</GDMContainer>
                  <GDMTypography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '18px',
                      width: '18px',
                      background: 'red',
                      borderRadius: '50%',
                      fontSize: '10px',
                      fontWeight: '700',
                      color: '#fff',
                    }}
                  >
                    {user.totalPendingHarvest}
                  </GDMTypography>
                </GDMContainer>
              )}
            </GDMContainer>
            <GDMButton
              variant="contained"
              sx={{
                fontSize: '14px',
              }}
              onClick={() => {
                navigate('/recommendation/pending');
                handleAvatarClose();
              }}
            >
              {getLabelTranslation('view_recommendations_pending')}
            </GDMButton>
          </GDMContainer>
        ) : (
          <GDMContainer
            sx={{
              padding: '24px',
            }}
          >
            <GDMTypography
              sx={{ textAlign: 'center', fontWeight: 700, color: '#222', fontSize: '14px' }}
            >
              {getLabelTranslation('dont_have_notifications')}
            </GDMTypography>
          </GDMContainer>
        )}
      </Popover>
    </GDMContainer>
  );
};

export default GDMNotifications;
