/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/function-component-definition */
// ThemeSwitcher.tsx
import { useEffect, useMemo } from 'react';

import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';

import FlagArgentina from 'assets/images/argentina-flag.png';
import FlagBrasil from 'assets/images/brasil-flag.png';
import LogoBrandDonMarioArgentina from 'assets/images/donmario-argentina-logo.svg';
import LogoBrandDonMarioBrasil from 'assets/images/donmario-brasil-logo.svg';
import LogoBrandGDM from 'assets/images/gdm-logo.svg';

import { ThemeName } from './themeContext.d';

const allThemes = [
  {
    name: 'DONMARIOAR',
    image: LogoBrandDonMarioArgentina,
    label: 'DonMario Argentina',
    flag: FlagArgentina,
  },
  {
    name: 'DONMARIOBR',
    image: LogoBrandDonMarioBrasil,
    label: 'DonMario Brasil',
    flag: FlagBrasil,
  },
  { name: 'GDM', image: LogoBrandGDM, label: 'GDM Administradores' },
];

const ThemeSwitcher = () => {
  const themeBase = useTheme();
  const { themeName, switchTheme } = useThemeSwitcher();
  const { user } = useStore(UserProfileStore);
  const userCountry = user?.countryName;
  const handleThemeChange = (event: SelectChangeEvent<ThemeName>) => {
    const formattedValue = event.target.value.replace(/-/g, '');
    switchTheme(formattedValue as ThemeName);
    localStorage.setItem('theme_recomendador', formattedValue as ThemeName);
  };

  const userThemes = useMemo(() => {
    if (!user || !user.brands) {
      return [];
    }

    return allThemes.filter(theme =>
      user.brands.some(brand => brand.name.replace(/-/g, '') === theme.name),
    );
  }, [user]);

  useEffect(() => {
    if (userCountry === 'Argentina') {
      switchTheme('DONMARIOAR');
      localStorage.setItem('theme_recomendador', 'DONMARIOAR');
    } else if (userCountry === 'Brasil') {
      switchTheme('DONMARIOBR');
      localStorage.setItem('theme_recomendador', 'DONMARIOBR');
    }
  }, [userCountry]);

  return (
    <Box display="flex" alignItems="center">
      <FormControl variant="outlined">
        <Select
          sx={{
            width: '236px',
            height: '48px',
            bgcolor: '#fff',
            div: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            '.MuiSvgIcon-root ': {
              fill: '#222',
            },
          }}
          labelId="theme-switcher-label"
          id="theme-switcher"
          value={themeName}
          onChange={handleThemeChange}
          IconComponent={ExpandMoreIcon}
        >
          {userThemes.map(theme => (
            <MenuItem
              sx={{
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ':hover': { backgroundColor: themeBase.palette.secondary.main },
              }}
              key={theme.name}
              value={theme.name}
            >
              <img
                src={theme.image}
                alt={theme.label}
                style={{ width: '152', maxHeight: '48px', margin: '0 auto' }}
              />
              {theme.flag && (
                <img
                  src={theme.flag}
                  style={{ width: '17px', height: '13px', marginRight: '10px' }}
                  alt={theme.label}
                />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ThemeSwitcher;
