import { SxProps } from '@mui/material';
import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const dataCirclesContainerStyles: SxProps = {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: {
    xs: '80px',
    sm: '80px',
    md: '120px',
    lg: '120px',
    xl: '120px',
  },
  height: {
    xs: '80px',
    sm: '80px',
    md: '120px',
    lg: '120px',
    xl: '120px',
  },
};

export const topCircleStyles: SxProps = {
  color: '#F2F4F9',
  position: 'absolute',
  width: {
    xs: '68px!important',
    sm: '68px!important',
    md: '120px!important',
    lg: '120px!important',
    xl: '120px!important',
  },
  height: {
    xs: '68px!important',
    sm: '68px!important',
    md: '120px!important',
    lg: '120px!important',
    xl: '120px!important',
  },
};

export const topCircleTextContainerStyles: SxProps = {
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const topCircleTextStyles: SxProps = {
  lineHeight: '33.37px!important',
  fontWeight: '700!important',
  color: '#3A3A3C',
  fontSize: {
    xs: '20px!important',
    sm: '20px!important',
    md: '30px!important',
    lg: '30px!important',
    xl: '30px!important',
  },
};

export const bottomCircleStyles: SxPropsThemeFunction = theme => ({
  position: 'absolute',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.primary.main,
  bottom: {
    xs: '-8px',
    sm: '-8px',
    md: '-12px',
    lg: '-12px',
    xl: '-12px',
  },
  right: {
    xs: '16px',
    sm: '16px',
    md: '-12px',
    lg: '-12px',
    xl: '-12px',
  },
  width: {
    xs: '38px',
    sm: '38px',
    md: '65px',
    lg: '65px',
    xl: '65px',
  },
  height: {
    xs: '38px',
    sm: '38px',
    md: '65px',
    lg: '65px',
    xl: '65px',
  },
});

export const bottomCircleTextStyles: SxProps = {
  fontWeight: '700!important',
  color: '#FFFFFF',
  fontSize: {
    xs: '14px!important',
    sm: '14px!important',
    md: '22px!important',
    lg: '22px!important',
    xl: '22px!important',
  },
  lineHeight: {
    xs: '14px!important',
    sm: '14px!important',
    md: '32px!important',
    lg: '32px!important',
    xl: '32px!important',
  },
};
