import { LiaBoxSolid, LiaUserLockSolid } from 'react-icons/lia';
import { PiPlant, PiSuitcase, PiUserSquareThin } from 'react-icons/pi';

import { NavigateButton } from 'core/components/NavigateButton';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';

import { GDMContainer, GDMDivider, GDMLoading, GDMTypography } from 'components';
import { buttonsContainer, containerStyle } from './ListMenu.style';

export default function ListMenuPage(): JSX.Element {
  const { getLabelTranslation: gLT, loading } = useLanguageSwitcher();

  const { user } = UserProfileStore.getState();
  const userRole = user?.role;

  const disableButtonsByRole = (typeOfRegister: string, roleOfUser: string) => {
    if (typeOfRegister === 'administrator') {
      return roleOfUser !== 'ADMINISTRATOR';
    }
    if (typeOfRegister === 'seller_gdm' && roleOfUser === 'SELLER_GDM') {
      return true;
    }
    if (roleOfUser === 'DISTRIBUTOR_SELLER' || roleOfUser === 'MULTIPLIER_SELLER') {
      return true;
    }
    if (roleOfUser === 'DISTRIBUTOR_MANAGER' || roleOfUser === 'MULTIPLIER_MANAGER') {
      if (
        typeOfRegister === 'distributor' ||
        typeOfRegister === 'multiplier' ||
        typeOfRegister === 'seller_multiplier' ||
        typeOfRegister === 'seller_distributor'
      ) {
        return false;
      }
    }
    return roleOfUser !== 'ADMINISTRATOR' && roleOfUser !== 'SELLER_GDM';
  };

  const buttonsData = [
    {
      label: gLT('administrator'),
      icon: <LiaUserLockSolid />,
      link: '/admin-list',
      disabled: disableButtonsByRole('administrator', userRole),
    },
    {
      label: gLT('multiplier'),
      icon: <PiPlant />,
      link: '/multiplier-list',
      disabled: disableButtonsByRole('multiplier', userRole),
    },
    {
      label: gLT('distributor'),
      icon: <LiaBoxSolid />,
      link: '/distributor-list',
      disabled: disableButtonsByRole('distributor', userRole),
    },
    {
      label: gLT('seller_gdm'),
      icon: <PiSuitcase />,
      link: '/account-seller-gdm-list',
      disabled: disableButtonsByRole('seller_gdm', userRole),
    },
    {
      label: gLT('seller_multiplier'),
      icon: <PiSuitcase />,
      link: '/account-seller-mult-list',
      disabled: disableButtonsByRole('seller_multiplier', userRole),
    },
    {
      label: gLT('seller_distributor'),
      icon: <PiSuitcase />,
      link: '/account-seller-dist-list',
      disabled: disableButtonsByRole('seller_distributor', userRole),
    },
    {
      label: gLT('manager_multiplier'),
      icon: <PiUserSquareThin />,
      link: '/account-manager-mult-list',
      disabled: disableButtonsByRole('manager_multiplier', userRole),
    },
    {
      label: gLT('manager_distributor'),
      icon: <PiUserSquareThin />,
      link: '/account-manager-dist-list',
      disabled: disableButtonsByRole('manager_distributor', userRole),
    },
  ];

  if (loading) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  return (
    <GDMContainer sx={containerStyle}>
      <GDMContainer>
        <GDMTypography
          sx={{
            color: '#A1A9B8',
            fontSize: '14px',
            margin: '0px',
            paddingTop: {
              xs: '32px',
              md: '0px',
            },
          }}
          display="block"
          gutterBottom
        >
          {gLT('administrative_view_records')}
        </GDMTypography>
      </GDMContainer>
      <GDMTypography
        className="txt-mobile"
        sx={{
          padding: {
            xs: '32px',
            md: '32px 0px',
          },
        }}
        fontSize={32}
        color="#222"
        fontWeight={700}
      >
        {gLT('view_records')}
      </GDMTypography>
      <GDMDivider style={{ width: '100%' }} />
      <GDMTypography
        sx={{
          fontSize: {
            xs: '20px',
            md: '24px',
          },
          padding: {
            xs: '32px',
            md: '32px 0px',
          },
        }}
        align="center"
        color="#222"
        fontWeight={700}
      >
        {`${gLT('select_option')}:`}
      </GDMTypography>
      <GDMContainer sx={buttonsContainer}>
        {buttonsData.map(button => (
          <NavigateButton
            disabled={button.disabled}
            key={button.label}
            label={button.label}
            icon={button.icon}
            to={button.link}
          />
        ))}
      </GDMContainer>
    </GDMContainer>
  );
}
