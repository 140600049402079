export type CountriesDomain = [
  {
    id: string;
    name: string;
    defaultLanguageIso: string;
  },
];

export enum CountriesDomainEnum {
  Brasil = 'Brasil',
  Argentina = 'Argentina',
}

export enum CountriesPhoneCodeDomainEnum {
  Brasil = '55',
  Argentina = '54',
}
