import { UserProfileService } from 'core/api/services/user';
import { loadUserProfile, loadChangeLanguage, loadUserProfileFail } from 'core/store/stores';

const execute = async (language: string): Promise<void> => {
  const errorCallback = () => {
    loadUserProfileFail();
  };

  loadUserProfile();

  const iso = () => {
    if (language === '56d656e4-42e4-41c0-8b69-7f3eb0a894ab') return 'pt-BR';

    return 'es-AR';
  };

  return UserProfileService.changeLanguage(language)
    .then(() => {
      loadChangeLanguage(iso());
    })
    .catch(errorCallback);
};

const LoadChangeLanguageUseCase = {
  execute,
};

export default LoadChangeLanguageUseCase;
