/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const getDistributors = async ({
  currentPage,
  itemsPerPage,
  search = '',
  searchColumn,
  orderDesc,
  orderColumn = null,
}): Promise<any> => {
  let url = `/Distributors?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (searchColumn && searchColumn !== 'all') {
    url += `&SearchColumn=${searchColumn}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }
  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const upSertDistributor = async (method, body: any, id = ''): Promise<any> => {
  return Api[method]({
    url: `/Distributors/${id}`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};
const deleteDistributorById = async (id: string): Promise<any> => {
  return Api.delete({
    url: `/Distributors/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getDistributorById = async (id: string | number): Promise<any> => {
  return Api.get({
    url: `/Distributors/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getDistributorStatisticsById = async (id: string): Promise<any> => {
  return Api.get({
    url: `/Distributors/GetDistributorStatistics/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getDistributorAccountsById = async ({
  id,
  page,
  orderDesc,
  orderColum,
}: {
  id: string;
  orderDesc: boolean;
  orderColum: string;
  page: number;
}): Promise<any> => {
  return Api.get({
    url: `/Distributors/accounts/distributor/${id}?Page=${page}&ItemsPerPage=4&OrderDesc=${orderDesc}${orderColum && `&OrderColum=${orderColum}`}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
export const DistributorService = {
  getDistributors,
  upSertDistributor,
  deleteDistributorById,
  getDistributorById,
  getDistributorStatisticsById,
  getDistributorAccountsById,
};
