import { SxProps } from '@mui/material';

export const containerStyle: SxProps = {
  backgroundColor: '#FAFAFA',
  borderRadius: '24px',
  padding: '64px 64px',
  boxShadow: ' 0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  '.MuiFormHelperText-root': {
    marginLeft: '0',
  },
  '@media only screen and (max-width: 600px)': {
    boxShadow: 'none',
    borderRadius: 0,
    marginTop: '20px',
    p: {
      textAlign: 'center',
    },
    'p.txt-mobile': {
      fontSize: '32px',
    },
  },
  '@media only screen and (max-width: 768px)': {
    boxShadow: 'none',
    p: {
      textAlign: 'center',
    },
    'p.txt-mobile': {
      fontSize: '32px',
    },
  },
  '@media only screen and (max-width: 850px)': {
    boxShadow: 'none',
    p: {
      textAlign: 'center',
      wordBreak: 'break-word',
    },
  },
  '@media only screen and (max-width: 900px)': {
    boxShadow: 'none',
    padding: '20px 0px',
    p: {
      textAlign: 'center',
      wordBreak: 'break-word',
    },
    hr: {
      marginTop: '12px',
      marginBottom: '12px',
    },
  },
};

export const buttonsContainer: SxProps = {
  margin: '0 auto',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  flexWrap: 'wrap',
  padding: '0 12px',
  '@media only screen and (max-width: 600px)': {
    padding: '0 24px',
  },
};
export const flapStyleActive: SxProps = {
  display: 'flex',
  alignItems: 'center',
  background: 'white',
  justifyContent: 'center',
  gap: '8px',
  padding: '8px 24px',
  borderRadius: '8px 8px 0 0',
  border: '1px solid #a1a9b8',
  borderBottom: '1px solid white',
  cursor: 'pointer',
};

export const flapStyleDisabled: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: '12px 32px',
  borderBottom: '1px solid white',
  cursor: 'pointer',
};
