import { GDMInputLabel } from 'components';

import { GDMCustomInputLabelComponent } from './GDMCustomInputLabel.type';

const GDMCustomInputLabel: GDMCustomInputLabelComponent = ({
  label,
  labelId = '',
  required = false,
  fontSize = '16px',
  color = '#000',
}) => {
  return (
    <GDMInputLabel id={labelId} sx={{ fontSize, color, marginBottom: '10px' }}>
      {label} {required && <span style={{ color: '#FB2047' }}>*</span>}
    </GDMInputLabel>
  );
};

export default GDMCustomInputLabel;
