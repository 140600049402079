/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Divider, FormControl, RadioGroup } from '@mui/material';
import { GDMButton, GDMContainer, GDMSecureImage, GDMTypography } from 'components';
import { useThemeContext } from 'core/design-system/styles/ThemeContext';
import { ThemeName } from 'core/design-system/styles/themeContext.d';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { PiArrowLeftBold, PiArrowRightBold, PiLeafBold } from 'react-icons/pi';
import {
  ImageRadio,
  boxBrand,
  brandContainer,
  brandImageContainer,
  cardContainer,
} from './SelectBrand.styles';

type BrandsSelectorProps = {
  handleChose?: () => void;
  handleBack?: () => void;
  handleExit?: () => void;
  termWasOpen: boolean;
};

export default function SelectBrand({
  handleChose,
  handleBack,
  handleExit,
  termWasOpen,
}: BrandsSelectorProps) {
  const { user } = useStore(UserProfileStore);
  const [value, setValue] = useState('');
  const { switchTheme } = useThemeContext();
  const { getLabelTranslation } = useLanguageSwitcher();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleNextClick = () => {
    const formattedValue = value.replace(/-/g, '');
    localStorage.setItem('theme_recomendador', formattedValue as ThemeName);
    if (handleChose) {
      switchTheme(formattedValue as ThemeName);
      handleChose();
    }
  };
  return (
    <GDMContainer sx={brandContainer}>
      <GDMContainer sx={boxBrand}>
        <GDMContainer sx={{ display: 'flex', flexDirection: 'column' }}>
          <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
            <PiLeafBold fontSize={64} />
            <GDMTypography fontSize={34} fontWeight={700}>
              {getLabelTranslation('select_brand')}
            </GDMTypography>
          </GDMContainer>
          {user.onboardingStatus && (
            <GDMTypography
              fontSize={20}
              fontWeight={600}
              sx={{ color: '#777D88', marginLeft: '55px', width: '600px' }}
            >
              {getLabelTranslation('next_login')}
            </GDMTypography>
          )}
        </GDMContainer>
        <Divider orientation="vertical" variant="middle" sx={{ minHeight: '500px' }} />
        <FormControl sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px' }}>
          <RadioGroup
            sx={cardContainer}
            aria-label="options"
            name="options"
            value={value}
            onChange={handleChange}
          >
            {user.brands.map(brand => (
              <GDMContainer sx={brandImageContainer} key={brand.id}>
                <GDMContainer
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '20px',
                  }}
                >
                  <ImageRadio disableRipple value={brand.name} id={brand.id} />
                  <GDMTypography fontSize={14} fontWeight={700} sx={{ width: '100%' }}>
                    {brand.name}
                  </GDMTypography>
                </GDMContainer>
                <label htmlFor={brand.id}>
                  <GDMSecureImage
                    src={brand.logoCdnUrl}
                    alt={brand.name}
                    width={100}
                    height={100}
                  />
                </label>
              </GDMContainer>
            ))}
          </RadioGroup>
        </FormControl>
      </GDMContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <GDMButton
          sx={{
            width: '243px',
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
            },
            border: '1px solid #06C270',
          }}
          variant="outlined"
          onClick={termWasOpen ? handleBack : handleExit}
        >
          <PiArrowLeftBold style={{ marginRight: '8px' }} />
          {termWasOpen ? getLabelTranslation('back') : getLabelTranslation('exit')}
        </GDMButton>

        <GDMButton
          sx={{
            width: '243px',
            boxShadow: 'none',
            color: '#FAFAFA',
            background: '#06C270',
            ':hover': {
              boxShadow: 'none',
              background: '#06C270',
            },
          }}
          variant="contained"
          onClick={handleNextClick}
        >
          {getLabelTranslation('next')}
          <PiArrowRightBold style={{ marginLeft: '8px' }} />
        </GDMButton>
      </Box>
    </GDMContainer>
  );
}
