/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { BsCloudRain } from 'react-icons/bs';
import { CiCalendarDate } from 'react-icons/ci';
import { PiCirclesFourLight } from 'react-icons/pi';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { GDMButton, GDMContainer, GDMTypography } from 'components';
import {
  TbChartBar,
  TbChartPie2,
  TbDna2,
  TbDroplet,
  TbPlant2,
  TbRefresh,
  TbTemperature,
} from 'react-icons/tb';

import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { useMediaQuery } from '@mui/material';
import { GDMSlider } from 'components/gdm-slider';
import SkeletonParams from './components/skeleton-params';
import colors from '../../../core/design-system/style-guide/colors.json';
import GDMPanelField from '../components/gdm-panel-field/GDMPanelField';
import { GDMPanelType } from '../components/gdm-panel-field/GDMPanelField.type';
import { IParamsForm } from './ParamsForm.type';

export default function ParamsForm({
  recommendation,
  setEditedFields,
  handleUpdateRecommendation,
  isRecommendationSaved,
  setFieldsValue,
  setRecommendationEdited,
  isReadOnly = false,
  isPageReloading = false,
}: IParamsForm) {
  const { getLabelTranslation } = useLanguageSwitcher();
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [techs, setTechs] = useState(recommendation?.technologies);
  const [environmentalKeys, setEnvironmentalKeys] = useState([]);
  const [haveAlterations, setHaveAlterations] = useState(false);
  const updateButtonRef = document.getElementById('update-recommendation') as HTMLButtonElement;

  const handleFieldChange = (name, value) => {
    setHaveAlterations(true);
    setRecommendationEdited(true);
    setEditedFields(prev => {
      const updatedFields = { ...prev, [name]: value.toString() };
      return updatedFields;
    });

    setFieldsValue(prev => [...prev, { [name]: value }]);
  };
  const handleUpdateRecommendationClick = async () => {
    await handleUpdateRecommendation();
    setHaveAlterations(false);
  };
  useEffect(() => {
    setTechs(recommendation?.technologies);
  }, [recommendation]);

  const foreachObj = (obj, callback) => {
    // Percorre as entradas do objeto (pares chave-valor)
    for (const [key, value] of Object.entries(obj)) {
      // Verifica se o valor é um objeto
      if (typeof value === 'object' && value !== null) {
        // Executa a função callback passando a chave e o objeto aninhado como argumentos
        callback(key, value);
      }
    }
  };

  useEffect(() => {
    if (recommendation?.environmentalVariables) {
      setEnvironmentalKeys([]);
      foreachObj(recommendation?.environmentalVariables, key => {
        setEnvironmentalKeys(prevKeys => [...prevKeys, key]);
      });
    }
  }, [recommendation?.environmentalVariables]);

  const formatedDate = (data: any) => {
    if (data === 'Early') return getLabelTranslation('early');
    if (data === 'Late') return getLabelTranslation('late');
    if (data === 'Normal') return getLabelTranslation('normal');

    return data;
  };

  return (
    <GDMContainer
      component="div"
      sx={{
        gap: 1,
        marginTop: 1,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        margin: '32px 0',
      }}
    >
      <GDMContainer
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <GDMTypography
          textAlign="left"
          fontWeight={700}
          color="#222222"
          sx={{
            paddingRight: !isDesktop && '14px',
            fontSize: {
              xs: '20px',
              sm: '20px',
              md: '24px',
              lg: '24px',
              xl: '24px',
            },
          }}
        >
          {getLabelTranslation('recommendation_params_form_title')}
        </GDMTypography>
        {!isReadOnly && !isDesktop && (
          <GDMButton
            sx={{
              height: '44px',
              minWidth: '144px',
              width: '144px',
              maxWidth: '144px',
              fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
              fontWeight: 700,
              textWrap: 'nowrap',
            }}
            color="primary"
            variant="contained"
            onClick={handleUpdateRecommendationClick}
            disabled={isRecommendationSaved || !haveAlterations}
            id="update-recommendation"
          >
            {getLabelTranslation('recommendation_params_update')}
          </GDMButton>
        )}
      </GDMContainer>
      <GDMContainer
        component="div"
        sx={{
          gap: 1,
          marginTop: 1,
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: isDesktop && 'center',
        }}
      >
        {!isReadOnly && (
          <GDMContainer
            component="div"
            sx={{
              gap: 1,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <GDMTypography
              fontWeight={300}
              color="#222222"
              sx={{ fontSize: { xs: '16px', sm: '16px', md: '18px', lg: '18px', xl: '18px' } }}
            >
              {getLabelTranslation('recommendation_params_form_subtitle')}
            </GDMTypography>
            <GDMTypography
              fontWeight={300}
              component="div"
              color="#222222"
              sx={{ fontSize: { xs: '16px', sm: '16px', md: '18px', lg: '18px', xl: '18px' } }}
            >
              {getLabelTranslation('recommendation_params_form_description')}
            </GDMTypography>
          </GDMContainer>
        )}
        {!isReadOnly && isDesktop && (
          <GDMButton
            sx={{
              height: '51px',
              width: '220px',
              fontSize: { xs: '11px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
              fontWeight: 700,
              textWrap: 'nowrap',
            }}
            color="primary"
            variant="contained"
            startIcon={<TbRefresh />}
            onClick={handleUpdateRecommendationClick}
            disabled={isRecommendationSaved || !haveAlterations}
            id="update-recommendation"
          >
            {getLabelTranslation('recommendation_params_update')}
          </GDMButton>
        )}
      </GDMContainer>
      {isDesktop ? (
        <GDMContainer
          component="div"
          sx={{
            gap: 1,
            marginTop: 1,
            borderRadius: 2,
          }}
        >
          <GDMContainer
            component="div"
            sx={{
              gap: 2,
              marginTop: 1,
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            {isPageReloading && <SkeletonParams />}
            {((techs && techs.length > 0 && !isReadOnly) ||
              (isReadOnly && recommendation?.technology)) && (
              <GDMPanelField
                id="tech"
                icon={<TbPlant2 color={colors.dark2} size={32} />}
                title={getLabelTranslation('recommendation_params_field_tech')}
                placeholder={getLabelTranslation('recommendation_params_placeholder_tech')}
                type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.SELECT}
                options={techs?.map(x => ({ id: x, name: x }))}
                width={
                  isReadOnly
                    ? recommendation?.technology
                      ? 4
                      : 3
                    : techs && techs.length > 0
                    ? 4
                    : 3
                }
                defaultValue={recommendation?.technology}
                handleFieldChange={handleFieldChange}
                parameterName={recommendation?.technologyParameterName}
                disabled={isRecommendationSaved}
              />
            )}
            {recommendation?.productivity && (
              <GDMPanelField
                icon={<TbChartBar color={colors.dark2} size={32} />}
                title={getLabelTranslation('recommendation_params_field_productivity')}
                type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.INPUT}
                minValue={recommendation?.minProductivity}
                maxValue={recommendation?.maxProductivity}
                defaultValue={recommendation?.productivity}
                updateButtonRef={updateButtonRef}
                width={
                  isReadOnly
                    ? recommendation.technology
                      ? 4
                      : 3
                    : techs && techs.length > 0
                    ? 4
                    : 3
                }
                id="productivity"
                inputLegend="kg/ha"
                handleFieldChange={handleFieldChange}
                parameterName={recommendation?.productivityParameterName}
                disabled={isRecommendationSaved}
                inputRuleLegend={`Min. ${recommendation?.minProductivity}/Max. ${recommendation?.maxProductivity}`}
              />
            )}
            {recommendation?.plantingDensity && (
              <GDMPanelField
                id="plantingDensity"
                icon={<PiCirclesFourLight color={colors.dark2} size={32} />}
                title={getLabelTranslation('recommendation_params_field_plantingDensity')}
                type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.INPUT}
                updateButtonRef={updateButtonRef}
                disabled
                width={
                  isReadOnly
                    ? recommendation.technology
                      ? 4
                      : 3
                    : techs && techs.length > 0
                    ? 4
                    : 3
                }
                defaultValue={recommendation?.plantingDensity}
                inputLegend="pl/m²"
                handleFieldChange={handleFieldChange}
              />
            )}
            {recommendation?.userSowingDate && recommendation?.userSowingDateIsDate && (
              <GDMPanelField
                id="userSowingDate"
                parameterName={recommendation?.userSowingDateParameterName}
                handleFieldChange={handleFieldChange}
                icon={<CiCalendarDate color={colors.dark2} size={32} />}
                title={getLabelTranslation('recommendation_params_field_planting_date')}
                type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.DATE}
                width={
                  isReadOnly
                    ? recommendation.technology
                      ? 4
                      : 3
                    : techs && techs.length > 0
                    ? 4
                    : 3
                }
                defaultValue={
                  isReadOnly
                    ? recommendation?.userSowingDate
                    : `${recommendation?.userSowingDate?.split('/')[2]}/${
                        recommendation?.userSowingDate?.split('/')[1]
                      }/${recommendation?.userSowingDate?.split('/')[0]}`
                }
                minValue={`${recommendation?.minUserSowingDoy?.split('/')[2]}/${
                  recommendation?.minUserSowingDoy?.split('/')[1]
                }/${recommendation?.minUserSowingDoy?.split('/')[0]}`}
                maxValue={`${recommendation?.maxUserSowingDoy?.split('/')[2]}/${
                  recommendation?.maxUserSowingDoy?.split('/')[1]
                }/${recommendation?.maxUserSowingDoy?.split('/')[0]}`}
                disabled={isRecommendationSaved}
                inputRuleLegend={`Min. ${recommendation?.minUserSowingDoy}/Máx. ${recommendation?.maxUserSowingDoy}`}
              />
            )}
            {recommendation?.userSowingDate && !recommendation?.userSowingDateIsDate && (
              <GDMPanelField
                id="userSowingDate"
                parameterName={recommendation?.userSowingDateParameterName}
                icon={<CiCalendarDate color={colors.dark2} size={32} />}
                title={getLabelTranslation('recommendation_params_field_userSowingDate')}
                type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.SELECT}
                width={
                  isReadOnly
                    ? recommendation.technology
                      ? 4
                      : 3
                    : techs && techs.length > 0
                    ? 4
                    : 3
                }
                defaultValue={
                  isReadOnly
                    ? formatedDate(recommendation?.userSowingDate)
                    : recommendation?.userSowingDate
                }
                handleFieldChange={handleFieldChange}
                options={[
                  { id: 'Early', name: getLabelTranslation('early') },
                  { id: 'Normal', name: getLabelTranslation('normal') },
                  { id: 'Late', name: getLabelTranslation('late') },
                ]}
                disabled={isRecommendationSaved}
              />
            )}
          </GDMContainer>
          <GDMContainer
            component="div"
            sx={{
              gap: 2,
              marginTop: 1,
              borderRadius: 2,
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
                xl: 'row',
              },
              justifyContent: 'space-between',
            }}
          >
            {environmentalKeys
              ?.sort((a, b) => a.localeCompare(b))
              .map(env => {
                return (
                  <GDMPanelField
                    id={env}
                    isReadOnly={isReadOnly || isRecommendationSaved}
                    handleFieldChange={handleFieldChange}
                    updateButtonRef={updateButtonRef}
                    icon={
                      env === 'clay' ? (
                        <TbChartPie2 color={colors.dark2} size={32} />
                      ) : env === 'prec' ? (
                        <BsCloudRain color={colors.dark2} size={32} />
                      ) : env === 'temp' ? (
                        <TbTemperature color={colors.dark2} size={32} />
                      ) : env === 'water' ? (
                        <TbDroplet color={colors.dark2} size={32} />
                      ) : env === 'melo' ? (
                        <TbDna2 color={colors.dark2} size={32} />
                      ) : env === 'heterodera' ? (
                        <TbDna2 color={colors.dark2} size={32} />
                      ) : env === 'om' ? (
                        <TbDna2 color={colors.dark2} size={32} />
                      ) : (
                        <TbDna2 color={colors.dark2} size={32} />
                      )
                    }
                    title={getLabelTranslation(
                      `recommendation_params_field_${recommendation?.environmentalVariables[env].name}`,
                    )}
                    type={
                      recommendation?.environmentalVariables[env].feature === 'slider'
                        ? GDMPanelType.INPUT
                        : recommendation?.environmentalVariables[env].feature === 'radio button'
                        ? isReadOnly
                          ? GDMPanelType.STRING
                          : GDMPanelType.RADIO
                        : recommendation?.environmentalVariables[env].feature === 'select'
                        ? GDMPanelType.SELECT
                        : GDMPanelType.STRING
                    }
                    minValue={recommendation?.environmentalVariables[env]?.min}
                    maxValue={recommendation?.environmentalVariables[env]?.max}
                    defaultValue={recommendation?.environmentalVariables[env]?.defaultValue}
                    options={recommendation?.environmentalVariables[env]?.front
                      ?.split('/')
                      ?.map(item => {
                        return { id: item, name: item };
                      })}
                    width={environmentalKeys.length}
                    parameterName={recommendation?.environmentalVariables[env]?.variableName}
                    disabled={isRecommendationSaved}
                    inputLegend={
                      recommendation?.environmentalVariables[env]?.variableName === 'prec'
                        ? 'mm'
                        : recommendation?.environmentalVariables[env]?.variableName === 'temp'
                        ? '°C'
                        : recommendation?.environmentalVariables[env]?.variableName === 'water'
                        ? '%'
                        : recommendation?.environmentalVariables[env]?.variableName === 'clay'
                        ? '%'
                        : recommendation?.environmentalVariables[env]?.variableName === 'om'
                        ? '%'
                        : ''
                    }
                    inputRuleLegend={`Min. ${recommendation?.environmentalVariables[env]?.min}/Máx. ${recommendation?.environmentalVariables[env]?.max}`}
                  />
                );
              })}
          </GDMContainer>
        </GDMContainer>
      ) : (
        <>
          {techs?.length > 0 &&
            recommendation &&
            Object.keys(recommendation).length > 0 &&
            environmentalKeys?.length > 0 && (
              <GDMSlider>
                {((techs && techs.length > 0 && !isReadOnly) ||
                  (isReadOnly && recommendation?.technology)) && (
                  <div className="keen-slider__slide">
                    <GDMPanelField
                      id="tech"
                      icon={<TbPlant2 color={colors.dark2} size={32} />}
                      title={getLabelTranslation('recommendation_params_field_tech')}
                      placeholder={getLabelTranslation('recommendation_params_placeholder_tech')}
                      type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.SELECT}
                      options={techs?.map(x => ({ id: x, name: x }))}
                      width={
                        isReadOnly
                          ? recommendation?.technology
                            ? 4
                            : 3
                          : techs && techs.length > 0
                          ? 4
                          : 3
                      }
                      defaultValue={recommendation?.technology}
                      handleFieldChange={handleFieldChange}
                      parameterName={recommendation?.technologyParameterName}
                      disabled={isRecommendationSaved}
                    />
                  </div>
                )}
                {recommendation?.productivity && (
                  <div className="keen-slider__slide">
                    <GDMPanelField
                      icon={<TbChartBar color={colors.dark2} size={32} />}
                      title={getLabelTranslation('recommendation_params_field_productivity')}
                      type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.INPUT}
                      minValue={recommendation?.minProductivity}
                      maxValue={recommendation?.maxProductivity}
                      defaultValue={recommendation?.productivity}
                      updateButtonRef={updateButtonRef}
                      width={
                        isReadOnly
                          ? recommendation.technology
                            ? 4
                            : 3
                          : techs && techs.length > 0
                          ? 4
                          : 3
                      }
                      id="productivity"
                      inputLegend="kg/ha"
                      handleFieldChange={handleFieldChange}
                      parameterName={recommendation?.productivityParameterName}
                      disabled={isRecommendationSaved}
                      inputRuleLegend={`Min. ${recommendation?.minProductivity}/Max. ${recommendation?.maxProductivity}`}
                    />
                  </div>
                )}
                {recommendation?.plantingDensity && (
                  <div className="keen-slider__slide">
                    <GDMPanelField
                      id="plantingDensity"
                      icon={<PiCirclesFourLight color={colors.dark2} size={32} />}
                      title={getLabelTranslation('recommendation_params_field_plantingDensity')}
                      type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.INPUT}
                      updateButtonRef={updateButtonRef}
                      disabled
                      width={
                        isReadOnly
                          ? recommendation.technology
                            ? 4
                            : 3
                          : techs && techs.length > 0
                          ? 4
                          : 3
                      }
                      defaultValue={recommendation?.plantingDensity}
                      inputLegend="pl/m2"
                      handleFieldChange={handleFieldChange}
                    />
                  </div>
                )}
                {recommendation?.userSowingDate && recommendation?.userSowingDateIsDate && (
                  <div className="keen-slider__slide">
                    <GDMPanelField
                      id="userSowingDate"
                      parameterName={recommendation?.userSowingDateParameterName}
                      handleFieldChange={handleFieldChange}
                      icon={<CiCalendarDate color={colors.dark2} size={32} />}
                      title={getLabelTranslation('recommendation_params_field_planting_date')}
                      type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.DATE}
                      width={
                        isReadOnly
                          ? recommendation.technology
                            ? 4
                            : 3
                          : techs && techs.length > 0
                          ? 4
                          : 3
                      }
                      defaultValue={
                        isReadOnly
                          ? recommendation?.userSowingDate
                          : `${recommendation?.userSowingDate?.split('/')[2]}/${
                              recommendation?.userSowingDate?.split('/')[1]
                            }/${recommendation?.userSowingDate?.split('/')[0]}`
                      }
                      minValue={`${recommendation?.minUserSowingDoy?.split('/')[2]}/${
                        recommendation?.minUserSowingDoy?.split('/')[1]
                      }/${recommendation?.minUserSowingDoy?.split('/')[0]}`}
                      maxValue={`${recommendation?.maxUserSowingDoy?.split('/')[2]}/${
                        recommendation?.maxUserSowingDoy?.split('/')[1]
                      }/${recommendation?.maxUserSowingDoy?.split('/')[0]}`}
                      disabled={isRecommendationSaved}
                      inputRuleLegend={`Min. ${recommendation?.minUserSowingDoy}/Máx. ${recommendation?.maxUserSowingDoy}`}
                    />
                  </div>
                )}
                {recommendation?.userSowingDate && !recommendation?.userSowingDateIsDate && (
                  <div className="keen-slider__slide">
                    <GDMPanelField
                      id="userSowingDate"
                      parameterName={recommendation?.userSowingDateParameterName}
                      icon={<CiCalendarDate color={colors.dark2} size={32} />}
                      title={getLabelTranslation('recommendation_params_field_userSowingDate')}
                      type={isReadOnly ? GDMPanelType.STRING : GDMPanelType.SELECT}
                      width={
                        isReadOnly
                          ? recommendation.technology
                            ? 4
                            : 3
                          : techs && techs.length > 0
                          ? 4
                          : 3
                      }
                      defaultValue={recommendation?.userSowingDate}
                      handleFieldChange={handleFieldChange}
                      options={[
                        { id: 'Early', name: getLabelTranslation('early') },
                        { id: 'Normal', name: getLabelTranslation('normal') },
                        { id: 'Late', name: getLabelTranslation('late') },
                      ]}
                      disabled={isRecommendationSaved}
                    />
                  </div>
                )}
                {environmentalKeys
                  ?.sort((a, b) => a.localeCompare(b))
                  .map((env, index) => (
                    <div className="keen-slider__slide" key={index}>
                      <GDMPanelField
                        id={env}
                        isReadOnly={isReadOnly || isRecommendationSaved}
                        handleFieldChange={handleFieldChange}
                        updateButtonRef={updateButtonRef}
                        icon={
                          env === 'clay' ? (
                            <TbChartPie2 color={colors.dark2} size={32} />
                          ) : env === 'prec' ? (
                            <BsCloudRain color={colors.dark2} size={32} />
                          ) : env === 'temp' ? (
                            <TbTemperature color={colors.dark2} size={32} />
                          ) : env === 'water' ? (
                            <TbDroplet color={colors.dark2} size={32} />
                          ) : env === 'melo' ? (
                            <TbDna2 color={colors.dark2} size={32} />
                          ) : env === 'heterodera' ? (
                            <TbDna2 color={colors.dark2} size={32} />
                          ) : env === 'om' ? (
                            <TbDna2 color={colors.dark2} size={32} />
                          ) : (
                            <TbDna2 color={colors.dark2} size={32} />
                          )
                        }
                        title={getLabelTranslation(
                          `recommendation_params_field_${recommendation?.environmentalVariables[env].name}`,
                        )}
                        type={
                          recommendation?.environmentalVariables[env].feature === 'slider'
                            ? GDMPanelType.INPUT
                            : recommendation?.environmentalVariables[env].feature === 'radio button'
                            ? isReadOnly
                              ? GDMPanelType.STRING
                              : GDMPanelType.RADIO
                            : recommendation?.environmentalVariables[env].feature === 'select'
                            ? GDMPanelType.SELECT
                            : GDMPanelType.STRING
                        }
                        minValue={recommendation?.environmentalVariables[env]?.min}
                        maxValue={recommendation?.environmentalVariables[env]?.max}
                        defaultValue={recommendation?.environmentalVariables[env]?.defaultValue}
                        options={recommendation?.environmentalVariables[env]?.front
                          ?.split('/')
                          ?.map(item => {
                            return { id: item, name: item };
                          })}
                        width={environmentalKeys.length}
                        parameterName={recommendation?.environmentalVariables[env]?.variableName}
                        disabled={isRecommendationSaved}
                        inputLegend={
                          recommendation?.environmentalVariables[env]?.variableName === 'prec'
                            ? 'mm'
                            : recommendation?.environmentalVariables[env]?.variableName === 'temp'
                            ? '°C'
                            : recommendation?.environmentalVariables[env]?.variableName === 'water'
                            ? '%'
                            : recommendation?.environmentalVariables[env]?.variableName === 'clay'
                            ? '%'
                            : recommendation?.environmentalVariables[env]?.variableName === 'om'
                            ? '%'
                            : ''
                        }
                        inputRuleLegend={`Min. ${recommendation?.environmentalVariables[env]?.min}/Máx. ${recommendation?.environmentalVariables[env]?.max}`}
                      />
                    </div>
                  ))}
              </GDMSlider>
            )}
        </>
      )}
    </GDMContainer>
  );
}
