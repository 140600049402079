import { TransformedCharacteristicValues } from '../VarietiesToCompare.types';

export const transformData = data => {
  const groups: { [key: string]: { [key: string]: TransformedCharacteristicValues } } = {};

  data.forEach(item => {
    item.genotypeCharacteristics.forEach(characteristic => {
      const group = characteristic.group;
      const characteristicName = characteristic.characteristic;
      const toolTips = characteristic.toolTip;

      if (!groups[group]) {
        groups[group] = {};
      }

      if (!groups[group][characteristicName]) {
        groups[group][characteristicName] = {};
      }

      const productId = item.genotype;
      const characteristicKey = `characteristicValue_${productId}`;
      const legendKey = `legend_${productId}`;
      const toolTipKey = `toolTip_${productId}`;

      if (group && characteristicName) {
        if (!groups[group][characteristicName][characteristicKey]) {
          groups[group][characteristicName][characteristicKey] = [];
        }
        (groups[group][characteristicName][characteristicKey] as string[]).push(
          characteristic.characteristicValue,
        );
      } else {
        groups[group][characteristicName][characteristicKey] = characteristic.characteristicValue;
      }

      if (group && toolTips) {
        if (!groups[group][characteristicName][toolTipKey]) {
          groups[group][characteristicName][toolTipKey] = [];
        }
        (groups[group][characteristicName][toolTipKey] as string[]).push(characteristic.toolTip);
      } else {
        groups[group][characteristicName][toolTipKey] = characteristic.toolTip;
      }

      groups[group][characteristicName][legendKey] = characteristic.legend;
    });
  });

  const transformedGroups = Object.keys(groups).map(group => ({
    group,
    lines: Object.keys(groups[group]).map(characteristic => {
      const values = groups[group][characteristic];
      Object.keys(values).forEach(key => {
        if (Array.isArray(values[key])) {
          values[key] = values[key] as string[];
        }
      });
      return {
        characteristic,
        values,
      };
    }),
  }));

  return { groups: transformedGroups };
};
