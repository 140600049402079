import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { Autocomplete, TextField } from '@mui/material';

import { GDMBrandSelectorComponent } from './GDMBrandSelector.type';

const GDMBrandSelector: GDMBrandSelectorComponent = ({
  brands,
  onChange,
  defaultValues,
  entityType,
  errors,
  isDisabled,
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();

  return (
    <Autocomplete
      sx={{
        input: {
          border: 'none',
        },
        '.Mui-disabled': {
          background: '#F2F4F9',
        },
        '.MuiTextField-root > p': {
          background: '#fff',
        },
        '.MuiChip-root': {
          color: '#222222',
          backgroundColor: '#E7E9F1',
        },
      }}
      multiple
      id="tags-standard"
      disabled={
        [
          `${getLabelTranslation('brandeselector_multiplier')}`,
          `${getLabelTranslation('brandeselector_distributor')}`,
        ].includes(entityType) || isDisabled
      }
      options={brands?.filter(brand => brand.isAdministratorBrand === false)}
      value={brands?.filter(brand => defaultValues?.includes(brand.id))}
      getOptionLabel={option => option.name}
      defaultValue={brands.filter(brand => defaultValues?.includes(brand.id))}
      onChange={onChange}
      filterSelectedOptions
      readOnly={[
        `${getLabelTranslation('brandeselector_multiplier')}`,
        `${getLabelTranslation('brandeselector_distributor')}`,
      ].includes(entityType)}
      renderInput={params => (
        <TextField
          focused={false}
          {...params}
          error={!!errors.brandIds}
          helperText={errors.brandIds}
          placeholder={!isDisabled ? '' : `${getLabelTranslation('brandeselector_brand(s)')}`}
          sx={{
            input: {
              border: 'none',
            },
          }}
        />
      )}
    />
  );
};

export default GDMBrandSelector;
