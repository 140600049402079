import { SxProps } from '@mui/material';
import { SxPropsThemeFunction } from './components/dashboard-filters/DashboardFilters.type';

export const dashboardContainerStyles: SxProps = {
  display: 'flex',
  minHeight: '680px',
  width: '100%',
  backgroundColor: '#fafafa',
  borderRadius: '24px',
  padding: {
    xs: '4px',
    sm: '4px',
    md: '32px 40px',
    lg: '36px 48px',
    xl: '42px 64px',
  },
};

export const dashboardLoadingContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  paddingTop: '1px',
  top: 0,
  left: 0,
  background: 'rgba(0,0,0,0.2)',
  zIndex: 9999,
};

export const welcomeTitleStyles: SxPropsThemeFunction = theme => ({
  lineHeight: '30px!important',
  fontWeight: '400',
  color: theme.palette.primary.main,
  fontSize: {
    xs: '20px!important',
    sm: '20px!important',
    md: '24px!important',
    lg: '24px!important',
    xl: '24px!important',
  },
  paddingTop: {
    xs: '12px',
    sm: '12px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
});

export const internalContainerDashboardStyles: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: {
    xs: '16px',
    sm: '16px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
};

export const titleContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  gap: {
    xs: '16px',
    sm: '16px',
    md: '8px',
    lg: '8px',
    xl: '8px',
  },
  padding: {
    xs: '0px 16px 32px',
    sm: '0px 16px 32px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
};

export const formReportLinkStyles: SxPropsThemeFunction = theme => ({
  fontSize: {
    xs: '16px!important',
    sm: '16px!important',
    md: '20px!important',
    lg: '20px!important',
    xl: '20px!important',
  },
  lineHeight: '24px!important',
  fontWeight: '400',
  textDecoration: 'underline',
  color: theme.palette.primary.main,
});
