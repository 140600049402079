import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';

import { Divider, useMediaQuery } from '@mui/material';

import { GDMButton, GDMContainer, GDMDivider, GDMLoading, GDMTypography } from 'components';
import { TbArrowLeft, TbDeviceFloppy, TbDownload } from 'react-icons/tb';

import { RecommendationService } from 'core/api/services/recommendation';

import { useRecommendationContext } from 'core/store/contexts/recommendationContext';
import useModal from 'hooks/useModal/useModal';
import generatePDF from 'react-to-pdf';
import HeaderImage from '../../assets/images/recommendation-header.png';
import colors from '../../core/design-system/style-guide/colors.json';
import GDMGenericModal from './components/gdm-modal/GDMGenericModal';
import ParamsForm from './ParamsForm/ParamsForm';
import RecommendationChart from './RecommendationChart/RecommendationChart';
import RecommendationPageForm from './RecommendationForm/RecommendationPageForm';
import RecommendationToDownload from './RecommendationToDownload/RecommendationToDownload';
import RecommendationVarieties from './RecommendationVarieties/RecommendationVarieties';

export default function RecommendationPage(): JSX.Element {
  const navigate = useNavigate();

  const {
    recommendationContext,
    setRecommendationContext,
    editedFields,
    setEditedFields,
    varietyValue,
    setVarietyValue,
  } = useRecommendationContext();

  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation, loading } = useLanguageSwitcher();
  const [isRecommendationLoaded, setIsRecommendationLoaded] = useState(true);
  const [isPageReloading, setIsPageReloading] = useState(true);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [recommendationData, setRecommendationData] = useState<any>();
  const { openModal, closeModal } = useModal();
  const [chartDateData, setChartDateData] = useState([]);
  const [chartDensityData, setChartDensityData] = useState([]);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recommendationEdited, setRecommendationEdited] = useState(false);
  const [fieldsValue, setFieldsValue] = useState([]);
  const [rattingParameters, setRattingParameters] = useState([]);
  const [isRecommendationSaved, setIsRecommendationSaved] = useState(false);
  const [saveRecommendationData, setSaveRecommendationData] = useState({
    fixedVariables: [],
    environmentalVariables: [],
  });
  const [updateData, setUpdateData] = useState({
    brandId: '',
    cultureId: '',
    lotId: '',
    parameters: {},
  });

  useEffect(() => {
    setIsLoading(true);
    const handleLoadRecommendation = async () => {
      try {
        if (!recommendationContext) {
          setIsPageReloading(true);
          const sessionRecid = sessionStorage.getItem('recommendationId');
          const sessionVariety = sessionStorage.getItem('variety');
          const data = await RecommendationService.getRecommendationById(sessionRecid);
          setRecommendationContext(data);
          setRecommendationData(data);
          setVarietyValue(sessionVariety ?? data?.recommendationVariety[0]?.id);
          setIsRecommendationLoaded(true);
          setIsPageReloading(false);
          setEditedFields(
            sessionStorage.getItem('editedFields') &&
              JSON.parse(sessionStorage.getItem('editedFields')),
          );
        } else {
          setIsPageReloading(false);
          setRecommendationData(recommendationContext);
          setVarietyValue(varietyValue ?? recommendationContext.recommendationVariety[0]?.id);
          setIsRecommendationLoaded(true);
          // setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleLoadRecommendation();
  }, []);

  useEffect(() => {
    // if (!loading) setIsLoading(false);
  }, [loading]);

  useEffect(() => {
    updateRecommendationByVariety();
  }, [varietyValue, recommendationData]);

  const setRecommendationSaved = () => {
    setIsRecommendationSaved(true);
  };

  const convertToSaveInitialData = () => {
    setSaveRecommendationData({
      fixedVariables: [],
      environmentalVariables: [],
    });

    if (recommendationData?.technologies?.length > 0) {
      const tech = {
        id: 'tech',
        name: 'tech',
        type: 'string',
        value: recommendationData?.technology ?? '',
      };
      setSaveRecommendationData(prev => ({
        ...prev,
        fixedVariables: [...prev.fixedVariables, tech],
      }));
    }

    if (recommendationData?.productivity) {
      const productivity = {
        id: 'productivity',
        name: 'productivity',
        type: 'number',
        value: recommendationData?.productivity,
        legend: 'kg/ha',
      };

      setSaveRecommendationData(prev => ({
        ...prev,
        environmentalVariables: [...prev.environmentalVariables, productivity],
      }));
    }

    if (recommendationData?.plantingDensity) {
      const plantingDensity = {
        id: 'plantingDensity',
        name: 'plantingDensity',
        type: 'number',
        value: recommendationData?.plantingDensity,
        legend: 'pl/m²',
      };
      setSaveRecommendationData(prev => ({
        ...prev,
        environmentalVariables: [...prev.environmentalVariables, plantingDensity],
      }));
    }

    if (recommendationData?.userSowingDate) {
      const userSowingDate = {
        id: 'userSowingDate',
        name: 'userSowingDate',
        type: 'string',
        value: recommendationData?.userSowingDate,
      };
      setSaveRecommendationData(prev => ({
        ...prev,
        fixedVariables: [...prev.fixedVariables, userSowingDate],
      }));
    }

    const nitrogen = {
      id: 'nitrogen',
      name: 'nitrogen',
      type: 'number',
      value: '',
      legend: 'kg/ha',
    };
    setSaveRecommendationData(prev => ({
      ...prev,
      environmentalVariables: [...prev.environmentalVariables, nitrogen],
    }));

    const environmentalVariables = recommendationData?.environmentalVariables
      ? Object.keys(recommendationData?.environmentalVariables).forEach(key => {
          const variable = recommendationData?.environmentalVariables[key];
          const varData = {
            id: key,
            name: variable.name,
            type: variable.feature === 'slider' ? 'number' : 'string',
            value: variable.defaultValue,
            legend:
              key === 'prec'
                ? 'mm'
                : key === 'temp'
                ? '°C'
                : key === 'water'
                ? '%'
                : key === 'clay'
                ? '%'
                : key === 'om'
                ? '%'
                : '',
          };
          setSaveRecommendationData(prev => ({
            ...prev,
            environmentalVariables: [...prev.environmentalVariables, varData],
          }));
        })
      : [];
  };

  const updateRecommendationByVariety = (showLoading = false) => {
    const variety = recommendationData?.recommendationVariety?.find(x => x.id === varietyValue);
    if (variety) {
      if (showLoading) setIsLoading(true);
      if (variety?.technology && saveRecommendationData.fixedVariables.find(x => x.id === 'tech')) {
        setSaveRecommendationData(prev => ({
          ...prev,
          fixedVariables: prev.fixedVariables.map(item =>
            item.id === 'tech' ? { ...item, value: variety?.technology } : item,
          ),
        }));
      }

      if (
        variety?.sowingDate &&
        saveRecommendationData.fixedVariables.find(x => x.id === 'sowingDate')
      ) {
        setSaveRecommendationData(prev => ({
          ...prev,
          fixedVariables: prev.fixedVariables.map(item =>
            item.id === 'sowingDate' ? { ...item, value: variety?.sowingDate } : item,
          ),
        }));
      }

      if (
        variety?.nitrogen &&
        saveRecommendationData?.environmentalVariables.find(x => x.id === 'nitrogen')
      ) {
        setSaveRecommendationData(prev => ({
          ...prev,
          environmentalVariables: prev.environmentalVariables.map(item =>
            item.id === 'nitrogen' ? { ...item, value: variety?.nitrogen } : item,
          ),
        }));
      }

      if (
        variety?.productivity &&
        saveRecommendationData?.environmentalVariables.find(x => x.id === 'productivity')
      ) {
        setSaveRecommendationData(prev => ({
          ...prev,
          environmentalVariables: prev.environmentalVariables.map(item =>
            item.id === 'productivity' ? { ...item, value: variety?.productivity } : item,
          ),
        }));
      }

      if (
        variety?.density &&
        saveRecommendationData?.environmentalVariables.find(x => x.id === 'plantingDensity')
      ) {
        setSaveRecommendationData(prev => ({
          ...prev,
          environmentalVariables: prev.environmentalVariables.map(item =>
            item.id === 'plantingDensity' ? { ...item, value: variety?.density } : item,
          ),
        }));
      }
      if (showLoading) setIsLoading(false);
    }
  };

  useEffect(() => {
    if (editedFields) {
      if (Object.keys(editedFields).length > 0) {
        setUpdateData(prevState => ({
          ...prevState,
          parameters: editedFields,
        }));
      }

      const keys = Object.keys(editedFields);
      const updatedFixedVariables = saveRecommendationData.fixedVariables?.map(variable => {
        const key = keys.find(keyToFind => keyToFind.toLowerCase() === variable.id);
        if (key) {
          return {
            ...variable,
            value: editedFields[key],
          };
        }
        return variable; // Retornar o objeto original se não houver alteração
      });

      const updatedEnvironmentalVariables = saveRecommendationData.environmentalVariables?.map(
        variable => {
          const key = keys.find(k => k === variable.id);
          if (key) {
            return {
              ...variable,
              value: editedFields[key],
            };
          }
          return variable; // Retornar o objeto original se não houver alteração
        },
      );

      // Criar um novo estado com os valores atualizados
      const updatedState = {
        fixedVariables: updatedFixedVariables,
        environmentalVariables: updatedEnvironmentalVariables,
      };
      setSaveRecommendationData(updatedState);
    }
  }, [editedFields, fieldsValue]);

  useEffect(() => {
    const initialDate = recommendationData?.recommendationVariety
      ?.map(item => {
        return item.plantingDate.map(planting => {
          const order = item.order;
          return {
            variableT: planting.variableT,
            exibitionDate: planting.exibitionDate,
            [`Produtividade_${order}`]: planting.yield,
          };
        });
      })
      .flat();

    const resultDate = initialDate?.sort((a, b) => {
      const dateA = new Date(a.variableT.split('/').reverse().join('/')).getTime();
      const dateB = new Date(b.variableT.split('/').reverse().join('/')).getTime();
      return dateA - dateB;
    });

    const resultDensity = recommendationData?.recommendationVariety
      ?.map(item => {
        return item.plantingDensity.map(planting => {
          const order = item.order;
          return {
            density: planting.density,
            [`Recomendação_${order}`]: planting.yield,
          };
        });
      })
      .flat();

    const combinedDateData = resultDate?.reduce((acc, curr) => {
      const dateIndex = acc.findIndex(item => item.variableT === curr.variableT);
      if (dateIndex === -1) {
        acc.push({ ...curr });
      } else {
        Object.keys(curr).forEach(key => {
          if (key !== 'variableT') {
            acc[dateIndex][key] = curr[key];
          }
        });
      }
      return acc;
    }, []);

    const combinedDensityData = resultDensity?.reduce((acc, curr) => {
      const dateIndex = acc.findIndex(item => item.density === curr.density);
      if (dateIndex === -1) {
        acc.push({ ...curr });
      } else {
        Object.keys(curr).forEach(key => {
          if (key !== 'density') {
            acc[dateIndex][key] = curr[key];
          }
        });
      }
      return acc;
    }, []);

    const orderedDensityData =
      combinedDensityData && combinedDensityData?.length > 0
        ? combinedDensityData?.sort((a, b) => a.density - b.density)
        : combinedDensityData;

    setChartDateData(combinedDateData);
    setChartDensityData(orderedDensityData);
    setUpdateData({
      ...updateData,
      brandId: recommendationData?.brandId,
      cultureId: recommendationData?.cultureId,
      lotId: recommendationData?.lotId,
    });

    convertToSaveInitialData();

    const fetchRattingParameters = async () => {
      try {
        const response = await RecommendationService.getRattingParameters();
        setRattingParameters(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRattingParameters();
  }, [recommendationData]);

  const updateRecommendation = async () => {
    if (updateData.brandId && updateData.cultureId && updateData.lotId) {
      setIsLoading(true);
      try {
        const updatedRecommendation = await RecommendationService.upSertRecommendation(updateData);

        setRecommendationData(updatedRecommendation);
        setRecommendationContext(updatedRecommendation);
        sessionStorage.setItem('recommendationId', updatedRecommendation?.id);
        setVarietyValue(updatedRecommendation.recommendationVariety[0]?.id);
        sessionStorage.setItem('variety', updatedRecommendation.recommendationVariety[0]?.id);
        sessionStorage.setItem('editedFields', JSON.stringify(editedFields));
        setRecommendationEdited(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        openModal({
          type: 'error',
          title: getLabelTranslation('error_update_params'),
          onClose: closeModal,
        });
        console.error(error);
      }
    }
  };

  const handleBackButton = () => {
    setIsLoading(true);
    setRecommendationData({});
    setRecommendationContext({});
    setVarietyValue('');
    setEditedFields({});
    sessionStorage.removeItem('recommendationId');
    sessionStorage.removeItem('variety');
    sessionStorage.removeItem('editedFields');
    setIsLoading(false);
    navigate(`/recommendation`);
  };

  const handleDownloadRecommendation = async () => {
    try {
      setIsLoading(true);
      await RecommendationService.getRecommendationToDownload(recommendationData?.id).then(() => {
        const containerToDownload = () => document.getElementById('recommendation-to-download');

        generatePDF(containerToDownload, {
          method: 'open',
          filename: `${recommendationData?.name}Gld.pdf`,
        });
        setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {(isLoading || loading) && (
        <GDMContainer
          sx={{
            display: 'flex',
            alignItems: recommendationData ? 'top' : 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100%',
            position: 'absolute',
            paddingTop: '1px',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.2)',
            zIndex: 9999,
          }}
        >
          <GDMContainer sx={{ paddingTop: recommendationData ? '25%' : '0' }}>
            <GDMLoading />
          </GDMContainer>
        </GDMContainer>
      )}
      <GDMContainer
        component="div"
        sx={{
          borderRadius: 2,
          boxShadow: isDesktop && '0 2px 8px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderTopRightRadius: '24px',
          borderTopLeftRadius: '24px',
          backgroundColor: isDesktop && theme.palette.background.paper,
        }}
      >
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: isRecommendationLoaded
              ? {
                  xs: '4px 4px 4px 4px',
                  sm: '12px 14px 0 14px',
                  md: '32px 64px 0 64px',
                  lg: '32px 64px 0 64px',
                  xl: '32px 64px 0 64px',
                }
              : '0',
          }}
        >
          {isDesktop && isRecommendationLoaded && (
            <>
              <GDMContainer sx={{ marginBottom: '32px' }}>
                <GDMTypography
                  sx={{ color: '#A1A9B8', fontSize: '14px' }}
                  display="block"
                  gutterBottom
                >
                  {'Recomendação > Gerar Recomendação'}
                </GDMTypography>
              </GDMContainer>

              <GDMContainer>
                <GDMTypography
                  variant="h4"
                  textAlign={isDesktop ? 'left' : 'center'}
                  mb={2}
                  fontWeight={700}
                  color="#222222"
                >
                  {getLabelTranslation('recommended_suggestion')}
                </GDMTypography>
              </GDMContainer>

              <Divider sx={{ my: 2 }} />
            </>
          )}
          {!isDesktop && isRecommendationLoaded && (
            <>
              <GDMContainer sx={{ marginBottom: '32px' }}>
                <GDMTypography
                  sx={{ color: '#A1A9B8', fontSize: '14px' }}
                  display="block"
                  gutterBottom
                >
                  {'Recomendação > Gerar Recomendação'}
                </GDMTypography>
              </GDMContainer>

              <GDMContainer>
                <GDMTypography
                  textAlign={isDesktop ? 'left' : 'center'}
                  mb={2}
                  fontWeight={700}
                  color="#222222"
                  sx={{
                    fontSize: {
                      xs: '20px',
                      sm: '20px',
                      md: '32px',
                      lg: '32px',
                      xl: '32px',
                    },
                  }}
                >
                  {getLabelTranslation('recommended_suggestion')}
                </GDMTypography>
              </GDMContainer>
            </>
          )}

          {isDesktop && !isRecommendationLoaded && (
            <>
              <GDMContainer
                sx={{
                  marginBottom: '32px',
                  height: '229px',
                  width: '100%',
                  background: theme.palette.primary.main,
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  display: 'flex',
                }}
              >
                <GDMContainer
                  sx={{
                    width: '50%',
                    height: '100%',
                    backgroundImage: `url(${HeaderImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left',
                    borderTopLeftRadius: '24px',
                  }}
                />
                <GDMContainer
                  sx={{
                    width: '50%',
                    padding: {
                      xs: '20px 16px 16px 16px',
                      sm: '20px 16px 16px 16px',
                      md: '20px 16px 16px 16px',
                      lg: '24px 16px 16px 16px',
                      xl: '40px 48px 16px 16px',
                    },
                  }}
                >
                  <GDMTypography
                    textAlign={isDesktop ? 'left' : 'center'}
                    fontWeight={700}
                    color={colors.white}
                    gutterBottom
                    component="span"
                    sx={{
                      fontSize: '32px',
                      '@media (max-width: 1024px)': {
                        fontSize: '14px',
                      },
                      '@media (max-width: 1366px)': {
                        fontSize: '18px',
                      },
                      '@media (max-width: 1600px)': {
                        fontSize: '22px',
                      },
                    }}
                  >
                    {getLabelTranslation('recommendation_header_title')}
                  </GDMTypography>
                  <GDMTypography
                    variant="h4"
                    textAlign={isDesktop ? 'left' : 'center'}
                    mb={6}
                    fontWeight={400}
                    sx={{
                      fontSize: '16px',
                      lineHeight: '32px',
                      '@media (max-width: 1024px)': {
                        fontSize: '14px',
                        lineHeight: '18px',
                      },
                      '@media (max-width: 1366px)': {
                        lineHeight: '20px',
                      },
                      '@media (max-width: 1600px)': {
                        lineHeight: '24px',
                      },
                    }}
                    gutterBottom
                    component="div"
                    color={colors.white}
                  >
                    {getLabelTranslation('recommendation_header_description')}
                  </GDMTypography>
                </GDMContainer>
              </GDMContainer>

              <GDMContainer
                sx={{
                  padding: '0 64px',
                }}
              >
                <GDMTypography
                  variant="h4"
                  textAlign={isDesktop ? 'left' : 'center'}
                  mb={1}
                  fontWeight={700}
                  component="div"
                  color="#222222"
                >
                  {getLabelTranslation('recommendation_initial_form_title')}
                </GDMTypography>
                <GDMTypography
                  variant="body2"
                  textAlign={isDesktop ? 'left' : 'center'}
                  fontWeight={400}
                  color="#222222"
                >
                  {getLabelTranslation('recommendation_initial_form_description')}
                </GDMTypography>
              </GDMContainer>
            </>
          )}

          {!isDesktop && !isRecommendationLoaded && (
            <>
              <GDMContainer
                sx={{
                  height: '144px',
                  width: '100%',
                }}
              >
                <GDMContainer
                  sx={{
                    position: 'absolute',
                    top: '-19px',
                    left: 0,
                    width: '100%',
                    height: '144px',
                    backgroundImage: `url(${HeaderImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
              </GDMContainer>
              <GDMContainer
                sx={{
                  height: {
                    xs: '162px',
                    sm: '152px',
                  },
                  width: '100%',
                }}
              >
                <GDMContainer
                  sx={{
                    background: theme.palette.primary.main,
                    top: '124px',
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                    height: {
                      xs: '162px',
                      sm: '152px',
                    },
                    py: {
                      xs: 1.5,
                      sm: 2.5,
                    },
                    px: {
                      xs: 1.5,
                      sm: 2.5,
                    },
                  }}
                >
                  <GDMTypography
                    textAlign="left"
                    fontWeight={700}
                    color={colors.white}
                    gutterBottom
                    component="span"
                    fontSize="20px"
                  >
                    {getLabelTranslation('recommendation_header_title')}
                  </GDMTypography>
                  <GDMTypography
                    variant="h4"
                    textAlign="left"
                    mt={1}
                    sx={{
                      width: { xs: '97%', sm: '100%' },
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 400,
                      flexWrap: 'wrap',
                    }}
                    gutterBottom
                    component="div"
                    color={colors.white}
                  >
                    {getLabelTranslation('recommendation_header_description')}
                  </GDMTypography>
                </GDMContainer>
              </GDMContainer>
              <GDMContainer
                sx={{
                  padding: '0 3px',
                  mt: -3,
                }}
              >
                <GDMTypography fontSize="16px" fontWeight={700} color="#222222" mb={1}>
                  {getLabelTranslation('recommendation_initial_form_title')}
                </GDMTypography>
                <GDMTypography fontSize="14px" fontWeight={400} color="#222222">
                  {getLabelTranslation('recommendation_initial_form_description')}
                </GDMTypography>
              </GDMContainer>
            </>
          )}

          <RecommendationPageForm
            setFormLoaded={setIsRecommendationLoaded}
            isFormLoaded={isRecommendationLoaded}
            setFieldErrors={setFieldErrors}
            fieldErrors={fieldErrors}
            setRecommendation={setRecommendationData}
            setIsLoading={setIsLoading}
            recommendation={recommendationData}
            setVarietyValue={setVarietyValue}
            isReadOnly
            isPageReloading={isPageReloading}
          />

          {isRecommendationLoaded && (
            <>
              <GDMDivider />
              <ParamsForm
                recommendation={recommendationData}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
                setRecommendationEdited={setRecommendationEdited}
                handleUpdateRecommendation={updateRecommendation}
                setFieldsValue={setFieldsValue}
                isRecommendationSaved={isRecommendationSaved}
                isPageReloading={isPageReloading}
              />
              {isDesktop && <GDMDivider />}
              <RecommendationVarieties
                recommendationData={recommendationData}
                setVarietyValue={setVarietyValue}
                varietyValue={varietyValue}
                disabled={isRecommendationSaved}
                setSaveRecommendationData={setSaveRecommendationData}
              />
              <RecommendationChart
                recommendationData={recommendationData}
                chartDateData={chartDateData}
                chartDensityData={chartDensityData}
                varietyValue={varietyValue}
              />
            </>
          )}

          <GDMDivider />

          <GDMContainer
            sx={{
              display: isRecommendationLoaded ? 'flex' : 'none',
              marginTop: 4,
              width: '100%',
              paddingBottom: 4,
              gap: !isDesktop && '16px',
              flexDirection: isRecommendationLoaded && !isDesktop && 'column-reverse',
            }}
          >
            <GDMButton
              sx={{
                height: '51px',
                width: isDesktop ? '220px' : '100%',
                float: 'left',
                fontWeigt: 700,
                fontSize: '14px',
              }}
              color="primary"
              variant="outlined"
              fullWidth={false}
              startIcon={<TbArrowLeft />}
              onClick={handleBackButton}
            >
              {getLabelTranslation('back')}
            </GDMButton>
            <GDMContainer
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                gap: isDesktop ? '32px' : '16px',
                flexDirection: !isDesktop && 'column-reverse',
              }}
            >
              <GDMButton
                sx={{
                  height: '51px',
                  width: isDesktop ? '220px' : '100%',
                  float: 'right',
                  fontWeigt: 700,
                  fontSize: '14px',
                  background: theme.palette.secondary.main,
                  color: '#222',
                  ':hover': {
                    borderStyle: 'none',
                    background: theme.palette.primary.light,
                  },
                }}
                variant="contained"
                fullWidth={false}
                startIcon={<TbDownload />}
                onClick={() => handleDownloadRecommendation()}
                disabled={!isRecommendationSaved}
              >
                {getLabelTranslation('download')}
              </GDMButton>
              <GDMButton
                sx={{
                  height: '51px',
                  width: isDesktop ? '220px' : '100%',
                  fontWeigt: 700,
                  fontSize: '14px',
                }}
                color="primary"
                variant="contained"
                fullWidth={false}
                onClick={() => {
                  updateRecommendationByVariety(true);
                  setOpenModalSave(true);
                }}
                startIcon={<TbDeviceFloppy />}
                disabled={isRecommendationSaved || !varietyValue || recommendationEdited}
              >
                {getLabelTranslation('save')}
              </GDMButton>
            </GDMContainer>
          </GDMContainer>
        </GDMContainer>
        <GDMGenericModal
          openModal={openModalSave}
          recommendationVarietyId={varietyValue}
          setOpenModal={setOpenModalSave}
          recommendationId={recommendationData && recommendationData.id}
          recommendationParametersData={saveRecommendationData}
          genotype={
            recommendationData?.recommendationVariety?.find(x => x.id === varietyValue)?.genotype
          }
          rattingParameters={rattingParameters}
          recommendationSaved={setRecommendationSaved}
        />
      </GDMContainer>
      {isRecommendationSaved && (
        <RecommendationToDownload
          recommendationId={recommendationData?.id}
          chartDateData={chartDateData}
          chartDensityData={chartDensityData}
          varietyValue={
            recommendationData?.recommendationVariety.find(x => x.id === varietyValue)?.order
          }
          cultureName={recommendationData?.cultureName}
        />
      )}
    </>
  );
}
