import { dynamicFormValidateDocument } from 'utils/dynamic-form-validate-document';
import { DynamicFormValidateFormFunction, FieldErrorsMessages } from 'utils/dynamic-form-validate-form/dynamicFormValidateForm.type';

 
const DEFAULT_FIELD_ERRORS_MESSAGES: FieldErrorsMessages = {
  required: {
    address: 'validatorForm_address_is_required',
    ufId: 'validatorForm_state_is_required',
    cityId: 'validatorForm_city_is_required',
    logoFile: 'validatorForm_logo_is_required',
    contactEmail: 'validatorForm_email_is_required',
    documentNumber: 'validatorForm_is_required',
    fantasyName: 'validatorForm_fantasy_name_is_required',
    name: 'validatorForm_full_name_is_required',
    personInChargeName: 'validatorForm_responsible_is_required',
    phoneNumber: 'validatorForm_phone_is_required',
    regionId: 'validatorForm_region_is_required',
  },
  invalid: {
    contactEmail: 'validatorForm_invalid_email',
    phoneNumber: 'validatorForm_invalid_phone',
    documentNumber: 'validatorForm_invalid_document_number',
  },
};
 
const phoneValidationRegex = {
  BrasilFixed: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{3})$/,
  Brasil: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
  Argentina: /^[0-9()\s\-+]+$/,
};
 
const validateField = (
  condition: boolean,
  errorKey: keyof FieldErrorsMessages['required'],
  requiredMessages: FieldErrorsMessages['required'],
  errors: ObjectLiteral<string>,
) => {
  if (condition) {
    errors[errorKey] = requiredMessages[errorKey];
  }
};

const validateEmail = (
  email: string,
  requiredMessages: FieldErrorsMessages['required'],
  invalidMessages: FieldErrorsMessages['invalid'],
  errors: ObjectLiteral<string>,
) => {
  const isValidEmail = (email: string) => {
    const atSymbol = email.indexOf('@');
    const dot = email.lastIndexOf('.');
    return atSymbol > 0 && dot > atSymbol + 1 && dot < email.length - 1;
  };

  if (!email.trim()) {
    errors.contactEmail = requiredMessages.contactEmail; 
  } else if (!isValidEmail(email)) {
    errors.contactEmail = invalidMessages.contactEmail; 
  }
};
 
const validateDocumentNumber = (
  documentNumber: string,
  countryName: string,
  requiredMessages: FieldErrorsMessages['required'],
  invalidMessages: FieldErrorsMessages['invalid'],
  getLabelTranslation: (label: string) => string,
  errors: ObjectLiteral<string>,
) => {
  const cleanedDocument = documentNumber.replace(/\D/g, '');
 
  if (!cleanedDocument?.trim()) {
    const documentLabels = {
      Brasil: 'CPF/CNPJ',
      Argentina: 'CUIT',
      default: 'Document',
    };
    const documentLabel = documentLabels[countryName] || documentLabels.default;
 
    errors.documentNumber = `${documentLabel} ${getLabelTranslation(requiredMessages.documentNumber)}`;
  } else if (
    (cleanedDocument.length !== 11 && cleanedDocument.length !== 14) ||
    !dynamicFormValidateDocument({ documentNumber, countryName })
  ) {
    errors.documentNumber = invalidMessages.documentNumber;
  }
};
 
const validatePhoneNumber = (
  phoneNumber: string,
  countryName: string,
  invalidMessages: FieldErrorsMessages['invalid'],
  errors: ObjectLiteral<string>,
) => {
  const sanitizedPhoneNumber = phoneNumber.replace(/[ \-+_]/g, '');
  const figuresOfPhoneNumber = sanitizedPhoneNumber.length;
 
  if (!figuresOfPhoneNumber || figuresOfPhoneNumber === 2 || !phoneValidationRegex[countryName]?.test(phoneNumber)) {
    errors.phoneNumber = invalidMessages.phoneNumber;
  }
};

const validateFantasyName =(
  fantasyName: string,
  documentNumber: string,
  countryName: string,
  requiredMessages: FieldErrorsMessages['required'],
  errors: ObjectLiteral<string>,
) => {
  if(countryName === "Brasil" && fantasyName?.trim() === "" && documentNumber.replace(/\D/g, '').length > 11) {
    errors.fantasyName = requiredMessages.fantasyName;
  }
}
 
const dynamicFormValidateForm: DynamicFormValidateFormFunction = ({
  countryName,
  entityType,
  formData,
  getLabelTranslation,
  fieldErrorsMessages = DEFAULT_FIELD_ERRORS_MESSAGES,
}) => {
  const {
    name = '',
    contactEmail = '',
    phoneNumber = '',
    documentNumber = '',
    address = '',
    ufId = '',
    cityId = '',
    brandIds = [],
    logoFile = '',
    personInChargeName = '',
    fantasyName = '',
    regionId = '',
  } = formData;
 
  const { required: requiredMessages, invalid: invalidMessages } = fieldErrorsMessages;
  const errors: ObjectLiteral<string> = {};
 
  validateField(!name.trim(), 'name', requiredMessages, errors);
  validateFantasyName(fantasyName,documentNumber, countryName, requiredMessages, errors);
  validateEmail(contactEmail, requiredMessages, invalidMessages, errors);
  validatePhoneNumber(phoneNumber, countryName, invalidMessages, errors);
  validateDocumentNumber(documentNumber, countryName, requiredMessages, invalidMessages, getLabelTranslation, errors);
 
  const entityValidationRules = {
    Distribuidor: ['address', 'ufId', 'cityId', 'logoFile', 'personInChargeName'],
    Multiplicador: ['address', 'ufId', 'cityId', 'logoFile', 'personInChargeName'],
    'Vendedor GDM': ['brandIds', 'personInChargeName', 'regionId'],
  };
 
  const fieldsToValidate = entityValidationRules[entityType] || [];
  fieldsToValidate.forEach((field) => {
    if (field === 'brandIds' && !brandIds?.length) {
      errors[field] = getLabelTranslation(
        entityType === 'Vendedor GDM'
          ? 'validatorForm_gdm_seller_must_have_at_least_one_brand'
          : 'validatorForm_at_least_one_brand_is_required',
      );
    }
     if(field === 'logoFile') {
      if (!logoFile){
        errors[field] = getLabelTranslation('validatorForm_logo_is_required');
      }
    }
    if(field !== 'logoFile' && field !== 'brandIds'){
      console.log(field, formData[field])
      validateField(!formData[field]?.trim(), field as keyof typeof formData, requiredMessages, errors);
    }
  });
 
  const cleanedDocument = documentNumber.replace(/\D/g, '');
  const requiresFantasyName =
    (countryName === 'Brasil' && cleanedDocument.length > 11 && cleanedDocument.length < 14) ||
    (countryName === 'Argentina' && cleanedDocument.length > 11);
  validateField(requiresFantasyName && !fantasyName?.trim(), 'fantasyName', requiredMessages, errors);
 
  return errors;
};
 
export default dynamicFormValidateForm;