import { useThemeContext } from 'core/design-system/styles/ThemeContext';

import { useTheme } from '@mui/material/styles';

const useThemeSwitcher = () => {
  const theme = useTheme();
  const { themeName, switchTheme } = useThemeContext();

  return { theme, themeName, switchTheme };
};

export default useThemeSwitcher;
