import { ActionButton } from 'hooks/useModal/useModal';
import { GDMFeedbackModalSetOldActionsFunction } from './gdmFeedbackModalSetOldActions.type';

const gdmFeedbackModalSetOldActions: GDMFeedbackModalSetOldActionsFunction = ({
  modalActionButtons,
  primaryAction,
  secondAction,
  setModalActionButtons,
}) => {
  let _modalActionButtons = modalActionButtons || [];

  _modalActionButtons = _modalActionButtons.filter(
    action => action.key !== 'secondAction' && action.key !== 'primaryAction',
  );

  if (!!secondAction && !!secondAction.label) {
    const defaultSecondAction: ActionButton = {
      key: 'secondAction',
      action: secondAction.action,
      label: secondAction.label,

      properties: {
        sx: {
          backgroundColor: '#007D5F',
          ':hover': {
            backgroundColor: '#006B44',
          },
        },
      },
    };

    _modalActionButtons.push(defaultSecondAction);
  }
  if (!!primaryAction && !!primaryAction.label) {
    const defaultPrimaryAction: ActionButton = {
      key: 'primaryAction',
      action: primaryAction.action,
      label: primaryAction.label,
      properties: {
        color: 'primary',
      },
    };

    _modalActionButtons.push(defaultPrimaryAction);
  }
  setModalActionButtons(_modalActionButtons);
};

export default gdmFeedbackModalSetOldActions;
