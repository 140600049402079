import { Divider, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import {
  GDMBox,
  GDMSecureImage,
  GDMSelect,
  GDMTextField,
  GDMTypography,
  GDMButton,
  GDMCustomInputLabel,
  GDMGrid,
  GDMMenuItem,
} from 'components';
import { GDMDrawerMap } from 'components/gdm-drawer-map';
import { GDMFormControl } from 'components/gdm-form-control';
import { GDMFormHelperText } from 'components/gdm-form-helper-text';
import { GDMMapTypes } from 'components/gdm-maps/GDMMaps.type';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useEffect, useState } from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';
import { IoImageOutline } from 'react-icons/io5';
import { stringTruncate } from 'utils/formaters';

const centroidCalculation = (coordinates: { latitude: string; longitude: string }[]) => {
  try {
    const latXTotal = coordinates.reduce(
      (total, { latitude }) => total + Math.cos((Math.PI * Number(latitude)) / 180),
      0,
    );
    const latYTotal = coordinates.reduce(
      (total, { latitude }) => total + Math.sin((Math.PI * Number(latitude)) / 180),
      0,
    );
    const lonDegreesTotal = coordinates.reduce(
      (total, { longitude }) => total + Number(longitude),
      0,
    );
    const finalLatRadians = Math.atan2(latYTotal, latXTotal);
    const finalLatDegrees = (finalLatRadians * 180) / Math.PI;
    const finalLonDegrees = lonDegreesTotal / coordinates.length;
    return { latitude: `${finalLatDegrees}`, longitude: `${finalLonDegrees}` };
  } catch (err) {
    return { latitude: `${0}`, longitude: `${0}` };
  }
};

const GDMLotRegisterForm = ({
  formData,
  errors,
  handleChangeValues,
  states,
  cities,
  setCoordinates,
  openModalState = false,
  setMapPinListLot,
  setSaveLatLng = undefined,
  latLong,
  view = 'create',
  farmerName = '',
  farmName = '',
  coordinatesBackup = [],
  setCoordinatesBackup = undefined,
  updateParentMapImageURL = undefined,
  typeForm,
}) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();

  const [showButton, setShowButton] = useState(true);
  const [showLatLngField, setShowLatLngField] = useState(false);
  const [fieldCoordinates, setFieldCoordinates] = useState('');
  const [modalInfos, setModalInfos] = useState<any>({ img: '', lotName: '' });
  const [openImageModal, setOpenImageModal] = useState(false);
  const setOpenModal = (v: boolean) => {
    setSaveLatLng(prev => ({ ...prev, openModalLot: v }));
  };

  const handleCapture = async () => {
    const zoom = 16;
    const size = '1920x1080';
    const mapType = 'satellite';
    const apiUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${fieldCoordinates}&zoom=${zoom}&size=${size}&maptype=${mapType}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      updateParentMapImageURL(prev => ({ ...prev, isLoading: true }));
      if (!response.ok) {
        throw new Error(`Erro ao obter mapa: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();

      updateParentMapImageURL(prev => ({ ...prev, img: blob }));
      setModalInfos(prev => ({ ...prev, img: blob }));
    } catch (error) {
      console.error('Erro ao gerar mapa:', error);
    } finally {
      updateParentMapImageURL(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSave = () => {
    handleCapture();
    setShowButton(false);
    setShowLatLngField(true);
    setOpenModal(false);
    if (setSaveLatLng) {
      setSaveLatLng(prev => ({ ...prev, lot: true, openModalLot: false }));
    }
    if (setCoordinatesBackup) {
      setCoordinatesBackup(formData?.mapPinList);
    }
  };

  const handleOpenModal = () => {
    setModalInfos({ img: formData.lotImage, lotName: formData.name });
    setOpenImageModal(true);
  };

  const handleCancel = () => {
    if (view !== 'edit') {
      setShowLatLngField(false);
      setShowButton(true);
    }
    if (coordinatesBackup) {
      setMapPinListLot(coordinatesBackup);
    }
    setOpenModal(false);
    setSaveLatLng(prev => ({ ...prev, lot: false, openModalLot: false }));
  };

  useEffect(() => {
    setOpenModal(openModalState);
    if (latLong.lat && latLong.lng) {
      const formattedCoordinates = `${latLong.lat}, ${latLong.lng}`;
      setFieldCoordinates(formattedCoordinates);
    }
  }, [latLong]);

  useEffect(() => {
    const filteredValidList = formData?.mapPinList?.filter(x => !!Number(x?.latitude)) || [];
    if (filteredValidList?.length === 0) setFieldCoordinates('0, 0');
    else if (filteredValidList?.length === 1)
      setFieldCoordinates(`${filteredValidList[0]?.latitude}, ${filteredValidList[0]?.longitude}`);
    else {
      const centroid = centroidCalculation(filteredValidList);
      setFieldCoordinates(`${centroid.latitude}, ${centroid.longitude}`);
      setCoordinates({ lat: centroid.latitude, lng: centroid.longitude });
    }
  }, [formData?.mapPinList]);

  useEffect(() => {
    if (view === 'view' || view === 'edit') {
      setShowButton(false);
      setShowLatLngField(true);
    }
  }, [view]);

  return (
    <>
      <GDMGrid container spacing={2} sx={{ marginLeft: '0', width: '100%', marginTop: '12px' }}>
        <GDMGrid xs={12} sm={12} md={6} sx={!isDesktop && { textAlign: 'start' }}>
          <GDMCustomInputLabel
            label={getLabelTranslation('lot_name')}
            required={view !== 'view'}
            fontSize="16px"
            color="#222222"
          />
          <GDMFormControl fullWidth>
            <GDMTextField
              required
              focused={false}
              id="lotName"
              disabled={view === 'view'}
              variant="outlined"
              placeholder={getLabelTranslation('lot_name')}
              fullWidth
              value={formData.lotName}
              name="lotName"
              onChange={handleChangeValues}
              sx={{
                background: view === 'view' && '#F2F4F9',
              }}
            />
            {!!errors.lotName && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.lotName}</GDMFormHelperText>
            )}
          </GDMFormControl>
        </GDMGrid>

        {/* AREA TOTAL */}
        <GDMGrid
          xs={12}
          sm={12}
          md={6}
          sx={{
            textAlign: 'start',
            paddingLeft: isDesktop ? '16px' : 0,
            paddingTop: !isDesktop ? '16px' : '0',
          }}
        >
          <GDMCustomInputLabel
            label={getLabelTranslation('total_area')}
            fontSize="16px"
            color="#222222"
          />
          <GDMFormControl fullWidth>
            <GDMTextField
              type="number"
              focused={false}
              id="totalArea"
              disabled={view === 'view'}
              variant="outlined"
              placeholder={getLabelTranslation('total_area')}
              fullWidth
              value={formData.totalArea}
              onChange={handleChangeValues}
              name="totalArea"
              sx={{
                background: view === 'view' && '#F2F4F9',
              }}
              inputProps={{ min: 0 }}
              onKeyDown={e => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
            />

            {!!errors.totalArea && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.totalArea}</GDMFormHelperText>
            )}
          </GDMFormControl>
        </GDMGrid>

        {/* ESTADO */}
        <GDMGrid xs={12} sm={12} md={3} sx={{ textAlign: 'start', paddingTop: '16px' }}>
          <GDMCustomInputLabel
            label={getLabelTranslation('formfields_state')}
            required={view !== 'view'}
            fontSize="16px"
            color="#222222"
          />
          <GDMFormControl fullWidth>
            <GDMSelect
              sx={{
                background: '#F2F4F9',
                fontSize: '14px',
                marginTop: '5px',
                height: '48px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                color: '#A1A9B8',
              }}
              IconComponent={IoIosArrowDown}
              value={formData.stateId}
              variant="outlined"
              fullWidth
              disabled
            >
              <GDMMenuItem value="0">{getLabelTranslation('formfields_state')}</GDMMenuItem>
              {states.map(state => (
                <GDMMenuItem key={state.id} value={state.id}>
                  {state.name}
                </GDMMenuItem>
              ))}
            </GDMSelect>
            {!!errors.stateId && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.ufId}</GDMFormHelperText>
            )}
          </GDMFormControl>
        </GDMGrid>

        {/* CIDADE */}
        <GDMGrid
          xs={12}
          sm={12}
          md={3}
          sx={{ textAlign: 'start', paddingTop: '16px', paddingLeft: isDesktop ? '16px' : 0 }}
        >
          <GDMCustomInputLabel
            label={getLabelTranslation('formfields_correction_city')}
            required={view !== 'view'}
            fontSize="16px"
            color="#222222"
          />
          <GDMSelect
            sx={{
              background: '#F2F4F9',
              fontSize: '14px',
              marginTop: '5px',
              height: '48px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              color: '#A1A9B8',
            }}
            disabled
            IconComponent={IoIosArrowDown}
            value={formData.cityId}
            variant="outlined"
            fullWidth
          >
            <GDMMenuItem value="0">{getLabelTranslation('formfields_correction_city')}</GDMMenuItem>
            {cities.map(city => (
              <GDMMenuItem key={city.id} value={city.id}>
                {city.name}
              </GDMMenuItem>
            ))}
          </GDMSelect>

          {errors.cityId && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.cityId}</GDMFormHelperText>
          )}
        </GDMGrid>

        {showLatLngField && (
          <GDMGrid
            xs={12}
            sm={12}
            md={3}
            xl={4}
            sx={{ textAlign: 'start', paddingTop: '16px', paddingLeft: isDesktop ? '16px' : 0 }}
          >
            <GDMCustomInputLabel
              label={getLabelTranslation('lat_long')}
              required={view !== 'view'}
              fontSize="16px"
              color="#222222"
            />
            <GDMFormControl
              fullWidth
              sx={{
                background: '#F2F4F9',
                border: '1px solid #A1A9B8',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                paddingLeft: '10px',
              }}
            >
              {typeForm === 'create' && (
                <a
                  target="_blank"
                  href={`https://www.google.com/maps?q=${fieldCoordinates}`}
                  rel="noreferrer"
                  aria-label="button"
                  style={{ color: '#0063F7' }}
                >
                  <GoLinkExternal />
                </a>
              )}
              {typeForm === 'view' && (
                <IconButton onClick={() => handleOpenModal()} sx={{ color: '#0063F7' }}>
                  <IoImageOutline color="#0063F7" />
                </IconButton>
              )}

              <GDMTextField
                focused={false}
                id="lat_long"
                placeholder={getLabelTranslation('lat_long')}
                fullWidth
                value={fieldCoordinates}
                disabled
                name="lat_long"
                sx={{
                  paddingLeft: 0,
                  fieldset: { border: 'none', paddingLeft: 0 },
                }}
              />
            </GDMFormControl>
            {errors.mapPinList && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.mapPinList}</GDMFormHelperText>
            )}
          </GDMGrid>
        )}

        {/* SELECIONAR LATITUDE/LONGITUDE */}
        {showButton && (
          <GDMGrid
            xs={12}
            sm={12}
            md={3}
            sx={{
              marginTop: isDesktop ? '35px' : 0,
              marginBottom: isDesktop ? '35px' : 0,
              paddingTop: '16px',
              paddingLeft: isDesktop ? '16px' : 0,
            }}
          >
            <GDMButton
              onClick={() => setOpenModal(true)}
              sx={{
                mr: 4,
                width: isDesktop ? 'auto' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                height: isDesktop ? '51px' : '40px',
                boxShadow: 'none',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_one_latitude_longitude')}
            </GDMButton>
            {errors.mapPinList && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.mapPinList}</GDMFormHelperText>
            )}
          </GDMGrid>
        )}
        {!showButton && !isDesktop && (
          <GDMGrid
            xs={12}
            sm={12}
            md={3}
            sx={{
              paddingTop: '16px',
              paddingLeft: isDesktop ? '16px' : 0,
            }}
          >
            <GDMButton
              onClick={() => setOpenModal(true)}
              sx={{
                mr: 4,
                width: isDesktop ? 'auto' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                height: isDesktop ? '51px' : '40px',
                boxShadow: 'none',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_latitude_longitude')}
            </GDMButton>
          </GDMGrid>
        )}
      </GDMGrid>
      <GDMDrawerMap
        InitialCoordinates={{
          latitude: latLong.lat,
          longitude: latLong.lng,
        }}
        isOpen={openModalState}
        onClose={handleCancel}
        title={getLabelTranslation('indicate_location')}
        type={GDMMapTypes.field}
        handleClear={() => {}}
        mapPinListLot={formData?.mapPinList || []}
        handleMapClick={params => {
          const arrayWithoutNumbers = [...formData.mapPinList, params];
          setMapPinListLot(arrayWithoutNumbers);
        }}
        handleMarkerDelete={params => {
          const newList = [...formData.mapPinList]?.filter(x => {
            return x.latitude !== params.latitude && x.longitude !== params.longitude;
          });
          setMapPinListLot(newList);
        }}
        subtitle={
          <GDMTypography sx={{ fontSize: '20px', marginBottom: '12px' }}>
            {getLabelTranslation('indicate_location_of_lote')} <strong> {farmName} </strong>
            {getLabelTranslation('of_farmer')} <strong> {farmerName}</strong>
          </GDMTypography>
        }
        actions={
          <GDMBox
            sx={{
              display: 'flex',
              alignItems: {
                xs: 'start',
                sm: 'start',
                md: 'center',
                lg: 'center',
                xl: 'center',
              },
              gap: '12px',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <GDMTypography sx={{ fontSize: '16px' }}>Latitude/Longitude</GDMTypography>
            <a
              style={{
                display: 'flex',
                padding: '8px 30px',
                background: '#F2F4F9',
                border: '1px solid #A1A9B8',
                color: '#0063F7',
                alignItems: 'center',
                gap: '8px',
                fontSize: '16px',
                justifyContent: 'start',
                borderRadius: '8px',
                maxWidth: '500px',
              }}
              target="_blank"
              href={`https://www.google.com/maps?q=${fieldCoordinates}`}
              rel="noreferrer"
            >
              <GoLinkExternal />
              {fieldCoordinates}
            </a>
            <GDMButton
              onClick={handleCancel}
              variant="contained"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '187px',
                  lg: '187px',
                  xl: '187px',
                },
                height: '51px',
                background: theme.palette.secondary.main,
                color: '#313131',
                ':hover': {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              {getLabelTranslation('cancel')}
            </GDMButton>
            <GDMButton
              onClick={handleSave}
              variant="contained"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '187px',
                  lg: '187px',
                  xl: '187px',
                },
                height: '51px',
                background: theme.palette.primary.main,
                color: 'white',
              }}
            >
              {getLabelTranslation('ReusableFormKey_Save')}
            </GDMButton>
          </GDMBox>
        }
      />
      <Modal open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <GDMBox
          sx={{
            width: isDesktop ? '40vw' : '95%',
            height: '83vh',
            background: '#fff',
            margin: '10vh auto',
            borderRadius: '24px',
            display: 'flex',
            flexDirection: 'column',
            padding: isDesktop ? '42px 41px' : '24px 16px',
            gap: '24px',
          }}
        >
          <GDMBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <GDMBox sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoImageOutline style={{ fontSize: '32px', marginRight: '8px' }} />
              <GDMTypography
                variant="inherit"
                sx={{ fontWeight: 700, color: '#222', fontSize: '20px', wordBreak: 'break-word' }}
              >
                {getLabelTranslation('image_lot')} {stringTruncate(modalInfos.lotName, 30)}
              </GDMTypography>
            </GDMBox>
            <IconButton
              onClick={() => {
                setOpenImageModal(false);
              }}
            >
              <IoMdClose color="#222" />
            </IconButton>
          </GDMBox>
          <Divider sx={{ borderColor: '#CBCDD9' }} />
          {modalInfos.img && (
            <GDMBox sx={{ height: '80%' }}>
              <GDMSecureImage
                src={modalInfos.img}
                alt="img_lot"
                style={{ width: '100%', height: '100%' }}
              />
            </GDMBox>
          )}
          <GDMButton variant="contained" onClick={() => setOpenImageModal(false)}>
            {getLabelTranslation('close')}
          </GDMButton>
        </GDMBox>
      </Modal>
    </>
  );
};

export default GDMLotRegisterForm;
