type GetGroupByRouteFunction = (route: string) => string;

const getGroupByRoute: GetGroupByRouteFunction = route => {
  if (route.includes('admin')) {
    return '/admin';
  }
  if (route.includes('account')) {
    return '/account';
  }
  if (route.includes('multiplier')) {
    return '/multiplier';
  }
  if (route.includes('distributor')) {
    return '/distributor';
  }
  if (route.includes('profile')) {
    return '/profile';
  }
  if (route.includes('farmer')) {
    return '/farmer';
  }
  if (route.includes('varieties')) {
    return '/varieties';
  }
  if (route.includes('recommendation')) {
    return '/recommendation';
  }
  if (route.includes('catalog')) {
    return '/catalog';
  }
  return route;
};

export default getGroupByRoute;
