import { AdminListChangeOrderFunction } from './adminListChangeOrder.type';

const adminListChangeOrder: AdminListChangeOrderFunction = ({
  adminListConfig,
  setAdminListConfig,
}) => {
  const newAdminListConfig = {
    ...adminListConfig,
    orderDesc: !adminListConfig.orderDesc,
    currentPage: 1, // sends the user back to the first page of the searched results
  };

  setAdminListConfig(newAdminListConfig);
};

export default adminListChangeOrder;
