import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UsersAdministratorService } from 'core/api/services/administrator';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { Box } from '@mui/material';

import { GDMDynamicForm, IDynamicFormData } from 'components';

import useModal from 'hooks/useModal/useModal';

import { errorHandler } from 'utils/form/errorHandler';

export default function AdminRegister(): JSX.Element {
  const { admId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(null);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const { openModal } = useModal();
  const { getLabelTranslation } = useLanguageSwitcher();

  let viewType = '';
  if (location.pathname.includes('edit')) {
    viewType = 'edit';
  } else if (location.pathname.includes(admId)) {
    viewType = 'view';
  } else {
    viewType = 'create';
  }

  const adminBreadcrumbs = () => {
    if (viewType === 'edit') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('ReusableFormKey_Administrator')} > ${getLabelTranslation(
        'edit',
      )}`;
    }
    if (viewType === 'view') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('ReusableFormKey_Administrator')}`;
    }
    if (viewType === 'create') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'cadastrar_administrador',
      )}`;
    }

    return '';
  };

  const handleCloseModal = () => {
    navigate('/admin-list');
  };

  const handleFormSubmit = async (formData: IDynamicFormData) => {
    try {
      const admData = {
        name: formData.name,
        email: formData.contactEmail,
        phoneNumber: formData.phoneNumber,
        documentTypeId: formData.documentTypeId,
        documentNumber: formData.documentNumber,
        countryId: formData.countryId,
      };

      await UsersAdministratorService.upSertAdmin(
        viewType === 'edit' ? 'put' : 'post',
        admData,
        admId,
      );

      openModal({
        type: 'success',
        title: getLabelTranslation('modal_tittle_register_success'),
        onClose: handleCloseModal,
      });
    } catch (error) {
      errorHandler(error, setFieldErrors);

      openModal({
        type: 'error',
        title: getLabelTranslation('modal_tittle_register_error'),
        message: getLabelTranslation('modal_message_register_error'),
      });
    }
  };

  useEffect(() => {
    if (viewType === 'create') {
      setInitialData(null);
      return;
    }
    if (viewType === 'edit' || viewType === 'view') {
      UsersAdministratorService.getAdmById(admId).then(response => {
        setInitialData(response);
      });
    }
  }, [viewType, admId]);

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <GDMDynamicForm
          viewType={viewType}
          entityType={getLabelTranslation('administrator')}
          initialData={initialData}
          onSubmit={handleFormSubmit}
          fieldErrors={fieldErrors}
          key={initialData ? initialData.id : 'initialData'}
          breadcrumb={adminBreadcrumbs()}
        />
      </Box>
    </Box>
  );
}
