import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const markerAvatarStyles: SxPropsThemeFunction = theme => ({
  borderRadius: 0,
  background: 'transparent',
  color: theme.palette.primary.main,
});

export const markerAvatarInitialsContainerStyles: SxPropsThemeFunction = theme => ({
  width: '21.34px',
  height: '21.34px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '4px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: theme.palette.primary[100],
});

export const markerAvatarInitialsStyles: SxPropsThemeFunction = theme => ({
  fontWeight: 700,
  fontSize: '10px!important',
  lineHeight: 'normal!important',
  color: theme.palette.primary.main,
});
