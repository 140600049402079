/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';
import { CountriesDomain } from 'core/typing/domains/CountriesDomain/CountriesDomain';

// DEPOIS REMOVER ESSA CHAMADA
const getCountries = async (): Promise<any> => {
  return Api.get({
    url: `/Locations/Countries`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

// CHAMADA PARA USE CASE
const getCountriesUseCase = async (): Promise<{ data: CountriesDomain }> => {
  return Api.get({
    url: `/Locations/Countries`,
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getStates = async (countryId: string): Promise<any> => {
  if (!countryId) throw new Error(`countryId is not defined: { countryId: ${countryId} }`);

  return Api.get({
    url: `/Locations/States/?CountryId=${countryId}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getCities = async (stateId: string): Promise<any> => {
  if (!stateId) throw new Error(`stateId is not defined: { stateId: ${stateId} }`);

  return Api.get({
    url: `/Locations/Cities/?StateId=${stateId}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getDocumentTypes = async (): Promise<any> => {
  return Api.get({
    url: `/DocumentTypes`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const LocationsService = {
  getCountries,
  getDocumentTypes,
  getStates,
  getCities,
  getCountriesUseCase,
};
