import {
  DynamicFormData,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
} from 'components';
import { MultiplierService } from 'core/api/services/multiplier';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { errorHandler } from 'utils/form/errorHandler';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dynamicFormValidateForm, getBrands, getCities, getGDMSellers, getStates } from 'utils';
import { updateMultiplier } from '../utils/update-multiplier';
import multiplierUpdateOnSubmit from '../utils/update-multiplier/updateMultiplier';

const DEFAULT_MULTIPLIER: Partial<DynamicFormData> = {
  address: '',
  brandIds: undefined,
  cityId: '',
  contactEmail: '',
};

export const MultiplierEditPage = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const [multiplier, setMultiplier] = useState<Partial<DynamicFormData>>(DEFAULT_MULTIPLIER);
  const [brands, setBrands] = useState([]);
  const [gdmSellers, setgdmSellers] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const {
    user: { countryId, countryName },
  } = useStore(UserProfileStore);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [entityType, setEntityType] = useState(getLabelTranslation('brandeselector_multiplier'));
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();
  const { multiplierId } = useParams();

  const handleBack = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const getMultiById = async () => {
    try {
      setIsLoading(true);
      const response = await MultiplierService.getMultiById(multiplierId);
      setMultiplier({
        id: multiplierId,
        name: response.name,
        fantasyName: response.fantasyName || '',
        contactEmail: response.contactEmail,
        phoneNumber: response.phoneNumber,
        personInChargeName: response.personInChargeName,
        documentNumber: response.documentNumber,
        cityId: response.cityId,
        countryId: response.countryId,
        address: response.address,
        brandIds: response.brands.map(item => item.id),
        sellerId: response.sellerGdmUserId,
        ufId: response.stateId,
        cityName: response.cityName,
        stateName: response.stateName,
        sellerGdmName: response.sellerGdmName,
        logoFile: response.logoCdnUrl,
        documentTypeId: response.documentTypeId,
      });
      console.log(response.cityId);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const loadBrands = () => {
    return getBrands({
      countryId,
      onError: () => console.error('Error on load brands!'),
      onInit: () => setIsLoading(true),
      onSuccess: brands => setBrands(brands),
    });
  };
  const loadStates = () => {
    return getStates({
      countryId,
      onSuccess: states => setStates(states),
      onInit: () => setIsLoading(false),
      onError: () => console.error('Error on load states!'),
    });
  };
  const loadSellersGDM = () => {
    return getGDMSellers({
      payload: {
        currentPag: 1,
        itemsPerPage: 999999999,
      },
      onSuccess: sellersGDM => setgdmSellers(sellersGDM.items),
    });
  };

  const handleChange = (e: any) => {
    const {
      target: { value, name },
    } = e;

    setMultiplier(multiplierData => ({ ...multiplierData, [name]: value }));
  };

  const setDocumentTypeId = () => {
    if (countryName === 'Argentina') {
      setMultiplier(prev => ({
        ...prev,
        documentTypeId: 'b85d6c71-e469-4268-a813-1bac98f596ae',
      }));
    }

    let onlyNumbers: number;

    if (multiplier.documentNumber) {
      onlyNumbers = multiplier.documentNumber.replace(/\D/g, '').length;
    }

    if (countryName === 'Brasil' && onlyNumbers === 11) {
      setMultiplier(prev => ({
        ...prev,
        documentTypeId: 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d',
        fantasyName: '',
      }));
    } else {
      setMultiplier(prev => ({
        ...prev,
        documentTypeId: 'cc49f596-0841-4708-9ef8-6e372ea70ddf',
      }));
    }
  };

  const loadCities = () => {
    return getCities({
      stateId: multiplier.ufId,
      onInit: () => setIsLoading(true),
      onError: () => console.error('Error on load cities!'),
      onSuccess: cities => setCities(cities),
    });
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      onClose: closeModal,
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const onInit = () => {
    setIsSubmiting(true);
  };

  const onSuccess = () => {
    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_update_success'),
      onClose: () => navigate('/multiplier-list'),
      primaryAction: {
        label: 'Ok',
        action: () => {
          closeModal();
          navigate('/multiplier-list');
        },
      },
    });
  };

  const onFinally = () => {
    setIsSubmiting(false);
  };
  const onError = errors => {
    errorHandler(errors, setErrors);

    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_update_error'),
      onClose: closeModal,
    });
  };

  const handleValidate = multiplierFormData =>
    dynamicFormValidateForm({
      formData: multiplierFormData,
      countryName,
      entityType,
      getLabelTranslation,
    });

  const onValidate = errors => {
    if (Object.keys(errors)?.length) {
      setIsSubmiting(false);
    }
    setErrors(errors);
  };

  const handleSubmit = async () => {
    await multiplierUpdateOnSubmit({
      multiplier,
      onError,
      onInit,
      onFinally,
      onSuccess,
      handleValidate,
      onValidate,
    });
  };

  useEffect(() => {
    if (multiplier.ufId) {
      loadCities();
      setMultiplier(prev => ({ ...prev, cityId: '', cityName: '' }));
    }
  }, [multiplier.ufId]);

  useEffect(() => {
    if (multiplier.sellerId && gdmSellers.length) {
      const seller = gdmSellers.find(seller => seller.id === multiplier.sellerId);
      if (seller.brands && seller.brands.length === 1) {
        setMultiplier(prev => ({ ...prev, brandIds: seller.brands.map(brand => brand.id) }));
      }
    }
  }, [multiplier.sellerId, gdmSellers]);

  useEffect(() => {
    const loadData: Array<Promise<any>> = [loadBrands(), loadSellersGDM(), loadStates()];
    if (multiplierId) {
      getMultiById();
    }
    Promise.all(loadData).finally(() => {
      setIsLoading(false);
    });
  }, [multiplierId]);

  useEffect(() => {
    if (multiplier.documentNumber) {
      setDocumentTypeId();
    }
  }, [multiplier.documentNumber]);

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('ReusableFormKey_Multiplier')} > ${getLabelTranslation('edit')}`}
      title={`${getLabelTranslation('reusableForm_EditRegistrationOf_')} ${getLabelTranslation(
        'ReusableFormKey_Multiplier',
      )}`}
    >
      <GDMConditionalRender canRender={isLoading}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>

      {multiplier !== DEFAULT_MULTIPLIER && (
        <GDMConditionalRender canRender={!isLoading}>
          <GDMDynamicFormFields
            {...{
              entityType,
              viewType: 'edit',
              formData: multiplier,
              isSubmiting,
              errors,
              formDataEvents: {
                onBack: handleBack,
                onSubmit: handleSubmit,
                onChange: handleChange,
                onCancel: handleCancel,
              },
              formDataOptions: {
                brands,
                cities,
                gdmSellers,
                roles: [],
                states,
              },
            }}
          />
        </GDMConditionalRender>
      )}
    </GDMPageCard>
  );
};
