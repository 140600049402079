import { SxProps } from '@mui/material';
import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const dashboardProfileContainerStyles: SxProps = {
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '20px',
  border: '1px solid #CBCDD9',
  alignItems: 'stretch',
  display: 'flex',
  justifyContent: 'space-between',
  gap: {
    xs: '0px',
    sm: '0px',
    md: '48px',
    lg: '64px',
    xl: '100px',
  },
  padding: {
    xs: '16px',
    sm: '16px',
    md: '42px 24px',
    lg: '42px 24px',
    xl: '42px 24px',
  },
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
};

export const dashboardProfileTitleContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto',
  gap: '8px',
  paddingBottom: {
    xs: '8px',
    sm: '8px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
};

export const dashboardProfileTitleStyles: SxProps = {
  lineHeight: '32px!important',
  fontWeight: 700,
  color: '#777D88',
  width: '100%',
  whiteSpace: 'nowrap',
  fontSize: {
    xs: '20px!important',
    sm: '20px!important',
    md: '32px!important',
    lg: '32px!important',
    xl: '32px!important',
  },
};

export const dashboardProfileTitleIconStyles: SxPropsThemeFunction = theme => ({
  fontSize: {
    xs: '24px',
    sm: '24px',
    md: '42px',
    lg: '42px',
    xl: '42px',
  },
  color: theme.palette.primary.main,
});

export const dashboardKPIContainerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px',
  gap: {
    xs: '32px',
    sm: '32px',
    md: '48px',
    lg: '64px',
    xl: '100px',
  },
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
};

export const dashboardKPIContentStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  padding: '0px',
  width: 'fit-content',
  alignItems: {
    xs: 'center',
    sm: 'center',
    md: 'flex-start',
    lg: 'flex-start',
    xl: 'flex-start',
  },
  flexDirection: {
    xs: 'row',
    sm: 'row',
    md: 'column',
    lg: 'column',
    xl: 'column',
  },
};

export const dashboardKPITitleStyles: SxProps = {
  fontSize: {
    xs: '16px!important',
    sm: '16px!important',
    md: '18px!important',
    lg: '18px!important',
    xl: '18px!important',
  },
  lineHeight: 'inherit!important',
  fontWeight: 400,
  color: '#777D88',
};

export const dashboardKPIValueStyles: SxProps = {
  fontSize: {
    xs: '36px!important',
    sm: '36px!important',
    md: '56px!important',
    lg: '56px!important',
    xl: '56px!important',
  },
  lineHeight: '61.6px!important',
  fontWeight: 700,
  color: '#222222',
  maxWidth: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: 'none',
    xl: 'none',
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const implementedRecommendationsValueContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  height: '100%',
  paddingLeft: '16px',
};

export const implementedRecommendationsIndicatorStyles: SxProps = {
  borderRadius: '50%',
  width: '16px',
  height: '16px',
};

export const implementedRecommendationsValueStyles: SxProps = {
  fontSize: '24px',
  fontWeight: 700,
  color: '#222222',
};

export const implementedRecommendationsLabelStyles: SxProps = {
  fontSize: '18px',
  fontWeight: 400,
  color: '#777D88',
  paddingBottom: {
    xs: '0px',
    sm: '0px',
    md: '8px',
    lg: '8px',
    xl: '8px',
  },
};

export const dashboardDataContainerStyles: SxProps = {
  display: {
    xs: 'flex',
    sm: 'flex',
    md: 'contents',
    lg: 'contents',
    xl: 'contents',
  },
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
};
