/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';
import { DashboardFilters } from 'core/typing/domains/DashboardDomain/CulturesDomain';

const getDashboard = async (filters?: DashboardFilters): Promise<any> => {
  return Api.post({
    url: `/Dashboard`,
    body: filters,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const DashboardService = {
  getDashboard,
};
