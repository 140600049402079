import { SxProps } from '@mui/material';

export const boxStyle: SxProps = {
  backgroundColor: '#FAFAFA',
  padding: {
    xs: '0',
    md: '48px 36px 32px 36px',
  },
  borderRadius: '24px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};
