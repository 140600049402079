import { Radio, SxProps, styled } from '@mui/material';

export const brandContainer: SxProps = {
  width: '100%',
  borderRadius: '24px',
  padding: '30px 40px',
  background: '#FFF',
};

export const boxBrand: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '100px',
};

export const textStyles: SxProps = {
  fontSize: '24px',
};

export const ImageRadio = styled(Radio)(({ theme }) => ({
  '& + label': {
    cursor: 'pointer',
    border: '2px solid transparent',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  '&:checked + label': {
    borderColor: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
  },
}));

export const cardContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
};
export const brandImageContainer: SxProps = {
  borderRadius: '21px',
  border: '0.713px solid #CBCDD9',
  background: '#FFF',
  width: '238px',
  height: '238px',
  boxShadow: '0px 2px 7px 0px rgba(34, 34, 34, 0.10)',
  flexDirection: 'column',
  gap: '20px',
  justifyContent: 'center',
  display: 'flex',
  overflow: 'hidden',
  label: {
    padding: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
};
