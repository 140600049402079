import { LocationsService } from 'core/api/services/locations';
import { loadCountries, loadCountriesDone, loadCountriesFail } from 'core/store/stores';

const execute = async (): Promise<void> => {
  const errorCallback = () => {
    loadCountriesFail();
  };

  loadCountries();

  return LocationsService.getCountriesUseCase()
    .then(({ data }) => {
      loadCountriesDone(data);
    })
    .catch(errorCallback);
};

const LoadCountriesUseCase = {
  execute,
};

export default LoadCountriesUseCase;
