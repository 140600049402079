import { UserProfileService } from 'core/api/services/user';

const execute = async (props): Promise<void> => {
  return UserProfileService.changeUserPassword(props).catch(error => console.error(error));
};

const LoadChangeUserPasswordUseCase = {
  execute,
};

export default LoadChangeUserPasswordUseCase;
