import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const recommendationLinkStyles: SxPropsThemeFunction = theme => ({
  fontSize: '16px!important',
  lineHeight: '18px!important',
  fontWeight: '500!important',
  width: 'fit-content',
  height: 'fit-content',
  padding: '0px',
  marginTop: 'auto',
  textDecoration: 'underline',
  color: theme.palette.primary.main,
  ':hover, :active, :focus': {
    textDecoration: 'underline',
    background: 'transparent',
  },
  margin: {
    xs: '0px auto',
    sm: '0px auto',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
});
