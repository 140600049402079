import { debounce } from '@mui/material';

import { AdminListHandleSearchFunction } from './adminListHandleSearch.type';

const adminListHandleSearch: AdminListHandleSearchFunction = ({
  adminListConfig,
  setAdminListConfig,
  debounceTime = 1000,
}) => {
  debounce((query: any, columnName: string) => {
    const isValidQuery = typeof query === 'string' && !!query;

    const newAdminListConfig = {
      ...adminListConfig,
      columnName,
      currentPage: 1, // sends the user back to the first page of the searched results
      ...(isValidQuery ? { search: query } : {}),
    };
    setAdminListConfig(newAdminListConfig);
  }, debounceTime);
  debounce(adminListConfig.search as any, adminListConfig.searchColumn as any);
};

export default adminListHandleSearch;
