import { GDMButton, GDMDialogActions } from 'components';
import { GDMFeedbackModalActionsComponent } from './GDMFeedbackModalActions.type';

const GDMFeedbackModalActions: GDMFeedbackModalActionsComponent = ({ actionButtons = [] }) => {
  const styleOnlyOne = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 0px',
  };
  const styleMoreOne = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 20px 0px',
  };
  return (
    <GDMDialogActions
      sx={{
        ...(actionButtons.length === 1 ? styleOnlyOne : styleMoreOne),
        ...(actionButtons.length === 1 ? { justifyContent: 'center' } : {}),
      }}
    >
      {actionButtons.map(actionButton => (
        <GDMButton
          key={actionButton.key}
          {...{
            onClick: actionButton.action,

            fullWidth: true,
            variant: 'contained',
            ...actionButton.properties,
            sx: {
              margin: '0px',
              maxWidth: `calc((100% - (${actionButtons.length - 1} * 16px)) / ${
                actionButtons.length
              } )`,
              ...(actionButton.properties?.sx || {}),
            },
          }}
        >
          {actionButton.label}
        </GDMButton>
      ))}
    </GDMDialogActions>
  );
};

export default GDMFeedbackModalActions;
