/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';
import { RolesDomain } from 'core/typing/domains/RolesDomain/RolesDomain';

const getRoles = async (): Promise<any> => {
  return Api.get({
    url: `/Roles`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};
const getRolesUseCase = async (): Promise<{ data: RolesDomain }> => {
  return Api.get({
    url: `/Roles`,
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const RolesServices = {
  getRoles,
  getRolesUseCase,
};
