import { Pagination, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GDMBox } from 'components/gdm-box';
import { GDMButton } from 'components/gdm-button';
import { GDMDivider } from 'components/gdm-divider';
import { GDMList } from 'components/gdm-list';
import { GDMTypography } from 'components/gdm-typography';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import colors from '../../core/design-system/style-guide/colors.json';
import textStyles from '../../core/design-system/style-guide/typography.json';

export default function GDMTableMobile({ rows, changePage, currentPage, pages, emptyMessage }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const { getLabelTranslation } = useLanguageSwitcher();

  return (
    <GDMBox>
      {rows.length > 0 && (
        <>
          <GDMDivider />

          <GDMList component="nav" aria-labelledby="nested-list-subheader" disablePadding>
            {rows}
          </GDMList>

          <Pagination
            count={pages}
            page={currentPage}
            onChange={(_, page) => changePage(page)}
            variant="outlined"
            shape="rounded"
            boundaryCount={isDesktop ? 1 : 0}
            sx={{
              mt: 2,
              justifyContent: 'center',
              display: 'flex',
              '& ul > li > button': {
                backgroundColor: colors.light2,
                width: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                height: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                border: 'none',
                ...textStyles.table.pagination,
              },
              '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
                backgroundColor: colors.dark3,
              },
            }}
          />
        </>
      )}

      {!rows?.length && !!emptyMessage && (
        <GDMTypography
          sx={{
            textAlign: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}

      <GDMButton
        onClick={() => {
          navigate(`/farmer-register`);
        }}
        sx={{
          mt: 4,
          height: '51px',
          width: isDesktop ? '200px' : '100%',
          color: theme.palette.secondary.dark,
          background: 'transparent',
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          gap: '8px',
          marginRight: 0,
          ':hover': {
            background: theme.palette.secondary.light,
            boxShadow: 'none',
          },
        }}
        variant="outlined"
      >
        {getLabelTranslation('add_farmer_information')}
      </GDMButton>
    </GDMBox>
  );
}
