import { SxProps } from '@mui/material';

export const containerStyle: SxProps = {
  borderRadius: '1.5rem', // 24px

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
    borderRadius: 'none',
    width: '100%',
    boxShadow: 'none',
  },
  input: {
    borderRadius: '6px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    color: 'initial',
    ':disabled': {
      background: '#F2F4F9',
    },
  },

  '& .MuiInputLabel-shrink': {
    visibility: 'hidden',
  },
  '.MuiFormHelperText-root': {
    marginLeft: '0',
  },
};

export const gridForm: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(5, 80px)',
};
