/* eslint-disable react/jsx-no-constructed-context-values */
// ThemeContext.js
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { ThemeProvider, Theme } from '@mui/material';

import { themeDonMarioAr, themeGDMAdministradores, themeDonMarioBr } from './theme';
import { ThemeContextType, ThemeName } from './themeContext.d';

const ThemeContext = createContext<ThemeContextType>({
  themeName: 'GDM',
  switchTheme: () => {},
});

const themes: Record<ThemeName, Theme> = {
  DONMARIOAR: themeDonMarioAr,
  DONMARIOBR: themeDonMarioBr,
  GDM: themeGDMAdministradores,
};

interface ThemeContextProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line react/function-component-definition
export const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<ThemeName>(
    (localStorage.getItem('theme_recomendador') as ThemeName) || 'GDM',
  );
  const theme = themes[themeName] || themes.GDM;

  useEffect(() => {
    const storedThemeName = localStorage.getItem('theme_recomendador') as ThemeName;
    if (storedThemeName && storedThemeName !== themeName) {
      setThemeName(storedThemeName);
    }
  }, [themeName]);

  const switchTheme = (newThemeName: ThemeName) => {
    setThemeName(newThemeName);
  };

  return (
    <ThemeContext.Provider value={{ themeName, switchTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
