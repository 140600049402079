/* eslint-disable react/require-default-props */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, useTheme } from '@mui/material';

import { NavigateButtonProps } from './NavigateButton.types';

// eslint-disable-next-line react/function-component-definition
export const NavigateButton: React.FC<NavigateButtonProps> = ({
  label,
  icon,
  to,
  sx,
  disabled = false,
}: NavigateButtonProps) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      startIcon={icon}
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '300px',
        height: '70px',
        borderRadius: '6px',
        fontSize: '18px',
        fontWeight: 400,
        color: '#222222',
        svg: {
          width: 25,
          height: 25,
        },
        ':hover': {
          background: theme.palette.secondary.main,
        },
        ...sx,
      }}
    >
      {label}
    </Button>
  );
};
