import Api from 'core/api/clients/api/Api';

const getLotsListByFarm = async (farmId: string): Promise<any> => {
  const url = `/Lots/ListByFarm/${farmId}`;
  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

export const LotsService = {
  getLotsListByFarm,
};
