import { createStore } from 'effector';

import { loadCultures, loadCulturesDone, loadCulturesFail } from './CulturesEvents';
import { CulturesState } from './CulturesState';

const initialState: CulturesState & { isLoading: boolean } = {
  cultures: [
    {
      id: null,
      name: null,
    },
  ],
  isLoading: false,
};

const CulturesStore = createStore<CulturesState & { isLoading: boolean }>(initialState)
  .on(loadCultures, state => ({
    ...state,
    isLoading: true,
  }))
  .on(loadCulturesDone, (_, data) => ({
    isLoading: false,
    cultures: data,
  }))
  .on(loadCulturesFail, state => ({
    ...state,
    isLoading: false,
    cultures: {
      ...state.cultures,
    },
  }));

export default CulturesStore;
