import { IGDMButtonProps } from 'components';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export enum ModalType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  DELETE = 'delete',
}

export type ActionButton = {
  label: string;
  action: () => void;
  properties?: Partial<IGDMButtonProps>;
  key: string;
};

export interface ModalState {
  open: boolean;
  type: ModalType | `${ModalType}`;
  title?: string;
  message?: string;
  primaryAction?: { label: string; action?: () => void };
  secondAction?: { label: string; action?: () => void };
  actionButtons?: Array<ActionButton>;
}

export interface OpenModalState extends Omit<ModalState, 'open'> {
  onClose?: () => void;
}

export interface ModalContextType {
  modalState: ModalState;
  openModal: ({
    type,
    title,
    message,
    onClose,
    primaryAction,
    secondAction,
    actionButtons,
  }: OpenModalState) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function ModalProvider({ children }) {
  const DEFAULT_MODAL_STATE: ModalState = {
    open: false,
    title: '',
    message: '',
    type: 'success',
    primaryAction: {
      label: 'OK',
      action: () => closeModal(),
    },
    secondAction: {
      label: '',
      action: () => closeModal(),
    },
    actionButtons: [],
  };

  const [modalState, setModalState] = useState<ModalState>(DEFAULT_MODAL_STATE);

  const [onCloseCallback, setOnCloseCallback] = useState<(() => void) | undefined>(undefined);

  const closeModal = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      open: false,
    }));
    if (onCloseCallback) {
      onCloseCallback();
      setOnCloseCallback(undefined);
    }
  }, [onCloseCallback]);

  const openModal = useCallback(
    ({
      type,
      title,
      message,
      onClose,
      primaryAction,
      secondAction,
      actionButtons = [],
    }: OpenModalState) => {
      setModalState({
        open: true,
        title,
        message,
        type,
        primaryAction: primaryAction ?? {
          label: 'OK',
          action: () => {
            closeModal();
            if (onClose) onClose();
          },
        },
        secondAction: secondAction ?? {
          label: '',
          action: () => {
            closeModal();
            if (onClose) onClose();
          },
        },
        actionButtons,
      });
      setOnCloseCallback(() => onClose);
    },
    [closeModal],
  );

  const value = useMemo(
    () => ({ modalState, openModal, closeModal }),
    [modalState, openModal, closeModal],
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export default useModal;
