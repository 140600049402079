import { useCallback, useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { IoArrowBack, IoEyeOutline, IoImageOutline } from 'react-icons/io5';
import { LuTrash2 } from 'react-icons/lu';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { LotsService } from 'core/api/services/lot';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TableCell,
  TableRow,
  debounce,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { GDMTableDesktop } from 'components/gdm-table-desktop';
import { GDMTableFilter } from 'components/gdm-table-filter';
import { GDMTableMobile } from 'components/gdm-table-mobile';

import {
  GDMButton,
  GDMCollapse,
  GDMDivider,
  GDMIconButton,
  GDMList,
  GDMListItem,
  GDMListItemButton,
  GDMListItemIcon,
  GDMTypography,
  GDMSecureImage,
} from 'components';
import GDMBox from 'components/gdm-box/GDMBox';
import useModal from 'hooks/useModal/useModal';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { stringTruncate } from 'utils/formaters';
import colors from '../../core/design-system/style-guide/colors.json';
import { containerStyle } from './LotList.syle';

export default function LotList() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(null);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [itemsPerPage] = useState(4);
  const [modalInfos, setModalInfos] = useState({ img: '', lotName: '' });
  const [openModal, setOpenModal] = useState(false);
  const [orderDesc, setOrderDesc] = useState(false);
  const [orderColumn, setOrderColumn] = useState('');
  const [search, setSearch] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [searchColumn, setSearchColumn] = useState<string>('none' as string);
  const { getLabelTranslation, loading } = useLanguageSwitcher();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [emptyMessage, setEmptyMessage] = useState<string | undefined>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const columns: { column_name: string; column_title: string; type: string }[] = [
    {
      column_name: 'Name',
      column_title: getLabelTranslation('name'),
      type: 'text',
    },
    {
      column_name: 'StateName',
      column_title: getLabelTranslation('state_name'),
      type: 'text',
    },
    {
      column_name: 'CityName',
      column_title: getLabelTranslation('city_name'),
      type: 'text',
    },
    {
      column_name: 'TotalPlantedArea',
      column_title: getLabelTranslation('total_planted_area'),
      type: 'text',
    },
    {
      column_name: 'Image',
      column_title: 'Imagem',
      type: 'text',
    },
  ];
  const columnTitle: { column_name: string; column_title: string; type: string }[] = [
    {
      column_name: 'Name',
      column_title: getLabelTranslation('name'),
      type: 'text',
    },
    {
      column_name: 'StateName',
      column_title: getLabelTranslation('state_name'),
      type: 'text',
    },
    {
      column_name: 'CityName',
      column_title: getLabelTranslation('city_name'),
      type: 'text',
    },
    {
      column_name: 'TotalPlantedArea',
      column_title: getLabelTranslation('total_planted_area'),
      type: 'text',
    },
  ];

  useEffect(() => {
    if (!loadingPage && !rows?.length) {
      const isSearching = !!search;

      if (hasError) setEmptyMessage(getLabelTranslation('error_on_load_table_data_text'));
      else if (isSearching) setEmptyMessage(getLabelTranslation('empty_table_on_filter_text'));
      else setEmptyMessage(getLabelTranslation('empty_table_text'));
    }
  }, [rows, loadingPage, search, hasError]);

  const changeOrder = () => {
    setOrderDesc(!orderDesc);
    setCurrentPage(1);
  };
  const changePage = page => {
    setCurrentPage(page);
  };

  const handleClick = id => {
    setOpen(open === id ? null : id);
  };
  const handleOpenModal = (img, lotName) => {
    setModalInfos({ img, lotName });
    setOpenModal(true);
  };
  const handleSearch = useCallback(
    debounce((query: any, columnName: string) => {
      setSearchColumn(columnName);
      if (query !== null && query !== '') {
        setSearch(query);
      } else {
        setSearch('');
      }
      setCurrentPage(1); // sends the user back to the first page of the searched results
    }, 1000),
    [],
  );
  const loadLots = async () => {
    try {
      const response = await LotsService.getLotsList({
        currentPage,
        itemsPerPage,
        search,
        searchColumn,
        orderDesc,
        orderColumn,
      });

      setHasError(false);
      setRows(response.items);
      setPages(response.totalPages);
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    loadLots();
  }, [currentPage, itemsPerPage, search, searchColumn, orderDesc]);

  if (loading || loadingPage) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={containerStyle(rows.length === 0)}>
      <Box>
        <GDMTypography
          sx={{ fontWeight: '400', fontSize: '14px', color: '#A1A9B8' }}
        >{`${getLabelTranslation('see_register')} > ${getLabelTranslation(
          'farmer',
        )} > ${getLabelTranslation('farm')} > ${getLabelTranslation('lot')}`}</GDMTypography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media (max-width: 900px)': { flexDirection: 'column', gap: '16px' },
        }}
      >
        <GDMTypography variant="h4" sx={{ fontWeight: '700' }}>
          {getLabelTranslation('view_lot_registrations')}
        </GDMTypography>
        <GDMTableFilter handleSearch={handleSearch} columns={columnTitle} />
      </Box>
      <Box sx={{ marginTop: isDesktop ? '24px' : '0' }}>
        {isDesktop ? (
          <GDMTableDesktop
            emptyMessage={emptyMessage}
            pages={pages}
            currentPage={currentPage}
            changePage={changePage}
            columns={columns}
            changeOrder={changeOrder}
            setOrderColumn={setOrderColumn}
            rows={rows.map(row => (
              <TableRow
                sx={{ background: idToDelete === row.id ? '#CFEFFF' : '#FAFAFA' }}
                key={row.id}
              >
                <TableCell>
                  <GDMTypography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '19.6px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '30ch',
                    }}
                  >
                    {stringTruncate(row.name, 25)}
                  </GDMTypography>
                </TableCell>
                <TableCell>
                  <GDMTypography
                    variant="subtitle1"
                    sx={{ fontSize: '14px', lineHeight: '19.6px' }}
                  >
                    {row.stateName}
                  </GDMTypography>
                </TableCell>
                <TableCell>
                  <GDMTypography
                    variant="subtitle1"
                    sx={{ fontSize: '14px', lineHeight: '19.6px' }}
                  >
                    {row.cityName}
                  </GDMTypography>
                </TableCell>
                <TableCell>
                  <GDMTypography
                    variant="subtitle1"
                    sx={{ fontSize: '14px', lineHeight: '19.6px' }}
                  >
                    {stringTruncate(row.totalArea, 10)}
                  </GDMTypography>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleOpenModal(row.lotImageUrl, row.name)}
                    sx={{ marginLeft: '20px' }}
                  >
                    <IoImageOutline />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => navigate(`/farmer-lot-register/${row.id}`)}>
                    <IoEyeOutline />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => navigate(`/farmer-lot-register/${row.id}/edit`)}
                    sx={{ marginLeft: '10px' }}
                  >
                    <FiEdit />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setIdToDelete(row.id);
                    }}
                    sx={{ marginLeft: '15px' }}
                  >
                    <DeleteConfirmationModal
                      setDeleteId={setIdToDelete}
                      id={row.id}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      setPages={setPages}
                      setRows={setRows}
                      canBeDeleted={row.canBeDeleted}
                      orderDesc={orderDesc}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          />
        ) : (
          <GDMTableMobile
            emptyMessage={emptyMessage}
            changePage={changePage}
            currentPage={currentPage}
            pages={pages}
            rows={rows.map(item => (
              <GDMBox
                key={item.id}
                sx={{ background: idToDelete === item.id ? '#CFEFFF' : 'transparent' }}
              >
                <GDMListItemButton onClick={() => handleClick(item.id)} sx={{ pl: 0, pr: 0 }}>
                  <GDMListItem sx={{ pl: 0 }}>
                    <GDMTypography variant="body2">{getLabelTranslation('name')} </GDMTypography>
                    <GDMTypography variant="body1" ml={1}>{` ${item.name}`}</GDMTypography>
                  </GDMListItem>
                  <GDMIconButton
                    sx={{
                      color: '#222',
                      width: '32px',
                      height: '32px',
                      backgroundColor: '#CFEFFF',
                      borderRadius: '50%',
                    }}
                  >
                    {open === item.id ? <MdExpandLess /> : <MdExpandMore />}
                  </GDMIconButton>
                </GDMListItemButton>
                <GDMCollapse
                  in={open === item.id}
                  timeout="auto"
                  unmountOnExit
                  sx={{ background: 'transparent' }}
                >
                  <GDMList component="div" disablePadding>
                    <GDMListItem sx={{ pl: 0 }}>
                      <GDMTypography variant="body2">
                        {getLabelTranslation('state_name')}{' '}
                      </GDMTypography>
                      <GDMTypography variant="body1" ml={1}>{` ${item.stateName}`}</GDMTypography>
                    </GDMListItem>
                    <GDMListItem sx={{ pl: 0 }}>
                      <GDMTypography variant="body2">
                        {getLabelTranslation('city_name')}{' '}
                      </GDMTypography>
                      <GDMTypography variant="body1" ml={1}>{` ${item.cityName}`}</GDMTypography>
                    </GDMListItem>
                    <GDMListItem sx={{ pl: 0 }}>
                      <GDMTypography variant="body2">
                        {getLabelTranslation('total_planted_area')}{' '}
                      </GDMTypography>
                      <GDMTypography variant="body1" ml={1}>{` ${item.totalArea}`}</GDMTypography>
                    </GDMListItem>
                    <GDMListItem
                      sx={{ pl: 0, pr: 0, display: 'flex', justifyContent: 'space-between' }}
                    >
                      <GDMTypography variant="body2">
                        {getLabelTranslation('actions')}
                      </GDMTypography>
                      <GDMBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <GDMListItemIcon
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            fontSize: '22px',
                          }}
                        >
                          <IconButton onClick={() => handleOpenModal(item.lotImageUrl, item.name)}>
                            <IoImageOutline />
                          </IconButton>
                        </GDMListItemIcon>
                        <GDMListItemIcon
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            fontSize: '22px',
                          }}
                          onClick={() => navigate(`/farmer-lot-register/${item.id}`)}
                        >
                          <IoEyeOutline />
                        </GDMListItemIcon>
                        <GDMListItemIcon
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                          onClick={() => navigate(`/farmer-lot-register/${item.id}/edit`)}
                        >
                          <FiEdit />
                        </GDMListItemIcon>
                        <GDMListItemIcon
                          sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                          onClick={() => {
                            setIdToDelete(item.id);
                          }}
                        >
                          <DeleteConfirmationModal
                            id={item.id}
                            currentPage={currentPage}
                            canBeDeleted={item.canBeDeleted}
                            itemsPerPage={itemsPerPage}
                            setPages={setPages}
                            setRows={setRows}
                            orderDesc={orderDesc}
                            setDeleteId={setIdToDelete}
                          />
                        </GDMListItemIcon>
                      </GDMBox>
                    </GDMListItem>
                  </GDMList>
                </GDMCollapse>
                <GDMDivider />
              </GDMBox>
            ))}
          />
        )}{' '}
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <GDMBox
            sx={{
              width: isDesktop ? '40vw' : '95%',
              height: '83vh',
              background: '#fff',
              margin: '10vh auto',
              borderRadius: '24px',
              display: 'flex',
              flexDirection: 'column',
              padding: isDesktop ? '42px 41px' : '24px 16px',
              gap: '24px',
            }}
          >
            <GDMBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <GDMBox sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <IoImageOutline style={{ fontSize: '32px', marginRight: '8px' }} />
                <GDMTypography
                  variant="inherit"
                  sx={{ fontWeight: 700, color: '#222', fontSize: '20px', wordBreak: 'break-word' }}
                >
                  {getLabelTranslation('image_lot')} {stringTruncate(modalInfos.lotName, 30)}
                </GDMTypography>
              </GDMBox>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <IoMdClose color="#222" />
              </IconButton>
            </GDMBox>
            <Divider sx={{ borderColor: '#CBCDD9' }} />
            {modalInfos.img && (
              <GDMBox sx={{ height: '80%' }}>
                <GDMSecureImage
                  src={modalInfos.img}
                  alt="img_lot"
                  style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                />
              </GDMBox>
            )}
            <GDMButton variant="contained" onClick={() => setOpenModal(false)}>
              {getLabelTranslation('close')}
            </GDMButton>
          </GDMBox>
        </Modal>
      </Box>
      {loadingPage === false && rows.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            marginTop: 'auto',
            '@media (max-width: 900px)': {
              button: { width: '100%' },
            },
          }}
        >
          <GDMButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: isDesktop ? '187px' : '100%',
              color: '#222',
              gap: '8px',
            }}
            variant="outlined"
          >
            <IoArrowBack color="#222" />
            {getLabelTranslation('back')}
          </GDMButton>
        </Box>
      )}
    </Box>
  );
}

function DeleteConfirmationModal({
  id,
  currentPage,
  itemsPerPage,
  setRows,
  setPages,
  orderDesc,
  setDeleteId,
  canBeDeleted,
}) {
  const { getLabelTranslation } = useLanguageSwitcher();
  const { openModal, closeModal } = useModal();

  const handleClickOpen = () => {
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_confirm_question'),
      message: getLabelTranslation('modal_alert'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: handleDelete,
      },
      secondAction: {
        label: getLabelTranslation('cancel'),
        action: handleCancelClose,
      },
    });
  };

  const handleCloseModalFeedback = () => {
    const searchColumn = 'none';
    const search = '';
    LotsService.getLotsList({
      currentPage,
      itemsPerPage,
      searchColumn,
      search,
      orderDesc,
    }).then(response => {
      setRows(response.items);
      setPages(response.totalPages);
    });
  };

  const handleCancelClose = () => {
    closeModal();
    setDeleteId('');
  };
  const handleDelete = async () => {
    if (canBeDeleted) {
      LotsService.deleteLot({ id })
        .then(() => {
          openModal({
            type: 'success',
            title: getLabelTranslation('modal_tittle_delete_success'),
            onClose: handleCloseModalFeedback,
          });
          setDeleteId('');
        })
        .catch(() => {
          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
          setDeleteId('');
        });
    } else {
      openModal({
        type: 'error',
        title: getLabelTranslation('error_on_delete_register'),
        message: getLabelTranslation('error_on_delete'),
        onClose: closeModal,
      });
    }
  };

  return <LuTrash2 onClick={handleClickOpen} color={colors.dark1} style={{ fontSize: '1.2rem' }} />;
}
