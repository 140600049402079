import { createStore } from 'effector';

import { loadRoles, loadRolesDone, loadRolesFail } from './RolesEvents';
import { RolesState } from './RolesState';

const initialState: RolesState & { isLoading: boolean } = {
  roles: [
    {
      description: null,
      id: null,
      name: null,
      translations: null,
    },
  ],
  isLoading: false,
};

const RolesStore = createStore<RolesState & { isLoading: boolean }>(initialState)
  .on(loadRoles, state => ({
    ...state,
    isLoading: true,
  }))
  .on(loadRolesDone, (_, data) => ({
    isLoading: false,
    roles: data,
  }))
  .on(loadRolesFail, state => ({
    ...state,
    isLoading: false,
    roles: {
      ...state.roles,
    },
  }));

export default RolesStore;
