import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MultiplierService } from 'core/api/services/multiplier';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';


import { GDMBox, GDMDynamicForm } from 'components';

import useModal from 'hooks/useModal/useModal';

import { errorHandler } from 'utils/form/errorHandler';

export default function MultiplierRegister(): JSX.Element {
  const { multiplierId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(null);
  const [infosToView, setInfosToView] = useState({ accounts: undefined, statistics: null });
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [filters, setFilters] = useState({
    orderDesc: false,
    orderColum: '',
    pages: 1,
    currentPage: 1,
    totalPages: 1
  });

  const { getLabelTranslation } = useLanguageSwitcher();
  const { openModal } = useModal();

  let viewType = '';
  if (location.pathname.includes('edit')) {
    viewType = 'edit';
  } else if (location.pathname.includes(multiplierId)) {
    viewType = 'view';
  } else {
    viewType = 'create';
  }

  const multiplierBreadcrumbs = () => {
    if (viewType === 'edit') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('brandeselector_multiplier')} > ${getLabelTranslation('edit')}`;
    }
    if (viewType === 'view') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('brandeselector_multiplier')}`;
    }
    if (viewType === 'create') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'cadastrar_multiplicador',
      )}`;
    }

    return '';
  };

  useEffect(() => {
    if (viewType === 'edit' || viewType === 'view')
      MultiplierService.getMultiById(multiplierId).then(response => {
        setInitialData({
          ...response,
          email: response.contactEmail,
          logoFile: response.logoCdnUrl,
        });

        MultiplierService.getMultipliersStatistcsById(multiplierId).then(response => {
          setInfosToView(prev => ({ ...prev, statistics: response }));
        });
        MultiplierService.getMultiplierAccountById({
          id: multiplierId,
          orderColum: filters.orderColum,
          orderDesc: filters.orderDesc,
          page: filters.currentPage,
        }).then(response => {
          setInfosToView(prev => ({ ...prev, accounts: response }));
          setFilters(prev=>({...prev, totalPages: response.totalPages}))
        });
      });
    else setInitialData(null);
  }, [viewType, multiplierId]);

  const handleCloseModal = () => {
    navigate('/multiplier-list');
  };

  const handleFormSubmit = async (formData: any) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('fantasyName', formData.fantasyName);
      formDataToSend.append('personInChargeName', formData.personInChargeName);
      formDataToSend.append('contactEmail', formData.contactEmail);
      formDataToSend.append('phoneNumber', formData.phoneNumber);
      formDataToSend.append('documentTypeId', formData.documentTypeId);
      formDataToSend.append('documentNumber', formData.documentNumber.replace('_', ''));
      formDataToSend.append('cityId', formData.cityId);
      formDataToSend.append('sellerGdmUserId', formData.sellerId);
      formDataToSend.append('brandIds', formData.brandIds);
      formDataToSend.append('address', formData.address);
      if (formData.logoFile) {
        formDataToSend.append('logoFile', formData.logoFile);
      }
      await MultiplierService.upSertMultiplier(
        viewType === 'edit' ? 'put' : 'post',
        formDataToSend,
        multiplierId,
      );

      openModal({
        type: 'success',
        title: getLabelTranslation('modal_tittle_register_success'),
        onClose: handleCloseModal,
      });
    } catch (error) {
      errorHandler(error, setFieldErrors);

      openModal({
        type: 'error',
        title: getLabelTranslation('modal_tittle_register_error'),
        message: getLabelTranslation('modal_message_register_error'),
      });
    }
  };

  useEffect(() => {
    if(viewType === 'edit' || viewType === 'view'){
      MultiplierService.getMultiplierAccountById({
        id: multiplierId,
        orderColum: filters.orderColum,
        orderDesc: filters.orderDesc,
        page: filters.currentPage,
      }).then(response => {
        setInfosToView(prev => ({ ...prev, accounts: response }));
      });
    }
   
  }, [filters]);
  return (
    <GDMBox
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <GDMDynamicForm
        viewType={viewType}
        entityType="Multiplicador"
        initialData={initialData}
        onSubmit={handleFormSubmit}
        breadcrumb={multiplierBreadcrumbs()}
        statistics={infosToView.statistics}
        accountsList={infosToView.accounts}
        fieldErrors={fieldErrors}
        key={initialData ? initialData.id : 'initialData'}
        filters={filters}
        setFilters={setFilters}
      />
    </GDMBox>
  );
}
