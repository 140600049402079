import { SxProps } from '@mui/material';
import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const recommendationButtonsGroupStyles: SxPropsThemeFunction = theme => ({
  gap: '2px',
  width: '100%',
  '& button:first-child': {
    borderTopLeftRadius: '9999px',
    borderBottomLeftRadius: '9999px',
  },
  '& button:last-child': {
    borderTopRightRadius: '9999px',
    borderBottomRightRadius: '9999px',
  },
  '& button': {
    height: '40px',
    borderRightColor: `${theme.palette.primary.main}!important`,
    borderLeftColor: `${theme.palette.primary.main}!important`,
    borderWidth: '1px!important',
    padding: '4px 8px',
    fontSize: '14px!important',
    lineHeight: '20px!important',
    display: 'flex',
    flex: 1,
    gap: '8px',
    minWidth: 'fit-content',
  },
});

export const recommendationButtonsGridStyles: SxPropsThemeFunction = theme => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  columnGap: '8px',
  rowGap: '16px',
  justifyContent: 'center',
  maxWidth: '350px',
  margin: '0 auto',

  '& button': {
    minWidth: '80px!important',
    borderRadius: '9999px !important',
    width: 'fit-content',
    height: '40px',
    borderRightColor: `${theme.palette.primary.main}!important`,
    borderLeftColor: `${theme.palette.primary.main}!important`,
    borderWidth: '1px!important',
    padding: '4px 12px',
    fontSize: '14px!important',
    lineHeight: '20px!important',
    display: 'flex',
    gap: '8px',
  },
});

export const recommendationButtonStyles: SxProps = {
  textWrap: 'wrap',
};

export const recommendationButtonCheckIconStyles: SxProps = {};
