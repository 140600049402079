import { useCallback, useEffect, useRef, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';

import LoadRegionsUseCase from 'core/api/useCases/RegiosUseCase/LoadRegionsUseCase';
import DeleteProfilePhotoUseCase from 'core/api/useCases/UserProfileUseCase/DeleteUserPhotoUseCase';
import EditUserProfileUseCase from 'core/api/useCases/UserProfileUseCase/EditUserProfileUseCase';
import LoadUserProfileUseCase from 'core/api/useCases/UserProfileUseCase/LoadUserProfileUseCase';
import { ChangePasswordModal } from 'core/components/ChangePasswordModal/index';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useDisabled } from 'core/store/contexts/disabledInputsContext';
import { updateUserProfile } from 'core/store/stores';
import RolesStore from 'core/store/stores/RolesStore/RolesStore';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { EditUserProfileDomain } from 'core/typing/domains/UserProfileDomain/UserProfileDomain';
import { useStore } from 'effector-react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useModal from 'hooks/useModal/useModal';

import { GDMContainer, GDMDivider, GDMSecureImage } from 'components';
import {
  avatarActionsContainer,
  avatarCard,
  avatarStyle,
  containerStyle,
  labelFileInputStyle,
  profileContainer,
  roleCard,
  userNameStyle,
} from './Profile.style';
import validationSchema from './schemaValidator';
import SkeletonProfile from './SkeletonProfile/SkeletonProfile';
import UserProfileForm from './UserProfileForm/UserProfileForm';

export default function UserProfile() {
  const theme = useTheme();

  const { openModal, closeModal } = useModal();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { isDisabled, setIsDisabled } = useDisabled();

  const form = useForm<EditUserProfileDomain>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<EditUserProfileDomain>,
  });

  const { user, isLoading } = useStore(UserProfileStore);
  const { roles } = useStore(RolesStore);

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [isImageDeleted, setIsImageDeleted] = useState(false);

  const { register, handleSubmit, formState, control, watch, reset, clearErrors, setValue } = form;
  const { errors } = formState;

  const userRole = roles.find(role => role.name === user.role);

  const userTranslationRoleValue =
    userRole?.translations?.map(translation => translation.value) || [];

  const loadProfileFormData = async () => {
    try {
      await LoadRegionsUseCase.execute({
        countryId: user.countryId,
      });

      await LoadUserProfileUseCase.execute();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadProfileFormData();
  }, []);

  const convertToBase64 = file => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result.toString());
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (user || isDisabled) {
      reset(user);
      setFilePreview(null);
    }
  }, [isDisabled, reset, user]);

  const loadUserProfile = async () => {
    try {
      return await LoadUserProfileUseCase.execute();
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const deleteProfilePhoto = async () => {
    try {
      await DeleteProfilePhotoUseCase.execute();
    } catch (error) {
      console.error(error);
    } finally {
      setIsImageDeleted(false);
    }
  };

  const handleSuccess = async () => {
    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_update_success'),
      onClose: () => true,
    });
  };

  const handleError = () => {
    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_update_error'),
    });
  };

  const executeEditUserProfile = (convertedData: EditUserProfileDomain) =>
    EditUserProfileUseCase.execute(convertedData);

  const onSubmit = async (data: EditUserProfileDomain) => {
    setIsDisabled(true);
    setIsLoadingSubmit(true);

    const profileData = { ...data, ...(data.imageFile[0] ? { imageFile: data.imageFile[0] } : {}) };

    try {
      const result = await executeEditUserProfile(profileData);

      if (isImageDeleted) {
        await deleteProfilePhoto();
      }

      handleSuccess();

      return result;
    } catch (error) {
      console.error(error);

      handleError();

      return error;
    } finally {
      await loadUserProfile();
    }
  };

  const watchFile = watch('imageFile');

  useEffect(() => {
    if (watchFile && watchFile.length > 0) {
      convertToBase64(watchFile[0]);
    }
  }, [watchFile]);

  const handleDeletePhoto = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_warning_title'),
      message: getLabelTranslation('modal_warning_message'),
      primaryAction: {
        label: 'OK',
        action: () => {
          setValue('imageFile', '');

          updateUserProfile({ ...user, imageCdnUrl: '' });

          setFilePreview(null);

          if (user.imageCdnUrl !== null) {
            setIsImageDeleted(true);
            updateUserProfile({ ...user, imageCdnUrl: '' });
          }

          if (errors?.imageFile?.message) {
            clearErrors('imageFile');
            setValue('imageFile', '');
          }

          closeModal();
        },
      },
    });
  };

  const handleChangePassword = useCallback(() => {
    setOpenChangePasswordModal(prev => !prev);
  }, []);

  const { getLabelTranslation } = useLanguageSwitcher();

  if (isLoading || isLoadingSubmit) {
    return <SkeletonProfile />;
  }

  return (
    <>
      <ChangePasswordModal open={openChangePasswordModal} handleClose={handleChangePassword} />
      <GDMContainer
        component="form"
        id="profile-form"
        onSubmit={handleSubmit(onSubmit)}
        sx={containerStyle}
      >
        <Box sx={(avatarCard as any)(theme)}>
          <Box
            sx={{
              ...profileContainer,
              paddingBottom: isDisabled
                ? {
                    xs: '32px',
                    sm: '32px',
                    md: '64px',
                    lg: '64px',
                    xl: '64px',
                  }
                : '0px!important',
            }}
          >
            <GDMSecureImage
              useAvatar
              sx={{
                ...avatarStyle,
                backgroundColor: theme.palette.primary.main,
              }}
              src={filePreview || user?.imageCdnUrl}
              key={user?.imageCdnUrl || filePreview}
            >
              {user?.imageCdnUrl ? '' : user?.name?.charAt(0)}
            </GDMSecureImage>
            <Typography sx={userNameStyle}>{user?.name}</Typography>

            <Box
              sx={{
                ...roleCard,

                background: theme.palette.secondary.main,
              }}
            >
              {userTranslationRoleValue[0]}
            </Box>

            {!!errors.imageFile && (
              <FormHelperText sx={{ color: '#FB2047' }}>
                {errors?.imageFile?.message}
              </FormHelperText>
            )}
          </Box>
          {!isDisabled && (
            <Box sx={avatarActionsContainer}>
              <Button
                variant="outlined"
                disabled={filePreview === null && user.imageCdnUrl === null}
                sx={{ color: theme.palette.secondary.dark }}
                onClick={handleDeletePhoto}
              >
                {getLabelTranslation('delete_image')}
              </Button>
              <input
                name="imageFile"
                accept="image/*"
                hidden
                id="button-file"
                ref={inputFileRef} // Adicione a referência aqui
                {...register('imageFile')}
                type="file"
              />
              <label
                htmlFor="button-file"
                style={{
                  ...labelFileInputStyle,
                  boxShadow: 'none',
                  color: theme.palette.secondary.contrastText,
                  background: theme.palette.primary.main,
                }}
              >
                {getLabelTranslation('upload_image')}
              </label>
            </Box>
          )}
        </Box>
        <GDMDivider
          sx={{
            width: '100%',
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
              lg: 'none',
              xl: 'none',
            },
          }}
        />

        <UserProfileForm
          control={control}
          errors={errors}
          register={register}
          handleChangePassword={handleChangePassword}
          setValue={setValue}
        />
      </GDMContainer>
    </>
  );
}
