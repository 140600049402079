/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoIosArrowDown } from 'react-icons/io';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountsService } from 'core/api/services/accounts';
import { DistributorService } from 'core/api/services/distributor';
import { MultiplierService } from 'core/api/services/multiplier';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { Formatter } from 'core/utils';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useStore } from 'effector-react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, FormControl, FormHelperText, TextField, useTheme } from '@mui/material';

import {
  GDMButton,
  GDMContainer,
  GDMCustomInputLabel,
  GDMLoading,
  GDMTypography,
} from 'components';

import useModal from 'hooks/useModal/useModal';

import { errorHandler } from 'utils/form/errorHandler';

import {
  boxStyle,
  inputMaskStyle,
  formControlStyle,
  formHelperTextStyle,
} from './AccountsEdit.styles';

export default function AccountsEdit(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId } = useParams();

  const { user } = useStore(UserProfileStore);

  const [brands, setBrands] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [multiplier, setMultiplier] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [distributorNames, setDistributorNames] = useState(['No options']);
  const [multipliersNames, setMultipliersNames] = useState(['No options']);
  const [initialData, setInitialData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [fieldErrors, setFieldErrors] = useState<ObjectLiteral<string>>({});

  const { getLabelTranslation } = useLanguageSwitcher();

  const { openModal, closeModal } = useModal();

  const phoneValidation = {
    BrasilFixedLinePhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{3})$/,
    BrasilCellPhone:
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
    Argentina: /^[0-9()\s\-+]+$/,
  };

  const accountsFormSchema = yup.object({
    nameOfMultOrDist: yup.string().required(`${getLabelTranslation('select_multdist')}`),
    fullName: yup.string().required(`${getLabelTranslation('full_name_required')}`),
    country: yup.string().required(`${getLabelTranslation('country_required')}`),
    email: yup
      .string()
      .email(`${getLabelTranslation('invalid_email')}`)
      .required(`${getLabelTranslation('email_required')}`),
    phoneNumber: yup
      .string()
      .required(`${getLabelTranslation('phone_number_required')}`)
      .test('phoneNumber', `${getLabelTranslation('invalid_phone')}`, function (value) {
        const isBrasil = user.countryName === 'Brasil';
        const isArgentina = user.countryName === 'Argentina';
        const phoneValue = value as string;
        const cleanedPhoneNumber = phoneValue.replace(/[ \-+_]/g, '');
        const qtyOfNumbers = cleanedPhoneNumber.length;
        const fixedLinePhoneWithPrefixNine = cleanedPhoneNumber[4] === '9';
        const isABrasilFixedLinePhone = qtyOfNumbers === 12;
        const isABrasilCellPhone = qtyOfNumbers === 13;

        if (isArgentina) {
          if (!phoneValidation.Argentina.test(phoneValue)) {
            return false;
          }
        }

        if (isABrasilFixedLinePhone && isBrasil && fixedLinePhoneWithPrefixNine) {
          if (!phoneValidation.BrasilFixedLinePhone.test(phoneValue)) {
            return false;
          }
        }

        if (isABrasilCellPhone && isBrasil) {
          if (!phoneValidation.BrasilCellPhone.test(phoneValue)) {
            return false;
          }
        }

        if (
          qtyOfNumbers !== 12 &&
          qtyOfNumbers !== 13 &&
          !isABrasilCellPhone &&
          !isABrasilFixedLinePhone
        ) {
          return false;
        }

        return true;
      }),
    documentNumber: yup
      .string()
      .required(`${getLabelTranslation('document_required')}`)
      .test('documentNumber', `${getLabelTranslation('invalid_document')}`, value => {
        const documentNumber = value as string;
        const cleanedDocumentNumber = documentNumber.replace(/[.\-+/ ]/g, '');

        if (user.countryName === 'Brasil' && cleanedDocumentNumber?.length === 11) {
          return cpf.isValid(cleanedDocumentNumber);
        }

        if (user.countryName === 'Brasil' && cleanedDocumentNumber?.length === 14) {
          return cnpj.isValid(cleanedDocumentNumber);
        }

        if (user.countryName === 'Argentina') {
          return Formatter.cuitIsValid(cleanedDocumentNumber);
        }

        return true;
      })
      .test(
        'documentNumber',
        `O número do documento deve conter 11 dígitos (CPF) ou 14 dígitos (CNPJ).`,
        function (value) {
          const documentNumber = value as string;
          const cleanedDocumentNumber = documentNumber.replace(/[.\-+/ ]/g, '');
          if (
            user.countryName === 'Brasil' &&
            cleanedDocumentNumber?.length !== 11 &&
            cleanedDocumentNumber?.length !== 14
          ) {
            return false;
          }

          return true;
        },
      ),
    businessName: yup
      .string()
      .test('businessName', `${getLabelTranslation('business_name_required')}`, function (value) {
        const { documentNumber } = this.parent;
        const cleanedDocumentNumber = documentNumber ? documentNumber.replace(/\D/g, '') : '';
        if (cleanedDocumentNumber && cleanedDocumentNumber.length > 11 && !value) {
          return false;
        }
        return true;
      }),
    responsible: yup.string().required(`${getLabelTranslation('responsible_required')}`),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accountsFormSchema),
  });

  const documentNumberTyped = watch('documentNumber');
  const onlyNumbersOfDocumentTyped = documentNumberTyped
    ? documentNumberTyped.replace(/\D/g, '')
    : '';

  const setDocumentTypeId = () => {
    if (user.countryName === 'Argentina') {
      return 'b85d6c71-e469-4268-a813-1bac98f596ae';
    }

    let onlyNumbers: number;

    if (documentNumberTyped) {
      onlyNumbers = documentNumberTyped.replace(/\D/g, '').length;
    }

    if (user.countryName === 'Brasil' && onlyNumbers === 11) {
      return 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d';
    }

    return 'cc49f596-0841-4708-9ef8-6e372ea70ddf';
  };

  const handleCloseModal = () => {
    if (roleId) {
      if (roleId === '6131237f-2943-468a-81ec-87661e9377bd') {
        return navigate('/account-seller-dist-list');
      }
      if (roleId === '82f43a26-7855-4faf-88a1-4801d6181401') {
        return navigate('/account-manager-dist-list');
      }
      if (roleId === 'dbb87c0b-34ac-4d45-8ace-80b08b1df2a9') {
        return navigate('/account-seller-mult-list');
      }
      if (roleId === 'dd1e670b-30d3-4bc7-bb13-40ebb93109b9') {
        return navigate('/account-manager-mult-list');
      }
    }

    return navigate('/');
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const setPerfilTitle = () => {
    if (roleId) {
      if (roleId === '6131237f-2943-468a-81ec-87661e9377bd') {
        return getLabelTranslation('seller_distributor');
      }
      if (roleId === '82f43a26-7855-4faf-88a1-4801d6181401') {
        return getLabelTranslation('manager_distributor');
      }
      if (roleId === 'dbb87c0b-34ac-4d45-8ace-80b08b1df2a9') {
        return getLabelTranslation('seller_multiplier');
      }
      if (roleId === 'dd1e670b-30d3-4bc7-bb13-40ebb93109b9') {
        return getLabelTranslation('manager_multiplier');
      }
    }

    return ' ';
  };

  const handleChangeBrands = (value: string) => {
    if (!value) {
      return;
    }
    const multOrDist = value;
    if (setPerfilTitle().includes('Distribuidor')) {
      const distChosed = distributor.find(dist => dist.name === multOrDist);
      const distChosedBrands = distChosed.brands.map(brand => brand.name);
      setBrands(distChosedBrands);
    }

    if (setPerfilTitle().includes('Multiplicador')) {
      const multChosed = multiplier.find(mult => mult.name === multOrDist);
      const multChosedBrands = multChosed.brands.map(brand => brand.name);
      setBrands(multChosedBrands);
    }
  };

  const setMultiplierId = async () => {
    if (setMultOrDistTitle().includes('Multiplicador') && getValues('nameOfMultOrDist')) {
      const multplierSelected = multiplier.find(
        mult => mult.name === getValues('nameOfMultOrDist'),
      );
      return multplierSelected ? multplierSelected.id : null;
    }
    return null;
  };

  const setDistributorId = async () => {
    if (setMultOrDistTitle().includes('Distribuidor') && getValues('nameOfMultOrDist')) {
      const distSelected = distributor.find(dist => dist.name === getValues('nameOfMultOrDist'));
      return distSelected ? distSelected.id : null;
    }
    return null;
  };

  const setMultOrDistTitle = () => {
    if (roleId) {
      if (
        roleId === '6131237f-2943-468a-81ec-87661e9377bd' ||
        roleId === '82f43a26-7855-4faf-88a1-4801d6181401'
      ) {
        return getLabelTranslation('brandeselector_distributor');
      }
      if (
        roleId === 'dbb87c0b-34ac-4d45-8ace-80b08b1df2a9' ||
        roleId === 'dd1e670b-30d3-4bc7-bb13-40ebb93109b9'
      ) {
        return getLabelTranslation('brandeselector_multiplier');
      }
    }

    return ' ';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountRes = await AccountsService.getAccountId(accountId);
        setInitialData(accountRes);
        setBrands(accountRes.brands);
        setRoleId(accountRes.roleId);
        setValue('fullName', accountRes.name);
        setValue('email', accountRes.email);
        setValue('country', accountRes.countryName);
        setValue('phoneNumber', accountRes.phoneNumber);
        setValue('documentNumber', accountRes.documentNumber);
        setValue('businessName', accountRes.fantasyName ? accountRes.fantasyName : '');
        setValue('responsible', accountRes.personInChargeName);

        if (accountRes.multiplierName) {
          setValue('nameOfMultOrDist', accountRes.multiplierName);
        } else if (accountRes.distributorName) {
          setValue('nameOfMultOrDist', accountRes.distributorName);
        }

        const distributorsRes = await DistributorService.getDistributors({
          currentPage: 1,
          itemsPerPage: 200,
          search: '',
          searchColumn: '',
          orderDesc: false,
        });
        setDistributor(distributorsRes.items);
        setDistributorNames(distributorsRes.items.map(dist => dist.name));

        const multipliersRes = await MultiplierService.getMultipliers({
          currentPage: 1,
          itemsPerPage: 200,
          search: '',
          searchColumn: '',
          orderDesc: false,
        });
        setMultiplier(multipliersRes.items);
        setMultipliersNames(multipliersRes.items.map(mult => mult.name));

        handleChangeBrands(getValues('nameOfMultOrDist'));
      } catch (error) {
        console.error('Failed to fetch data', error);
      } finally {
        setLoadingPage(false);
      }
    };

    fetchData();
  }, [accountId, setValue, getValues]);

  const handleBlur = event => {
    const inputValue = event.target.value.replace(/\D/g, '');

    if (inputValue.length === 11 && user.countryName === 'Brasil') {
      const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      event.target.value = formattedValue;

      setValue('businessName', '');
    }

    if (inputValue.length === 14 && user.countryName === 'Brasil') {
      const formattedValue = inputValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
      event.target.value = formattedValue;
    }

    if (inputValue.length === 11 && user.countryName === 'Argentina') {
      const formattedValue = inputValue.replace(/(\d{2})(\d{8})(\d{1})/, '$1-$2-$3');
      event.target.value = formattedValue;
    }
  };

  const onSubmit = async (data: any) => {
    const { fullName, phoneNumber, documentNumber, businessName, responsible } = data;
    const multiplierId = await setMultiplierId();
    const distributorId = await setDistributorId();

    const account = {
      name: fullName,
      email: initialData.email,
      phoneNumber,
      documentTypeId: setDocumentTypeId(),
      documentNumber,
      countryId: initialData.countryId,
      roleId: initialData.roleId,
      multiplierId,
      distributorId,
      fantasyName: businessName,
      personInChargeName: responsible,
    };

    try {
      setLoading(true);
      await AccountsService.editAccountId(initialData.id, account);
      openModal({
        type: 'success',
        title: getLabelTranslation('modal_tittle_create_success'),
        onClose: handleCloseModal,
      });
    } catch (error) {
      errorHandler(error, setFieldErrors);

      openModal({
        type: 'error',
        title: getLabelTranslation('modal_tittle_create_error'),
        message: getLabelTranslation('modal_message_create_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  if (loadingPage) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  return (
    <>
      <Box>
        <Box sx={boxStyle}>
          <GDMTypography
            sx={{ color: '#A1A9B8', fontSize: '14px', m: 0 }}
            display="block"
            gutterBottom
          >
            {`${getLabelTranslation('administrative')} > ${getLabelTranslation(
              'ver_cadastros',
            )} > ${setPerfilTitle()} > ${getLabelTranslation('edit')}`}
          </GDMTypography>
          <GDMTypography
            variant="h4"
            textAlign="left"
            mt={3}
            mb={3}
            fontWeight={700}
            gutterBottom
            component="div"
            color="#222222"
          >
            {`${getLabelTranslation('reusableForm_EditRegistrationOf_')} ${setPerfilTitle()}`}
          </GDMTypography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '11px',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <GDMCustomInputLabel
                label={getLabelTranslation('customRolesInputs_profile')}
                fontSize="16px"
                color="#222222"
              />
              <TextField
                focused={false}
                value={setPerfilTitle()}
                disabled
                variant="outlined"
                sx={{
                  widows: '100%',
                  background: '#F2F4F9',
                  input: {
                    background: '#F2F4F9',
                    border: 'none',
                  },
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <GDMCustomInputLabel
                required
                label={`${getLabelTranslation('select')} ${
                  setMultOrDistTitle().includes('Multiplicador')
                    ? `${getLabelTranslation('ReusableFormKey_Multiplier')}`
                    : `${getLabelTranslation('brandeselector_distributor')}`
                }`}
                fontSize="16px"
                color="#222222"
              />
              <Controller
                name="nameOfMultOrDist"
                control={control}
                rules={{ required: true }}
                defaultValue={getValues('nameOfMultOrDist')}
                render={({ field }) => (
                  <Autocomplete
                    {...register('nameOfMultOrDist')}
                    onChange={(_, value) => setValue('nameOfMultOrDist', value)}
                    defaultValue={getValues('nameOfMultOrDist')}
                    popupIcon={<IoIosArrowDown />}
                    fullWidth
                    options={
                      setMultOrDistTitle().includes('Multiplicador')
                        ? multipliersNames
                        : distributorNames
                    }
                    sx={{
                      width: {
                        xs: '100%',
                        md: '338px',
                      },
                      background: '#FAFAFA',
                      input: {
                        background: '#FAFAFA',
                        border: 'none',
                      },
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        focused={false}
                        placeholder={`${getLabelTranslation('select')} ${
                          setMultOrDistTitle().includes('Multiplicador')
                            ? `${getLabelTranslation('ReusableFormKey_Multiplier')}`
                            : `${getLabelTranslation('brandeselector_distributor')}`
                        }`}
                        variant="outlined"
                        fullWidth
                        error={!!errors.nameOfMultOrDist}
                        helperText={errors.nameOfMultOrDist?.message}
                        {...field}
                        sx={{
                          '& .Mui-disabled': {
                            backgroundColor: '#F2F4F9',
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Box>
          <form id="accounts-form" noValidate>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_full_name')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  {...register('fullName')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_full_name')}
                      variant="outlined"
                      fullWidth
                      error={!!errors.fullName || !!fieldErrors?.fullName}
                      helperText={errors.fullName?.message || fieldErrors?.fullName}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_email')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  {...register('email')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_email')}
                      variant="outlined"
                      fullWidth
                      disabled
                      error={!!errors.email || !!fieldErrors?.contactEmail}
                      helperText={errors.email?.message || fieldErrors?.contactEmail}
                      {...field}
                      sx={{
                        '& .Mui-disabled': {
                          backgroundColor: '#F2F4F9',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_country')}
                  fontSize="16px"
                  color="#222222"
                />
                <FormControl>
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <Autocomplete
                        {...register('country')}
                        popupIcon={<IoIosArrowDown />}
                        defaultValue={getValues('country')}
                        fullWidth
                        disabled
                        options={[user.countryName]}
                        sx={{
                          background: '#FAFAFA',
                          input: {
                            background: '#FAFAFA',
                            border: 'none',
                          },
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            focused={false}
                            placeholder={getLabelTranslation('formfields_country')}
                            variant="outlined"
                            fullWidth
                            disabled
                            error={!!errors.country}
                            helperText={errors.country?.message}
                            {...field}
                            sx={{
                              '& .Mui-disabled': {
                                backgroundColor: '#F2F4F9',
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_phone')}
                  fontSize="16px"
                  color="#222222"
                />
                <FormControl sx={errors?.phoneNumber?.message && formControlStyle}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true }}
                    {...register('phoneNumber')}
                    render={({ field }) => (
                      <InputMask
                        style={inputMaskStyle}
                        mask={`${
                          user.countryName === 'Brasil' ? '+99 99 99999-9999' : '+99 9 999 999-9999'
                        }`}
                        name="phoneNumber"
                        placeholder={getLabelTranslation('formfields_phone')}
                        maskChar={null}
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                  {!!errors?.phoneNumber && (
                    <FormHelperText sx={formHelperTextStyle}>
                      {errors?.phoneNumber?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <GDMCustomInputLabel
                  required
                  label={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
                  fontSize="16px"
                  color="#222222"
                />
                <FormControl sx={errors?.documentNumber?.message && formControlStyle}>
                  <TextField
                    {...register('documentNumber')}
                    placeholder={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
                    variant="outlined"
                    fullWidth
                    onBlur={e => handleBlur(e)}
                    inputProps={{ maxLength: 18 }}
                  />
                  {!!errors?.documentNumber && (
                    <FormHelperText sx={formHelperTextStyle}>
                      {errors?.documentNumber?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_business_name')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="businessName"
                  control={control}
                  rules={{ required: true }}
                  {...register('businessName')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_business_name')}
                      disabled={!cnpj.isValid(getValues('documentNumber').replace(/[.\-+/ ]/g, ''))}
                      variant="outlined"
                      fullWidth
                      error={!!errors.businessName || !!fieldErrors.businessName}
                      helperText={errors.businessName?.message || fieldErrors.businessName}
                      {...field}
                      sx={{
                        '& .Mui-disabled': {
                          backgroundColor: '#F2F4F9',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                mb: 8,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_responsible')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="responsible"
                  control={control}
                  rules={{ required: true }}
                  {...register('responsible')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_responsible')}
                      variant="outlined"
                      error={!!errors.responsible || !!fieldErrors.personInChargeName}
                      helperText={errors.responsible?.message || fieldErrors.personInChargeName}
                      {...field}
                      sx={{
                        width: {
                          xs: '100%',
                          md: '338px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('header_brand')}
                  fontSize="16px"
                  color="#222222"
                />
                <Autocomplete
                  sx={{
                    '& .MuiAutocomplete-disabled': {
                      cursor: 'not-allowed',
                    },
                    '& .MuiChip-root.Mui-disabled': {
                      color: '#222222',
                    },
                    maxWidth: '312px',
                  }}
                  multiple
                  id="tags-outlined"
                  options={brands?.map(brand => brand.name)}
                  value={brands?.map(brand => brand.name)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      sx={{
                        width: {
                          xs: '100%',
                          md: '338px',
                        },
                        '& .MuiFormHelperText-root': {
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: theme.spacing(0.5),
                          backgroundColor: '#FAFAFA',
                        },
                        '& .Mui-disabled': {
                          backgroundColor: '#F2F4F9',
                        },
                      }}
                    />
                  )}
                  disabled
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <GDMButton
                onClick={() => handleCancel()}
                disabled={loading}
                variant="outlined"
                sx={{
                  borderStyle: 'none',
                  width: {
                    xs: '100%',
                    md: '187px',
                  },
                  boxShadow: 'none',
                  color: theme.palette.secondary.dark,
                  background: theme.palette.secondary.main,
                  ':hover': {
                    borderStyle: 'none',
                    boxShadow: 'none',
                    background: theme.palette.secondary.light,
                  },
                }}
              >
                {getLabelTranslation('changePasswordModal_cancel')}
              </GDMButton>
              <GDMButton
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
                type="button"
                variant="outlined"
                sx={{
                  background: loading ? '#F2F4F9' : theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  height: '51px',
                  width: {
                    xs: '100%',
                    md: '187px',
                  },
                  boxShadow: 'none',
                  ':hover': {
                    boxShadow: 'none',
                    background: theme.palette.primary.light,
                  },
                }}
              >
                {loading ? (
                  <GDMLoading type="circular" {...{ size: 24 }} />
                ) : (
                  getLabelTranslation('changePasswordModal_save')
                )}
              </GDMButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}
