import { useMemo } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { getDocumentLabel } from 'core/utils/getDocumentLabel/getDocumentLabel';
import { getDocumentMask } from 'core/utils/getDocumentMask/getDocumentMask';
import { isObjectEmpty } from 'core/utils/validators/forms';
import { useStore } from 'effector-react';

import { useMediaQuery } from '@mui/material';

import {
  GDMAutocomplete,
  GDMButton,
  GDMContainer,
  GDMCustomInputLabel,
  GDMFormControl,
  GDMFormHelperText,
  GDMGrid,
  GDMInputLabel,
  GDMInputMask,
  GDMLink,
  GDMLoading,
  GDMMenuItem,
  GDMSelect,
  GDMTextField,
} from 'components';

import { GDMBrandSelector, GDMCustomRoleFields, GDMFileUpload } from './components';
import { containerStyle } from './GDMFormFields.style';
import { GDMFormFieldsComponent } from './GDMFormFields.type';

const GDMFormFields: GDMFormFieldsComponent = ({
  entityType,
  handlers,
  config,
  errors,
  changeInputValue,
  disableForm,
  cancelForm,
}) => {
  const { user } = useStore(UserProfileStore);
  const { theme } = useThemeSwitcher();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const disableInputByRole = user.role !== 'ADMINISTRATOR';
  const countryOfUser = user.countryName;
  const documentInputValue = config?.formData?.documentNumber ?? '';

  const enableBusinessNameInput = () => {
    const onlyNumbers = documentInputValue.replace(/\D/g, '');
    const isCnpj = onlyNumbers.length > 11;
    return !isCnpj;
  };

  const handleBlur = () => {
    const maskedValue = applyMask(documentInputValue);
    handlers.changeValues({ target: { name: 'documentNumber', value: maskedValue } });
  };

  const applyMask = value => {
    const valueWithoutMask = value.replace(/\D/g, '');

    if (valueWithoutMask.length === 11 && user.countryName === 'Brasil') {
      const formattedValue = valueWithoutMask.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4',
      );
      return formattedValue;
    }

    if (valueWithoutMask.length === 14 && user.countryName === 'Brasil') {
      const formattedValue = valueWithoutMask.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
      return formattedValue;
    }

    if (valueWithoutMask.length === 11 && user.countryName === 'Argentina') {
      const formattedValue = valueWithoutMask.replace(/(\d{2})(\d{8})(\d{1})/, '$1-$2-$3');
      return formattedValue;
    }

    return value;
  };

  const isDisabled = useMemo(() => {
    if (!config) return false;
    return config.disableInputs || disableForm || disableInputByRole;
  }, [config, disableForm, disableInputByRole]);

  const cleanedFantasyName = () => {
    const onlyNumbers = documentInputValue.replace(/\D/g, '');

    if (onlyNumbers.length <= 11) {
      handlers.changeValues({ target: { name: 'fantasyName', value: '' } });
    }
  };

  return (
    <>
      {!!config && (
        <GDMContainer
          component="form"
          onSubmit={handlers.submit}
          sx={containerStyle}
          {...{ noValidate: true, autoComplete: 'off' }}
        >
          <GDMGrid container spacing={2}>
            <GDMCustomRoleFields
              entityType={entityType}
              config={config}
              handlers={handlers}
              viewType={config.viewType}
            />
            <GDMGrid item xs={12} sm={12} md={6}>
              <GDMCustomInputLabel
                label={getLabelTranslation('formfields_full_name')}
                required={config.viewType !== 'view'}
                fontSize="16px"
                color="#222222"
              />
              <GDMTextField
                required
                focused={false}
                id="name"
                name="name"
                variant="outlined"
                placeholder={getLabelTranslation('formfields_full_name')}
                disabled={isDisabled}
                fullWidth
                value={config.formData?.name}
                onChange={handlers.changeValues}
                error={!!errors?.name}
                helperText={errors?.name}
              />
            </GDMGrid>
            <GDMGrid item xs={12} sm={12} md={6}>
              <GDMCustomInputLabel
                label={getLabelTranslation('formfields_email')}
                required={config.viewType !== 'view'}
                fontSize="16px"
                color="#222222"
              />
              <GDMTextField
                focused={false}
                required
                id="contactEmail"
                name="contactEmail"
                placeholder={getLabelTranslation('formfields_email')}
                variant="outlined"
                disabled={
                  config.viewType === 'view' ||
                  (config.viewType === 'edit' && entityType === 'Administrador') ||
                  (config.viewType === 'edit' && entityType === 'Vendedor GDM') ||
                  isDisabled
                }
                fullWidth
                value={config.formData?.contactEmail}
                onChange={handlers.changeValues}
                error={!!errors?.contactEmail}
                helperText={errors?.contactEmail}
              />
            </GDMGrid>
            <GDMGrid item xs={12} sm={12} md={3}>
              <GDMCustomInputLabel
                label={getLabelTranslation('formfields_country')}
                required={config.viewType !== 'view'}
                fontSize="16px"
                color="#222222"
              />
              <GDMFormControl fullWidth>
                <GDMSelect
                  sx={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#A1A9B8',
                    '.Mui-disabled': {
                      background: '#F2F4F9',
                    },
                  }}
                  IconComponent={IoIosArrowDown}
                  placeholder={getLabelTranslation('formfields_country')}
                  disabled
                  value={countryOfUser}
                  onChange={handlers.changeCountry}
                  variant="outlined"
                  fullWidth
                >
                  <GDMMenuItem value={countryOfUser}>{countryOfUser}</GDMMenuItem>
                </GDMSelect>
              </GDMFormControl>
            </GDMGrid>
            <GDMGrid item xs={12} sm={12} md={3}>
              <GDMCustomInputLabel
                label={getLabelTranslation('formfields_phone')}
                required={config.viewType !== 'view'}
                fontSize="16px"
                color="#222222"
              />
              <GDMInputMask
                mask={config.fieldsConfigPerCountry[config.country]?.phoneNumber?.mask}
                onChange={handlers.changeValues}
                value={config.formData?.phoneNumber}
                error={!!errors?.phoneNumber}
                helperText={errors?.phoneNumber}
                disabled={isDisabled}
              >
                <GDMTextField
                  focused={false}
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={getLabelTranslation('formfields_phone')}
                  variant="outlined"
                  fullWidth
                />
              </GDMInputMask>
            </GDMGrid>
            <GDMGrid item xs={12} sm={12} md={3}>
              <GDMCustomInputLabel
                label={getDocumentLabel({ entityType, countryOfUser })}
                required={config.viewType !== 'view'}
                fontSize="16px"
                color="#222222"
              />
              <GDMInputMask
                mask={getDocumentMask({ entityType, countryOfUser })}
                maskChar={null}
                onChange={handlers.changeValues}
                onBlur={() => {
                  if (countryOfUser === 'Brasil') {
                    handleBlur();
                    cleanedFantasyName();
                  }
                }}
                value={documentInputValue}
                disabled={isDisabled}
                // error={isNotDocumentError && !!errors?.documentNumber}
                error={!!errors?.documentNumber}
                helperText={errors?.documentNumber}
                // helperText={isNotDocumentError && errors?.documentNumber : undefined}
              >
                <GDMTextField
                  id="documentNumber"
                  name="documentNumber"
                  focused={false}
                  placeholder={getDocumentLabel({ entityType, countryOfUser })}
                  variant="outlined"
                  fullWidth
                />
              </GDMInputMask>
            </GDMGrid>
            {entityType !== 'Administrador' && (
              <>
                <GDMGrid item xs={12} sm={12} md={3}>
                  <GDMCustomInputLabel
                    required={config.viewType !== 'view'}
                    label={getLabelTranslation('formfields_business_name')}
                    fontSize="16px"
                    color="#222222"
                  />
                  <GDMTextField
                    required
                    focused={false}
                    id="fantasyName"
                    name="fantasyName"
                    placeholder={getLabelTranslation('formfields_business_name')}
                    variant="outlined"
                    disabled={config.viewType === 'view' || enableBusinessNameInput()}
                    fullWidth
                    value={config.formData?.fantasyName}
                    onChange={handlers.changeValues}
                    error={!!errors?.fantasyName}
                    helperText={errors?.fantasyName}
                  />
                </GDMGrid>
                <GDMGrid item xs={12} sm={12} md={3}>
                  <GDMCustomInputLabel
                    required={config.viewType !== 'view'}
                    label={getLabelTranslation('formfields_responsible')}
                    fontSize="16px"
                    color="#222222"
                  />
                  <GDMTextField
                    required
                    focused={false}
                    id="personInChargeName"
                    name="personInChargeName"
                    placeholder={getLabelTranslation('formfields_responsible')}
                    variant="outlined"
                    disabled={isDisabled}
                    fullWidth
                    value={config.formData?.personInChargeName}
                    onChange={handlers.changeValues}
                    error={!!errors?.personInChargeName}
                    helperText={errors?.personInChargeName}
                  />
                </GDMGrid>
                {entityType !== 'Vendedor GDM' && entityType !== 'Administrador' && (
                  <>
                    <GDMGrid item xs={12} sm={12} md={6}>
                      <GDMCustomInputLabel
                        label={getLabelTranslation('formfields_adress')}
                        required={config.viewType !== 'view'}
                        fontSize="16px"
                        color="#222222"
                      />
                      <GDMTextField
                        focused={false}
                        sx={{
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '19px',
                          color: '#A1A9B8',
                          '& :disabled': {
                            background: '#F2F4F9',
                          },
                        }}
                        required
                        id="address"
                        placeholder={getLabelTranslation('formfields_adress')}
                        name="address"
                        variant="outlined"
                        disabled={isDisabled}
                        fullWidth
                        value={config.formData?.address}
                        onChange={handlers.changeValues}
                        error={!!errors?.address}
                        helperText={errors?.address}
                      />
                    </GDMGrid>

                    {isDesktop && (
                      <GDMGrid item sx={{ position: 'relative' }} xs={12} sm={12} md={3}>
                        <GDMContainer
                          sx={{
                            position: isDesktop ? 'absolute' : 'relative',
                            height: '174%',
                            width: '90%',
                            '.MuiBox-root': {
                              border: errors?.logoFile
                                ? '1px dashed #FB2047'
                                : '1px dashed #A1A9B8',
                            },
                          }}
                        >
                          <GDMCustomInputLabel
                            label={getLabelTranslation('formfields_company_logo')}
                            required={config.viewType !== 'view'}
                            fontSize="16px"
                            color="#222222"
                          />
                          <GDMFileUpload
                            label={getLabelTranslation('formfields_upload_logo')}
                            required
                            value={config.formData?.logoFile}
                            disabled={isDisabled}
                            defaultValue={config.formData?.logoFile}
                            onChange={handlers.file}
                          />
                          {!!errors?.logoFile && (
                            <GDMFormHelperText sx={{ color: '#FB2047' }}>
                              {errors?.logoFile}
                            </GDMFormHelperText>
                          )}
                        </GDMContainer>
                      </GDMGrid>
                    )}
                    <GDMGrid item xs={12} sm={12} md={3}>
                      <GDMCustomInputLabel
                        label={getLabelTranslation('formfields_state')}
                        required={config.viewType !== 'view'}
                        fontSize="16px"
                        color="#222222"
                      />
                      <GDMFormControl fullWidth>
                        <GDMInputLabel
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {getLabelTranslation('formfields_state')}
                        </GDMInputLabel>
                        <GDMSelect
                          sx={{
                            fontSize: '14px',
                            marginTop: '5px',
                            height: '48px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '19px',
                            color: '#A1A9B8',
                            '.Mui-disabled': {
                              background: '#F2F4F9',
                            },
                            '.MuiOutlinedInput-notchedOutline	': {
                              legend: {
                                display: 'none',
                              },
                            },
                            fieldset: {
                              border: errors.ufId ? '1px solid #FB2047' : '1px solid #A1A9B8',
                            },
                          }}
                          value={config.formData?.ufId || ''}
                          IconComponent={IoIosArrowDown}
                          disabled={isDisabled || !config.formData?.countryId}
                          onChange={handlers.changeStates}
                          variant="outlined"
                          fullWidth
                        >
                          <GDMMenuItem value="" style={{ display: 'none' }} selected disabled>
                            {getLabelTranslation('formfields_state')}
                          </GDMMenuItem>
                          {config.states?.map(state => (
                            <GDMMenuItem key={state.id} value={state.id}>
                              {state.name}
                            </GDMMenuItem>
                          ))}
                        </GDMSelect>
                        {!!errors?.ufId && (
                          <GDMFormHelperText sx={{ color: '#FB2047' }}>
                            {errors?.ufId}
                          </GDMFormHelperText>
                        )}
                      </GDMFormControl>
                    </GDMGrid>
                    <GDMGrid item xs={12} sm={12} md={3}>
                      <GDMCustomInputLabel
                        label={getLabelTranslation('formfields_label_city')}
                        required={config.viewType !== 'view'}
                        fontSize="16px"
                        color="#222222"
                      />
                      <GDMAutocomplete
                        options={config.cities}
                        sx={{
                          input: {
                            border: 'none',
                          },
                          '.Mui-disabled': {
                            background: '#F2F4F9',
                          },
                        }}
                        fullWidth
                        getOptionKey={option => option.id}
                        getOptionLabel={option => option.name}
                        disabled={
                          !config.formData?.ufId
                            ? true
                            : config.viewType === 'create' || config.viewType === 'edit'
                            ? false
                            : isDisabled
                        }
                        value={config.cities.find(city => city.id === config.formData?.cityId)}
                        inputValue={config.cityInputValue}
                        onChange={handlers.changeCities}
                        renderInput={params => (
                          <GDMTextField
                            focused={false}
                            placeholder={getLabelTranslation('formfields_label_city')}
                            sx={{
                              input: {
                                border: 'none',
                              },
                              fieldset: {
                                borderColor: errors.cityId && '#FB2047',
                              },
                            }}
                            {...params}
                            value={config.cityInputValue}
                            onChange={changeInputValue}
                          />
                        )}
                      />
                      {!!errors.cityId && (
                        <GDMFormHelperText sx={{ color: '#FB2047' }}>
                          {errors.cityId}
                        </GDMFormHelperText>
                      )}
                    </GDMGrid>
                  </>
                )}
                <GDMGrid item xs={12} sm={12} md={3}>
                  <GDMCustomInputLabel
                    label={`${getLabelTranslation('formfields_brand')} `}
                    required={config.viewType !== 'view'}
                    fontSize="16px"
                    color="#222222"
                  />
                  <GDMBrandSelector
                    isDisabled={
                      config.viewType === 'view' || config.viewType === 'edit' ? true : isDisabled
                    }
                    brands={config.brands}
                    entityType={entityType}
                    onChange={handlers.changeBrand}
                    defaultValues={config.formData?.brandIds}
                    errors={errors}
                  />
                </GDMGrid>
                {entityType === 'Vendedor GDM' && (
                  <GDMGrid item xs={12} sm={12} md={3}>
                    <GDMCustomInputLabel
                      label={getLabelTranslation('formfields_region')}
                      required={config.viewType !== 'view'}
                      fontSize="16px"
                      color="#222222"
                    />
                    <GDMFormControl fullWidth>
                      <GDMInputLabel
                        sx={{
                          fontSize: '14px',
                        }}
                      >
                        {getLabelTranslation('formfields_region')}
                      </GDMInputLabel>
                      <GDMSelect
                        sx={{
                          fontSize: '14px',
                          marginTop: '5px',
                          height: '48px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '19px',
                          color: '#A1A9B8',
                          '.Mui-disabled': {
                            background: '#F2F4F9',
                          },
                          '.MuiOutlinedInput-notchedOutline	': {
                            legend: {
                              display: 'none',
                            },
                          },
                        }}
                        IconComponent={IoIosArrowDown}
                        value={config.formData.regionId}
                        disabled={
                          config.viewType === 'create' || config.viewType === 'edit'
                            ? false
                            : isDisabled || !config.formData?.ufId
                        }
                        onChange={handlers.changeRegion}
                        variant="outlined"
                        fullWidth
                        error={!!errors?.regionId}
                      >
                        {config.regions?.map(region => (
                          <GDMMenuItem key={region.id} value={region.id}>
                            {region.name}
                          </GDMMenuItem>
                        ))}
                      </GDMSelect>
                      {!!errors?.regionId && (
                        <GDMFormHelperText sx={{ color: '#FB2047' }}>
                          {errors?.regionId}
                        </GDMFormHelperText>
                      )}
                    </GDMFormControl>
                  </GDMGrid>
                )}
              </>
            )}
            {!isDesktop && entityType !== 'Vendedor GDM' && entityType !== 'Administrador' && (
              <GDMGrid
                item
                sx={{ position: 'relative', marginBottom: '54px' }}
                xs={12}
                sm={12}
                md={3}
              >
                <GDMContainer
                  sx={{
                    position: isDesktop ? 'absolute' : 'relative',
                    height: '174%',
                    width: '100%',
                  }}
                >
                  <GDMFileUpload
                    label={getLabelTranslation('formfields_upload_logo')}
                    required
                    defaultValue={config.formData?.logoFile}
                    value={config.formData?.logoFile}
                    disabled={isDisabled}
                    onChange={handlers.file}
                  />
                  {!!errors?.logoFile && (
                    <GDMFormHelperText sx={{ color: '#FB2047' }}>
                      {errors?.logoFile}
                    </GDMFormHelperText>
                  )}
                </GDMContainer>
              </GDMGrid>
            )}
            {!isDesktop && config.viewType !== 'view' && (
              <GDMContainer
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '12px',
                  marginTop: '32px',
                  marginLeft: '14px',
                }}
              >
                <GDMContainer>
                  <GDMLink href={config.pageList}>
                    <GDMButton
                      onClick={cancelForm}
                      sx={{
                        height: '51px',
                        width: '155px',
                        boxShadow: 'none',
                        color: '#222222',
                      }}
                      color="secondary"
                      variant="contained"
                    >
                      {getLabelTranslation('formfields_cancel')}
                    </GDMButton>
                  </GDMLink>
                </GDMContainer>
                <GDMContainer>
                  <GDMButton
                    variant="contained"
                    onClick={handlers.submit}
                    sx={{
                      height: '51px',
                      width: '155px',
                      boxShadow: 'none',
                    }}
                    disabled={config.loading}
                  >
                    {config.loading ? (
                      <GDMLoading type="circular" {...{ size: 24 }} />
                    ) : (
                      getLabelTranslation('formfields_save')
                    )}
                  </GDMButton>
                </GDMContainer>
              </GDMContainer>
            )}
          </GDMGrid>
        </GDMContainer>
      )}
    </>
  );
};

export default GDMFormFields;
