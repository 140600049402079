/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react';

const DisabledContext = createContext(undefined);

export function DisabledProvider({ children }) {
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <DisabledContext.Provider value={{ isDisabled, setIsDisabled }}>
      {children}
    </DisabledContext.Provider>
  );
}

export const useDisabled = () => {
  const context = useContext(DisabledContext);
  if (!context) {
    throw new Error('useDisabled must be used within a DisabledProvider');
  }
  return context;
};
