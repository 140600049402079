/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Theme } from '@mui/material';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { GDMTypography } from 'components';
import { GDMButton } from 'components/gdm-button';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { IRecommendation } from 'pages/dashboard/DashboardPage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { stringTruncate } from 'utils/formaters';
import { DashboardMapCustomMarker } from '../dashboard-map-custom-marker';
import { DashboardMapModalDataText } from '../dashboard-map-modal-data-text';
import {
  mapModalTitleStyles,
  recommendationLinkButtonStyles,
  userNameStyles,
} from './DashboardMapMarkersTree.styles';

export type DashboardMapMarkersTreeProps = {
  data: IRecommendation[];
  theme: Theme;
  handleNavigate: (route: string) => void;
};

const DashboardMapMarkersTree = ({ data, theme, handleNavigate }: DashboardMapMarkersTreeProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const { getLabelTranslation } = useLanguageSwitcher();
  const [selectedTreeKey, setSelectedTreeKey] = useState<string | null>(null);

  const selectedTree = useMemo(
    () => (data && selectedTreeKey ? data.find(t => t.id === selectedTreeKey)! : null),
    [data, selectedTreeKey],
  );

  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers(prev => {
      if ((marker && prev[key]) || (!marker && !prev[key])) return prev;

      if (marker) {
        return { ...prev, [key]: marker };
      }
      const { [key]: _, ...newMarkers } = prev;

      return newMarkers;
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedTreeKey(null);
  }, []);

  const handleMarkerClick = useCallback((item: IRecommendation) => {
    setSelectedTreeKey(item.id);
  }, []);

  return (
    <>
      {data?.map(item => (
        <DashboardMapCustomMarker
          key={item.id}
          data={item}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
          theme={theme}
        />
      ))}

      {selectedTreeKey && selectedTree && (
        <InfoWindow
          anchor={markers[selectedTreeKey]}
          className="gdm-markers-tree-content"
          headerContent={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <GDMTypography
                title={stringTruncate(selectedTree?.name, 65)}
                sx={mapModalTitleStyles(theme)}
              >
                {stringTruncate(selectedTree?.name, 65)}
              </GDMTypography>

              <GDMTypography title={selectedTree?.userName} sx={userNameStyles(theme)}>
                {selectedTree?.userName}
              </GDMTypography>
            </div>
          }
          onCloseClick={handleInfoWindowClose}
        >
          <div style={{ color: '#222222', fontSize: '14px' }}>
            <DashboardMapModalDataText
              label={getLabelTranslation(`dash_culture`)}
              value={selectedTree?.cultureName}
            />
            <DashboardMapModalDataText
              label={getLabelTranslation(`dash_variety`)}
              value={selectedTree?.genotype}
            />
            <DashboardMapModalDataText
              label={getLabelTranslation(`dash_technology`)}
              value={selectedTree?.technology}
            />

            <GDMButton
              sx={recommendationLinkButtonStyles(theme)}
              type="button"
              onClick={() => {
                handleNavigate(`/recommendation/view/${selectedTree?.id}`);
              }}
            >
              {getLabelTranslation(`dash_marker_access_recommentaion`)}
            </GDMButton>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default DashboardMapMarkersTree;
