import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const mapModalTitleStyles: SxPropsThemeFunction = theme => ({
  fontSize: '14px!important',
  lineHeight: '20px!important',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.primary.main,
});

export const userNameStyles: SxPropsThemeFunction = theme => ({
  fontSize: '12px!important',
  lineHeight: '20px!important',
  display: 'block',
  paddingBottom: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.primary.main,
});

export const recommendationLinkButtonStyles: SxPropsThemeFunction = theme => ({
  fontWeight: 600,
  fontSize: '14px!important',
  lineHeight: '20px!important',
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'flex-end',
  width: 'fit-content',
  height: 'fit-content',
  padding: '0px',
  margin: '14px 0px auto auto',
  textDecoration: 'underline',
  ':hover, :active, :focus': {
    textDecoration: 'underline',
    background: 'transparent',
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
});
