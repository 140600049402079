import { SxProps } from '@mui/material';

export const filterFormFieldContainerStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flex: 1,
};

export const filterFormFieldStyles: SxProps = {
  width: '100%',
};

export const labelFilterStyles: SxProps = {
  fontSize: '16px!important',
  lineHeight: '22.4px!important',
  fontWeight: '400',
  color: '#777D88',
};

export const filterFormFieldContentStyles: SxProps = {
  display: 'flex',
  padding: '0px',
  gap: '8px',
  width: '100%',
  flex: 1,
  '& .MuiBox-root': {
    width: '100%',
  },
};

export const datePickerStyles: SxProps = {
  width: '100%',
  display: 'flex',
  flex: 1,
  '& .MuiInputBase-root': {
    height: '48px',
    padding: '4px 8px',
    background: '#FAFAFA',
    width: '100%',
    maxWidth: '100%',
    input: {
      paddingLeft: '8px',
      color: '#222222',
      display: 'flex',
      alignItems: 'center',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '14px!important',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiBox-root, & .MuiFormControl-root': {
    width: '100%',
  },
};

export const selectFilterStyles: SxProps = {
  fontSize: '14px!important',
  height: '48px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '19px',
  color: '#222222',
  width: '100%',
  overflow: 'hidden',
  maxWidth: '100%',
  background: '#FAFAFA',
  '& .MuiInputBase-input': {
    fontSize: '14px!important',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiSelect-select': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
};

export const dateFilterContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

export const labelDateFilterStyles: SxProps = {
  fontSize: '12px!important',
  lineHeight: '16.8px!important',
  fontWeight: '400',
  color: '#222222',
  paddingBottom: {
    xs: '8px',
    sm: '8px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
};
