import Api from 'core/api/clients/api/Api';

const getBrands = async (): Promise<any> => {
  return Api.get({
    url: `/Brands`,
  })
    .then(response => response.data?.filter(x => !x.isAdministratorBrand))
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const BrandsServices = {
  getBrands,
};
