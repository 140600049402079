import { SxProps } from '@mui/material';

import colors from '../../design-system/style-guide/colors.json';

export const paperStyle: SxProps = {
  bgcolor: 'primary.main',
  padding: '64px 26px',
  color: colors.white,
  width: '320px',
  position: 'relative',
  height: '100%',
  cursor: 'pointer',
  overflowX: 'hidden',
};

export const drawerStyle: SxProps = {
  height: '100%',
  gridColumn: 1,
  gridRow: 1,
};
