import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Pagination,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GDMButton, GDMContainer, GDMMenuItem, GDMSelect, GDMTypography } from 'components';
import { stringTruncate } from 'utils/formaters';
import { RecommendationService } from 'core/api/services/recommendation';
import { DataTableMode } from 'core/typing/types/common/dataTableMode.type';
import { useEffect, useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { FaArrowRight } from 'react-icons/fa6';
import { IoCloseSharp } from 'react-icons/io5';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import colors from '../../../core/design-system/style-guide/colors.json';
import textStyles from '../../../core/design-system/style-guide/typography.json';
import useLanguageSwitcher from '../../../core/hooks/useLanguageSwitcher';

const GDMRecommendationTable = ({
  header,
  rows,
  currentPage,
  setFilter = undefined,
  pages,
  orderDesc,
  setListResponse = undefined,
  dataTableMode,
}: {
  header: any;
  rows: any;
  currentPage: number;
  pages: number;
  setFilter: any;
  orderDesc: boolean;
  setListResponse: any;
  dataTableMode: DataTableMode;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [nameRecommendation, setNameRecommendation] = useState('');
  const themeChosed = localStorage.getItem('theme_recomendador');
  const [openCollapse, setOpenCollapse] = useState(null);
  const [background, setBackground] = useState({ button: '', select: '' });
  const [status, setStatus] = useState([]);
  const [emptyTableText, setEmptyTableText] = useState('');

  const [stateFeedback, setStateFeedBack] = useState<{
    open: boolean;
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  }>({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = stateFeedback;

  const handleClose = () => {
    setStateFeedBack(prev => ({ ...prev, open: false }));
  };

  const handleOpenCollapse = id => {
    setOpenCollapse(openCollapse === id ? null : id);
  };

  const changeOrderColumn = column => {
    setFilter(prev => ({ ...prev, orderColumn: column.name, orderDesc: !orderDesc, page: 1 }));
  };

  const updateRecommendationStatus = ({
    recommendationId,
    statusId,
    nameRecommendation,
  }: {
    recommendationId: string;
    statusId: string;
    nameRecommendation: string;
  }) => {
    RecommendationService.updateRecommendationStatus({ recommendationId, statusId })
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        setListResponse(prev => ({
          ...prev,
          rows: prev.rows.map(item =>
            item.id === recommendationId ? { ...item, recommendationStatusId: statusId } : item,
          ),
        }));
        setStateFeedBack(prev => ({ ...prev, open: true }));
        setNameRecommendation(nameRecommendation);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (themeChosed === 'DONMARIOAR') {
      setBackground({ button: '#1FCA6E', select: '#C2EAD4' });
    } else if (themeChosed === 'DONMARIOBR') {
      setBackground({ button: '#41877A', select: '#D0EAE5' });
    } else {
      setBackground({ button: '#0A3C56', select: '#CFEFFF' });
    }
  }, [themeChosed]);

  useEffect(() => {
    switch (dataTableMode) {
      case DataTableMode.ERROR:
        setEmptyTableText('error_on_load_table_data_text');
        break;
      case DataTableMode.SEARCH:
        setEmptyTableText('empty_table_on_filter_text');
        break;
      case DataTableMode.DEFAULT:
        setEmptyTableText('empty_table_text');
        break;
      default:
        setEmptyTableText('empty_table_text');
        break;
    }
  }, [dataTableMode]);

  useEffect(() => {
    RecommendationService.getRecommendationStatus()
      .then(response => setStatus(response))
      .catch(err => console.error(err));
  }, []);

  return (
    <GDMContainer>
      <TableContainer>
        <Table>
          {isDesktop && (
            <TableHead>
              <TableRow sx={{ background: '#F2F4F9' }}>
                {header.map(column => (
                  <TableCell
                    sx={{
                      border: 'none',
                    }}
                  >
                    <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                      <GDMTypography
                        variant="subtitle2"
                        sx={{
                          color: '#777D88',
                        }}
                      >
                        {column.title}
                      </GDMTypography>
                      <IconButton
                        onClick={() => {
                          changeOrderColumn(column);
                        }}
                      >
                        <CgArrowsExchangeAltV />
                      </IconButton>
                    </GDMContainer>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      Status
                    </GDMTypography>
                  </GDMContainer>
                </TableCell>
                <TableCell
                  sx={{
                    border: 'none',
                  }}
                />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isDesktop
              ? rows.map(row => (
                  <TableRow>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 700 }}>
                        {stringTruncate(row.name, 25)}
                      </GDMTypography>
                      <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                        {row.cultureName} | {row.recommendationVariety[0].genotype}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 700 }}>
                        {row.farmerName}
                      </GDMTypography>
                      <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                        {row.farmName} | {row.lotName}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 700 }}>
                        {row.userName}
                      </GDMTypography>
                      <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                        {getLabelTranslation(row.userRole)}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                        {row.rattingValue}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMSelect
                        value={row.recommendationStatusId}
                        onChange={({ target }) => {
                          updateRecommendationStatus({
                            recommendationId: row.id,
                            statusId: target.value as string,
                            nameRecommendation: row.name,
                          });
                        }}
                        sx={{
                          width: '200px',
                          borderRadius: '6px',
                          border: '1px solid #A1A9B8',
                          background: '#fafafa',
                        }}
                      >
                        {status.map(stt => (
                          <GDMMenuItem
                            value={stt.id}
                            sx={{ '&::hover': { background: background.select } }}
                          >
                            {getLabelTranslation(stt.status)}
                          </GDMMenuItem>
                        ))}
                      </GDMSelect>
                    </TableCell>
                    <TableCell>
                      <GDMButton
                        variant="contained"
                        onClick={() => {
                          navigate(`/recommendation/view/${row.id}`);
                        }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          background: background.button,
                          cursor: 'pointer',
                        }}
                      >
                        {getLabelTranslation('access')}

                        <FaArrowRight />
                      </GDMButton>
                    </TableCell>
                  </TableRow>
                ))
              : rows.map((row, index) => (
                  <GDMContainer>
                    <ListItemButton
                      onClick={() => handleOpenCollapse(row.id)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 0 10px 0',
                        ...(index !== rows.length - 1 &&
                          openCollapse !== row.id && {
                            borderStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderBottomColor: '#E7E9F1',
                            marginBottom: '10px',
                            paddingBottom: '10',
                          }),
                      }}
                    >
                      <GDMContainer
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                            padding: '10px 0',
                          }}
                        >
                          <GDMTypography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: { xs: '260px', sm: '500px' },
                            }}
                          >
                            {row.name}
                          </GDMTypography>
                          <GDMTypography variant="body1">
                            {row.cultureName} | {row.recommendationVariety[0].genotype}
                          </GDMTypography>
                        </ListItem>
                        <IconButton
                          sx={{
                            color: '#222',
                            width: '32px',
                            height: '32px',
                            backgroundColor: background.select,
                            borderRadius: '50%',
                          }}
                        >
                          {openCollapse === row.id ? <MdExpandLess /> : <MdExpandMore />}
                        </IconButton>
                      </GDMContainer>
                    </ListItemButton>
                    <Collapse in={openCollapse === row.id} timeout="auto" unmountOnExit>
                      <List
                        component="div"
                        disablePadding
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">{row.farmerName}</GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.farmName} | {row.lotName}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">{row.userName}</GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {getLabelTranslation(row.userRole)}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">Avaliação</GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.rattingValue}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            alignItems: 'start',
                          }}
                        >
                          <GDMTypography variant="body2">Status</GDMTypography>
                          <GDMSelect
                            value={row.recommendationStatusId}
                            onChange={({ target }) => {
                              updateRecommendationStatus({
                                recommendationId: row.id,
                                statusId: target.value as string,
                                nameRecommendation: row.name,
                              });
                            }}
                            sx={{
                              width: isDesktop ? '200px' : '100%',
                              borderRadius: '6px',
                              border: '1px solid #A1A9B8',
                              background: '#fafafa',
                            }}
                          >
                            {status.map(stt => (
                              <GDMMenuItem
                                value={stt.id}
                                sx={{ '&::hover': { background: background.select } }}
                              >
                                {getLabelTranslation(stt.status)}
                              </GDMMenuItem>
                            ))}
                          </GDMSelect>
                        </ListItem>
                        <ListItem sx={{ padding: '0 0 8px 0' }}>
                          <GDMButton
                            variant="contained"
                            onClick={() => {
                              navigate(`/recommendation/view/${row.id}`);
                            }}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              background: background.button,
                              cursor: 'pointer',
                              width: '100%',
                            }}
                          >
                            {getLabelTranslation('access')}
                            <FaArrowRight />
                          </GDMButton>
                        </ListItem>
                        <Divider sx={{ margin: '10px 0 8px 0' }} />
                      </List>
                    </Collapse>
                  </GDMContainer>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!rows.length && (
        <GDMTypography
          sx={{
            textAlign: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyTableText)}
        </GDMTypography>
      )}
      {!!rows.length && (
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_, page) => setFilter(prev => ({ ...prev, page }))}
          variant="outlined"
          shape="rounded"
          boundaryCount={isDesktop ? 1 : 0}
          sx={{
            mt: 2,
            justifyContent: 'center',
            display: 'flex',
            '& ul > li > button': {
              backgroundColor: colors.light2,
              width: {
                xs: '35px',
                sm: '40px',
                md: '45px',
                lg: '51px',
                xl: '51px',
              },
              height: {
                xs: '35px',
                sm: '40px',
                md: '45px',
                lg: '51px',
                xl: '51px',
              },
              border: 'none',
              ...textStyles.table.pagination,
            },
            '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
              backgroundColor: colors.dark3,
            },
          }}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message={
          <GDMTypography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
            {getLabelTranslation('status_recommendation_text')}{' '}
            <strong>{nameRecommendation}</strong> {getLabelTranslation('change_success')}
          </GDMTypography>
        }
        key={vertical + horizontal}
        ContentProps={{
          sx: {
            background: '#FAFAFA',
            borderLeft: '17px solid #06c270',
            color: '#222',
            fontSize: '14px',
          },
        }}
        action={
          <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
            <IoCloseSharp color="#222" />
          </IconButton>
        }
      />
    </GDMContainer>
  );
};

export default GDMRecommendationTable;
