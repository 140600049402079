import {
  DynamicFormData,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { useEffect, useState } from 'react';
import { errorHandler } from 'utils/form/errorHandler';
import { useNavigate, useParams } from 'react-router-dom';
import {
  dynamicFormValidateForm,
  getBrands,
  getCities,
  getGDMSellers,
  getMultipliers,
  getStates,
} from 'utils';
import { DistributorService } from 'core/api/services/distributor';
import { DistributorRegisterPageComponent } from './DistributorRegisterPage.type';
import distributorUpdateOnSubmit from '../utils/update-distributor/updateMultiplier';

const DEFAULT_DISTRIBUTOR: Partial<DynamicFormData> = {
  address: '',
  brandIds: undefined,
  cityId: '',
  contactEmail: '',
};

const DistributorEditPage: DistributorRegisterPageComponent = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const {
    user: { countryName, countryId },
  } = useStore(UserProfileStore);

  const { distributorId } = useParams();

  const [distributor, setDistributor] = useState<Partial<DynamicFormData>>(DEFAULT_DISTRIBUTOR);
  const [errors, setErrors] = useState({});
  const [brands, setBrands] = useState([]);
  const [sellersGDM, setSellersGDM] = useState([]);
  const [multiplers, setMultipliers] = useState([]);
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entityType, setEntityType] = useState(getLabelTranslation('brandeselector_multiplier'));
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    const loadData: Array<Promise<any>> = [
      loadBrands(),
      loadSellersGDM(),
      loadStates(),
      loadMultipliers(),
      loadDistributor(),
    ];
    setDistributor(prev => ({ ...prev, countryId }));
    Promise.all(loadData).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (distributor.ufId) {
      loadCities();
    }
  }, [distributor.ufId]);

  useEffect(() => {
    setEntityType(getLabelTranslation('brandeselector_distributor'));
  }, [currentLanguage]);

  useEffect(() => {
    if (distributor.documentNumber) {
      setDocumentTypeId();
    }
  }, [distributor.documentNumber]);

  useEffect(() => {
    console.error(errors);
  }, [errors]);

  const loadBrands = () => {
    return getBrands({
      countryId,
      onError: () => console.error('Error on load brands!'),
      onInit: () => setIsLoading(true),
      onSuccess: brands => setBrands(brands),
    });
  };
  const loadStates = () => {
    return getStates({
      countryId,
      onSuccess: states => setStates(states),
      onInit: () => setIsLoading(true),
      onError: () => console.error('Error on load states!'),
    });
  };
  const loadCities = () => {
    return getCities({
      stateId: distributor.ufId,
      onError: () => console.error('Error on load cities!'),
      onSuccess: cities => setCities(cities),
      onInit: () => setIsLoading(true),
    });
  };

  const loadSellersGDM = () => {
    return getGDMSellers({
      payload: {
        currentPag: 1,
        itemsPerPage: 999999999,
      },
      onInit: () => setIsLoading(true),
      onSuccess: sellersGDM => setSellersGDM(sellersGDM.items),
    });
  };

  const loadMultipliers = () => {
    return getMultipliers({
      payload: {
        currentPage: 1,
        itemsPerPage: 999999999,
      },
      onInit: () => setIsLoading(true),
      onSuccess: multiplers => setMultipliers(multiplers.items as any),
    });
  };

  const handleValidate = distributorFormData =>
    dynamicFormValidateForm({
      formData: distributorFormData,
      countryName,
      entityType,
      getLabelTranslation,
    });

  const onValidate = errors => {
    if (Object.keys(errors)?.length) {
      setIsSubmiting(false);
    }
    setErrors(errors);
  };

  const handleChange = (e: any) => {
    const {
      target: { value, name },
    } = e;

    setDistributor(distributorData => ({ ...distributorData, [name]: value }));
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      onClose: closeModal,
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const onError = errors => {
    errorHandler(errors, setErrors);

    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_update_error'),
      onClose: closeModal,
    });
  };

  const onInit = () => {
    setIsSubmiting(true);
  };

  const onSuccess = () => {
    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_update_success'),
      onClose: () => navigate('/distributor-list'),
      primaryAction: {
        label: 'Ok',
        action: () => {
          closeModal();
          navigate('/distributor-list');
        },
      },
    });
  };

  const onFinally = () => {
    setIsSubmiting(false);
  };

  const handleSubmit = async () => {
    await distributorUpdateOnSubmit({
      distributor,
      onError,
      onInit,
      onFinally,
      onSuccess,
      handleValidate,
      onValidate,
    });
  };

  const setDocumentTypeId = () => {
    if (countryName === 'Argentina') {
      setDistributor(prev => ({
        ...prev,
        documentTypeId: 'b85d6c71-e469-4268-a813-1bac98f596ae',
      }));
    }

    let onlyNumbers: number;

    if (distributor.documentNumber) {
      onlyNumbers = distributor.documentNumber.replace(/\D/g, '').length;
    }

    if (countryName === 'Brasil' && onlyNumbers === 11) {
      setDistributor(prev => ({
        ...prev,
        documentTypeId: 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d',
        fantasyName: '',
      }));
    } else if (countryName === 'Brasil' && onlyNumbers === 14) {
      setDistributor(prev => ({
        ...prev,
        documentTypeId: 'cc49f596-0841-4708-9ef8-6e372ea70ddf',
      }));
    }
  };

  const loadDistributor = async () => {
    try {
      setIsLoading(true);
      const response = await DistributorService.getDistributorById(distributorId);
      setDistributor({
        id: distributorId,
        name: response.name,
        fantasyName: response.fantasyName,
        contactEmail: response.contactEmail,
        phoneNumber: response.phoneNumber,
        personInChargeName: response.personInChargeName,
        documentNumber: response.documentNumber,
        cityId: response.cityId,
        countryId: response.countryId,
        address: response.address,
        brandIds: response.brands.map(item => item.id),
        sellerId: response.sellerGdmUserId,
        ufId: response.stateId,
        cityName: response.cityName,
        stateName: response.stateName,
        sellerGdmName: response.sellerGdmName,
        logoFile: response.logoCdnUrl,
        multiplierId: response.multiplierId,
        multiplierName: response.multiplierName,
        documentTypeId: response.documentTypeId,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (brands && sellersGDM.length > 0 && multiplers.length > 0) {
      if (distributor.sellerId && distributor.multiplierId) {
        const sellerBrands = sellersGDM
          .find(seller => seller.id === distributor.sellerId)
          .brands.map(brand => brand.id);
        const multiplierBrands = multiplers
          .find(mult => mult.id === distributor.multiplierId)
          .brands.map(brand => brand.id);
        console.log('seller:', sellerBrands);
        console.log('multiplier', multiplierBrands);

        setDistributor(prev => ({
          ...prev,
          brandIds: sellerBrands.concat(
            multiplierBrands.filter(brand => !sellerBrands.includes(brand)),
          ),
        }));
      }
      if (distributor.sellerId) {
        const seller = sellersGDM.find(seller => seller.id === distributor.sellerId);
        setDistributor(prev => ({ ...prev, brandIds: seller.brands.map(brand => brand.id) }));
      }
      if (distributor.multiplierId && multiplers.length > 0) {
        const multipler = multiplers.find(multipler => multipler.id === distributor.multiplierId);
        setDistributor(prev => ({ ...prev, brandIds: multipler.brands.map(brand => brand.id) }));
      }
    }
  }, [distributor.sellerId, distributor.multiplierId]);

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'see_registrations',
      )} > ${getLabelTranslation('distributor')} > ${getLabelTranslation('edit')}`}
      title={`${getLabelTranslation('reusableForm_EditRegistrationOf_')} ${getLabelTranslation(
        'distributor',
      )}`}
    >
      <GDMConditionalRender canRender={isLoading}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>
      {distributor !== DEFAULT_DISTRIBUTOR && (
        <GDMConditionalRender canRender={!isLoading}>
          <GDMDynamicFormFields
            {...{
              entityType,
              viewType: 'create',
              formData: distributor,
              isSubmiting,
              errors,
              formDataEvents: {
                onCancel: handleCancel,
                onChange: handleChange,
                onSubmit: handleSubmit,
              },
              formDataOptions: {
                cities,
                gdmSellers: sellersGDM,
                brands,
                roles: [],
                states,
                multipliers: multiplers,
              },
            }}
          />
        </GDMConditionalRender>
      )}
    </GDMPageCard>
  );
};

export default DistributorEditPage;
