/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const createAccount = async (body: any): Promise<any> => {
  return Api.post({
    url: `/AccountUser`,
    body,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getAccounts = async ({
  roleId,
  currentPage,
  itemsPerPage,
  search = '',
  searchColumn = null,
  orderDesc = false,
  orderColumn = '',
}): Promise<any> => {
  let url = `/AccountUser?roleid=${roleId}&page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (searchColumn && searchColumn !== 'all') {
    url += `&SearchColumn=${searchColumn}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }
  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getAccountId = async (id: string): Promise<any> => {
  return Api.get({
    url: `/AccountUser/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const editAccountId = async (id: string, body): Promise<any> => {
  return Api.put({
    url: `/AccountUser/${id}`,
    body,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const deleteAccountId = async (id: string): Promise<any> => {
  return Api.delete({
    url: `/AccountUser/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const AccountsService = {
  createAccount,
  getAccounts,
  getAccountId,
  editAccountId,
  deleteAccountId,
};
