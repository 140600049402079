/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  useMediaQuery,
  Zoom,
} from '@mui/material';
import {
  GDMBox,
  GDMButton,
  GDMContainer,
  GDMLoading,
  GDMTextField,
  GDMTypography,
} from 'components';
import { RecommendationService } from 'core/api/services/recommendation';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { useEffect, useState } from 'react';
import { FaArrowLeftLong, FaCircleInfo } from 'react-icons/fa6';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useModal from 'hooks/useModal/useModal';
import { CatalogService } from 'core/api/services/catalog';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import firstPlace from '../../assets/images/recomm_1_white.svg';
import secondPlace from '../../assets/images/recomm_2_white.svg';
import thirdPlace from '../../assets/images/recomm_3.svg';
import ContentLines from './components/ContentLines/ContentLines';
import { transformData } from './utils/transformData';
import { boxStyle } from './VarietiesToCompare.styles';
import { ApiResponse, Genotypes, TransformedData } from './VarietiesToCompare.types';
import ListMobile from './components/ListMobile/ListMobile';

export default function VarietiesToCompare() {
  const [genotypes, setGenotypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingdButton, setLoadingdButton] = useState(false);
  const [sellingTip, setSellingTip] = useState('');
  const [openCollapse, setOpenCollapse] = useState(null);

  const { varietyId } = useParams();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:960px)');
  const { getLabelTranslation } = useLanguageSwitcher();
  const { openModal, closeModal } = useModal();
  const { theme } = useThemeSwitcher();
  const navigate = useNavigate();

  let viewType = '';
  if (location.pathname.includes('edit')) {
    viewType = 'edit';
  } else if (location.pathname.includes(varietyId)) {
    viewType = 'view';
  } else {
    viewType = 'create';
  }

  const handleOpenCollapse = id => {
    setOpenCollapse(openCollapse === id ? null : id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const varieties = [];

        if (varietyId) {
          varieties.push(varietyId);
        } else {
          JSON.parse(localStorage.getItem('varietiesIdsToCompare')).forEach(variety => {
            varieties.push(variety);
          });
        }

        const getVarieties: ApiResponse = await RecommendationService.getVarietiesToCompare(
          varieties,
        );

        if (varietyId) setSellingTip(getVarieties[0].sellingTip);

        const genotypesData: Genotypes[] = getVarieties.map(variety => ({
          id: variety.id,
          genotype: variety.genotype,
        }));

        setGenotypes(genotypesData);

        const transformResponse = transformData(getVarieties);
        localStorage.setItem('varieties', JSON.stringify(transformResponse));
      } catch (error) {
        console.error('Erro ao buscar variedades:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    document.getElementById('root').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [varietyId]);

  const varietiesToCompare: TransformedData = JSON.parse(localStorage.getItem('varieties'));

  const handleCancelUpdate = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('confirm_cancel'),
      message: getLabelTranslation('data_loss_warning'),
      primaryAction: {
        label: 'OK',
        action: () => {
          navigate('/catalog');
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('go_back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const handleChangeSellingTip = async () => {
    setLoadingdButton(true);

    await CatalogService.updateSellingTip({
      varietyId,
      sellingTip,
    })
      .then(() => {
        openModal({
          type: 'success',
          title: getLabelTranslation('update_success'),
          primaryAction: {
            label: 'OK',
            action: () => {
              navigate(`/catalog`);
              closeModal();
              setLoadingdButton(false);
            },
          },
        });
      })
      .catch(() => {
        openModal({
          type: 'error',
          title: getLabelTranslation('update_error'),
          primaryAction: {
            label: 'OK',
            action: () => {
              closeModal();
              setLoadingdButton(false);
            },
          },
        });
      });
  };

  const showingSellingTip = () => {
    if (viewType === 'edit') {
      return true;
    }
    if (viewType === 'view' && sellingTip) {
      return true;
    }
    return false;
  };

  if (loading) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  return (
    <Box sx={boxStyle}>
      {isDesktop && (
        <GDMTypography sx={{ color: '#A1A9B8', fontSize: '14px', mb: '20px' }}>
          {varietyId && getLabelTranslation('catalog_view')}
          {viewType === 'create' && getLabelTranslation('compare_varieties_page_breadcrumb')}
        </GDMTypography>
      )}

      <GDMTypography
        mb={2}
        fontWeight={700}
        color="#222222"
        sx={{ fontSize: { xs: '20px', md: '32px' }, lineHeight: { xs: '22px', md: '32px' } }}
      >
        {viewType === 'create' && getLabelTranslation('compare_varieties_page_title')}
        {viewType === 'view' && getLabelTranslation('view_technical_information')}
        {viewType === 'edit' && getLabelTranslation('edit_technical_information')}
      </GDMTypography>

      <Box sx={{ paddingLeft: !isDesktop ? '0' : varietyId ? '219px' : '66.5px' }}>
        {isDesktop && (
          <Box sx={{ display: 'flex', width: 'fit-content' }}>
            <Box
              sx={{
                width: varietyId ? '611px' : '340px',
                height: '43px',
                backgroundColor: theme.palette.primary.main,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
                ...(varietyId && {
                  borderStyle: 'solid',
                  borderWidth: '0 0 0 1px',
                  borderColor: '#D9DBE4',
                }),
                width: '290px',
                height: '43px',
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {!varietyId && <img src={firstPlace} alt="First Place" height="20px" width="20px" />}
              <GDMTypography
                sx={{
                  fontSize: '14px',
                  fontWeight: '700',
                  color: '#FAFAFA',
                }}
              >
                {genotypes.length > 0 ? genotypes[0].genotype : ''}
              </GDMTypography>
            </Box>
            {genotypes.length > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '290px',
                  height: '43px',
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <img src={secondPlace} alt="First Place" height="20px" width="20px" />
                <GDMTypography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#FAFAFA',
                  }}
                >
                  {genotypes[1].genotype}
                </GDMTypography>
              </Box>
            )}
            {genotypes.length > 2 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '290px',
                  height: '43px',
                  backgroundColor: theme.palette.primary[100],
                }}
              >
                <img src={thirdPlace} alt="First Place" height="20px" width="20px" />
                <GDMTypography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#222222',
                  }}
                >
                  {genotypes[2].genotype}
                </GDMTypography>
              </Box>
            )}
          </Box>
        )}

        {varietiesToCompare?.groups?.map((item, index) => {
          return (
            <>
              {isDesktop ? (
                <>
                  <GDMTypography
                    fontSize="14px"
                    margin="16px 0 16px 4px"
                    fontWeight={700}
                    color={theme.palette.primary.main}
                    sx={{ width: 'fit-content' }}
                  >
                    {item.group}
                  </GDMTypography>
                  <ContentLines lines={item.lines} genotypes={genotypes} varietyId={varietyId} />
                </>
              ) : (
                <ListMobile
                  lines={item.lines}
                  genotypes={genotypes}
                  index={index}
                  group={item.group}
                />
              )}
            </>
          );
        })}
      </Box>

      {viewType === 'edit' && (
        <Divider
          sx={{
            margin: isDesktop ? '32px 0 0 219px' : '32px 0 0 0',
            width: isDesktop ? '900px' : '100%',
          }}
        />
      )}

      {showingSellingTip() && (
        <GDMTypography
          margin={isDesktop ? '48px 0 0 219px' : '48px 0 0 0'}
          fontWeight={700}
          color={theme.palette.primary.main}
          sx={{
            width: 'fit-content',
            fontSize: { xs: '20px', sm: '20px' },
            lineHeight: { xs: '28px', sm: '28px' },
          }}
        >
          {getLabelTranslation('sales_tip')}
        </GDMTypography>
      )}

      {viewType === 'view' && sellingTip && (
        <GDMTypography
          fontSize="16px"
          lineHeight="22.4px"
          margin={isDesktop ? '16px 0 0 219px' : '0'}
          fontWeight={400}
          color={theme.palette.primary.main}
          sx={{ width: isDesktop ? '900px' : '100%' }}
        >
          {sellingTip}
        </GDMTypography>
      )}

      {viewType === 'edit' && (
        <GDMBox
          sx={{
            width: isDesktop ? '900px' : '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: isDesktop ? '40px 0 0 219px' : '24px 0 0 0',
          }}
        >
          <GDMTextField
            defaultValue={sellingTip || null}
            onChange={e => setSellingTip(e.target.value)}
            placeholder={getLabelTranslation('add_additional_information')}
            multiline
            inputProps={{
              maxLength: 300,
              style: {
                height: 'auto',
                minHeight: '95px',
              },
            }}
            sx={{
              width: '100%',
              borderRadius: '6px',
              fontFamily: 'Inter, sans-serif',
              fontSize: '16px',
              backgroundColor: '#FAFAFA',
              '&::placeholder': {
                color: '#A1A9B8',
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
              },
            }}
          />

          <GDMTypography
            fontSize="14px"
            lineHeight="19.6px"
            marginTop="4px"
            fontWeight={400}
            color="#777D88"
            sx={{ width: '100%' }}
          >
            {getLabelTranslation('character_limit')}
          </GDMTypography>

          <GDMBox
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isDesktop ? 'row' : 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '24px',
              gap: '24px',
            }}
          >
            <GDMButton
              onClick={() => handleCancelUpdate()}
              disabled={loadingdButton}
              sx={{
                width: !isDesktop && '100%',
                padding: isDesktop ? '16px 124px' : '0',
                backgroundColor: theme.palette.secondary.main,
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 0.8,
                },
              }}
            >
              {loadingdButton ? (
                <GDMLoading type="circular" {...{ size: 24 }} />
              ) : (
                getLabelTranslation('cancel')
              )}
            </GDMButton>
            <GDMButton
              onClick={() => handleChangeSellingTip()}
              disabled={loadingdButton}
              sx={{
                width: !isDesktop && '100%',
                padding: isDesktop ? '16px 124px' : '0',
                backgroundColor: theme.palette.primary.main,
                color: '#FAFAFA',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  opacity: 0.8,
                },
                ':disabled': {
                  backgroundColor: '#A1A9B8',
                },
              }}
            >
              {loadingdButton ? (
                <GDMLoading type="circular" {...{ size: 24 }} />
              ) : (
                getLabelTranslation('save')
              )}
            </GDMButton>
          </GDMBox>
        </GDMBox>
      )}

      <GDMButton
        onClick={() => navigate(-1)}
        sx={{
          width: {
            xs: '100%',
            sm: '185px',
          },
          height: '51px',
          marginTop: '32px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
        }}
      >
        <FaArrowLeftLong style={{ marginRight: '8px' }} />
        <GDMTypography variant="body2">{getLabelTranslation('go_back')}</GDMTypography>
      </GDMButton>
    </Box>
  );
}
