import { useState } from 'react';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';

import { Divider, useMediaQuery } from '@mui/material';

import { GDMContainer, GDMLoading, GDMTypography } from 'components';

import GDMBox from 'components/gdm-box/GDMBox';
import HeaderImage from '../../assets/images/recommendation-header.png';
import colors from '../../core/design-system/style-guide/colors.json';
import RecommendationPageForm from './RecommendationForm/RecommendationPageForm';

export default function RecommendationPage(): JSX.Element {
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const [isRecommendationLoaded, setIsRecommendationLoaded] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [recommendationData, setRecommendationData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading && (
        <GDMContainer
          sx={{
            display: 'flex',
            alignItems: recommendationData ? 'top' : 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100%',
            position: 'absolute',
            paddingTop: '1px',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.2)',
            zIndex: 9999,
          }}
        >
          <GDMBox sx={{ paddingTop: recommendationData ? '25%' : '0' }}>
            <GDMLoading />
          </GDMBox>
        </GDMContainer>
      )}
      <GDMContainer
        component="div"
        sx={{
          borderRadius: 2,
          boxShadow: isDesktop && '0 2px 8px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderTopRightRadius: '24px',
          borderTopLeftRadius: '24px',
          backgroundColor: isDesktop && theme.palette.background.paper,
        }}
      >
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: isRecommendationLoaded ? '32px 64px 0 64px' : '0',
          }}
        >
          {isDesktop && isRecommendationLoaded && (
            <>
              <GDMContainer sx={{ marginBottom: '32px' }}>
                <GDMTypography
                  sx={{ color: '#A1A9B8', fontSize: '14px' }}
                  display="block"
                  gutterBottom
                >
                  {getLabelTranslation('recommendation_breadcrumb')}
                </GDMTypography>
              </GDMContainer>

              <GDMContainer>
                <GDMTypography
                  variant="h4"
                  textAlign={isDesktop ? 'left' : 'center'}
                  mb={2}
                  fontWeight={700}
                  color="#222222"
                >
                  {getLabelTranslation('recommended_suggestion')}
                </GDMTypography>
              </GDMContainer>

              <Divider sx={{ my: 2 }} />
            </>
          )}
          {!isDesktop && isRecommendationLoaded && (
            <>
              <GDMContainer sx={{ marginBottom: '32px' }}>
                <GDMTypography
                  sx={{ color: '#A1A9B8', fontSize: '14px' }}
                  display="block"
                  gutterBottom
                >
                  {getLabelTranslation('recommendation_breadcrumb')}
                </GDMTypography>
              </GDMContainer>

              <GDMContainer>
                <GDMTypography
                  variant="h4"
                  textAlign={isDesktop ? 'left' : 'center'}
                  mb={2}
                  fontWeight={700}
                  color="#222222"
                >
                  {getLabelTranslation('recommended_suggestion')}
                </GDMTypography>
              </GDMContainer>
            </>
          )}

          {isDesktop && !isRecommendationLoaded && (
            <>
              <GDMContainer
                sx={{
                  marginBottom: '32px',
                  height: '229px',
                  width: '100%',
                  background: theme.palette.primary.main,
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  display: 'flex',
                }}
              >
                <GDMContainer
                  sx={{
                    width: '50%',
                    height: '100%',
                    backgroundImage: `url(${HeaderImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left',
                    borderTopLeftRadius: '24px',
                  }}
                />
                <GDMContainer
                  sx={{
                    width: '50%',
                    padding: {
                      xs: '20px 16px 16px 16px',
                      sm: '20px 16px 16px 16px',
                      md: '20px 16px 16px 16px',
                      lg: '24px 16px 16px 16px',
                      xl: '40px 48px 16px 16px',
                    },
                  }}
                >
                  <GDMTypography
                    textAlign={isDesktop ? 'left' : 'center'}
                    fontWeight={700}
                    color={colors.white}
                    gutterBottom
                    component="span"
                    sx={{
                      fontSize: '32px',
                      '@media (max-width: 1024px)': {
                        fontSize: '14px',
                      },
                      '@media (max-width: 1366px)': {
                        fontSize: '18px',
                      },
                      '@media (max-width: 1600px)': {
                        fontSize: '22px',
                      },
                    }}
                  >
                    {getLabelTranslation('recommendation_header_title')}
                  </GDMTypography>
                  <GDMTypography
                    variant="h4"
                    textAlign={isDesktop ? 'left' : 'center'}
                    mb={6}
                    fontWeight={400}
                    sx={{
                      fontSize: '16px',
                      lineHeight: '32px',
                      '@media (max-width: 1024px)': {
                        fontSize: '14px',
                        lineHeight: '18px',
                      },
                      '@media (max-width: 1366px)': {
                        lineHeight: '20px',
                      },
                      '@media (max-width: 1600px)': {
                        lineHeight: '24px',
                      },
                    }}
                    gutterBottom
                    component="div"
                    color={colors.white}
                  >
                    {getLabelTranslation('recommendation_header_description')}
                  </GDMTypography>
                </GDMContainer>
              </GDMContainer>

              <GDMContainer
                sx={{
                  padding: '0 64px',
                }}
              >
                <GDMTypography
                  variant="h4"
                  textAlign={isDesktop ? 'left' : 'center'}
                  mb={1}
                  fontWeight={700}
                  component="div"
                  color="#222222"
                >
                  {getLabelTranslation('recommendation_initial_form_title')}
                </GDMTypography>
                <GDMTypography
                  variant="body2"
                  textAlign={isDesktop ? 'left' : 'center'}
                  fontWeight={400}
                  color="#222222"
                >
                  {getLabelTranslation('recommendation_initial_form_description')}
                </GDMTypography>
              </GDMContainer>
            </>
          )}

          {!isDesktop && !isRecommendationLoaded && (
            <>
              <GDMContainer
                sx={{
                  height: '144px',
                  width: '100%',
                }}
              >
                <GDMContainer
                  sx={{
                    position: 'absolute',
                    top: '-19px',
                    left: 0,
                    width: '100%',
                    height: '144px',
                    backgroundImage: `url(${HeaderImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
              </GDMContainer>
              <GDMContainer
                sx={{
                  height: {
                    xs: '162px',
                    sm: '152px',
                  },
                  width: '100%',
                }}
              >
                <GDMContainer
                  sx={{
                    background: theme.palette.primary.main,
                    top: '124px',
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                    height: {
                      xs: '162px',
                      sm: '152px',
                    },
                    py: {
                      xs: 1.5,
                      sm: 2.5,
                    },
                    px: {
                      xs: 1.5,
                      sm: 2.5,
                    },
                  }}
                >
                  <GDMTypography
                    textAlign="left"
                    fontWeight={700}
                    color={colors.white}
                    gutterBottom
                    component="span"
                    fontSize="20px"
                  >
                    {getLabelTranslation('recommendation_header_title')}
                  </GDMTypography>
                  <GDMTypography
                    variant="h4"
                    textAlign="left"
                    mt={1}
                    sx={{
                      width: { xs: '97%', sm: '100%' },
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 400,
                      flexWrap: 'wrap',
                    }}
                    gutterBottom
                    component="div"
                    color={colors.white}
                  >
                    {getLabelTranslation('recommendation_header_description')}
                  </GDMTypography>
                </GDMContainer>
              </GDMContainer>
              <GDMContainer
                sx={{
                  padding: '0 3px',
                  mt: -3,
                }}
              >
                <GDMTypography fontSize="16px" fontWeight={700} color="#222222" mb={1}>
                  {getLabelTranslation('recommendation_initial_form_title')}
                </GDMTypography>
                <GDMTypography fontSize="14px" fontWeight={400} color="#222222">
                  {getLabelTranslation('recommendation_initial_form_description')}
                </GDMTypography>
              </GDMContainer>
            </>
          )}

          <RecommendationPageForm
            setFormLoaded={setIsRecommendationLoaded}
            isFormLoaded={isRecommendationLoaded}
            setFieldErrors={setFieldErrors}
            fieldErrors={fieldErrors}
            setRecommendation={setRecommendationData}
            setIsLoading={setIsLoading}
            recommendation={recommendationData}
          />
        </GDMContainer>
      </GDMContainer>
    </>
  );
}
