import Api from 'core/api/clients/api/Api';

const getLotsList = async ({
  currentPage,
  itemsPerPage,
  searchColumn,
  search = '',
  orderDesc,
  orderColumn = ''
}): Promise<any> => {
  let url = `/Lots?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }

  if (searchColumn) {
    url += `&searchColumn=${searchColumn}`;
  }

  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }

  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const deleteLot = async ({ id }) => {
  return Api.delete({
    url: `/Lots/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getLotById = async ({ id }) => {
  return Api.get({
    url: `/Lots/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const lotUpdate = async (body: any, id) => {
  return Api.put({
    url: `/Lots/${id}`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const createLot = async (body: any) => {
  return Api.post({
    url: '/Lots',
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
export const LotsService = {
  getLotsList,
  deleteLot,
  createLot,
  getLotById,
  lotUpdate,
};
