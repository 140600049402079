import { GDMDivider, GDMList, GDMTypography } from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { Fragment, useState } from 'react';
import { AdminListListItemBodyMobile } from '../admin-list-list-item-body-mobile';
import { AdminListListItemHeadMobile } from '../admin-list-list-item-head-mobile';
import { AdminListListMobileComponent } from './AdminListListMobile.type';

const AdminListListMobile: AdminListListMobileComponent = ({
  items,
  adminHeaderLabels,
  adminIdSelectedToDelete,
  handleOpenDeleteAdminModal,
  emptyMessage,
}) => {
  const [openedAdminId, setOpenedAdminId] = useState<string | null>(null);
  const { getLabelTranslation } = useLanguageSwitcher();

  const handleCollapse = id => {
    const isOpened: boolean = openedAdminId === id;

    setOpenedAdminId(isOpened ? null : id);
  };

  return (
    <GDMList component="nav" aria-labelledby="nested-list-subheader" disablePadding>
      {items.map(admin => (
        <Fragment key={admin.id}>
          <AdminListListItemHeadMobile
            {...{
              adminId: admin.id,
              adminName: admin.name,
              handleCollapse,
              nameLabel: adminHeaderLabels.nameLabel.label,
              openedAdminId,
              adminIdSelectedToDelete,
            }}
          />
          <AdminListListItemBodyMobile
            {...{
              admin,
              openedAdminId,
              adminHeaderLabels,
              adminIdSelectedToDelete,
              handleOpenDeleteAdminModal,
            }}
          />

          <GDMDivider />
        </Fragment>
      ))}
      {items.length === 0 && !!emptyMessage && (
        <GDMTypography
          sx={{
            paddingTop: '32px',
            paddingBottom: '32px',
            textAlign: 'center',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}
    </GDMList>
  );
};

export default AdminListListMobile;
