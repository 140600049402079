/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES, ptBR } from '@mui/x-date-pickers/locales';

import { GDMContainer } from 'components/gdm-container';

import { useLanguageContext } from 'core/store/contexts/languageContext';
import { GDMDatePickerComponent, GDMDatePickerLocale } from './GDMDatePicker.type';

const ptBRTexts: ILocale = {
  name: GDMDatePickerLocale.PT_BR,
  formats: {
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
    LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm',
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
  },
  relativeTime: {
    d: 'um dia',
    dd: '%d dias',
    future: 'em %s',
    h: 'uma hora',
    hh: '%d horas',
    M: 'um mês',
    m: 'um minuto',
    MM: '%d meses',
    mm: '%d minutos',
    past: 'há %s',
    s: 'alguns segundos',
    y: 'um ano',
    yy: '%d anos',
  },
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  ordinal: n => `${n}º`,
  weekdays: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ],
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  weekStart: 0,
};

const esARTexts: ILocale = {
  name: GDMDatePickerLocale.ES_AR,
  formats: {
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY [a las] HH:mm',
    LLLL: 'dddd, D [de] MMMM [de] YYYY [a las] HH:mm',
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
  },
  relativeTime: {
    d: 'un día',
    dd: '%d días',
    future: 'en %s',
    h: 'una hora',
    hh: '%d horas',
    M: 'un mes',
    m: 'un minuto',
    MM: '%d meses',
    mm: '%d minutos',
    past: 'hace %s',
    s: 'unos segundos',
    y: 'un año',
    yy: '%d años',
  },
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  ordinal: n => `${n}º`,
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
  weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  weekStart: 1,
};

const localeTextList = {
  [GDMDatePickerLocale.PT_BR]: ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
  [GDMDatePickerLocale.ES_AR]: esES.components.MuiLocalizationProvider.defaultProps.localeText,
};

const adapterLocaleList = {
  [GDMDatePickerLocale.PT_BR]: ptBRTexts,
  [GDMDatePickerLocale.ES_AR]: esARTexts,
};
const GDMDatePicker: GDMDatePickerComponent = ({
  defaultValue,
  minDate,
  maxDate,
  disabled = false,
  onChange,
  name,
  textField,
  ...props
}: any) => {
  const { currentLanguage } = useLanguageContext();

  const [selectedDate, setSelectedDate] = useState(defaultValue ? dayjs(defaultValue) : null);
  const datePickerDayjs = dayjs;

  useEffect(() => {
    setSelectedDate(datePickerDayjs(defaultValue));
  }, [defaultValue]);

  const handleDateChange = date => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <LocalizationProvider
      adapterLocale={adapterLocaleList[currentLanguage] as any}
      localeText={localeTextList[currentLanguage]}
      dateAdapter={AdapterDayjs}
    >
      <GDMContainer
        sx={{
          textAlign: 'center',
        }}
      >
        {textField ? (
          <DatePicker
            name={name}
            value={selectedDate}
            defaultValue={datePickerDayjs(defaultValue)}
            onChange={handleDateChange}
            format="DD/MM/YYYY"
            minDate={minDate && datePickerDayjs(minDate)}
            maxDate={maxDate && datePickerDayjs(maxDate)}
            disabled={disabled}
            {...props}
          />
        ) : (
          <DatePicker
            name={name}
            value={selectedDate}
            defaultValue={datePickerDayjs(defaultValue)}
            onChange={handleDateChange}
            format="DD/MM/YYYY"
            minDate={minDate && datePickerDayjs(minDate)}
            maxDate={maxDate && datePickerDayjs(maxDate)}
            disabled={disabled}
            {...props}
          />
        )}
      </GDMContainer>
    </LocalizationProvider>
  );
};

export default GDMDatePicker;
