import { useLanguageContext } from '../store/contexts/languageContext';

const useLanguageSwitcher = () => {
  const { currentLanguage, switchLanguage, translationsDictionary, getLabelTranslation, loading } =
    useLanguageContext();

  return { currentLanguage, switchLanguage, translationsDictionary, getLabelTranslation, loading };
};

export default useLanguageSwitcher;
