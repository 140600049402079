import { CircularProgress } from '@mui/material';
import { GDMContainer, GDMTypography } from 'components';
import {
  bottomCircleStyles,
  bottomCircleTextStyles,
  dataCirclesContainerStyles,
  topCircleStyles,
  topCircleTextContainerStyles,
  topCircleTextStyles,
} from './DashboardStatusDataCircles.styles';
import { DashboardStatusDataCirclesComponent } from './DashboardStatusDataCircles.type';

const DashboardStatusDataCircles: DashboardStatusDataCirclesComponent = ({
  theme,
  topCicleText,
  bottomCircleText,
}) => {
  return (
    <GDMContainer sx={dataCirclesContainerStyles}>
      <CircularProgress variant="determinate" value={100} thickness={6} sx={topCircleStyles} />

      <GDMContainer sx={topCircleTextContainerStyles}>
        <GDMTypography variant="caption" sx={topCircleTextStyles}>
          {topCicleText}
        </GDMTypography>
      </GDMContainer>
      <GDMContainer sx={bottomCircleStyles(theme)}>
        <GDMTypography sx={bottomCircleTextStyles}>{bottomCircleText}</GDMTypography>
      </GDMContainer>
    </GDMContainer>
  );
};

export default DashboardStatusDataCircles;
