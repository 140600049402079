import Api from 'core/api/clients/api/Api';

const getFarmList = async ({
  currentPage,
  itemsPerPage,
  search,
  searchColumn,
  orderDesc,
  orderColumn = '',
  farmerId = '',
}): Promise<any> => {
  let url = `/Farms?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (searchColumn && searchColumn !== 'none') {
    url += `&searchColumn=${searchColumn}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }
  if (farmerId) {
    url += `&farmerId=${farmerId}`;
  }

  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const createFarm = async (body: any) => {
  return Api.post({
    url: '/Farms',
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getListByFarmerId = ({ id }) => {
  return Api.get({
    url: `/Farms/v1/Farm/ListByFarmer/id=${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const deleteFarm = async ({ id }) => {
  return Api.delete({
    url: `/Farms/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getFarmById = async ({
  id,
  entityName,
  columnName,
  orderDesc,
}: {
  id: string;
  entityName?: string;
  columnName?: string;
  orderDesc?: boolean;
}): Promise<any> => {
  let url = `/Farms/${id}?`;

  const params = [
    { value: entityName, key: 'EntityName' },
    { value: columnName, key: '&OrderColumn' },
    { value: orderDesc, key: '&OrderDesc' },
  ];
  params.forEach(param => {
    if (param.value) {
      url += `${param.key}=${param.value}`;
    }
  });

  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const updateFarmById = async ({ id, formData }) => {
  return Api.put({
    url: `/Farms/${id}`,
    body: formData,
    config: {
      headers: {
        'X-Origin': 'GDM Recomendador',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const getFarmListByFarmer = async (farmerId: string): Promise<any> => {
  const url = `/Farms/ListByFarmer/${farmerId}`;
  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const getLotsByFarm = async (farmid: string): Promise<any> => {
  return Api.get({
    url: `/Lots/ListByFarm/${farmid}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
export const FarmService = {
  getFarmList,
  deleteFarm,
  getListByFarmerId,
  createFarm,
  getFarmById,
  updateFarmById,
  getFarmListByFarmer,
  getLotsByFarm,
};
