import { AdminListHandleOpenModalFunction } from './adminListHandleOpenModal.type';

const adminListHandleOpenModal: AdminListHandleOpenModalFunction = ({
  handleOpenModal,
  handleCancel,
  handleOnClose,
  handleDelete,
  gTL,
  adminId,
}) => {
  handleOpenModal({
    type: 'delete',
    title: gTL('modal_confirm_question'),
    message: gTL('modal_alert'),
    primaryAction: {
      label: gTL('delete'),
      action: () => handleDelete(adminId),
    },
    secondAction: {
      label: gTL('cancel'),
      action: handleCancel,
    },
    onClose: handleOnClose,
    actionButtons: [],
  });
};

export default adminListHandleOpenModal;
