import Api from 'core/api/clients/api/Api';
import { RegionsProp } from 'core/api/useCases/RegiosUseCase/LoadRegionsUseCase';
import { RegionsDomain } from 'core/typing/domains/RegionsDomain/RegionsDomain';

const getRegions = async (props): Promise<any> => {
  return Api.get({
    url: `/Regions?countryId=${props}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getRegionsUseCase = async ({ countryId }: RegionsProp): Promise<{ data: RegionsDomain }> => {
  return Api.get({
    url: `/Regions?countryId=${countryId}`,
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const RegionsServices = {
  getRegions,
  getRegionsUseCase,
};
