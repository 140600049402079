import { UserProfileService } from 'core/api/services/user';
import { loadUserProfile, loadUserProfileDone, loadUserProfileFail } from 'core/store/stores';

const execute = async (): Promise<void> => {
  const errorCallback = () => {
    loadUserProfileFail();
  };

  loadUserProfile();

  return UserProfileService.getUserProfile()
    .then(({ data }) => {
      loadUserProfileDone({
        ...data,
        isLogged: true,
      });
    })
    .catch(errorCallback);
};

const LoadUserProfileUseCase = {
  execute,
};

export default LoadUserProfileUseCase;
