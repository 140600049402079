import Api from 'core/api/clients/api/Api';

const upSertFarmer = async (body: any): Promise<any> => {
  return Api.post({
    url: `/Farmers`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getFarmersList = async ({
  currentPage,
  itemsPerPage,
  search = '',
  searchColumn,
  orderDesc,
  orderColumn = '',
}): Promise<any> => {
  let url = `/Farmers?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (searchColumn && searchColumn !== 'none' && searchColumn !== 'all') {
    url += `&searchColumn=${searchColumn}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }
  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const deleteFarmer = async ({ id }): Promise<any> => {
  return Api.delete({
    url: `/Farmers/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getFarmerById = async ({
  id,
  entityName,
  columnName,
  orderDesc,
}: {
  id: string;
  entityName?: string;
  columnName?: string;
  orderDesc?: boolean;
}): Promise<any> => {
  let url = `/Farmers/${id}?`;

  const params = [
    { value: entityName, key: 'EntityName' },
    { value: columnName, key: '&OrderColumn' },
    { value: orderDesc, key: '&OrderDesc' },
  ];
  params.forEach(param => {
    if (param.value) {
      url += `${param.key}=${param.value}`;
    }
  });

  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const updateFarmer = async (body: any, { id }): Promise<any> => {
  return Api.put({
    url: `/Farmers/${id}`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getFarmers = async (): Promise<any> => {
  return Api.get({
    url: `/Farmers/ListByOwner`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

export const FarmerService = {
  getFarmersList,
  deleteFarmer,
  getFarmers,
  upSertFarmer,
  getFarmerById,
  updateFarmer,
};
