import { SxProps } from '@mui/material';

export const dataTextContainerStyles: SxProps = {
  fontSize: '14px!important',
  lineHeight: '20px!important',
  display: 'block',
  color: '#222222',
};

export const textStyles: SxProps = {
  fontSize: '14px!important',
  lineHeight: '20px!important',
  display: 'inline-block',
  color: '#222222',
};

export const dataLabelStyles: SxProps = {
  ...textStyles,
  fontWeight: '700',
};

export const dataValueStyles: SxProps = {
  ...textStyles,
};
