/* eslint-disable react/function-component-definition */
import { LuTrash2 } from 'react-icons/lu';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import {
  GDMFeedbackModalTypeIconComponent,
  GDMFeedbackModalTypeIcons,
} from './GDMFeedbackModalTypeIcon.type';

const GDMFeedbackModalTypeIcon: GDMFeedbackModalTypeIconComponent = ({ type }) => {
  const icon: GDMFeedbackModalTypeIcons = {
    success: <CheckCircleOutlineRoundedIcon sx={{ color: '#06C270', fontSize: 52 }} />,
    error: <HighlightOffRoundedIcon sx={{ color: '#FB2047', fontSize: 52 }} />,
    delete: <LuTrash2 size={52} color="#777D88" />,
    warning: <ReportGmailerrorredRoundedIcon sx={{ color: '#FFC107', fontSize: 52 }} />,
    info: <InfoOutlinedIcon sx={{ color: '#3F51B5', fontSize: 52 }} />,
  };

  return icon[type];
};

export default GDMFeedbackModalTypeIcon;
