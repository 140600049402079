import { GDMButton, GDMContainer, GDMTypography } from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { TbFilter, TbRefresh } from 'react-icons/tb';
import { DashboardFiltersDynamicRender } from '../dashboard-filders-dynamic-render';
import {
  adminFiltersContainerStyles,
  allFiltersContainerStyles,
  filterContainerStyles,
  filterFormContainerStyles,
  filterHeaderStyles,
  filterResetButtonStyles,
  filterTitleContainerStyles,
  filterTitleStyles,
  filterUpdateButtonStyles,
  generalFiltersContainerStyles,
  showMoreStyles,
} from './DashboardFilters.styles';
import { IDashboardFilters } from './DashboardFilters.type';

export type Filter = {
  [key: string]: FilterOption[];
};

export type FilterOption = {
  id: string;
  name: string;
};

export type Keys = {
  name: string;
  admin: boolean;
  hide: boolean;
};

type IProps = {
  dashboardFilters: IDashboardFilters;
  setDashboardFilters: Dispatch<SetStateAction<IDashboardFilters>>;
  filters: Filter[];
  isAdmin: boolean;
  onUpdateDashboard: (filters?: IDashboardFilters) => Promise<void>;
};

const adminFilters = ['role', 'distributor', 'multiplier', 'user'];
const propsToHide = ['endDate', 'status'];

export default function DashboardFilters({
  dashboardFilters,
  setDashboardFilters,
  filters,
  isAdmin,
  onUpdateDashboard,
}: IProps) {
  const { theme } = useThemeSwitcher();
  const [generalFilterFields, setGeneralFilterFields] = useState<Keys[]>([]);
  const [adminFilterFields, setAdminFilterFields] = useState<Keys[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { getLabelTranslation } = useLanguageSwitcher();

  const [period] = useState({
    startDate: dayjs().startOf('year').toISOString(),
    endDate: dayjs().endOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
  });

  const isButtonDisabled = () => {
    if (
      dashboardFilters.brand ||
      dashboardFilters.state ||
      dashboardFilters.city ||
      dashboardFilters.culture ||
      dashboardFilters.role ||
      dashboardFilters.distributor ||
      dashboardFilters.multiplier ||
      dashboardFilters.user ||
      !dayjs(dashboardFilters.startDate).isSame(period.startDate) ||
      !dayjs(dashboardFilters.endDate).isSame(period.endDate)
    )
      return false;
    return true;
  };

  useEffect(() => {
    if (filters) {
      const activeFilterFields = Object.keys(filters || {}).map(filterName => {
        return {
          name: filterName,
          admin: adminFilters.includes(filterName),
          hide: propsToHide.includes(filterName),
        };
      });

      if (!!activeFilterFields.length) {
        const generalFields = activeFilterFields.filter(field => !field.admin && !field.hide);
        const adminFields = activeFilterFields.filter(field => field.admin && !field.hide);

        if (!!generalFields.length && generalFields !== generalFilterFields)
          setGeneralFilterFields(generalFields);

        if (!!adminFields.length && adminFields !== adminFilterFields)
          setAdminFilterFields(adminFields);
      }
    }

    setDashboardFilters(() => {
      const updatedFilters = {
        ...dashboardFilters,
        startDate: dashboardFilters.startDate ?? dayjs().startOf('year').toISOString(),
        endDate: dashboardFilters.endDate ?? dayjs().endOf('year').toISOString(),
      };

      return Object.fromEntries(Object.entries(updatedFilters).filter(([_, v]) => v !== undefined));
    });
  }, [filters]);

  const handleResetFilters = () => {
    setDashboardFilters({
      startDate: dayjs().startOf('year').toISOString(),
      endDate: dayjs().endOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
    });
    onUpdateDashboard();
  };

  const handleUpdateFilters = () => {
    onUpdateDashboard(dashboardFilters);
  };

  return (
    <GDMContainer sx={filterContainerStyles}>
      <GDMContainer sx={filterHeaderStyles}>
        <GDMContainer sx={filterTitleContainerStyles}>
          <GDMTypography sx={filterTitleStyles(theme)}>
            {getLabelTranslation(`dash_filter_title`)}
          </GDMTypography>
          <TbFilter size={24} />
        </GDMContainer>

        <GDMButton sx={filterResetButtonStyles} variant="text" onClick={handleResetFilters}>
          {getLabelTranslation(`dash_actions_filters_default`)}
        </GDMButton>

        <GDMButton
          variant="contained"
          onClick={handleUpdateFilters}
          sx={filterUpdateButtonStyles}
          disabled={isButtonDisabled()}
        >
          <TbRefresh size={16} />
          {getLabelTranslation(`dash_actions_filters_update`)}
        </GDMButton>
      </GDMContainer>

      <GDMContainer sx={filterFormContainerStyles}>
        <GDMContainer sx={allFiltersContainerStyles}>
          <GDMContainer sx={generalFiltersContainerStyles}>
            <DashboardFiltersDynamicRender
              activeFilterFields={generalFilterFields}
              dashboardFilters={dashboardFilters}
              filtersData={filters}
              setDashboardFilters={setDashboardFilters}
            />
          </GDMContainer>

          {!!adminFilters.length && isAdmin && isFilterOpen && (
            <GDMContainer sx={adminFiltersContainerStyles}>
              <DashboardFiltersDynamicRender
                isAdminFilters
                activeFilterFields={adminFilterFields}
                dashboardFilters={dashboardFilters}
                filtersData={filters}
                setDashboardFilters={setDashboardFilters}
              />
            </GDMContainer>
          )}
        </GDMContainer>

        {isAdmin && (
          <GDMContainer sx={showMoreStyles}>
            <GDMButton variant="text" onClick={() => setIsFilterOpen(!isFilterOpen)}>
              {isFilterOpen ? (
                <>
                  {getLabelTranslation(`dash_see_less`)}
                  <MdExpandLess color={theme.palette.primary.main} />
                </>
              ) : (
                <>
                  {getLabelTranslation(`dash_see_more`)}
                  <MdExpandMore color={theme.palette.primary.main} />
                </>
              )}
            </GDMButton>
          </GDMContainer>
        )}
      </GDMContainer>
    </GDMContainer>
  );
}
