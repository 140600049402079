// baseTheme.ts
import { ThemeOptions } from '@mui/material/styles';

const baseThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '56px',
      lineHeight: '61.6px',
      fontWeight: 400,
    },
    h2: {
      lineHeight: '52.8px',
      fontSize: '48px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '40px',
      lineHeight: '44px',
      fontWeight: 400,
    },
    h4: {
      fontSize: '32px',
      lineHeight: '35.2px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '24px',
      lineHeight: '26.4px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '20px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '25.2px',
    },
    body1: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 400,
      '@media (max-width:960px)': {
        fontSize: '14px',
        lineHeight: '19.6px',
      },
    },
    body2: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '25.2px',
      '@media (max-width:960px)': {
        fontSize: '14px',
        lineHeight: '19.6px',
      },
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    error: {
      main: '#FB2047',
    },
    success: {
      main: '#06C270',
    },
    warning: {
      main: '#FF9900',
    },
    info: {
      main: '#0063F7',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          height: '51px',
          fontWeight: 700,
          fontSize: '16px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: 0,
          '@media (max-width:960px)': {
            marginLeft: 0,
            justifyContent: 'center',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: '20px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            fontSize: '20px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          padding: 0,
          margin: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100vw',
          overflow: 'hidden',
        },
        '#root': {
          width: '100vw',
          height: '100vh',
          overflow: 'auto',
        },
        body: {
          width: '100vw',
          '@media (max-width:960px)': {
            background: '#FAFAFA',
          },
          background: '#E7E9F1',
        },
      },
    },
  },
};

export default baseThemeOptions;
