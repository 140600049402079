import { CgArrowsExchangeAltV } from 'react-icons/cg';

import colors from 'core/design-system/style-guide/colors.json';

import textStyles from 'core/design-system/style-guide/typography.json';

import { GDMContainer } from 'components/gdm-container';
import { GDMIconButton } from 'components/gdm-icon-button';
import { GDMTableCell } from 'components/gdm-table-cell';
import { GDMTableHead } from 'components/gdm-table-head';
import { GDMTableRow } from 'components/gdm-table-row';
import { GDMTypography } from 'components/gdm-typography';
import { HeaderTable } from 'pages/admin-list/AdminListPage.type';
import { AdminListTableHeadDesktopComponent } from './AdminListTableHeadDesktop.type';

const LabelText = ({ label }: { label: string }) => (
  <GDMTypography color={colors.dark1} sx={textStyles.table.column}>
    {label}
  </GDMTypography>
);

const Cell = ({
  label,
  onClick,
}: {
  label: HeaderTable;
  onClick: (orderColumn: string) => void;
}) => (
  <GDMTableCell>
    <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
      <LabelText label={label.label} />
      <GDMIconButton onClick={() => onClick(label.name)}>
        <CgArrowsExchangeAltV />
      </GDMIconButton>
    </GDMContainer>
  </GDMTableCell>
);

const AdminListTableHeadDesktop: AdminListTableHeadDesktopComponent = ({
  adminHeaderLabels,
  changeOrder,
}) => {
  const {
    countryNameLabel,
    deleteLabel,
    documentNumberLabel,
    editLabel,
    emailLabel,
    nameLabel,
    phoneNumberLabel,
    seeLabel,
  } = adminHeaderLabels;

  return (
    <GDMTableHead>
      <GDMTableRow sx={{ background: colors.light3 }}>
        <Cell onClick={changeOrder} label={nameLabel} />
        <Cell onClick={changeOrder} label={emailLabel} />
        <Cell onClick={changeOrder} label={phoneNumberLabel} />
        <Cell onClick={changeOrder} label={documentNumberLabel} />
        <Cell onClick={changeOrder} label={countryNameLabel} />

        <GDMTableCell>
          <LabelText label={seeLabel.label} />
        </GDMTableCell>
        <GDMTableCell>
          <LabelText label={editLabel.label} />
        </GDMTableCell>
        <GDMTableCell>
          <LabelText label={deleteLabel.label} />
        </GDMTableCell>
      </GDMTableRow>
    </GDMTableHead>
  );
};

export default AdminListTableHeadDesktop;
