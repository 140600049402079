import { createStore } from 'effector';
import * as CryptoJS from 'crypto-js';
import { persist } from 'effector-storage/local';
import {
  acceptTermsUser,
  loadUserProfile,
  loadUserProfileDone,
  loadUserProfileFail,
  updateUserProfile,
  loadChangeLanguage,
} from './UserProfileEvents';
import { UserProfileState } from './UserProfileState';

export const userInitialState: UserProfileState & { isLoading: boolean } = {
  user: {
    id: null,
    role: null,
    name: null,
    email: null,
    phoneNumber: null,
    documentTypeId: null,
    documentTypeAcronym: null,
    documentNumber: null,
    countryId: null,
    countryName: null,
    languageId: null,
    languageIso: null,
    imageCdnUrl: null,
    fantasyName: null,
    regionId: null,
    brands: [],
    onboardingStatus: false,
    termsAccepted: false,
    isLogged: false,
    emailReceiptAccepted: false,
    hasNotifications: false,
    totalPendingPlanting: null,
    totalPendingHarvest: null,
  },
  isLoading: false,
};

const UserProfileStore = createStore<UserProfileState & { isLoading: boolean }>(userInitialState)
  .on(loadUserProfile, state => ({
    ...state,
    isLoading: true,
  }))
  .on(loadUserProfileDone, (_, data) => {
    sessionStorage.setItem(
      'isAcceptedTerms',
      JSON.stringify({ isAcceptedTerms: data.termsAccepted }),
    );
    return {
      isLoading: false,
      user: data,
    };
  })
  .on(loadUserProfileFail, state => ({
    ...state,
    isLoading: false,
    user: {
      ...state.user,
    },
  }))
  .on(updateUserProfile, (state, updatedData) => ({
    ...state,
    user: {
      ...state.user,
      ...updatedData,
    },
  }))
  .on(acceptTermsUser, (state, accept) => ({
    ...state,
    user: {
      ...state.user,
      termsAccepted: accept,
    },
  }))
  .on(loadChangeLanguage, (state, language) => ({
    ...state,
    isLoading: false,
    user: {
      ...state.user,
      languageIso: language,
    },
  }));

function encryptSensitiveData(data: any) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), 'UserProfileStore').toString();
}

function decryptSensitiveData(encryptedData: string) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, 'UserProfileStore');
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

persist({
  store: UserProfileStore,
  key: 'log',
  serialize: state => encryptSensitiveData(state),
  deserialize: serializedState => decryptSensitiveData(serializedState),
});

export default UserProfileStore;
