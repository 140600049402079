import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import cuitValidator from 'cuit-validator';

import { IDynamicFormData } from 'components';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';

const {
  user: { languageIso },
} = UserProfileStore.getState();

const languageChosed = languageIso;
const defaultLanguage = languageIso;

const defineTranslateOfErrors = () => {
  if (languageChosed && languageChosed === 'pt-BR') {
    return 'pt-BR';
  }
  if (languageChosed && languageChosed === 'es-AR') {
    return 'es-AR';
  }
  if (!languageChosed && defaultLanguage === 'pt-BR') {
    return 'pt-BR';
  }
  if (!languageChosed && defaultLanguage === 'es-AR') {
    return 'es-AR';
  }
  return 'pt-BR';
};

export const validateDocument = (document: string, country: string) => {
  const cleaned = document.replace(/\D/g, '');
  if (country === CountriesDomainEnum.Brasil) {
    if (cleaned.length === 11) {
      return cpf.isValid(cleaned);
    }
    if (cleaned.length < 1 && cleaned.length < 11) {
      return true;
    }
    if (cleaned.length === 14) return cnpj.isValid(cleaned);
  } else if (country === CountriesDomainEnum.Argentina) {
    if (cleaned.length === 0) {
      return true;
    }
    return cuitValidator(cleaned);
  }
  return false;
};

export const validatePhone = (phone: string, country: string) => {
  const phoneValidation = {
    BrasilFixedLinePhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{4})$/,
    BrasilCellPhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(9\d{4})-?(\d{4})$/,
    Argentina: /^[0-9()\s\-+]+$/, // Permitir + e pelo menos 10 caracteres
  };

  if (country === CountriesDomainEnum.Argentina) {
    return phoneValidation.Argentina.test(phone);
  }

  if (country === CountriesDomainEnum.Brasil) {
    let numericPhone = phone;
    numericPhone = numericPhone.replace(/\D/g, '');
    if (numericPhone.length >= 12 && numericPhone.length <= 13) {
      return phoneValidation.BrasilCellPhone.test(phone);
    }
    if (numericPhone.length === 12) {
      return phoneValidation.BrasilFixedLinePhone.test(phone);
    }
    return false; // Número inválido se não tiver o comprimento esperado
  }

  return false; // Valor padrão se o país não for Brasil ou Argentina
};

export const validateEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validateForm = (
  formData: IDynamicFormData,
  country: string,
  entityType = 'Administrador',
) => {
  const errors: ObjectLiteral<string> = {};

  if (!formData.name.trim()) {
    errors.name =
      defineTranslateOfErrors() === 'pt-BR'
        ? 'Nome completo é obrigatório'
        : 'Nombre completo es obligatorio';
  }

  if (!formData.contactEmail.trim()) {
    errors.contactEmail =
      defineTranslateOfErrors() === 'pt-BR' ? 'E-mail é obrigatório' : 'E-mail es obligatorio';
  } else if (!/\S+@\S+\.\S+/.test(formData.contactEmail)) {
    errors.contactEmail =
      defineTranslateOfErrors() === 'pt-BR' ? 'E-mail inválido' : 'E-mail inválido';
  }

  const cleanedPhoneNumber = formData.phoneNumber.replace(/[ \-+_]/g, '');
  const qtyOfNumbers = cleanedPhoneNumber.length;
  const onlyDdiNumbers = 2;
  const fixedLinePhoneWithPrefixNine = cleanedPhoneNumber[4] === '9';
  const isABrasilFixedLinePhone = qtyOfNumbers === 12;
  const isABrasilCellPhone = qtyOfNumbers > 12;
  const isBrasil = country === 'Brasil';
  const isArgentina = country === 'Argentina';

  const phoneValidation = {
    BrasilFixedLinePhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{3})$/,
    BrasilCellPhone:
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
    Argentina: /^[0-9()\s\-+]+$/,
  };

  if (qtyOfNumbers === onlyDdiNumbers) {
    errors.phoneNumber =
      defineTranslateOfErrors() === 'pt-BR' ? 'Telefone é obrigatório' : 'Teléfono es obligatorio';
  }

  if (qtyOfNumbers === 0) {
    errors.phoneNumber =
      defineTranslateOfErrors() === 'pt-BR' ? 'Telefone é obrigatório' : 'Teléfono es obligatorio';
  }

  if (isArgentina) {
    if (!phoneValidation.Argentina.test(formData.phoneNumber)) {
      errors.phoneNumber =
        defineTranslateOfErrors() === 'pt-BR' ? 'Telefone inválido' : 'Teléfono no válido';
    }
  }

  if (isABrasilFixedLinePhone && isBrasil && fixedLinePhoneWithPrefixNine) {
    if (!phoneValidation.BrasilFixedLinePhone.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Telefone fixo inválido';
    }
  }

  if (isABrasilCellPhone && isBrasil) {
    if (!phoneValidation.BrasilCellPhone.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Celular inválido';
    }
  }

  const cleanedDocument = formData.documentNumber.replace(/\D/g, '');
  const documentCountry = country === 'Argentina' ? 'CUIT' : 'CPF/CNPJ';

  if (entityType === 'Distribuidor' || entityType === 'Multiplicador') {
    if (!formData.address || !formData.address.trim()) {
      errors.address =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Endereço é obrigatório'
          : 'Dirección es obligatorio';
    }

    if (!formData.ufId) {
      errors.ufId =
        defineTranslateOfErrors() === 'pt-BR' ? 'UF é obrigatório' : 'Estado es obligatorio';
    }

    if (!formData.cityId) {
      errors.cityId =
        defineTranslateOfErrors() === 'pt-BR' ? 'Município é obrigatório' : 'Ciudad é obrigatório';
    }

    if (!formData.brandIds || !formData.brandIds.length) {
      errors.brandIds =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Obrigatório pelo menos uma marca selecionada'
          : 'Se requiere al menos una marca seleccionada';
    }

    if (!formData.logoFile) {
      errors.logoFile =
        defineTranslateOfErrors() === 'pt-BR' ? 'Logotipo é obrigatório' : 'Logo es obligatorio';
    }
    if (!formData.personInChargeName || !formData.personInChargeName.trim()) {
      errors.personInChargeName =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Responsável é obrigatório'
          : 'Responsable es obligatorio';
    }
    if (cleanedDocument.length > 11 && !formData.fantasyName) {
      errors.fantasyName =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Nome fantasia é obrigatório'
          : 'Nombre de fantasia es obligatorio';
    }
    if (cleanedDocument.length === 0 && !formData.fantasyName) {
      errors.fantasyName =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Nome fantasia é obrigatório'
          : 'Nombre de fantasia es obligatorio';
    }
  }

  if (!cleanedDocument || cleanedDocument.length === 0) {
    errors.documentNumber = `${documentCountry} ${
      defineTranslateOfErrors() === 'pt-BR' ? 'é obrigatório' : 'es obligatorio'
    }`;
  } else if (!cleanedDocument.trim() || cleanedDocument.length < 11) {
    errors.documentNumber = `${
      defineTranslateOfErrors() === 'pt-BR'
        ? 'Número de documento inválido'
        : 'Número de documento no válido'
    }`;
  } else if (cleanedDocument.length > 11 && cleanedDocument.length !== 14) {
    errors.documentNumber =
      defineTranslateOfErrors() === 'pt-BR'
        ? 'Número de documento inválido'
        : 'Número de documento no válido';
  } else if (
    formData.documentNumber !== '' &&
    !validateDocument(formData.documentNumber, country)
  ) {
    errors.documentNumber =
      defineTranslateOfErrors() === 'pt-BR'
        ? 'Número de documento inválido'
        : 'Número de documento no válido';
  }

  if (entityType === 'Vendedor GDM') {
    if (cleanedDocument.length > 11 && !formData.fantasyName) {
      errors.fantasyName =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Nome fantasia é obrigatório'
          : 'Nombre de fantasia es obligatorio';
    }
    if (cleanedDocument.length === 0 && !formData.fantasyName) {
      errors.fantasyName =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Nome fantasia é obrigatório'
          : 'Nombre de fantasia es obligatorio';
    }
    if (!formData.brandIds || !formData.brandIds.length) {
      errors.brandIds =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Vendedor GDM deve conter pelo menos uma marca selecionada'
          : 'Vendedor GDM debe contener al menos una marca seleccionada';
    }
    if (!formData.regionId || !formData.regionId.trim()) {
      errors.regionId =
        defineTranslateOfErrors() === 'pt-BR' ? 'Região é obrigatório' : 'Región es obligatorio';
    }
    if (!formData.personInChargeName || !formData.personInChargeName.trim()) {
      errors.personInChargeName =
        defineTranslateOfErrors() === 'pt-BR'
          ? 'Responsável é obrigatório'
          : 'Responsable es obligatorio';
    }
  }

  return errors;
};

export const isObjectEmpty = (obj: Record<string, any>): boolean => {
  return Object.keys(obj).length === 0;
};
