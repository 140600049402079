import { GDMFeedbackModalStyles } from './GDMFeedbackModal.type';

const gdmFeedbackModalStyles: GDMFeedbackModalStyles = {
  paperStyle: {
    borderRadius: '32px',
    maxWidth: '100%',
    width: '390px',
    height: 'fit-content',
    maxHeight: '100%',
    padding: '32px 46px 46px',
    margin: '0px',

    '@media(max-width: 454px)': {
      width: 'calc(100vw - 64px)',
      padding: '32px 32px 46px',
    },
  },
  titleStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 0px 16px',
  },
  closeIconButtonStyle: { fontSize: '28px', padding: '0px', margin: '0px' },
  typeIconContainerStyle: {
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContentStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px',
  },
  dialogContentTitleStyle: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
    color: '#222222',
    margin: '0px',
    padding: '24px 0px',

    '@media(max-width: 960px)': {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  dividerStyle: {
    width: '100%',
  },
  dialogContentTextStyle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0px 0px',
  },
  dialogContentContainerStyle: {
    textAlign: 'center',
    width: '223px',
    maxWidth: '100%',
  },
};

export default gdmFeedbackModalStyles;
