import { Box, Skeleton } from '@mui/material';

import {
  avatarCard,
  avatarStyle,
  containerStyle,
  formCard,
  formContainer,
  profileContainer,
} from '../Profile.style';

function SkeletonProfile() {
  return (
    <Box sx={containerStyle}>
      <Box sx={avatarCard}>
        <Box sx={profileContainer}>
          <Skeleton sx={avatarStyle} variant="circular" width={150} height={150} />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={100} height={30} />
        </Box>
      </Box>
      <Box sx={formCard}>
        <Box sx={formContainer}>
          <Box>
            <Skeleton variant="text" height={56} />
          </Box>
          <Box>
            <Skeleton variant="rectangular" height={56} />
          </Box>
          <Box>
            <Skeleton variant="rectangular" height={56} />
          </Box>
          <Box className="bottom-form-container">
            <Box>
              <Skeleton variant="rectangular" height={56} />
            </Box>
            <Box>
              <Skeleton variant="rectangular" height={56} />
            </Box>
            <Box>
              <Skeleton variant="rectangular" height={56} />
            </Box>
          </Box>
          <Skeleton variant="rectangular" height={56} />
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonProfile;
