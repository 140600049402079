import { PublicOutlined } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { GDMContainer, GDMTypography } from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardMap from '../dashboard-map/DashboardMap';
import { DashboardRecommendationButtons } from '../dashboard-recommendation-buttons';
import { DashboardRecommendationLinkButton } from '../dashboard-recommendation-link-button';
import { DashboardStatusDataCircles } from '../dashboard-status-data-circles';
import {
  dashboardMapCardHeaderStyles,
  dashboardMapCardStyles,
  dashboardMapColumnsContainerStyles,
  dataPeriodTextStyles,
  dataSideContainerStyles,
  dataSideContentStyles,
  mapCardDataPeriodContainerStyles,
  mapCardTitleContainerStyles,
  mapCardTitleIconStyles,
  mapCardTitleStyles,
  mapSideContainerStyles,
  periodDatesStyles,
  statusContainerStyles,
  statusDataLabelStyles,
} from './DashboardMapCard.styles';

export default function DashboardMapCard({
  period,
  statusData,
  dashboardData,
  dashboardFilters,
  setDashboardFilters,
  handleUpdateDashboard,
}) {
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { getLabelTranslation } = useLanguageSwitcher();

  const [onMousePressed, setOnMousePressed] = useState(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(undefined);
  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    setZoom(4);
  }, [statusData]);

  const defaultCenter = { lat: -23.64, lng: -52.41 };

  const navigate = useNavigate();

  const handleNavigate = route => {
    navigate(route);
  };

  return (
    <GDMContainer sx={dashboardMapCardStyles}>
      <GDMContainer sx={dashboardMapCardHeaderStyles}>
        <GDMContainer sx={mapCardTitleContainerStyles}>
          <PublicOutlined sx={mapCardTitleIconStyles(theme)} />

          <GDMTypography sx={mapCardTitleStyles}>
            {getLabelTranslation('dash_map_label')}
          </GDMTypography>
        </GDMContainer>

        <GDMContainer sx={mapCardDataPeriodContainerStyles}>
          <GDMTypography sx={dataPeriodTextStyles}>
            {getLabelTranslation(`dash_period_label`)}
            <br />
            <GDMTypography sx={periodDatesStyles(theme)}>
              {`${period.startDate} - ${period.finalDate}`}
            </GDMTypography>
          </GDMTypography>
        </GDMContainer>
      </GDMContainer>

      <GDMContainer sx={dashboardMapColumnsContainerStyles}>
        <GDMContainer sx={mapSideContainerStyles}>
          <DashboardMap
            theme={theme}
            zoom={zoom}
            center={!onMousePressed ? center : null}
            dataSource={dashboardData.recommendations}
            streetViewControl={false}
            defaultCenter={defaultCenter}
            onZoomChanged={e => setZoom(e.detail.zoom)}
            onMousemove={() => setOnMousePressed(true)}
            onMouseover={() => setOnMousePressed(false)}
            onCenterChanged={e => setCenter(e.detail.center)}
            handleNavigate={handleNavigate}
          />

          {isDesktop && (
            <>
              <DashboardRecommendationButtons
                dashboardFilters={dashboardFilters}
                getLabelTranslation={getLabelTranslation}
                handleUpdateDashboard={handleUpdateDashboard}
                setDashboardFilters={setDashboardFilters}
                statusData={statusData}
                theme={theme}
                mode="button-group"
              />

              <DashboardRecommendationLinkButton
                dashboardFilters={dashboardFilters}
                label={getLabelTranslation('dash_view_recommendation_list')}
                navigate={navigate}
                theme={theme}
              />
            </>
          )}
        </GDMContainer>

        <GDMContainer sx={dataSideContainerStyles}>
          <GDMContainer sx={dataSideContentStyles}>
            {statusData?.map(status => {
              return (
                <GDMContainer key={status.id} sx={statusContainerStyles}>
                  <DashboardStatusDataCircles
                    theme={theme}
                    topCicleText={`${!!status.value ? Math.trunc(status.value) : '0'}`}
                    bottomCircleText={`${!!status.percent ? Math.round(status.percent) : '0'}%`}
                  />
                  <GDMTypography sx={statusDataLabelStyles(theme)}>
                    {getLabelTranslation(`status_${status?.name.toLowerCase()}`)}
                  </GDMTypography>
                </GDMContainer>
              );
            })}
          </GDMContainer>

          {!isDesktop && (
            <>
              <DashboardRecommendationButtons
                dashboardFilters={dashboardFilters}
                getLabelTranslation={getLabelTranslation}
                handleUpdateDashboard={handleUpdateDashboard}
                setDashboardFilters={setDashboardFilters}
                statusData={statusData}
                theme={theme}
                mode="button-flex-grid"
              />
              <DashboardRecommendationLinkButton
                dashboardFilters={dashboardFilters}
                label={getLabelTranslation('dash_view_recommendation_list')}
                navigate={navigate}
                theme={theme}
              />
            </>
          )}
        </GDMContainer>
      </GDMContainer>
    </GDMContainer>
  );
}
