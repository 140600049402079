import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const mapContainerStyles: SxProps = {
  height: {
    xs: '550px',
    sm: '550px',
    md: '720px',
    lg: '720px',
    xl: '720px',
  },
  width: '100%',

  '& .gm-style-iw[role="dialog"]': {
    width: '272px!important',
    maxWidth: '272px!important',
    maxHeight: '180px!important',
    paddingLeft: '16px!important',
    left: '136px',
    top: '1px',
  },
  '& .gm-style-iw-ch': {
    paddingTop: '16px!important',
  },
  '& .gm-style-iw-tc': {
    height: '12px',
    left: '3.5px',
    top: '0',
    transform: 'translateX(-50%)',
    width: '6px',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, .3)',
    ':after': {
      height: '24px',
      left: '-0.5px',
      top: '-6px',
      width: '20px',
      background: '#fff',
      mask: 'conic-gradient(from -0deg at 0% calc(200% - 3px), #000 60deg, #0000 0) 0 20% / 100% calc(100% - 3px) no-repeat, radial-gradient(1.65px at 1.65px 14px, #000 100%, #0000 100%)',
      clipPath: 'polygon(00% 0, 00% 100%, 100% 10%)',
      borderRadius: '0 0 4px 4px',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, .3)',
    },
  },
  '& .gdm-markers-tree-content .gm-style-iw-c': {
    paddingRight: '0px!important',
    paddingBottom: '0px!important',
  },
  '& .gdm-markers-tree-content .gm-style-iw-d': {
    paddingRight: '16px!important',
    paddingBottom: '16px!important',
  },
};

export const mapStyles: Partial<CSSProperties> = {
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  overflow: 'hidden',
};
