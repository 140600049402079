import { RegionsServices } from 'core/api/services/regions';
import { loadRegions, loadRegionsDone, loadRegionsFail } from 'core/store/stores';

export type RegionsProp = {
  countryId: string;
};

const execute = async ({ countryId }: RegionsProp): Promise<void> => {
  const errorCallback = () => {
    loadRegionsFail();
  };

  loadRegions();

  return RegionsServices.getRegionsUseCase({ countryId })
    .then(({ data }) => {
      loadRegionsDone(data);
    })
    .catch(errorCallback);
};

const LoadRegionsUseCase = {
  execute,
};

export default LoadRegionsUseCase;
