import { useState } from 'react';

import colors from 'core/design-system/style-guide/colors.json';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { GDMCollapse, GDMListItem, GDMListItemIcon, GDMListItemText, GDMList } from 'components';
import { GDMContainer } from 'components/gdm-container';

import { GDMCollapsibleMenuComponent } from './GDMCollapsibleMenu.type';

/* TODO: Implement component using GDM default design */
const GDMCollapsibleMenu: GDMCollapsibleMenuComponent = ({ icon, title, children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <GDMListItem {...{ button: true }} onClick={handleClick}>
        {icon && (
          <GDMListItemIcon style={{ color: colors.white, fontWeight: 700, minWidth: '2.275rem' }}>
            {icon}
          </GDMListItemIcon>
        )}
        <GDMListItemText primaryTypographyProps={{ variant: 'body2' }} primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </GDMListItem>
      <GDMCollapse in={open} timeout="auto" unmountOnExit>
        <GDMContainer
          sx={{ borderLeft: `1px solid ${colors.white}`, marginLeft: '24px', paddingLeft: '16px' }}
        >
          <GDMList component="div" disablePadding>
            {children}
          </GDMList>
        </GDMContainer>
      </GDMCollapse>
    </>
  );
};

export default GDMCollapsibleMenu;
