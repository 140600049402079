import { GDMButton } from 'components';
import { DashboardRecommendationLinkButtonComponent } from './DashboardRecommendationLinkButton.type';
import { recommendationLinkStyles } from './DashboardRecommendationLinkButton.styles';

const DashboardRecommendationLinkButton: DashboardRecommendationLinkButtonComponent = ({
  label,
  dashboardFilters,
  navigate,
  theme,
}) => {
  const handleClick = () => {
    sessionStorage.setItem('recommendationFilters', JSON.stringify(dashboardFilters));
    navigate('/recommendation-list');
  };

  return (
    <GDMButton onClick={handleClick} variant="text" sx={recommendationLinkStyles(theme)}>
      {label}
    </GDMButton>
  );
};

export default DashboardRecommendationLinkButton;
