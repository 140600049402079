import { useState, useEffect, useCallback } from 'react';
import { GDMContainer } from 'components/gdm-container';
import { GDMLoading } from 'components/gdm-loading';
import { SasTokenService } from 'core/api/services/sas-token';
import { GDMLink } from 'components/gdm-link';
import { GDMSecureLinkComponent } from './GDMSecureLink.type';

const GDMSecureLink: GDMSecureLinkComponent = ({ href, sx, children, ...rest }) => {
  const [expiration, setExpiration] = useState<Date | null>(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (href) {
      setLinkUrl(href);
      const newExpiration = extractExpirationFromUrl(href);
      setExpiration(newExpiration);
    }
  }, [href]);

  const extractExpirationFromUrl = (url: string): Date | null => {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const expirationParam = urlParams.get('se');
    return expirationParam ? new Date(decodeURIComponent(expirationParam)) : null;
  };

  const fetchNewToken = async (): Promise<{ newUrl: string; newExpiration: Date }> => {
    try {
      const newUrl = await SasTokenService.getSasToken(linkUrl);
      const newExpiration = extractExpirationFromUrl(newUrl);
      if (!newExpiration) {
        return { newUrl, newExpiration };
      }

      return { newUrl, newExpiration };
    } catch (error) {
      console.error('Failed to fetch new token', error);
      throw error;
    }
  };

  const checkAndRefreshToken = useCallback(async () => {
    if (linkUrl) {
      const now = new Date();
      if (!expiration || expiration.getTime() - now.getTime() <= 120000) {
        try {
          setIsLoading(true);
          const { newUrl, newExpiration } = await fetchNewToken();
          setLinkUrl(newUrl);
          setExpiration(newExpiration);
        } catch (error) {
          console.error('Error refreshing token:', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [linkUrl, expiration]);

  useEffect(() => {
    const interval = setInterval(checkAndRefreshToken, 300000); // Check every 5 minutes
    return () => clearInterval(interval);
  }, [checkAndRefreshToken]);

  useEffect(() => {
    checkAndRefreshToken();
  }, [checkAndRefreshToken]);

  if (isLoading) {
    return (
      <GDMContainer
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GDMLoading />
      </GDMContainer>
    );
  }

  return (
    <GDMLink href={linkUrl} sx={sx} {...rest}>
      {children}
    </GDMLink>
  );
};

export default GDMSecureLink;
