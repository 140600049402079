import { GetDocumentMaskInterface } from './getDocumentMask.types';

export const getDocumentMask = ({ entityType, countryOfUser }: GetDocumentMaskInterface) => {
  if (entityType === 'Administrador' && countryOfUser === 'Brasil') {
    return '999.999.999-99';
  }
  if (countryOfUser === 'Argentina') {
    return '99-99999999-9';
  }
  return '';
};
