/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
import { useCallback, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import useThemeSwitcher from 'core/hooks/useThemeSwitcher';

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from '@mui/material';

import { GDMButton, GDMContainer, GDMTextField, GDMTypography } from 'components';
import GDMDatePicker from 'components/gdm-date-picker/GDMDatePicker';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { FaCircleInfo } from 'react-icons/fa6';
import * as GDMPanelFieldType from './GDMPanelField.type';

/* TODO: Implement component using GDM default design */
const GDMPanelField: GDMPanelFieldType.GDMPanelFieldComponent = (
  {
    id,
    parameterName,
    icon,
    title,
    placeholder,
    type,
    maxValue,
    options,
    defaultValue,
    minValue,
    inputLegend,
    inputRuleLegend,
    handleFieldChange,
    disabled = false,
    isReadOnly = false,
    updateButtonRef,
  }: GDMPanelFieldType.GDMPanelFieldProps,
  ...props
) => {
  const { theme } = useThemeSwitcher();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [isUpButtonEnabled, setIsUpButtonEnabled] = useState(
    defaultValue && maxValue && !Number.isNaN(defaultValue) && !Number.isNaN(maxValue)
      ? Number(defaultValue) < Number(maxValue)
      : true,
  );
  const [isDownButtonEnabled, setDownButtonEnabled] = useState(
    defaultValue && minValue && !Number.isNaN(defaultValue) && !Number.isNaN(minValue)
      ? Number(defaultValue) > Number(minValue)
      : true,
  );
  const [inputValue, setInputValue] = useState(defaultValue ?? null);
  const [open, setOpen] = useState(null);

  const validateInputValue = useCallback(
    (value, mustUpdate = false) => {
      if (Number.isNaN(value ?? inputValue)) {
        setInputValue(minValue);
        setDownButtonEnabled(true);
        setIsUpButtonEnabled(false);
        handleFieldChange(parameterName, minValue);
      } else if (Number(value ?? inputValue) > maxValue) {
        setInputValue(Number(maxValue));
        handleFieldChange(parameterName, maxValue);
      } else if (Number(value ?? inputValue) < Number(minValue)) {
        setInputValue(Number(minValue));
        handleFieldChange(parameterName, minValue);
      }
      setTimeout(() => {
        if (mustUpdate && updateButtonRef) updateButtonRef.click();
      }, 100);
    },
    [inputValue, minValue, maxValue, parameterName, setInputValue, handleFieldChange],
  );

  const handleChangeValue = newValue => {
    if (!Number.isNaN(newValue)) {
      setIsUpButtonEnabled(Number(newValue) < Number(maxValue));
      setDownButtonEnabled(Number(newValue) > Number(minValue));
    }
    setInputValue(newValue);
    handleFieldChange(parameterName, newValue);
  };

  const handleIncrease = () => {
    handleChangeValue(Number(inputValue) + 1);
    validateInputValue(Number(inputValue) + 1);
  };

  const handleDecrease = () => {
    handleChangeValue(Number(inputValue) - 1);
    validateInputValue(Number(inputValue) - 1);
  };

  const handleInputChange = event => {
    const newValue = event.target.value;
    handleChangeValue(Number(newValue.replace(/^0+/, '')));
  };

  const returnToolTipText = (param: string) => {
    const translationMap: { [key: string]: string } = {
      Argila: 'tooltip_clay',
      Arcilla: 'tooltip_clay',
      'Água no solo': 'tooltip_groundwater',
      'Presencia de Napa': 'tooltip_groundwater',
      Heterodera: 'tooltip_heterodera',
      Heteroderous: 'tooltip_heterodera',
      Nitrogênio: 'tooltip_nitrogen',
      Nitrógeno: 'tooltip_nitrogen',
      'Matéria Orgânica': 'tooltip_organic_matter',
      'Materia Orgánica': 'tooltip_organic_matter',
      Pratylenchus: 'tooltip_pratylenchusr',
      Precipitação: 'tooltip_precipitation',
      Precipitación: 'tooltip_precipitation',
      pH: 'tooltip_ph',
      Temperatura: 'tooltip_temperature',
      'Índice Ambiental': 'tooltip_ambiental_index',
    };

    const translationKey = translationMap[param];

    if (translationKey) {
      return getLabelTranslation(translationKey);
    }

    console.error('option not listed');
    return '';
  };

  const handleTooltipClose = event => {
    if (open && !event.currentTarget.contains(event.relatedTarget)) {
      setOpen(null);
    }
  };

  const handleTooltipOpen = id => {
    setOpen(id);
  };

  return (
    <GDMContainer
      component="form"
      id="panel-fields-forms"
      {...props}
      sx={{
        gap: 1,
        marginTop: 1,
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #A1A9B8',
        padding: 2,
        width: '100%',
        height: '191px',
      }}
    >
      <GDMContainer
        component="div"
        sx={{
          gap: 1,
          display: 'flex',
          alignItems: 'center',
          borderRadius: '24px',
        }}
      >
        {icon}
        <GDMTypography fontWeight={700} sx={{ fontSize: { xs: '18px', md: '18px' } }}>
          {title}
        </GDMTypography>

        {returnToolTipText(title).length > 0 && isDesktop && (
          <Tooltip
            title={returnToolTipText(title)}
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <button type="button" style={{ border: 'none', background: 'none' }}>
              <FaCircleInfo color="#A1A9B8" size={20} />
            </button>
          </Tooltip>
        )}

        {returnToolTipText(title).length > 0 && !isDesktop && (
          <Tooltip
            title={returnToolTipText(title)}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open === returnToolTipText(title)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <button
              type="button"
              style={{ border: 'none', background: 'none' }}
              onClick={() => handleTooltipOpen(returnToolTipText(title))}
              onBlur={handleTooltipClose}
            >
              <FaCircleInfo color="#A1A9B8" size={20} />
            </button>
          </Tooltip>
        )}
      </GDMContainer>

      <GDMContainer
        component="div"
        sx={{
          gap: 1,
          borderRadius: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {type === GDMPanelFieldType.GDMPanelType.SELECT && (
          <Autocomplete
            popupIcon={<IoIosArrowDown />}
            fullWidth
            options={options}
            getOptionLabel={option => option.name}
            value={options?.find(opt => opt.id === inputValue) || null}
            onChange={(_, newValue) => {
              const newValueId = newValue ? newValue.id : '';
              handleFieldChange(parameterName, newValueId);
              setInputValue(newValueId);
            }}
            disabled={disabled || isReadOnly}
            sx={{
              background: disabled ? '#F2F4F9' : '#fff',
              input: {
                border: 'none',
              },
              '.Mui-disabled': {
                background: '#F2F4F9',
              },
            }}
            renderInput={params => (
              <TextField
                {...params}
                focused={false}
                sx={{
                  input: {
                    background: 'initial',
                    border: 'none',
                  },
                }}
                placeholder={placeholder}
                variant="outlined"
              />
            )}
          />
        )}
        {type === GDMPanelFieldType.GDMPanelType.INPUT && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
              width: {
                lg: '100%',
                xl: '90%',
              },
            }}
          >
            <GDMContainer
              component="div"
              sx={{
                gap: 1,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '70%',
              }}
            >
              {maxValue && minValue && !isReadOnly && (
                <GDMButton
                  disabled={disabled || !isDownButtonEnabled}
                  onClick={handleDecrease}
                  sx={{
                    background: theme.palette.primary.main,
                    fontSize: '27px',
                    fontWeight: '400',
                    color: 'white',
                    minWidth: 'unset',
                    height: '42px',
                    width: '42px',
                    padding: '0',
                    ':hover': {
                      background: theme.palette.primary.dark,
                    },
                    ':disabled': {
                      background: '#EBEBF0',
                      color: theme.palette.common.white,
                      cursor: 'not-allowed',
                    },
                  }}
                >
                  -
                </GDMButton>
              )}
              <GDMTextField
                id={id}
                name={id}
                type="number"
                value={inputValue}
                disabled={disabled || isReadOnly}
                onChange={e => {
                  handleInputChange(e);
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const currentInput = e.currentTarget as HTMLInputElement;
                    updateButtonRef.focus();
                    updateButtonRef.blur();
                    validateInputValue(null, true);
                  }
                }}
                onBlur={e => validateInputValue(Number(e.target.value))}
                sx={{
                  flex: 1,
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                    fontSize: '32px',
                    fontWeight: 700,
                    padding: 0,
                    textAlign: 'center',
                    backgroundColor: '#F2F4F9',
                    borderRadius: '8px',
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  inputProps: {
                    min: minValue,
                    max: maxValue,
                  },
                }}
              />
              {maxValue && minValue && !isReadOnly && (
                <GDMButton
                  disabled={disabled || !isUpButtonEnabled}
                  onClick={handleIncrease}
                  sx={{
                    background: theme.palette.primary.main,
                    fontSize: '27px',
                    fontWeight: '400',
                    color: 'white',
                    minWidth: 'unset',
                    height: '42px',
                    width: '42px',
                    padding: '0',
                    ':hover': {
                      background: theme.palette.primary.dark,
                    },
                    ':disabled': {
                      background: '#EBEBF0',
                      color: theme.palette.common.white,
                      cursor: 'not-allowed',
                    },
                  }}
                >
                  +
                </GDMButton>
              )}
            </GDMContainer>
            {inputLegend && (
              <Typography fontWeight={700} fontSize={16} lineHeight="20px" height="20%">
                {inputLegend}
              </Typography>
            )}
            {inputRuleLegend && (
              <Typography
                fontWeight={400}
                fontSize={12}
                lineHeight="16px"
                color="#777D88"
                height="10%"
              >
                {inputRuleLegend}
              </Typography>
            )}
          </GDMContainer>
        )}
        {type === GDMPanelFieldType.GDMPanelType.RADIO && (
          <GDMContainer
            component="div"
            sx={{
              gap: 1,
              marginTop: 1,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={defaultValue}
                name={`${id}-buttons-group`}
                onChange={e => {
                  handleFieldChange(parameterName, e.target.value);
                  setInputValue(e.target.value);
                }}
                value={inputValue}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    height: '24px',
                  },
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                    color: '#777D88',
                  },
                }}
              >
                {options?.map(item => (
                  <FormControlLabel
                    key={item.id}
                    value={item.id}
                    control={
                      <Radio
                        disabled={isReadOnly}
                        sx={{
                          color: '#777D88',
                          '& .MuiSvgIcon-root': { fontSize: '14px' },
                          '&.Mui-checked': {
                            color: '#43ACE3',
                          },
                          '&.Mui-checked .MuiSvgIcon-root': {
                            color: '#43ACE3',
                          },
                        }}
                      />
                    }
                    label={item.name
                      .replace('yes', getLabelTranslation('yes'))
                      .replace('no', getLabelTranslation('no'))}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </GDMContainer>
        )}
        {type === GDMPanelFieldType.GDMPanelType.DATE && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
              width: {
                lg: '100%',
                xl: '90%',
              },
            }}
          >
            <GDMTypography
              variant="body2"
              textAlign="center"
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '70%',
              }}
            >
              <GDMDatePicker
                defaultValue={defaultValue}
                minDate={minValue}
                maxDate={maxValue}
                disabled={disabled}
                onChange={e => {
                  handleFieldChange(parameterName, e.format('DD/MM/YYYY'));
                }}
              />
            </GDMTypography>
            {inputRuleLegend && (
              <Typography
                fontWeight={400}
                fontSize={12}
                lineHeight="16px"
                color="#777D88"
                height="10%"
              >
                {inputRuleLegend}
              </Typography>
            )}
          </GDMContainer>
        )}
        {type === GDMPanelFieldType.GDMPanelType.STRING && (
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              gap: '8px',
              width: {
                lg: '100%',
                xl: '90%',
              },
            }}
          >
            <GDMTypography
              id={id}
              sx={{
                MozAppearance: 'textfield',
                fontSize: '32px',
                fontWeight: 700,
                textAlign: 'center',
                backgroundColor: Number.isNaN(Number(inputValue)) ? '' : '#F2F4F9',
                borderRadius: '8px',
                padding: '4px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                },
              }}
            >
              {['heterodera', 'prati', 'irrigated', 'lowlands', 'groundwater'].includes(id) &&
              inputValue
                ? getLabelTranslation(inputValue)
                : inputValue}
            </GDMTypography>
            {inputLegend && (
              <Typography fontWeight={700} fontSize={16} lineHeight="20px" height="20%">
                {inputLegend}
              </Typography>
            )}
          </GDMContainer>
        )}
      </GDMContainer>
    </GDMContainer>
  );
};

export default GDMPanelField;
