/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import InputMask from 'react-input-mask';

import { inputMaskStyle } from './InputDocumentMask.style';

export default function InputDocumentMask({ value, onChange, ...props }) {
  const [mask, setMask] = React.useState(null);

  const inputMaskCommonStyle = {
    ...inputMaskStyle,
    background: props.isDisabled ? '#F2F4F9' : inputMaskStyle.background,
    color: props.isDisabled ? '#A1A9B8' : inputMaskStyle.color,
  };

  const handleChange = ev => {
    const { value } = ev.target;
    const nums = value.replace(/[^0-9]/g, '');
    if (props.countryName === 'Argentina') {
      setMask('99-99999999-9');
    }
    if (value.length === 3) {
      setMask(value[2] === '.' ? '99.999.999/9999-99' : '999.999.999-99');
    }
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
    onChange(ev);
  };
  return (
    <InputMask
      style={inputMaskCommonStyle}
      mask={mask}
      onChange={handleChange}
      maskChar="_"
      value={value}
      {...props}
    />
  );
}
