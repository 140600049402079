/* eslint-disable jsx-a11y/control-has-associated-label */
import { Avatar, FormControlLabel, Radio, Tooltip, useMediaQuery, Zoom } from '@mui/material';
import { GDMContainer } from 'components';
import { GDMTypography } from 'components/gdm-typography';
import { RecommendationService } from 'core/api/services/recommendation';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { ApiResponse } from 'pages/varieties-to-compare/VarietiesToCompare.types';
import { useEffect, useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { IoEyeOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../core/design-system/style-guide/colors.json';

import { ReactComponent as Recomm1 } from '../../../../assets/images/recomm_1.svg';
import { ReactComponent as Recomm2 } from '../../../../assets/images/recomm_2.svg';
import { ReactComponent as Recomm3 } from '../../../../assets/images/recomm_3.svg';
import { IGDMVarietyPanel } from './GDMVarietyPanel.type';

const recommendationRatingIcons = [Recomm1, Recomm2, Recomm3];

const CustomRatingIcon = ({ index }) => {
  const RatingIcon = recommendationRatingIcons[index];

  return <RatingIcon style={{ width: '100%', height: '100%' }} />;
};

const GDMVarietyPanel = ({ item, index, varietyValue, disabled = false }: IGDMVarietyPanel) => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleTooltipClose = event => {
    if (open && !event.currentTarget.contains(event.relatedTarget)) {
      setOpen(null);
    }
  };

  const handleTooltipOpen = id => {
    setOpen(id);
  };

  const [sellingTip, setSellingTip] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const varieties = [];

        if (item.genotypeId) varieties.push(item.genotypeId);

        const getVarieties: ApiResponse = await RecommendationService.getVarietiesToCompare(
          varieties,
        );

        if (getVarieties) setSellingTip(getVarieties[0].sellingTip);
      } catch (error) {
        console.error('Erro ao buscar variedades:', error);
      }
    };

    fetchData();
  }, [item.genotypeId]);

  return (
    <GDMContainer
      key={item.id}
      component="div"
      sx={{
        gap: 1,
        my: {
          xs: 0,
          sm: 0,
          md: 2,
          lg: 2,
          xl: 2,
        },
        borderRadius: '24px',
        padding: '18px',
        border: `2px solid ${
          varietyValue === item.id ? theme.palette.primary.main : colors.light1
        }`,
        display: 'flex',
        flexDirection: 'column',
        width: {
          xs: '100%',
          sm: '100%',
          md: 'calc(97% / 2)',
          lg: 'calc(97% / 2)',
          xl: 'calc(97% / 3)',
        },
      }}
    >
      <FormControlLabel
        key={item.id}
        value={item.id}
        control={
          <Radio
            disabled={disabled}
            checked={varietyValue === item.id}
            sx={{
              marginTop: '3px',
              '& .MuiSvgIcon-root': {
                fontSize: '22px',
              },
              '&.MuiFormControlLabel-root': {
                display: 'flex',
                alignItems: 'flex-start',
              },
              '&.MuiButtonBase-root': {
                paddingTop: 0,
                paddingLeft: 0,
              },
              color: varietyValue === item.id ? theme.palette.primary.main : '#777D88',
              '&.Mui-checked': {
                color: theme.palette.primary.main,
              },
            }}
          />
        }
        label={
          <GDMContainer
            component="div"
            sx={{
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <GDMContainer component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <GDMTypography
                fontSize="14px"
                mb={1}
                fontWeight={700}
                component="div"
                color={varietyValue === item.id ? theme.palette.primary.main : '#777D88'}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {getLabelTranslation('recommendation_variety_gdm_title')}

                {sellingTip && isDesktop && (
                  <Tooltip title={sellingTip} arrow placement="top" TransitionComponent={Zoom}>
                    <button type="button" style={{ border: 'none', background: 'none' }}>
                      <FaCircleInfo color="#A1A9B8" size={24} />
                    </button>
                  </Tooltip>
                )}
                {sellingTip && !isDesktop && (
                  <Tooltip
                    title={sellingTip}
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open === sellingTip + index}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none' }}
                      onClick={() => handleTooltipOpen(sellingTip + index)}
                      onBlur={handleTooltipClose}
                    >
                      <FaCircleInfo color="#A1A9B8" size={16} />
                    </button>
                  </Tooltip>
                )}
              </GDMTypography>
              <Avatar
                alt="recommendation order icon"
                sx={{
                  width: { xs: '32px', md: '42px' },
                  height: 'auto',
                  borderRadius: 0,
                  background: 'none',
                  svg: {
                    color: varietyValue === item.id ? theme.palette.primary.main : colors.light1,
                  },
                }}
              >
                <CustomRatingIcon index={index} />
              </Avatar>
            </GDMContainer>
            <GDMContainer
              component="div"
              sx={{
                marginTop: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <GDMTypography
                fontWeight={700}
                color="#222222"
                marginLeft="-27px"
                sx={{
                  fontSize: {
                    xs: '16px',
                    sm: '16px',
                    md: '20px',
                    lg: '20px',
                    xl: '20px',
                  },
                }}
              >
                {getLabelTranslation('recommendation_variety_option')}
              </GDMTypography>

              <GDMTypography
                fontWeight={400}
                component="div"
                color={colors.black}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '37px',
                  padding: '0 16px',
                  fontSize: '20px',
                  fontWeight: 400,
                  background: varietyValue === item.id ? theme.palette.primary.light : '#CBCDD9',
                  borderRadius: '50px',
                  zIndex: 1,
                }}
              >
                <IoEyeOutline
                  size={24}
                  cursor="pointer"
                  onClick={() => navigate(`/varieties-to-compare/${item.genotypeId}`)}
                />
                {item.genotype}
              </GDMTypography>
            </GDMContainer>
            <GDMContainer
              component="div"
              sx={[
                {
                  display: !!item.nitrogen ? 'grid' : 'flex',
                  columnGap: !!item.nitrogen
                    ? {
                        xl: '92px',
                        lg: '82px',
                        md: '72px',
                        sm: '52px',
                        xs: '32px',
                      }
                    : undefined,
                  gap: !item.nitrogen && isDesktop ? '18px' : '6px',
                  gridTemplateColumns: 'auto 1fr',
                  borderRadius: 2,
                  alignItems: 'center',
                  marginLeft: {
                    xs: !!item.nitrogen ? 0 : isDesktop ? '-18px' : '-30px',
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  justifyItems: 'center',
                  textAlign: 'center',
                },
                !item.nitrogen ? { margin: '0 auto' } : {},
              ]}
            >
              <GDMContainer
                component="div"
                sx={{
                  gap: 1,
                  marginTop: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <GDMTypography
                  fontWeight={700}
                  fontSize="16px"
                  sx={{ textWrap: 'nowrap' }}
                  component="div"
                  color="#222222"
                >
                  {getLabelTranslation('recommendation_variety_productivity')}
                </GDMTypography>

                <GDMTypography
                  fontSize="16px"
                  mb="2px"
                  fontWeight={400}
                  component="div"
                  color="#222222"
                >
                  {item.productivity
                    ? `${Number(item.productivity).toLocaleString('pt-BR', {
                        maximumFractionDigits: 2,
                      })} kg/ha`
                    : '-'}
                </GDMTypography>
              </GDMContainer>

              <GDMContainer
                component="div"
                sx={{
                  gap: 1,
                  marginTop: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <GDMTypography
                  fontWeight={700}
                  fontSize="16px"
                  component="div"
                  sx={{ textWrap: 'nowrap' }}
                  color="#222222"
                >
                  {getLabelTranslation('recommendation_variety_density')}
                </GDMTypography>

                <GDMTypography fontWeight={400} component="div" fontSize="16px" color="#222222">
                  {item.density ? `${item.density} pl/m²` : '-'}
                </GDMTypography>
              </GDMContainer>

              {!!item.nitrogen && (
                <GDMContainer
                  component="div"
                  sx={{
                    gap: 1,
                    marginTop: 1,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <GDMTypography
                    fontWeight={700}
                    fontSize="16px"
                    component="div"
                    sx={{ textWrap: 'nowrap' }}
                    color="#222222"
                  >
                    {getLabelTranslation('recommendation_variety_nitrogen')}
                  </GDMTypography>

                  <GDMTypography fontSize="16px" fontWeight={400} component="div" color="#222222">
                    {`${item.nitrogen || 0} kg/ha`}
                  </GDMTypography>
                </GDMContainer>
              )}

              <GDMContainer
                component="div"
                sx={{
                  gap: 1,
                  marginTop: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <GDMTypography
                  fontSize="16px"
                  fontWeight={700}
                  component="div"
                  color="#222222"
                  sx={{ textWrap: 'nowrap' }}
                >
                  {getLabelTranslation('recommendation_variety_planting_date')}
                </GDMTypography>

                <GDMTypography fontSize="16px" fontWeight={400} component="div" color="#222222">
                  {getLabelTranslation(item.sowingDate)}
                </GDMTypography>
              </GDMContainer>
            </GDMContainer>
          </GDMContainer>
        }
      />
    </GDMContainer>
  );
};

export default GDMVarietyPanel;
