import { Controller } from 'react-hook-form';
import { IoIosArrowDown } from 'react-icons/io';

import InputDocumentMask from 'core/components/InputDocumentMask/InputDocumentMask';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useDisabled } from 'core/store/contexts/disabledInputsContext';
import CountriesStore from 'core/store/stores/CountriesStore/CountriesStore';
import RegionsStore from 'core/store/stores/RegionsStore/RegionsStore';
import RolesStore from 'core/store/stores/RolesStore/RolesStore';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';

import { Chip, FormControlLabel, OutlinedInput } from '@mui/material';

import CustomInputLabel from 'components/gdm-custom-input-label/GDMCustomInputLabel';

import {
  GDMContainer,
  GDMFormControl,
  GDMFormHelperText,
  GDMInputCheckbox,
  GDMInputMask,
  GDMMenuItem,
  GDMSelect,
  GDMTextField,
  GDMTypography,
} from 'components';
import {
  checkboxButtonsContainer,
  formCard,
  formContainer,
  formFlexRowStyle,
  inputMaskStyle,
  pageNameStyle,
} from '../Profile.style';
import UserProfileActions from '../UserProfileActions/UserProfileActions';

export default function UserProfileForm({ control, errors, register, handleChangePassword }) {
  const { user } = useStore(UserProfileStore);
  const { countries } = useStore(CountriesStore);
  const { roles = [] } = useStore(RolesStore);
  const { regions } = useStore(RegionsStore);
  const { isDisabled } = useDisabled();

  const { getLabelTranslation } = useLanguageSwitcher();

  const inputMaskCommonStyle = {
    ...inputMaskStyle,
    background: isDisabled ? '#F2F4F9' : inputMaskStyle.background,
    color: isDisabled ? '#A1A9B8' : inputMaskStyle.color,
  };

  const userRole = roles?.find(role => role?.name === user.role);
  const userTranslationRoleValue =
    userRole?.translations?.map(translation => translation.value) || [];
  return (
    <GDMContainer sx={formCard}>
      <GDMContainer sx={formContainer}>
        <GDMTypography sx={pageNameStyle}>{getLabelTranslation('profile')}</GDMTypography>
        {!['ADMINISTRATOR', 'SELLER_GDM'].includes(user.role) && (
          <GDMContainer width={263}>
            <CustomInputLabel
              label={userTranslationRoleValue[0] || ''}
              fontSize="16px"
              color="#222222"
            />
            <Controller
              name="role"
              disabled
              control={control}
              rules={{ required: true }}
              {...register('role')}
              render={({ field }) => (
                <GDMTextField
                  variant="outlined"
                  error={!!errors.role}
                  {...field}
                  sx={{
                    '.Mui-disabled': {
                      background: '#F2F4F9',
                      padding: '11.5px 6px',
                    },
                  }}
                  value={userTranslationRoleValue[0] || ''}
                />
              )}
            />
          </GDMContainer>
        )}

        <GDMContainer>
          <CustomInputLabel
            label={getLabelTranslation('fullname')}
            fontSize="16px"
            color="#222222"
          />
          <Controller
            name="name"
            disabled={isDisabled}
            control={control}
            rules={{ required: true }}
            defaultValue={user.name}
            {...register('name')}
            render={({ field }) => (
              <GDMTextField
                placeholder={getLabelTranslation('fullname')}
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
                {...field}
                sx={{
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                    padding: '11.5px 6px',
                  },
                }}
              />
            )}
          />
        </GDMContainer>

        <GDMContainer>
          <CustomInputLabel label={getLabelTranslation('email')} fontSize="16px" color="#222222" />
          <Controller
            name="email"
            disabled
            control={control}
            rules={{ required: true }}
            defaultValue={user.email}
            {...register('email')}
            render={({ field }) => (
              <GDMTextField
                placeholder={getLabelTranslation('email')}
                variant="outlined"
                fullWidth
                error={!!errors.email}
                {...field}
                sx={{
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                    padding: '11.5px 6px',
                  },
                }}
              />
            )}
          />
        </GDMContainer>

        <GDMContainer sx={formFlexRowStyle}>
          <GDMContainer>
            <CustomInputLabel
              label={getLabelTranslation('country')}
              fontSize="16px"
              color="#222222"
            />
            <GDMFormControl>
              <Controller
                name="countryId"
                disabled
                control={control}
                rules={{ required: true }}
                defaultValue={user.countryId || ''}
                {...register('countryId')}
                render={({ field }) => (
                  <GDMSelect
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#A1A9B8',
                      '.Mui-disabled': {
                        background: '#F2F4F9',
                        padding: '11.5px 14px',
                      },
                    }}
                    IconComponent={IoIosArrowDown}
                    variant="outlined"
                    fullWidth
                    {...field}
                  >
                    {countries.map(country => (
                      <GDMMenuItem key={country.id} value={country.id}>
                        {country.name}
                      </GDMMenuItem>
                    ))}
                  </GDMSelect>
                )}
              />
            </GDMFormControl>
          </GDMContainer>
          <GDMContainer>
            <CustomInputLabel
              label={getLabelTranslation('phone')}
              fontSize="16px"
              color="#222222"
            />
            <GDMFormControl>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: true }}
                defaultValue={user.phoneNumber}
                {...register('phoneNumber')}
                render={({ field }) => (
                  <GDMInputMask
                    style={inputMaskCommonStyle}
                    mask="(+99) 99 99999-9999"
                    name="phoneNumber"
                    placeholder="Telefone"
                    disabled={isDisabled}
                    maskChar={null}
                    value={user.phoneNumber}
                    errors={errors}
                    {...field}
                  />
                )}
              />
              {!!errors?.phoneNumber && (
                <GDMFormHelperText sx={{ color: '#FB2047', position: 'absolute', top: '45px' }}>
                  {errors?.phoneNumber?.message}
                </GDMFormHelperText>
              )}
            </GDMFormControl>
          </GDMContainer>
          <GDMContainer>
            <CustomInputLabel
              label={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
              fontSize="16px"
              color="#222222"
            />
            <GDMFormControl>
              <Controller
                name="documentNumber"
                control={control}
                rules={{ required: true }}
                defaultValue={user.documentNumber}
                {...register('documentNumber')}
                render={({ field }) => (
                  <InputDocumentMask
                    style={inputMaskCommonStyle}
                    name="documentNumber"
                    placeholder={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
                    maskChar={null}
                    countryName={user.countryName}
                    value={user.documentNumber}
                    {...field}
                  />
                )}
              />
              {!!errors?.documentNumber && (
                <GDMFormHelperText sx={{ color: '#FB2047', position: 'absolute', top: '45px' }}>
                  {errors?.documentNumber?.message}
                </GDMFormHelperText>
              )}
            </GDMFormControl>

            <input
              hidden
              name="documentTypeId"
              {...register('documentTypeId')}
              value={user.documentTypeId}
            />
          </GDMContainer>
        </GDMContainer>

        <GDMContainer sx={formFlexRowStyle}>
          {!['ADMINISTRATOR'].includes(user.role) && (
            <>
              {user.documentTypeAcronym !== 'CPF' && (
                <GDMContainer>
                  <CustomInputLabel label="Nome fantasia" fontSize="16px" color="#222222" />
                  <Controller
                    name="fantasyName"
                    disabled={isDisabled}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={user.fantasyName}
                    {...register('fantasyName')}
                    render={({ field }) => (
                      <GDMTextField
                        placeholder="Nome fantasia"
                        variant="outlined"
                        fullWidth
                        error={!!errors.fantasyName}
                        {...field}
                      />
                    )}
                  />
                </GDMContainer>
              )}

              <GDMContainer>
                <CustomInputLabel label="Marcas" fontSize="16px" color="#222222" />
                <Controller
                  name="brands"
                  disabled
                  control={control}
                  rules={{ required: true }}
                  {...register('brands')}
                  render={({ field }) => (
                    <GDMSelect
                      readOnly
                      sx={{
                        '.Mui-disabled': {
                          background: '#F2F4F9',
                        },
                      }}
                      multiple
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={() => {
                        return user.brands.map(brand => (
                          <Chip key={brand.id} label={brand.name} size="medium" />
                        ));
                      }}
                      {...field}
                    />
                  )}
                />
              </GDMContainer>
            </>
          )}

          {['SELLER_GDM'].includes(user.role) && (
            <GDMContainer>
              <CustomInputLabel label="Região" fontSize="16px" color="#222222" />
              <Controller
                name="region"
                disabled={isDisabled}
                control={control}
                rules={{ required: true }}
                defaultValue={user.regionId}
                {...register('regionId')}
                render={({ field }) => (
                  <GDMSelect
                    sx={{
                      fontSize: '14px',
                      marginTop: '5px',
                      height: '48px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#A1A9B8',
                      '.Mui-disabled': {
                        background: '#F2F4F9',
                      },
                      '.MuiOutlinedInput-notchedOutline	': {
                        legend: {
                          display: 'none',
                        },
                      },
                    }}
                    IconComponent={IoIosArrowDown}
                    variant="outlined"
                    fullWidth
                    error={!!errors.regionId}
                    {...field}
                  >
                    {regions &&
                      regions.length > 0 &&
                      regions.map(region => (
                        <GDMMenuItem key={region.id} value={region.id}>
                          {region.name}
                        </GDMMenuItem>
                      ))}
                  </GDMSelect>
                )}
              />
            </GDMContainer>
          )}
        </GDMContainer>

        <GDMContainer style={checkboxButtonsContainer}>
          <GDMContainer>
            <GDMFormControl>
              <Controller
                name="emailReceiptAccepted"
                control={control}
                defaultValue={user.emailReceiptAccepted}
                render={({ field: { onChange, value, ...restField } }) => (
                  <FormControlLabel
                    label={getLabelTranslation(
                      'user_acceptance_of_receiving_seedguide_emails_profile_label',
                    )}
                    control={
                      <GDMInputCheckbox
                        {...restField}
                        sx={{
                          '.Mui-disabled': {
                            background: '#F2F4F9',
                            padding: '11.5px 6px',
                          },
                        }}
                        disabled={isDisabled}
                        checked={value}
                        onChange={onChange}
                      />
                    }
                  />
                )}
              />
            </GDMFormControl>
          </GDMContainer>
          <UserProfileActions handleChangePassword={handleChangePassword} />
        </GDMContainer>
      </GDMContainer>
    </GDMContainer>
  );
}
