/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useMsal } from '@azure/msal-react';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { GDMFeedbackModal } from 'components';
import LoadCountriesUseCase from 'core/api/useCases/CountriesUseCase/LoadCountriesUseCase';
import LoadRolesServicesUseCase from 'core/api/useCases/RolesUseCase/LoadRolesUseCase';
import LoadUserProfileUseCase from 'core/api/useCases/UserProfileUseCase/LoadUserProfileUseCase';
import { ThemeContextProvider } from 'core/design-system/styles/ThemeContext';
import { useAuth } from 'core/hooks/useAuth';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { DisabledProvider } from 'core/store/contexts/disabledInputsContext';
import { LanguageContextProvider } from 'core/store/contexts/languageContext';
import { RecommendationContextProvider } from 'core/store/contexts/recommendationContext';
import { RouteProvider } from 'core/store/contexts/routerContext';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { ModalProvider } from 'hooks/useModal/useModal';
import { useEffect } from 'react';
import { setKey } from 'react-geocode';
import { BrowserRouter } from 'react-router-dom';
import { loginRequest } from './authConfig';
import { Routes } from './core/router/Routes';

export default function App() {
  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  const { instance, isInitialized } = useAuth();
  const { accounts } = useMsal();
  const { user } = useStore(UserProfileStore);
  const { switchTheme } = useThemeSwitcher();
  const userRole = user?.role;

  useEffect(() => {
    if (userRole === 'ADMINISTRATOR') {
      switchTheme('GDM');
      localStorage.setItem('theme_recomendador', 'GDM');
    }
  }, [userRole]);

  useEffect(() => {
    if (isInitialized && accounts.length === 0) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
      });
    } else if (accounts.length > 0) {
      LoadUserProfileUseCase.execute();
      LoadCountriesUseCase.execute();
      LoadRolesServicesUseCase.execute();
    }
  }, [accounts, instance, isInitialized]);

  if (!isInitialized || accounts.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LanguageContextProvider>
      <ThemeContextProvider>
        <RecommendationContextProvider>
          <DisabledProvider>
            <BrowserRouter>
              <CssBaseline />
              <ModalProvider>
                <RouteProvider>
                  <Routes />
                </RouteProvider>
                <GDMFeedbackModal />
              </ModalProvider>
            </BrowserRouter>
          </DisabledProvider>
        </RecommendationContextProvider>
      </ThemeContextProvider>
    </LanguageContextProvider>
  );
}
