/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';
import { ExamplePageValue } from 'core/typing/domains/exampleDomain';

const getExampleData = async (): Promise<ExamplePageValue> => {
  return Api.get({
    url: `${process.env.REACT_APP_API_HOST}/example`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};
const getAdmins = async ({
  currentPage,
  itemsPerPage,
  search = '',
  searchColumn,
  orderDesc,
  orderColumn = '',
}): Promise<any> => {
  let url = `/UsersAdministrator?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (searchColumn && searchColumn !== 'all') {
    url += `&SearchColumn=${searchColumn}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }

  return Api.get({
    url,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const upSertAdmin = async (method, body: any, id = ''): Promise<any> => {
  return Api[method]({
    url: `/UsersAdministrator/${id}`,
    body: JSON.stringify(body),
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const deleteAdmById = async (id: string): Promise<any> => {
  return Api.delete({
    url: `/UsersAdministrator/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getAdmById = async (id: string | number): Promise<any> => {
  return Api.get({
    url: `/UsersAdministrator/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const UsersAdministratorService = {
  getExampleData,
  upSertAdmin,
  getAdmById,
  getAdmins,
  deleteAdmById,
};
