import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { GDMDocumentMaskComponent } from './GDMDocumentMask.type';

const GDMInputMask: GDMDocumentMaskComponent = props => {
  const [mask, setMask] = useState('999.999.999-999');

  useEffect(() => {
    const { value, country } = props;
    if (country === CountriesDomainEnum.Argentina) {
      setMask('99-99999999-9');
      return;
    }

    if (value.replace(/\D/g, '').length > 11) {
      setMask('99.999.999/9999-99');
    } else {
      setMask('999.999.999-999');
    }
  }, [props]);

  return <InputMask mask={mask} maskChar={null} {...props} />;
};

export default GDMInputMask;
