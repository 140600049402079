import { CircularProgress, LinearProgress } from '@mui/material';

import { GDMLoadingComponent, GDMLoadingType } from './GDMLoading.type';

/* TODO: Implement component using GDM default design */
const GDMLoading: GDMLoadingComponent = ({ type, ...props }) => {
  switch (type) {
    case GDMLoadingType.LINEAR:
      return <LinearProgress {...props} />;
    case GDMLoadingType.CIRCULAR:
      return <CircularProgress {...props} />;
    default:
      return <CircularProgress {...props} />;
  }
};

export default GDMLoading;
