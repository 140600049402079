import { Dayjs } from 'dayjs';

import { DatePickerProps } from '@mui/x-date-pickers';

import { GDMComponent } from 'components/GDMComponent.type';

export enum GDMDatePickerLocale {
  PT_BR = 'pt-BR',
  ES_AR = 'es-AR',
}

export type IGDMDatePickerProps = DatePickerProps<Dayjs>;

export type IGDMDatePickerCustomProps = {
  textField?: boolean;
};

export type GDMDatePickerComponent = GDMComponent<IGDMDatePickerProps | IGDMDatePickerCustomProps>;
