import { GDMContainer, GDMTypography } from 'components';
import {
  dataLabelStyles,
  dataTextContainerStyles,
  dataValueStyles,
} from './DashboardMapModalDataText.styles';
import { DashboardMapModalDataTextComponent } from './DashboardMapModalDataText.type';

const DashboardMapModalDataText: DashboardMapModalDataTextComponent = ({ label, value }) => {
  if (!label || !value) return <></>;

  return (
    <GDMContainer sx={dataTextContainerStyles}>
      <GDMTypography sx={dataLabelStyles}>{label}</GDMTypography>{' '}
      <GDMTypography sx={dataValueStyles}>{value}</GDMTypography>
    </GDMContainer>
  );
};

export default DashboardMapModalDataText;
