import { useCallback, useEffect, useState } from 'react';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { debounce, Divider, Pagination, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { GDMContainer, GDMLoading, GDMTypography } from 'components';
import { GDMTableFilter, TableFilterColumn } from 'components/gdm-table-filter';
import { useStore } from 'effector-react';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import GDMBox from 'components/gdm-box/GDMBox';
import { UsersAdministratorService } from 'core/api/services/administrator';
import { NavigateButton } from 'core/components/NavigateButton';
import useModal from 'hooks/useModal/useModal';
import colors from '../../core/design-system/style-guide/colors.json';
import textStyles from '../../core/design-system/style-guide/typography.json';
import { AdminHeaderLabels, AdminListConfig, AdminListTableData } from './AdminListPage.type';
import { AdminListListMobile, AdminListTableDesktop } from './components';
import {
  adminListHandleCancel,
  adminListHandleDelete,
  adminListHandleOnClose,
  adminListHandleOpenModal,
} from './utils';
import { adminListChangeOrder } from './utils/admin-list-change-order';
import adminListLoadAdmins from './utils/admin-list-load-admins/adminListLoadAdmins';

const DEFAULT_ADMIN_LIST_CONFIG: AdminListConfig = {
  currentPage: 1,
  itemsPerPage: 4,
  orderDesc: false,
  search: '',
  searchColumn: 'none',
  orderColumn: '',
};

const DEFAULT_ADMIN_LIST_TABLE_DATA: AdminListTableData = {
  items: [],
  totalPages: 1,
};

const AdminListPage = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // 'md' or another breakpoint like 'lg'
  const [isLoading, setIsLoading] = useState(true);
  const [adminListTableData, setAdminListTableData] = useState<AdminListTableData>(
    DEFAULT_ADMIN_LIST_TABLE_DATA,
  );

  const [emptyMessage, setEmptyMessage] = useState<string | undefined>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const [adminListConfig, setAdminListConfig] =
    useState<AdminListConfig>(DEFAULT_ADMIN_LIST_CONFIG);

  const { user } = useStore(UserProfileStore);

  const [adminIdSelectedToDelete, setAdminIdSelectedToDelete] = useState<string | null>(null);

  const { getLabelTranslation: gTL } = useLanguageSwitcher();
  const { openModal, closeModal } = useModal();

  const columns: Array<TableFilterColumn> = [
    {
      column_name: 'all',
      column_title: gTL('all'),
      type: 'text',
    },
    {
      column_name: 'Name',
      column_title: gTL('name'),
      type: 'text',
    },
    {
      column_name: 'Email',
      column_title: gTL('email'),
      type: 'text',
    },
    {
      column_name: 'PhoneNumber',
      column_title: gTL('phone'),
      type: 'text',
    },
    {
      column_name: 'DocumentNumber',
      column_title: user.countryName === 'Brasil' ? 'CPF' : 'CUIT',
      type: 'text',
    },
    {
      column_name: 'CountryName',
      column_title: gTL('country'),
      type: 'text',
    },
  ];

  const adminHeaderLabels: AdminHeaderLabels = {
    actionsLabel: { label: gTL('actions') },
    countryNameLabel: { label: gTL('country'), name: 'CountryName' },
    documentNumberLabel: {
      label: user.countryName === 'Brasil' ? 'CPF' : 'CUIT',
      name: 'DocumentNumber',
    },
    emailLabel: { label: gTL('email'), name: 'Email' },
    nameLabel: { label: gTL('name'), name: 'Name' },
    phoneNumberLabel: { label: gTL('phone'), name: 'PhoneNumber' },
    deleteLabel: { label: gTL('delete') },
    editLabel: { label: gTL('edit') },
    seeLabel: { label: gTL('to_see') },
  };

  useEffect(() => {
    if (!isLoading && !adminListTableData.items?.length) {
      const isSearching = !!adminListConfig?.search;

      if (hasError) setEmptyMessage('error_on_load_table_data_text');
      else if (isSearching) setEmptyMessage('empty_table_on_filter_text');
      else setEmptyMessage('empty_table_text');
    }
  }, [adminListTableData.items, isLoading, adminListConfig, hasError]);

  const handleLoadAdmins = () =>
    adminListLoadAdmins({
      setIsLoading,
      adminListConfig,
      setAdminListTableData,
      setHasError,
    });

  const handleOnClose = () => adminListHandleOnClose({ setAdminIdSelectedToDelete });

  const handleCancel = () =>
    adminListHandleCancel({
      handleOnClose,
      closeModal,
    });

  const handleDelete = (adminId: string) => {
    adminListHandleDelete({
      adminId,
      gTL,
      handleOpenModal: openModal,
      handleOnDeleteAdmin: handleLoadAdmins,
    });
  };

  const handleOpenDeleteAdminModal = async (adminId: string) => {
    setAdminIdSelectedToDelete(adminId);
    try {
      const data = await UsersAdministratorService.getAdmById(adminId);

      const { canBeDeleted } = data;

      if (!canBeDeleted) {
        openModal({
          type: 'error',
          title: gTL('modal_title_delete_register_error'),
          message: gTL('modal_message_bloq_dependencies'),
          onClose: closeModal,
        });
      } else {
        adminListHandleOpenModal({
          handleCancel,
          handleOnClose,
          handleOpenModal: openModal,
          gTL,
          handleDelete,
          adminId,
        });
      }
    } catch (error) {
      openModal({
        type: 'error',
        title: gTL('modal_title_delete_register_error'),
        message: gTL('modal_message_delete_register_error'),
        onClose: closeModal,
      });
    }
  };

  useEffect(() => {
    handleLoadAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminListConfig]);

  const handleSearch = useCallback(
    debounce((search, searchColumn) => {
      const isValidQuery = typeof search === 'string' && !!search;

      const newAdminListConfig = {
        ...adminListConfig,
        searchColumn,
        currentPage: 1, // sends the user back to the first page of the searched results
        ...(isValidQuery ? { search } : {}),
      };
      if (adminListConfig !== newAdminListConfig) {
        setAdminListConfig(newAdminListConfig);

        setIsLoading(true);
      }
    }, 1000),
    [],
  );

  return (
    <GDMContainer
      sx={{
        width: '100%',
        maxHeight: '852px',
        maxWidth: '1440px',
        margin: 'auto',
      }}
    >
      <Paper
        elevation={1}
        sx={{
          padding: {
            xs: '14px',
            sm: '16px',
            md: '22px',
            lg: '34px',
            xl: '46px',
          },
          borderRadius: {
            xs: 0,
            sm: 0,
            md: '24px',
            lg: '24px',
            xl: '24px',
          },
          minHeight: '100%',
          width: '100%',
          background: '#FAFAFA',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <GDMContainer
          sx={{ display: 'flex', justifyContent: isDesktop ? 'space-between' : 'center' }}
        >
          <GDMTypography
            display="block"
            gutterBottom
            sx={{
              ...textStyles.breadcrumbs,
              color: colors.dark2,
            }}
          >
            {`${gTL('administrative')} > ${gTL('see_registrations')} > ${gTL(
              'ReusableFormKey_Administrator',
            )}`}
          </GDMTypography>
        </GDMContainer>
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: isDesktop ? 'row' : 'column',
          }}
        >
          <GDMTypography
            variant="h4"
            textAlign={isDesktop ? 'left' : 'center'}
            my={2}
            gutterBottom
            color="#222222"
            sx={textStyles.pageTitle}
          >
            {gTL('view_admin_registration')}
          </GDMTypography>
          <GDMTableFilter handleSearch={handleSearch} columns={columns} />
        </GDMContainer>

        {isLoading && (
          <GDMBox sx={{ display: 'flex', justifyContent: 'center', paddingTop: '25%' }}>
            <GDMLoading />
          </GDMBox>
        )}
        {isDesktop && !isLoading && (
          <AdminListTableDesktop
            handleOpenDeleteAdminModal={handleOpenDeleteAdminModal}
            adminIdSelectedToDelete={adminIdSelectedToDelete}
            adminHeaderLabels={adminHeaderLabels}
            changeOrder={(orderColumn: string) =>
              adminListChangeOrder({
                adminListConfig: { ...adminListConfig, orderColumn },
                setAdminListConfig,
              })
            }
            items={adminListTableData.items}
            emptyMessage={emptyMessage}
          />
        )}
        {!isDesktop && !isLoading && (
          <>
            <Divider />

            <AdminListListMobile
              adminHeaderLabels={adminHeaderLabels}
              items={adminListTableData.items}
              adminIdSelectedToDelete={adminIdSelectedToDelete}
              handleOpenDeleteAdminModal={handleOpenDeleteAdminModal}
              emptyMessage={emptyMessage}
            />
          </>
        )}
        {!isLoading && !!adminListTableData.items?.length && (
          <Pagination
            count={adminListTableData.totalPages}
            page={adminListConfig.currentPage}
            onChange={(_, currentPage) => setAdminListConfig({ ...adminListConfig, currentPage })}
            variant="outlined"
            shape="rounded"
            boundaryCount={isDesktop ? 1 : 0}
            sx={{
              mt: 2,
              justifyContent: 'center',
              display: 'flex',
              '& ul > li > button': {
                backgroundColor: colors.light2,
                width: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                height: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                border: 'none',
                ...textStyles.table.pagination,
              },
              '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
                backgroundColor: colors.dark3,
              },
            }}
          />
        )}

        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '32px',
            alignItems: 'flex-end',
            '@media (max-width: 900px)': {
              button: { width: '100%' },
            },
          }}
        >
          <NavigateButton
            label={gTL('new_register')}
            to="/admin-register"
            icon=""
            sx={{
              width: '187px',
              height: '51px',
              fontSize: '16px',
              fontWeight: 700,
            }}
          />
        </GDMContainer>
      </Paper>
    </GDMContainer>
  );
};

export default AdminListPage;
