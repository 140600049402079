import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { GDMPhoneMaskComponent } from './GDMPhoneMask.type';

const GDMInputMask: GDMPhoneMaskComponent = props => {
  const [mask, setMask] = useState('+99 99 99999-9999');

  useEffect(() => {
    const { country } = props;
    if (country === CountriesDomainEnum.Argentina) {
      setMask('+99 9 999 999-9999');
    } else {
      setMask('+99 99 99999-9999');
    }
  }, [props]);

  return <InputMask mask={mask} maskChar=" " {...props} />;
};

export default GDMInputMask;
