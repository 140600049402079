import { useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Box } from '@mui/material';

import colors from '../../design-system/style-guide/colors.json';

export function CollapsibleMenu({ icon, title, children }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        {icon && (
          <ListItemIcon style={{ color: colors.white, fontWeight: 700, minWidth: '2.275rem' }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{ borderLeft: `1px solid ${colors.white}`, marginLeft: '24px', paddingLeft: '16px' }}
        >
          <List component="div" disablePadding>
            {children}
          </List>
        </Box>
      </Collapse>
    </>
  );
}
