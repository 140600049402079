import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavigateButton } from 'core/components/NavigateButton';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { PiFarm } from 'react-icons/pi';
import { RiPlantLine } from 'react-icons/ri';
import { buttonsContainer, containerStyle } from './ShowFarmerRegisters.style';

const ShowFarmerRegisters = () => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // 'md' or another breakpoint like 'lg'

  const buttonsData = [
    {
      label: getLabelTranslation('farmer'),
      icon: <RiPlantLine color={theme.palette.primary.main}/>,
      link: '/farmer-list',
    },
    {
      label: getLabelTranslation('farm'),
      icon: <PiFarm color={theme.palette.primary.main}/>,
      link: '/farmer-farm-list',
    },
    {
      label: getLabelTranslation('lot'),
      icon: <HiOutlineSquares2X2 color={theme.palette.primary.main}/>,
      link: '/farmer-lot-list',
    },
  ];

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          'only screen and (max-width: 850px)': { justifyContent: 'center' },
        }}
      >
        <Typography sx={{ color: '#A1A9B8', fontSize: '14px' }} display="block" gutterBottom>
          {`${getLabelTranslation('farmer')} > ${getLabelTranslation('see_register')}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: isDesktop ? 'row' : 'column',
          marginBottom: '20px',
          marginTop: '12px',
        }}
      >
        <Typography
          variant="h4"
          textAlign={isDesktop ? 'left' : 'center'}
          my={isDesktop ? 0 : 2}
          sx={{ fontWeight: '700' }}
          gutterBottom
        >
          {getLabelTranslation('see_register')}
        </Typography>
      </Box>
      <Divider style={{ width: '100%', marginTop: isDesktop ? '24px' : 0, marginBottom: '24px' }} />
      <Box sx={{ marginTop: '12px' }}>
        <Typography
          variant="h5"
          textAlign="center"
          gutterBottom
          sx={{ fontWeight: '700', paddingBottom: '40px' }}
        >
          {getLabelTranslation('select_one_of_the_options_below_to_continue')}
        </Typography>
        <Box sx={buttonsContainer}>
          {buttonsData.map(button => (
            <NavigateButton
              icon={button.icon}
              label={button.label}
              key={button.label}
              to={button.link}
              sx={{borderColor: theme.palette.primary.main}}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ShowFarmerRegisters;
