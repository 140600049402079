import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useDisabled } from 'core/store/contexts/disabledInputsContext';

import { Box, Button, useTheme } from '@mui/material';

import LoadUserProfileUseCase from 'core/api/useCases/UserProfileUseCase/LoadUserProfileUseCase';
import useModal from 'hooks/useModal/useModal';
import { actionsContainer, internalActionsContainer } from '../Profile.style';

export default function UserProfileActions({ handleChangePassword }) {
  const theme = useTheme();
  const { isDisabled, setIsDisabled } = useDisabled();
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
    setIsDisabled(true);
  };

  const handleEditUserProfile = e => {
    e.preventDefault();
    setIsDisabled(false);
  };

  const handleCloseModal = async () => {
    setIsDisabled(true);

    try {
      await LoadUserProfileUseCase.execute();
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
    }
  };

  const handleCancelEditUserProfile = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_warning_title'),
      message: getLabelTranslation('modal_warning_message'),
      primaryAction: {
        label: getLabelTranslation('ok'),
        action: () => {
          handleCloseModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('close'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const { getLabelTranslation } = useLanguageSwitcher();

  return (
    <Box sx={actionsContainer}>
      <Button
        variant="outlined"
        onClick={handleGoBack}
        sx={{ color: theme.palette.secondary.dark }}
      >
        <FaArrowLeftLong style={{ marginRight: '8px' }} />
        {getLabelTranslation('back')}
      </Button>
      <Box sx={internalActionsContainer}>
        {isDisabled ? (
          <Button
            variant="contained"
            sx={{
              boxShadow: 'none',
              color: theme.palette.secondary.dark,
              background: theme.palette.secondary.main,
              ':hover': {
                boxShadow: 'none',
                background: theme.palette.secondary.light,
              },
            }}
            onClick={handleChangePassword}
          >
            {getLabelTranslation('change_password')}
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              boxShadow: 'none',
              color: theme.palette.secondary.dark,
              background: theme.palette.secondary.main,
              ':hover': {
                boxShadow: 'none',
                background: theme.palette.secondary.light,
              },
            }}
            onClick={handleCancelEditUserProfile}
          >
            {getLabelTranslation('cancel')}
          </Button>
        )}
        {isDisabled ? (
          <Button
            variant="contained"
            sx={{
              boxShadow: 'none',
              color: theme.palette.secondary.contrastText,
              background: theme.palette.primary.main,
              ':hover': {
                boxShadow: 'none',
                background: theme.palette.primary.light,
              },
            }}
            onClick={handleEditUserProfile}
          >
            {getLabelTranslation('edit_information')}
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              boxShadow: 'none',
              color: theme.palette.secondary.contrastText,
              background: theme.palette.primary.main,
              ':hover': {
                boxShadow: 'none',
                background: theme.palette.primary.light,
              },
            }}
            type="submit"
            form="profile-form"
          >
            {getLabelTranslation('save')}
          </Button>
        )}
      </Box>
    </Box>
  );
}
