import { SxProps } from '@mui/material';

export const modalContainerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '26px',
  maxWidth: '613px',
  background: '#FAFAFA',
  boxShadow: '0px 18.382px 33.421px 0px rgba(34, 34, 34, 0.10)',
};

export const inputStyle: SxProps = {
  '.MuiInputBase-root': {
    height: '48px',
  },
};

export const actionButtonsStyle: SxProps = {
  marginTop: '15px',
  justifySelf: 'center',
  display: 'flex',
  flexDirection: {
    xs: 'column-reverse',
    md: 'row',
  },
  gap: '24px',
  width: '100%',
  '.MuiButtonBase-root': {
    width: '100%',
    maxWidth: {
      xs: 'unset',
      md: '243px',
    },
  },
};
