import { Formatter } from 'core/utils';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';

const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

const validationSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório').trim().min(1, 'Nome não pode ser vazio'),
  phoneNumber: yup.string().required('Número de telefone não pode ser vazio'),
  imageFile: yup
    .mixed()
    .test('fileSize', 'O arquivo é muito grande', value => {
      const files = value as File[];
      return !files || files.length === 0 || (files[0] && files[0].size <= FILE_SIZE);
    })
    .test('fileFormat', 'Formato de arquivo não suportado', value => {
      const files = value as File[];
      return (
        !files || files.length === 0 || (files[0] && SUPPORTED_FORMATS.includes(files[0].type))
      );
    }),
  documentNumber: yup.string().test('documentNumber', 'Documento inválido', function (value) {
    const { countryName } = this.parent;
    if (countryName === 'Brasil') {
      return cpf.isValid(value) || cnpj.isValid(value);
    }
    return Formatter.cuitIsValid(value);
  }),
});

export default validationSchema;
