import { SxProps } from '@mui/material';
import { SxPropsThemeFunction } from '../dashboard-filters/DashboardFilters.type';

export const dashboardMapCardStyles: SxProps = {
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '20px',
  border: '1px solid #CBCDD9',
  display: 'flex',
  gap: '32px',
  flexDirection: 'column',
  padding: {
    xs: '24px 16px',
    sm: '24px 16px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
};

export const dashboardMapCardHeaderStyles: SxProps = {
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  width: '100%',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
};

export const mapCardTitleContainerStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
};

export const mapCardTitleIconStyles: SxPropsThemeFunction = theme => ({
  color: theme.palette.primary.main,
  fontSize: {
    xs: '24px',
    sm: '24px',
    md: '42px',
    lg: '42px',
    xl: '42px',
  },
});

export const mapCardTitleStyles: SxProps = {
  lineHeight: '50px!important',
  fontWeight: '700!important',
  color: '#777D88',
  fontSize: {
    xs: '20px!important',
    sm: '20px!important',
    md: '32px!important',
    lg: '32px!important',
    xl: '32px!important',
  },
};

export const dashboardMapColumnsContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
};

export const mapSideContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: {
    xs: '100%',
    sm: '100%',
    md: '60%',
    lg: '60%',
    xl: '60%',
  },
  padding: {
    xs: '0px',
    sm: '0px',
    md: '16px',
    lg: '16px',
    xl: '16px',
  },
  gap: {
    xs: '42px',
    sm: '42px',
    md: '42px',
    lg: '42px',
    xl: '42px',
  },
  width: {
    xs: '100%',
    sm: '100%',
    md: 'fit-content',
    lg: 'fit-content',
    xl: 'fit-content',
  },
};

export const dataSideContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  flex: 1,
  padding: {
    xs: '32px 0px 0px',
    sm: '32px 0px 0px',
    md: '0px 16px 16px',
    lg: '0px 16px 16px',
    xl: '0px 16px 16px',
  },
};

export const dataSideContentStyles: SxProps = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '32px',
  flexDirection: {
    xs: 'row',
    sm: 'row',
    md: 'column',
    lg: 'column',
    xl: 'column',
  },
};

export const statusContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  marginLeft: 'auto',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  width: {
    xs: 'calc(50% - 16px)',
    sm: 'calc(50% - 16px)',
    md: '320px',
    lg: '320px',
    xl: '320px',
  },
};

export const statusDataLabelStyles: SxPropsThemeFunction = theme => ({
  fontSize: '16px!important',
  lineHeight: '20px!important',
  fontWeight: '400!important',
  maxWidth: '100%',
  color: theme.palette.primary.main,
  textAlign: {
    xs: 'center',
    sm: 'center',
    md: 'left',
    lg: 'left',
    xl: 'left',
  },
  width: {
    xs: '114px',
    sm: '114px',
    md: '180px',
    lg: '180px',
    xl: '180px',
  },
});

export const mapCardDataPeriodContainerStyles: SxProps = {
  display: 'flex',
};

export const dataPeriodTextStyles: SxProps = {
  fontWeight: 400,
  color: '#777D88',
  lineHeight: '18px!important',
  display: 'flex',
  flexDirection: 'column',
  fontSize: {
    xs: '16px!important',
    sm: '16px!important',
    md: '18px!important',
    lg: '18px!important',
    xl: '18px!important',
  },
};

export const periodDatesStyles: SxPropsThemeFunction = theme => ({
  lineHeight: '24px!important',
  width: '100%',
  paddingTop: '8px',
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: {
    xs: 'flex-start',
    sm: 'flex-start',
    md: 'flex-end',
    lg: 'flex-end',
    xl: 'flex-end',
  },
  fontSize: {
    xs: '16px!important',
    sm: '16px!important',
    md: '20px!important',
    lg: '20px!important',
    xl: '20px!important',
  },
});
