import { MsalProvider } from '@azure/msal-react';
import { GDMContainer, GDMLoading } from 'components';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { msalInstance } from '../../authConfig';

const AuthContext = createContext({
  isInitialized: false,
  instance: msalInstance,
  token: null,
});

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [token, setToken] = useState(null);

  const initialize = async () => {
    try {
      await msalInstance.initialize();
      const response = await msalInstance.handleRedirectPromise();

      if (response) {
        setToken(response.accessToken);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const contextValue = useMemo(() => {
    return {
      isInitialized,
      instance: msalInstance,
      token,
    };
  }, [isInitialized, token]);

  if (!isInitialized) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading />
      </GDMContainer>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </AuthContext.Provider>
  );
}
