import {
  DynamicFormData,
  GDMBox,
  GDMButton,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UsersAdministratorService } from 'core/api/services/administrator';
import { useMediaQuery, useTheme } from '@mui/material';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { AdminViewPageComponent } from '../AdminViewPage.type';

const DEFAULT_ADMIN = {
  name: '',
  contactEmail: '',
  phoneNumber: '',
  documentNumber: '',
};

const AdminViewPage: AdminViewPageComponent = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();

  const [admin, setAdmin] = useState<Partial<DynamicFormData>>(DEFAULT_ADMIN);
  const [errors] = useState({});
  const navigate = useNavigate();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [entityType, setEntityType] = useState('Administrador');

  const theme = useTheme();
  const { adminId } = useParams();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    setEntityType('Administrador');
    setIsSubmiting(true);

    UsersAdministratorService.getAdmById(adminId)
      .then(response => {
        const data = response;

        setAdmin({
          ...data,
          contactEmail: data.email,
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  }, [currentLanguage, adminId]);

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )}`}
      title={`${getLabelTranslation('reusableForm_ViewRegistrationOf_')} Administrador`}
    >
      <GDMConditionalRender canRender={isSubmiting}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>

      <GDMConditionalRender canRender={!isSubmiting}>
        <GDMDynamicFormFields
          {...{
            entityType,
            viewType: 'view',
            formData: admin,
            isSubmiting,
            errors,
            formDataEvents: {
              onCancel: null,
              onChange: null,
              onSubmit: null,
            },
            formDataOptions: {
              roles: [],
              brands: [],
              cities: [],
              states: [],
              regions: [],
              gdmSellers: [],
              multipliers: [],
            },
          }}
        />
      </GDMConditionalRender>
      <GDMBox sx={{ display: 'flex', justifyContent: 'start', gap: '24px', marginTop: 'auto' }}>
        <GDMButton
          variant="outlined"
          onClick={() => navigate(-1)}
          sx={{
            color: theme.palette.secondary.dark,
            width: isDesktop ? '187px' : '100% !important',
          }}
        >
          <FaArrowLeftLong style={{ marginRight: '8px' }} />
          {getLabelTranslation('ReusableFormKey_Back')}
        </GDMButton>
      </GDMBox>
    </GDMPageCard>
  );
};

export default AdminViewPage;
