import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { useMediaQuery, useTheme } from '@mui/material';

import { GDMButton, GDMContainer, GDMGrid, GDMLotList, GDMTypography } from 'components';
import { FaArrowLeftLong } from 'react-icons/fa6';

import { GDMFarmRegisterForm } from 'components/gdm-farm-register-form';
import { FarmService } from 'core/api/services/farm';
import { LocationsService } from 'core/api/services/locations';
import { LotsService } from 'core/api/services/lot';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { fromAddress } from 'react-geocode';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { IoAdd } from 'react-icons/io5';
import { PiFarm } from 'react-icons/pi';
import { errorHandler } from 'utils/form/errorHandler';
import { IFarmFormData } from './FarmForm.type';
import { containerStyle, flapStyleActive, flapStyleDisabled } from './FarmFormStyles';

const FarmForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { farmId } = useParams();
  const location = useLocation();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [activeFlap, setActiveFtep] = useState('farm');
  const [countries, setCountries] = useState([]);
  const [citySearchText, setCitySearchText] = useState('');

  const [country, setCountry] = useState(CountriesDomainEnum.Brasil as string);
  const [saveLatLng, setSaveLatLng] = useState({
    farm: false,
    openModalFarm: false,
  });

  const [states, setStates] = useState([]);
  const [viewType, setViewType] = useState('view');
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState<ObjectLiteral<string>>({});
  const { getLabelTranslation } = useLanguageSwitcher();
  const [latLong, setLatLong] = useState({ lat: '', lng: '' });
  const [title, setTitle] = useState({ title: '', subtitle: '' });
  const { openModal, closeModal } = useModal();
  const { user } = useStore(UserProfileStore);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [widthButton, setWidthButton] = useState('100%');
  const [hasInvalidLatLong, setHasInvalidLatLong] = useState(false);

  const [orderColumn, setOrderColumn] = useState({
    flap: '',
    orderDesc: false,
    column: '',
    entityName: '',
  });
  const [formDataFarm, setFormDataFarm] = useState<IFarmFormData>({
    cityId: '0',
    farmerId: '',
    farmName: '',
    farmerName: '',
    latitude: '',
    longitude: '',
    stateId: '0',
    totalArea: 0,
  });

  const getFarm = async ({
    farmIdParam,
    columnName,
    orderDesc,
    entityName,
  }: {
    farmIdParam: string;
    columnName?: string;
    orderDesc?: boolean;
    entityName?: string;
  }) => {
    const response = await FarmService.getFarmById({
      id: farmIdParam,
      columnName,
      orderDesc,
      entityName,
    });
    const ufId = states.find(v => v.name === response.stateName)?.id;
    const cityId = { target: { value: response.cityId } };

    setFormDataFarm({
      cityId: response.cityId,
      farmerId: response.farmerId,
      farmName: response.name,
      farmerName: response.farmerName,
      stateId: ufId,
      totalArea: response.totalArea,
      lots: response.farmLots.lots,
      latitude: response.latitude,
      longitude: response.longitude,
    });

    const invalidLatLongValues = [undefined, 'undefined', 0, '0'];

    const isInvalidLatitude = invalidLatLongValues.includes(response.latitude);
    const isInvalidLongitude = invalidLatLongValues.includes(response.longitude);

    if (isInvalidLatitude || isInvalidLongitude) {
      setHasInvalidLatLong(true);
    } else {
      setHasInvalidLatLong(false);
    }

    setLatLong({ lat: response.latitude, lng: response.longitude });
    handleStateChangeFarm(ufId);
    handleCityChangeFarm(cityId);
  };

  useEffect(() => {
    getFarm({ farmIdParam: farmId });
  }, [states]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user.countryName) {
          const countries = await LocationsService.getCountries();
          const selectedCountryId = countries.find(i => i.name === user.countryName)?.id;
          if (!selectedCountryId) throw new Error('Invalid country selection');
          const states = await LocationsService.getStates(selectedCountryId);

          setCountries(countries);
          setStates(states);
          setCountry(user.countryName);

          setFormDataFarm(prev => ({
            ...prev,
            countryId: selectedCountryId,
          }));
        }
      } catch (error) {
        openModal({
          type: 'error',
          title: getLabelTranslation('modal_message_start_error_title'),
          message: getLabelTranslation('modal_message_start_error_message'),
        });
      }
    };

    fetchData();
  }, [user]);

  const exit = () => {
    navigate(-1);
    closeModal();
  };
  const validateEntireForm = () => {
    return new Promise<boolean>(resolve => {
      const keys = Object.keys(formDataFarm) as (keyof IFarmFormData)[];
      let hasError = false;
      keys.forEach(key => {
        const error = validateFieldsFarm(key, formDataFarm[key]);
        if (error.length === 0) {
          setErrors(errors => {
            delete errors[key];
            return { ...errors };
          });
        } else {
          setErrors(errors => ({ ...errors, [key]: error }));
          hasError = true;
        }
      });
      resolve(hasError);
    });
  };

  const handleFarmFormSubmit = async () => {
    validateEntireForm().then(async hasError => {
      if (!hasError) {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formDataFarm.farmName);
        formDataToSend.append('farmerId', formDataFarm.farmerId);
        formDataToSend.append('cityId', formDataFarm.cityId);
        formDataToSend.append('totalArea', `${formDataFarm.totalArea}`);
        formDataToSend.append('latitude', `${formDataFarm.latitude}`);
        formDataToSend.append('longitude', `${formDataFarm.longitude}`);

        FarmService.updateFarmById({ id: farmId, formData: formDataToSend })
          .then(() => {
            openModal({
              type: 'success',
              title: getLabelTranslation('updated_success'),
              onClose: exit,
            });
          })
          .catch(error => {
            errorHandler(error, setFieldErrors);

            openModal({
              type: 'error',
              title: getLabelTranslation('modal_message_submit_error_title'),
              message: getLabelTranslation('modal_message_submit_error_message'),
            });
          });
      }
    });
  };

  const getCities = async stateId => {
    try {
      const citiesResponse = await LocationsService.getCities(stateId);
      const error = validateFieldsFarm('stateId', stateId);
      if (error.length === 0) {
        setErrors(errors => {
          delete errors.ufId;
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, ufId: error }));
      }

      if (citiesResponse !== cities) setCities(citiesResponse);
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_message_cities_error_title'),
        message: getLabelTranslation('modal_message_cities_error_message'),
      });
    }
  };

  const handleStateChangeFarm = stateId => {
    setFormDataFarm(prev => ({ ...prev, stateId, cityId: '0' }));
    if (stateId) getCities(stateId);
  };

  const handleChangeFlap = flap => {
    setOrderColumn({ column: '', flap, orderDesc: false, entityName: '' });
    setActiveFtep(flap);
  };

  const handleCityChangeFarm = event => {
    const cityId = event.target.value;
    const error = validateFieldsFarm('cityId', cityId);
    if (error.length === 0) {
      setErrors(errors => {
        delete errors.cityId;
        return { ...errors };
      });
    } else {
      setErrors(errors => ({ ...errors, cityId: error }));
    }
    setFormDataFarm(prev => ({ ...prev, cityId }));

    if (handleGetStarterCoordinations) {
      handleGetStarterCoordinations(cityId, formDataFarm.stateId);
    }
  };

  const handleGetStarterCoordinations = async (cityId: string, stateId: string) => {
    if (!cityId || !stateId || cityId === '0') return;

    try {
      const cityName = cities?.find(c => c?.id === cityId)?.name;
      const stateName = states?.find(s => s?.id === stateId)?.name;

      if (cityName && stateName) {
        const cityLabel = getLabelTranslation('formfields_correction_city');
        const stateLabel = getLabelTranslation('formfields_state');
        const citySearch = `${cityLabel}: ${cityName}, ${stateLabel}: ${stateName}, ${user.countryName}`;

        fromAddress(citySearch)
          .then(responseData => {
            const { lat, lng } = responseData.results[0].geometry.location;
            setFormDataFarm(prev => ({ ...prev, latitude: lat, longitude: lng }));
            setLatLong({ lat, lng });
          })
          .catch(err => {
            throw err;
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!cities?.find(c => c?.id === formDataFarm.cityId)?.name) {
      const cityName = cities?.find(c => c?.id === formDataFarm.cityId)?.name;
      const stateName = states?.find(s => s?.id === formDataFarm.stateId)?.name;
      const cityLabel = getLabelTranslation('formfields_correction_city');
      const stateLabel = getLabelTranslation('formfields_state');
      const citySearch = `${cityLabel}: ${cityName}, ${stateLabel}: ${stateName}, ${user.countryName}`;
      setCitySearchText(citySearch);
    }
  }, [formDataFarm.cityId, formDataFarm.stateId, cities, states]);

  const validateFieldsFarm = (fieldName, fieldValue) => {
    if (!fieldValue) return getLabelTranslation('required_field');

    if ((fieldName === 'stateId' || fieldName === 'cityId') && fieldValue === '0') {
      return getLabelTranslation('required_field');
    }
    if (
      (fieldName === 'longitude' || fieldName === 'latitude') &&
      (!fieldValue || !Number(fieldValue))
    ) {
      return getLabelTranslation('required_field');
    }
    return '';
  };

  const handleChangeValuesFarm = useCallback(
    event => {
      const { name: field, value } = event.target;

      const error = validateFieldsFarm(field, value);

      if (error.length === 0) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: error }));
      }

      setFormDataFarm(prev => ({
        ...prev,
        [field]: value,
      }));
    },
    [country],
  );
  const handleGetFarmAfterDeleteLot = () => {
    getFarm({ farmIdParam: farmId });
  };

  const openDeleteModal = (id, canBeDeleted) => {
    const handleDelete = () => {
      closeModal();
      if (canBeDeleted) {
        LotsService.deleteLot({ id }).then(() => {
          openModal({
            type: 'success',
            title: getLabelTranslation('modal_tittle_delete_success'),
            onClose: handleGetFarmAfterDeleteLot,
          });
        });
      } else {
        openModal({
          type: 'error',
          title: getLabelTranslation('error_on_delete_register'),
          message: getLabelTranslation('error_on_delete'),
          onClose: closeModal,
        });
      }
    };
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_confirm_question'),
      message: getLabelTranslation('modal_alert'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: handleDelete,
      },
      secondAction: {
        label: getLabelTranslation('cancel'),
        action: closeModal,
      },
    });
  };
  const getBreadcrumb = viewType => {
    if (viewType === 'view') {
      return `${getLabelTranslation('see_register')} > ${getLabelTranslation(
        'farmer',
      )} > ${getLabelTranslation('farm')}`;
    }

    return (
      `${getLabelTranslation('see_registries')} > ` +
      `${getLabelTranslation('farmer')} ` +
      `> ${getLabelTranslation('farm')} ` +
      `> ${getLabelTranslation('to_edit')}`
    );
  };

  useEffect(() => {
    if (!isDesktop) {
      setWidthButton('100%');
    } else if (isDesktop) {
      setWidthButton('187px');
    }
  }, [activeFlap]);
  useEffect(() => {
    getFarm({
      farmIdParam: farmId,
      columnName: orderColumn.column,
      entityName: orderColumn.entityName,
      orderDesc: orderColumn.orderDesc,
    });
  }, [orderColumn]);

  useEffect(() => {
    if (location.pathname.includes('lot-flap')) {
      setActiveFtep('lot');
      setViewType('view');
    }
    if (location.pathname.includes('edit')) {
      setViewType('edit');
    } else {
      setViewType('view');
    }
  }, []);
  useEffect(() => {
    if (viewType === 'edit') {
      setTitle(() => ({
        subtitle: '',
        title: getLabelTranslation('edit_farm'),
      }));
    } else if (activeFlap === 'farm') {
      setTitle(() => ({
        title: getLabelTranslation('view_farm_registration'),
        subtitle: 'swa',
      }));
    } else if (activeFlap === 'lot') {
      setTitle(() => ({
        title: 'Ver Cadastro de Lote',
        subtitle: 'Lotes das Fazendas do',
      }));
    }
  }, [activeFlap]);
  return (
    <GDMContainer
      component="form"
      onSubmit={handleFarmFormSubmit}
      sx={containerStyle}
      {...{ noValidate: true, autoComplete: 'off' }}
    >
      <GDMTypography variant="caption" display="block" gutterBottom sx={{ color: '#A1A9B8' }}>
        {getBreadcrumb(viewType)}
      </GDMTypography>

      <GDMTypography
        variant="h4"
        sx={{ fontWeight: '700', marginBottom: '0', textAlign: 'start' }}
        gutterBottom
      >
        {viewType === 'edit'
          ? getLabelTranslation('edit_farm')
          : getLabelTranslation('view_farm_registration')}
      </GDMTypography>

      {(viewType === 'view' || viewType === 'edit') && (
        <GDMTypography
          variant="subtitle1"
          sx={{ fontWeight: '400', marginBottom: '24px', textAlign: 'start' }}
          gutterBottom
        >
          {formDataFarm.farmName} {getLabelTranslation('of_the')} <b>{formDataFarm.farmerName}</b>
        </GDMTypography>
      )}

      {viewType === 'view' && (
        <GDMContainer
          sx={{
            width: '100%',
            background: '#F2F4F9',
            display: isDesktop ? 'flex' : 'grid',
            gridTemplateColumns: '1fr 1fr',
            borderRadius: '8px 8px 0 0',
            justifyContent: 'start',
          }}
        >
          <GDMGrid
            item
            xs={6}
            md={8}
            sx={activeFlap === 'farm' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              handleChangeFlap('farm');
            }}
          >
            <PiFarm color="#222" fontSize={24} />
            <GDMTypography sx={{ fontSize: '16px' }}>{getLabelTranslation('farm')}</GDMTypography>
          </GDMGrid>{' '}
          <GDMGrid
            item
            xs={6}
            md={8}
            sx={activeFlap === 'lot' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              handleChangeFlap('lot');
            }}
          >
            <HiOutlineSquares2X2 color="#222" fontSize={24} />
            <GDMTypography sx={{ fontSize: '16px' }}>{getLabelTranslation('lot')}</GDMTypography>
          </GDMGrid>
        </GDMContainer>
      )}

      <GDMGrid container sx={{ marginTop: isDesktop ? '30px' : 0, marginBottom: '20px' }}>
        {activeFlap === 'farm' && (
          <GDMFarmRegisterForm
            typeIcon="view"
            formData={formDataFarm}
            latLong={latLong}
            errors={errors}
            handleChangeValues={handleChangeValuesFarm}
            handleStateChange={event => handleStateChangeFarm(event.target.value)}
            setSaveLatLng={setSaveLatLng}
            viewType="view"
            states={states}
            cities={cities}
            handleCityChange={handleCityChangeFarm}
            disabledFields={viewType === 'view'}
            openModalState={saveLatLng.openModalFarm}
            handleGetStarterCoordinations={handleGetStarterCoordinations}
            citySearchText={citySearchText}
            hasInvalidLatLong={hasInvalidLatLong}
            setCoordinates={({ lat, lng }) => {
              setLatLong({ lat, lng });
              setFormDataFarm(prev => {
                return { ...prev, latitude: lat, longitude: lng };
              });
            }}
          />
        )}
        {activeFlap === 'lot' && (
          <GDMLotList
            lots={formDataFarm.lots}
            type="farm"
            openDeleteModal={openDeleteModal}
            orderColumn={orderColumn}
            setOrderColumn={setOrderColumn}
          />
        )}

        <GDMGrid
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{
            marginTop: isDesktop ? '30px' : '24px',
            marginBottom: '20px',
            justifyContent: viewType === 'view' ? 'space-between' : 'end',
            flexDirection: isDesktop ? 'row' : 'column-reverse',
            alignItems: 'start',
            gap: !isDesktop && '8px',
          }}
        >
          {viewType === 'edit' && isDesktop && (
            <GDMButton
              onClick={() => {
                setSaveLatLng(prev => ({ ...prev, openModalFarm: true }));
              }}
              sx={{
                mr: 4,
                height: '51px',
                width: isDesktop ? 'auto' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                marginBottom: 'auto',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_latitude_longitude')}
            </GDMButton>
          )}
          <GDMButton
            onClick={() => {
              if (viewType === 'edit') {
                openModal({
                  type: 'warning',
                  title: getLabelTranslation('warning_exit'),
                  message: getLabelTranslation('modal_message_exit_warning'),
                  primaryAction: {
                    label: getLabelTranslation('exit'),
                    action: exit,
                  },
                  secondAction: {
                    label: getLabelTranslation('cancel'),
                    action: closeModal,
                  },
                });
              } else {
                exit();
              }
            }}
            sx={{
              mr: isDesktop ? 4 : 0,
              height: '51px',
              marginBottom: '0',
              width: widthButton,
              color: theme.palette.secondary.dark,
              display: 'flex',
              gap: '8px',
              background: viewType === 'edit' ? theme.palette.secondary.main : 'transparent',
              alignItems: 'center',
              flex: !isDesktop && 1,
              boxShadow: 'none',
              ':hover': {
                background: viewType === 'edit' ? theme.palette.primary.light : 'transparent',
                boxShadow: 'none',
              },
            }}
            variant={viewType === 'view' ? 'outlined' : 'text'}
          >
            {viewType === 'view' && <FaArrowLeftLong />}
            {viewType !== 'view'
              ? getLabelTranslation('ReusableFormKey_Cancel')
              : getLabelTranslation('ReusableFormKey_Back')}
          </GDMButton>
          {viewType !== 'view' && (
            <GDMButton
              variant="contained"
              onClick={handleFarmFormSubmit}
              sx={{
                height: '51px',
                width: isDesktop ? '187px' : '100%',
                boxShadow: 'none',
                background: theme.palette.primary.main,
                flex: !isDesktop && 1,
                ':hover': {
                  boxShadow: 'none',
                  background: theme.palette.primary.dark,
                },
              }}
            >
              {getLabelTranslation('ReusableFormKey_Save')}
            </GDMButton>
          )}
          {viewType === 'view' && activeFlap === 'lot' && (
            <GDMButton
              onClick={() => {
                navigate(`/farmer-register/${farmId}/lot-step`);
              }}
              sx={{
                mr: 4,
                height: '51px',
                width: isDesktop ? '200px' : '100%',
                marginBottom: isDesktop ? 0 : '32px',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                gap: '16px',
                marginRight: 0,
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="outlined"
            >
              <IoAdd style={{ fontSize: '24px' }} color="#222" />
              {getLabelTranslation('register_lot')}
            </GDMButton>
          )}
        </GDMGrid>
      </GDMGrid>
    </GDMContainer>
  );
};

export default FarmForm;
