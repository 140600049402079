import { createStore } from 'effector';

import { loadCountries, loadCountriesDone, loadCountriesFail } from './CountriesEvents';
import { CountriesState } from './CountriesState';

const initialState: CountriesState & { isLoading: boolean } = {
  countries: [
    {
      id: null,
      name: null,
      defaultLanguageIso: 'pt-BR',
    },
  ],
  isLoading: false,
};

const CountriesStore = createStore<CountriesState & { isLoading: boolean }>(initialState)
  .on(loadCountries, state => ({
    ...state,
    isLoading: true,
  }))
  .on(loadCountriesDone, (_, data) => ({
    isLoading: false,
    countries: data,
  }))
  .on(loadCountriesFail, state => ({
    ...state,
    isLoading: false,
    countries: {
      ...state.countries,
    },
  }));

export default CountriesStore;
