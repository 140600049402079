import { UserProfileService } from 'core/api/services/user';

const execute = async (): Promise<void> => {
  return UserProfileService.deleteUserPhotoProfile().catch(error => console.error(error));
};

const DeleteProfilePhotoUseCase = {
  execute,
};

export default DeleteProfilePhotoUseCase;
