import { createStore } from 'effector';

import { loadRegions, loadRegionsDone, loadRegionsFail } from './RegionsEvents';
import { RegionsState } from './RegionsState';

const initialState: RegionsState & { isLoading: boolean } = {
  regions: [
    {
      id: null,
      name: null,
    },
  ],
  isLoading: false,
};

const RegionsStore = createStore<RegionsState & { isLoading: boolean }>(initialState)
  .on(loadRegions, state => ({
    ...state,
    isLoading: true,
  }))
  .on(loadRegionsDone, (_, data) => ({
    isLoading: false,
    regions: data,
  }))
  .on(loadRegionsFail, state => ({
    ...state,
    isLoading: false,
    regions: {
      ...state.regions,
    },
  }));

export default RegionsStore;
