import { BoxProps } from '@mui/material';

import { GDMComponent } from 'components/GDMComponent.type';

export enum GDMPanelType {
  SELECT = 'select',
  INPUT = 'input',
  RADIO = 'radio',
  DATE = 'date',
  STRING = 'string',
}

export interface IGDMRecommendationSelect {
  id: string;
  name: string;
}

export interface IGDMPanelFieldProps {
  id: string;
  parameterName?: string;
  title: string;
  placeholder?: string;
  icon: any;
  type: GDMPanelType;
  maxValue?: any;
  minValue?: any;
  defaultValue?: any;
  width?: number;
  techs?: IGDMRecommendationSelect[];
  options?: IGDMRecommendationSelect[];
  inputLegend?: string;
  inputRuleLegend?: string;
  setFormValues?: void;
  disabled?: boolean;
  handleFieldChange(id: string, value: any): void;
  isReadOnly?: boolean;
  locale?: string;
  updateButtonRef?: HTMLButtonElement;
}

export interface IGraphDateProps {
  variableT: Date;
  yield: number;
}

export type GDMPanelFieldProps = IGDMPanelFieldProps & BoxProps;

export type GDMPanelFieldComponent = GDMComponent<GDMPanelFieldProps>;
