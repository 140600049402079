import { UsersAdministratorService } from 'core/api/services/administrator';
import { AdminListHandleDeleteFunction } from './adminListHandleDelete.type';

const adminListHandleDelete: AdminListHandleDeleteFunction = async ({
  adminId,
  gTL,
  handleOpenModal,
  handleOnDeleteAdmin,
}) => {
  try {
    await UsersAdministratorService.deleteAdmById(adminId);

    handleOpenModal({
      type: 'success',
      title: gTL('modal_tittle_delete_success'),
      onClose: handleOnDeleteAdmin,
    });
  } catch (error) {
    handleOpenModal({
      type: 'error',
      title: gTL('modal_tittle_delete_error'),
      message: gTL('modal_message_delete_error'),
    });
  }
};

export default adminListHandleDelete;
