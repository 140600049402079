import { SxProps } from '@mui/material';

export const containerDashboard: SxProps = {
  background: '#FAFAFA',
  padding: '20px 30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2480px',
  zIndex: -9999,
  position: 'absolute',
  left: 0,
  top: '-9999px',
};
