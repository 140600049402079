import { Avatar } from '@mui/material';
import { GDMContainer } from 'components/gdm-container';
import { GDMLoading } from 'components/gdm-loading';
import { SasTokenService } from 'core/api/services/sas-token';
import React, { useState, useEffect, useCallback } from 'react';
import { GDMSecureImageComponent } from './GDMSecureImage.type';

const GDMSecureImage: GDMSecureImageComponent = ({ src, alt, useAvatar = false, sx, ...rest }) => {
  const [expiration, setExpiration] = useState<Date | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (src) {
      setImageUrl(src);
      const newExpiration = extractExpirationFromUrl(src);
      setExpiration(newExpiration);
    }
  }, [src]);

  const extractExpirationFromUrl = (url: string): Date | null => {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const expirationParam = urlParams.get('se');
    return expirationParam ? new Date(decodeURIComponent(expirationParam)) : null;
  };

  const fetchNewToken = async (): Promise<{ newUrl: string; newExpiration: Date }> => {
    try {
      const newUrl = await SasTokenService.getSasToken(imageUrl);
      const newExpiration = extractExpirationFromUrl(newUrl);
      if (!newExpiration) {
        return { newUrl, newExpiration };
      }

      return { newUrl, newExpiration };
    } catch (error) {
      console.error('Failed to fetch new token', error);
      throw error;
    }
  };

  const checkAndRefreshToken = useCallback(async () => {
    if (imageUrl) {
      const now = new Date();
      if (!expiration || expiration.getTime() - now.getTime() <= 300000) {
        try {
          setIsLoading(true);
          const { newUrl, newExpiration } = await fetchNewToken();
          setImageUrl(newUrl);
          setExpiration(newExpiration);
        } catch (error) {
          console.error('Error refreshing token:', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [expiration, imageUrl]);

  useEffect(() => {
    const interval = setInterval(checkAndRefreshToken, 1200000); // Check every 20 minutes
    return () => clearInterval(interval);
  }, [checkAndRefreshToken]);

  useEffect(() => {
    checkAndRefreshToken();
  }, [checkAndRefreshToken]);

  if (isLoading) {
    return (
      <GDMContainer
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GDMLoading />
      </GDMContainer>
    );
  }

  if (useAvatar) {
    return <Avatar alt={alt} src={imageUrl} sx={sx} {...rest} />;
  }

  return <img alt={alt} src={imageUrl} {...(rest as React.ImgHTMLAttributes<HTMLImageElement>)} />;
};

export default GDMSecureImage;
