import { useMediaQuery } from '@mui/material';
import { GDMContainer, GDMLoading, GDMTypography } from 'components';
import GDMBox from 'components/gdm-box/GDMBox';
import { CatalogService } from 'core/api/services/catalog';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import CatalogFilter from './CatalogFilter/CatalogFilter';
import CatalogTable from './CatalogTable/CatalogTable';

const Catalog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useMediaQuery('(min-width:960px)');
  const [listResponse, setListResponse] = useState({ rows: null, pages: 0, filters: null });
  const { getLabelTranslation } = useLanguageSwitcher();
  const { user } = useStore(UserProfileStore);
  const [filter, setFilter] = useState({
    page: 1,
    itemsPerPage: 4,
    orderDesc: false,
    orderColumn: '',
    brand: [],
    culture: [],
    technology: [],
    genotype: [],
  });
  const [emptyMessage, setEmptyMessage] = useState<string | undefined>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const countryOfUser = user?.countryName;

  useEffect(() => {
    if (!isLoading && !listResponse?.rows?.length) {
      const isSearching =
        !!filter.brand.length ||
        !!filter.culture.length ||
        !!filter.technology.length ||
        !!filter.genotype.length;

      if (hasError) setEmptyMessage('error_on_load_table_data_text');
      else if (isSearching) setEmptyMessage('empty_table_on_filter_text');
      else setEmptyMessage('empty_table_text');
    }
  }, [
    listResponse?.rows,
    isLoading,
    filter.brand,
    filter.culture,
    filter.technology,
    filter.genotype,
    hasError,
  ]);

  const columnTitle: { name: string; title: any; type: string }[] = [
    {
      name: 'CultureName',
      title: getLabelTranslation('culture'),
      type: 'text',
    },
    {
      name: 'BrandName',
      title: getLabelTranslation('brand'),
      type: 'text',
    },
    {
      name: 'Genotype',
      title: getLabelTranslation('variety'),
      type: 'text',
    },
    {
      name: 'Technology',
      title: getLabelTranslation('technology'),
      type: 'text',
    },
    {
      name: 'SellingTip',
      title: getLabelTranslation('sell_tip'),
      type: 'text',
    },
  ];

  const loadCatalog = async () => {
    try {
      const response = await CatalogService.getCatalog(filter);

      setHasError(false);
      setListResponse({
        pages: response.totalPages,
        rows: response.items,
        filters: response.filter,
      });
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCatalog();
  }, [filter]);

  return (
    <GDMContainer
      component="div"
      sx={{
        boxShadow: isDesktop && '0 2px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        borderRadius: '24px',
        padding: isDesktop ? '59px 66px' : '0px',
        backgroundColor: isDesktop && '#fff',
      }}
    >
      <GDMTypography
        sx={{
          marginBottom: '32px',
          color: '#A1A9B8',
          fontSize: '14px',
          textAlign: isDesktop ? 'start' : 'center',
        }}
      >
        {getLabelTranslation('product_catalogs_view_records')}
      </GDMTypography>

      <GDMContainer
        sx={{
          display: 'flex',
          marginBottom: '32px',
          gap: isDesktop ? '80px' : '24px',
          flexDirection: isDesktop ? 'row' : 'column',
        }}
      >
        <GDMBox
          sx={{
            color: '#222',
            display: 'flex',
            flexDirection: 'column',
            gap: isDesktop ? '8px' : '9px',
            textAlign: isDesktop ? 'start' : 'center',
          }}
        >
          <GDMTypography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '32px', sm: '32px' },
              lineHeight: { xs: '35.2px', sm: '35.2px' },
            }}
          >
            {getLabelTranslation('view_product_catalog')}
          </GDMTypography>
          <GDMTypography
            sx={{
              fontSize: { xs: '16px', sm: '16px' },
              lineHeight: { xs: '28px', sm: '28px' },
              padding: { xs: '0 40px', sm: 0 },
            }}
          >
            {getLabelTranslation('view_available_catalog')} <b>{countryOfUser}</b>
          </GDMTypography>
        </GDMBox>
        <GDMBox sx={{ flex: 1 }}>
          <CatalogFilter filters={listResponse.filters} setFilter={setFilter} filter={filter} />
        </GDMBox>
      </GDMContainer>

      <GDMContainer>
        {isLoading && (
          <GDMBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20%',
              paddingBottom: '15%',
            }}
          >
            <GDMLoading />
          </GDMBox>
        )}
        {!isLoading && (
          <CatalogTable
            emptyMessage={emptyMessage}
            orderDesc={filter.orderDesc}
            header={columnTitle}
            rows={listResponse.rows || []}
            currentPage={filter.page}
            pages={listResponse.pages || 0}
            setFilter={setFilter}
          />
        )}
      </GDMContainer>
    </GDMContainer>
  );
};

export default Catalog;
