import { useMediaQuery, useTheme } from '@mui/material';
import { GDMButton, GDMGrid } from 'components';
import GDMBox from 'components/gdm-box/GDMBox';
import { GDMContainer } from 'components/gdm-container';
import { GDMLotRegisterForm } from 'components/gdm-forms/gdm-lot-register-form';
import { GDMTypography } from 'components/gdm-typography';
import { LocationsService } from 'core/api/services/locations';
import { LotsService } from 'core/api/services/lot';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { useCallback, useEffect, useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { errorHandler } from 'utils/form/errorHandler';
import { containerStyle, flapStyleActive } from './LotForm.styles';
import { ILotFormData } from './LotForm.type';

export default function LotForm(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const { lotId } = useParams();
  const location = useLocation();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [country, setCountry] = useState(CountriesDomainEnum.Brasil as string);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [latLngLot, setLatLngLot] = useState({ lat: '', lng: '' });
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState<ObjectLiteral<string>>({});
  const { getLabelTranslation } = useLanguageSwitcher();
  const { openModal, closeModal } = useModal();
  const { user } = useStore(UserProfileStore);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [saveLatLng, setSaveLatLng] = useState({
    lot: false,
    openModalLot: false,
  });

  const [mapImageURL, setMapImageURL] = useState('');

  const viewType = location.pathname.includes('edit') ? 'edit' : 'view';
  const [formDataLot, setFormDataLot] = useState<ILotFormData>({
    cityId: '0',
    stateId: '0',
    farmId: '',
    lotName: '',
    farmerName: '',
    farmName: '',
    mapPinList: [
      {
        latitude: '',
        longitude: '',
      },
    ],
    totalArea: 0,
  });

  const [coordinatesBackup, setCoordinatesBackup] = useState([] as { lat: number; lng: number }[]);

  const setMapPinListLot = mapPinList => {
    setFormDataLot(prev => ({ ...prev, mapPinList }));
  };
  const exit = () => {
    navigate(-1);
    closeModal();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user.countryName) {
          const countries = await LocationsService.getCountries();
          const selectedCountryId = countries.find(i => i.name === user.countryName)?.id;
          if (!selectedCountryId) throw new Error('Invalid country selection');
          const states = await LocationsService.getStates(selectedCountryId);

          setCountries(countries);
          setStates(states);
          setCountry(user.countryName);
        }
      } catch (error) {
        openModal({
          type: 'error',
          title: getLabelTranslation('modal_message_start_error_title'),
          message: getLabelTranslation('modal_message_start_error_message'),
        });
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    getLot(lotId);
  }, []);

  const getCities = async stateId => {
    try {
      const cities = await LocationsService.getCities(stateId);
      setCities(cities);
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_message_cities_error_title'),
        message: getLabelTranslation('modal_message_cities_error_message'),
      });
    }
  };

  const getLot = async lotId => {
    const response = await LotsService.getLotById({ id: lotId });
    setFormDataLot({
      cityId: response.cityId,
      farmId: response.farmId,
      lotName: response.name,
      mapPinList: response.mapPinList,
      totalArea: response.totalArea,
      stateId: response.stateId,
      farmerName: response.farmerName,
      farmName: response.farmName,
      lotImage: response.lotImageUrl,
    });
    setCoordinatesBackup(response.mapPinList);
    getCities(response.stateId);
    setLatLngLot({ lat: response.latitude.toString(), lng: response.longitude.toString() });
  };

  const validateEntireForm = form => {
    return new Promise<boolean>(resolve => {
      const keys = Object.keys(form) as (keyof ILotFormData)[];
      let hasError = false;
      keys.forEach(key => {
        const error = validateFields(key, form[key]);
        if (error.length === 0) {
          setErrors(errors => {
            delete errors[key];
            return { ...errors };
          });
        } else {
          setErrors(errors => ({ ...errors, [key]: error }));
          hasError = true;
        }
      });
      resolve(hasError);
    });
  };
  const handleCloseModal = () => {
    navigate(-1);
    closeModal();
  };

  const handleLotFormSubmit = async () => {
    validateEntireForm(formDataLot).then(async hasError => {
      if (!hasError) {
        try {
          const formDataToSend = {
            name: formDataLot.lotName,
            farmId: formDataLot.farmId,
            cityId: formDataLot.cityId,
            totalArea: formDataLot.totalArea,
            mapPinList: formDataLot.mapPinList,
            longitude: latLngLot.lng,
            latitude: latLngLot.lat,
            lotImage: mapImageURL,
          };
          LotsService.lotUpdate(formDataToSend, lotId).then(() => {
            openModal({
              type: 'success',
              title: getLabelTranslation('updated_success'),
              message: getLabelTranslation('modal_message_register_success'),
              onClose: handleCloseModal,
            });
          });
        } catch (error) {
          errorHandler(error, setFieldErrors);

          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
        }
      }
    });
  };

  const validateFields = (fieldName, fieldValue) => {
    if (!fieldValue && fieldName !== 'totalArea') return getLabelTranslation('required_field');

    if (fieldName === 'totalPlantedArea') {
      if (parseInt(fieldValue, 10) <= 0) {
        return getLabelTranslation('register_farmer_area_error');
      }
    }
    if ((fieldName === 'longitude' || fieldName === 'latitude') && !fieldValue) {
      return getLabelTranslation('required_field');
    }

    if ((fieldName === 'stateId' || fieldName === 'cityId') && fieldValue === '0') {
      return getLabelTranslation('required_field');
    }

    if (fieldName === 'mapPinList' && fieldValue?.filter(x => !!Number(x?.latitude))?.length < 3) {
      return getLabelTranslation('min_3_pin_lot');
    }

    return '';
  };

  const handleChangeValuesLot = useCallback(
    event => {
      const { name: field, value } = event.target;
      const error = validateFields(field, value);

      if (error.length === 0) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: error }));
      }

      setFormDataLot(prev => ({
        ...prev,
        [field]: value,
      }));
    },
    [country],
  );

  let widthButton;
  if (!isDesktop) {
    widthButton = '100%';
  } else if (isDesktop) {
    widthButton = '200px';
  }
  const getBreadcrumb = viewType => {
    if (viewType === 'view') {
      return `${getLabelTranslation('see_register')} > ${getLabelTranslation(
        'farmer',
      )} > ${getLabelTranslation('farm')} > ${getLabelTranslation('lot')}`;
    }

    return (
      `${getLabelTranslation('see_registries')} > ` +
      `${getLabelTranslation('farmer')} ` +
      `> ${getLabelTranslation('farm')} ` +
      `> ${getLabelTranslation('lot')} ` +
      `> ${getLabelTranslation('to_edit')}`
    );
  };

  return (
    <GDMContainer
      component="form"
      onSubmit={handleLotFormSubmit}
      sx={containerStyle}
      {...{ noValidate: true, autoComplete: 'off' }}
    >
      <GDMTypography variant="caption" display="block" gutterBottom sx={{ color: '#A1A9B8' }}>
        {getBreadcrumb(viewType)}
      </GDMTypography>

      <GDMTypography
        variant="h4"
        sx={{ fontWeight: '700', marginBottom: '0', textAlign: 'start' }}
        gutterBottom
      >
        {viewType === 'edit' && `${getLabelTranslation('edit_lot')}`}
        {viewType === 'view' && `${getLabelTranslation('view_lot_registration')}`}
      </GDMTypography>

      {(viewType === 'view' || viewType === 'edit') && (
        <GDMTypography
          variant="subtitle1"
          sx={{ fontWeight: '400', marginBottom: '24px', textAlign: 'start' }}
          gutterBottom
        >
          {getLabelTranslation('lot')} da {getLabelTranslation('farm')}{' '}
          <b>{formDataLot.farmName}</b> {getLabelTranslation('of_the')}{' '}
          <b>{formDataLot.farmerName}</b>
        </GDMTypography>
      )}

      {viewType === 'view' && (
        <GDMBox
          sx={{
            width: '100%',
            background: '#F2F4F9',
            display: 'flex',
            borderRadius: '8px 8px 0 0',
            justifyContent: 'start',
          }}
        >
          <GDMGrid item xs={6} md={8} sx={flapStyleActive}>
            <HiOutlineSquares2X2 color="#222" fontSize={24} />
            <GDMTypography sx={{ fontSize: '16px' }}>{getLabelTranslation('lot')}</GDMTypography>
          </GDMGrid>
        </GDMBox>
      )}

      <GDMGrid container sx={{ marginTop: '30px', marginBottom: '20px' }}>
        <GDMBox sx={{ width: '100%' }}>
          <GDMLotRegisterForm
            typeForm="view"
            formData={formDataLot}
            farmName={formDataLot.farmName}
            farmerName={formDataLot.farmerName}
            view={viewType}
            setSaveLatLng={setSaveLatLng}
            errors={errors}
            handleChangeValues={handleChangeValuesLot}
            states={states}
            openModalState={saveLatLng.openModalLot}
            cities={cities}
            setMapPinListLot={setMapPinListLot}
            setCoordinates={setLatLngLot}
            latLong={latLngLot}
            coordinatesBackup={coordinatesBackup}
            setCoordinatesBackup={setCoordinatesBackup}
            updateParentMapImageURL={setMapImageURL}
          />
        </GDMBox>

        <GDMGrid
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{
            marginTop: isDesktop ? '10vh' : '24px',
            marginBottom: '0',
            flex: 1,
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: viewType === 'view' ? 'start' : 'end',
            gap: '26px',
          }}
        >
          {viewType === 'edit' && isDesktop && (
            <GDMButton
              onClick={() => {
                setSaveLatLng(prev => ({ ...prev, openModalLot: true }));
              }}
              sx={{
                height: '51px',
                width: isDesktop ? 'auto' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                marginBottom: 'auto',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_latitude_longitude')}
            </GDMButton>
          )}
          <GDMBox
            sx={{
              display: 'flex',
              gap: isDesktop ? '26px' : '8px',
              flexDirection: isDesktop ? 'row' : 'column-reverse',
              width: isDesktop ? 'auto' : '100%',
            }}
          >
            <GDMButton
              onClick={() => {
                if (viewType === 'edit') {
                  openModal({
                    type: 'warning',
                    title: getLabelTranslation('warning_exit'),
                    message: getLabelTranslation('modal_message_exit_warning'),
                    primaryAction: {
                      label: getLabelTranslation('exit'),
                      action: exit,
                    },
                    secondAction: {
                      label: getLabelTranslation('cancel'),
                      action: closeModal,
                    },
                  });
                } else {
                  exit();
                }
              }}
              sx={{
                height: '51px',
                marginBottom: '0',
                width: widthButton,
                color: theme.palette.secondary.dark,
                display: 'flex',
                gap: '8px',
                background: viewType === 'edit' ? theme.palette.secondary.main  : 'transparent',
                alignItems: 'center',
                boxShadow: 'none',
                flex: !isDesktop && 1,
                ':hover': {
                  borderStyle: 'none',
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant={viewType === 'view' ? 'outlined' : 'text'}
            >
              {viewType === 'view' && <FaArrowLeftLong />}
              {viewType !== 'view'
                ? getLabelTranslation('ReusableFormKey_Cancel')
                : getLabelTranslation('ReusableFormKey_Back')}
            </GDMButton>
            {viewType !== 'view' && (
              <GDMButton
                variant="contained"
                onClick={handleLotFormSubmit}
                sx={{
                  height: '51px',
                  width: isDesktop ? '187px' : '100%',
                  flex: !isDesktop && 1,
                  boxShadow: 'none',
                  ':hover': {
                    boxShadow: 'none',
                    background: theme.palette.primary.light,
                  },
                }}
              >
                {getLabelTranslation('ReusableFormKey_Save')}
              </GDMButton>
            )}
          </GDMBox>
        </GDMGrid>
      </GDMGrid>
    </GDMContainer>
  );
}
