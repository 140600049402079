import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Senha atual é obrigatória.'),
  newPassword: yup
    .string()
    .required('Nova senha é obrigatória.')
    .min(8, 'A nova senha deve ter no mínimo 8 caracteres.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'A nova senha deve incluir uma combinação de letras maiúsculas, minúsculas, números e um caracter especial.',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'As senhas não coincidem.')
    .required('Confirmação da nova senha é obrigatória.'),
});
