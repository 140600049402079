/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

import { RecommendationContextProviderProps, RecommendationType } from './recommendationContext.d';

const RecommendationContext = createContext(null);

export const useRecommendation = () => useContext(RecommendationContext);

// Provider para envolver a aplicação
export const RecommendationContextProvider: React.FC<RecommendationContextProviderProps> = ({
  children,
}) => {
  const [recommendationContext, setRecommendationContext] = useState<RecommendationType>(null);
  const [editedFields, setEditedFields] = useState({});
  const [varietyValue, setVarietyValue] = useState('');

  const contextValue = {
    recommendationContext,
    setRecommendationContext,
    editedFields,
    setEditedFields,
    varietyValue,
    setVarietyValue,
  };
  return (
    <RecommendationContext.Provider value={contextValue}>{children}</RecommendationContext.Provider>
  );
};

export const useRecommendationContext = () => {
  const context = useContext(RecommendationContext);
  if (!context) {
    throw new Error('useRecommendationContext must be used within a RecommendationProvider');
  }
  return context;
};
