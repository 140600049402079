import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SellerGDMService } from 'core/api/services/sellerGDM';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { GDMContainer, GDMDynamicForm } from 'components';

import useModal from 'hooks/useModal/useModal';

import { accountRegisterPageFormSubmit } from './utils';

export default function AccountRegisterPage(): JSX.Element {
  const { accountId } = useParams();
  const location = useLocation();
  const [initialData, setInitialData] = useState(null);

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const { openModal } = useModal();
  const navigate = useNavigate();

  const { getLabelTranslation } = useLanguageSwitcher();

  let viewType = '';
  if (location.pathname.includes('edit')) {
    viewType = 'edit';
  } else if (location.pathname.includes(accountId)) {
    viewType = 'view';
  } else {
    viewType = 'create';
  }

  useEffect(() => {
    if (viewType === 'create') {
      setInitialData(null);
      return;
    }
    if (viewType === 'edit' || viewType === 'view')
      SellerGDMService.getSellerGDMId(accountId).then(response => {
        setInitialData({
          ...response,
          email: response.email,
        });
      });
  }, [viewType, accountId]);

  const sellerGDMBreadcrumbs = () => {
    if (viewType === 'edit') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('customRolesInputs_gdm_seller')} > ${getLabelTranslation('edit')}`;
    }
    if (viewType === 'view') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('customRolesInputs_gdm_seller')}`;
    }
    if (viewType === 'create') {
      return `${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'cadastrar_vendedor_gdm',
      )}`;
    }

    return '';
  };

  return (
    <GDMContainer>
      <GDMContainer
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <GDMDynamicForm
          viewType={viewType}
          entityType="Vendedor GDM"
          initialData={initialData}
          onSubmit={formData =>
            initialData
              ? accountRegisterPageFormSubmit({
                  formData,
                  isNew: false,
                  accountId,
                  openModal,
                  navigate,
                  setFieldErrors,
                  getLabelTranslation,
                })
              : accountRegisterPageFormSubmit({
                  formData,
                  isNew: true,
                  accountId,
                  openModal,
                  navigate,
                  setFieldErrors,
                  getLabelTranslation,
                })
          }
          breadcrumb={sellerGDMBreadcrumbs()}
          key={initialData ? initialData.id : 'initialData'}
          fieldErrors={fieldErrors}
        />
      </GDMContainer>
    </GDMContainer>
  );
}
