import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

import { Box, IconButton, InputAdornment, TextField } from '@mui/material';

import CustomInputLabel from 'components/gdm-custom-input-label/GDMCustomInputLabel';

import { inputStyle } from '../ChangePasswordModal.style';

function PasswordInput({ label, name, error, register, control, errors, ...rest }) {
  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = passwordLabel => {
    setShowPasswords({ ...showPasswords, [passwordLabel]: !showPasswords[passwordLabel] });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Box sx={inputStyle}>
      <CustomInputLabel label={label} fontSize="16px" color="#222222" />
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        {...register(name)}
        render={({ field }) => (
          <TextField
            placeholder={label}
            variant="outlined"
            fullWidth
            type={showPasswords[name] ? 'text' : 'password'}
            error={error}
            helperText={errors[name]?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disableRipple
                    sx={{
                      ':hover': {
                        background: 'none',
                      },
                    }}
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword(name)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswords.currentPassword ? (
                      <IoEyeOffOutline fontSize={20} color="#A1A9B8" />
                    ) : (
                      <IoEyeOutline fontSize={20} color="#A1A9B8" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
            {...rest}
          />
        )}
      />
    </Box>
  );
}

export default PasswordInput;
