import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';

import colors from 'core/design-system/style-guide/colors.json';

import { GDMIconButton } from 'components/gdm-icon-button';
import { GDMLink } from 'components/gdm-link';
import { GDMTableCell } from 'components/gdm-table-cell';
import { GDMTableRow } from 'components/gdm-table-row';
import { GDMTypography } from 'components/gdm-typography';
import { useNavigate } from 'react-router-dom';
import { LuTrash2 } from 'react-icons/lu';
import { AdminListTableItemDesktopComponent } from './AdminListTableItemDesktop.type';

const Cell = ({ text }: { text: string }) => (
  <GDMTableCell>
    <GDMTypography variant="subtitle1" sx={{ fontSize: '14px', lineHeight: '19.6px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',width: '20ch' }}>
      {text}
    </GDMTypography>
  </GDMTableCell>
);

const AdminListTableItemDesktop: AdminListTableItemDesktopComponent = ({
  admin,
  adminIdSelectedToDelete,
  handleOpenDeleteAdminModal,
}) => {
  const { countryName, documentNumber, email, id, name, phoneNumber } = admin;
  const navigate = useNavigate()
  const isSelectedToDelete = adminIdSelectedToDelete === id;

  return (
    <GDMTableRow sx={{ background: isSelectedToDelete ? '#CFEFFF' : '#fff' }}>
      <Cell text={name} />
      <Cell text={email} />
      <Cell text={phoneNumber} />
      <Cell text={documentNumber} />
      <Cell text={countryName} />
      <GDMTableCell>
          <GDMIconButton onClick={() => navigate(`/admin-register/${id}`)}> 
            <IoEyeOutline color={colors.dark1} style={{ fontSize: '1.3rem' }} />
          </GDMIconButton>
      </GDMTableCell>
      <GDMTableCell>
        <GDMIconButton onClick={() => navigate(`/admin-register/${id}/edit`)}> 
            <FiEdit color={colors.dark1} style={{ fontSize: '1.2rem' }} />
          </GDMIconButton>
      </GDMTableCell>
      <GDMTableCell>
        <GDMIconButton onClick={() => handleOpenDeleteAdminModal(id)}>
          <LuTrash2
            onClick={() => handleOpenDeleteAdminModal(id)}
            color={colors.dark1}
            style={{ fontSize: '1.2rem' }}
          />
        </GDMIconButton>
      </GDMTableCell>
    </GDMTableRow>
  );
};

export default AdminListTableItemDesktop;
