import { GDMBox } from 'components/gdm-box';
import { GDMTypography } from 'components/gdm-typography';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import React from 'react';
import { PiNotebookBold } from 'react-icons/pi';

export const GDMCard = ({
  title,
  value,
  percent,
}: {
  title: string;
  value: number;
  percent: boolean;
}) => {
  const { theme } = useThemeSwitcher();

  return (
    <GDMBox
      sx={{
        textAlign: 'center',
        background: '#fafafa',
        border: '1px solid #cbcdd9',
        boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.25)',
        borderRadius: '8px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '16px',
        minHeight: '160px',
      }}
    >
      <GDMBox sx={{ display: 'flex', alignItems: 'center', gap: '16px', alignSelf: 'center' }}>
        <PiNotebookBold
          color={theme.palette.primary.main}
          size={24}
          style={{ minWidth: 24, minHeight: 24 }}
        />
        <GDMTypography
          sx={{
            fontSize: { xs: '20px!important', md: '20px!important' },
            lineHeight: { xs: '24px!important', md: '24px!important' },
            fontWeight: 700,
            color: '#777D88',
          }}
        >
          {title}
        </GDMTypography>
      </GDMBox>
      <GDMTypography
        sx={{
          fontSize: { xs: '40px!important', md: '40px!important' },
          fontWeight: 700,
          lineHeight: { xs: '44px!important', md: '44px!important' },
          color: '#222',
        }}
      >
        {value === 0 ? 0 : value}
        {percent && '%'}
      </GDMTypography>
    </GDMBox>
  );
};
