import { GetDocumentLabelInterface } from './getDocumentLabel.types';

export const getDocumentLabel = ({ entityType, countryOfUser }: GetDocumentLabelInterface) => {
  if (entityType !== 'Administrador' && countryOfUser === 'Brasil') {
    return 'CPF/CNPJ';
  }
  if (entityType === 'Administrador' && countryOfUser === 'Brasil') {
    return 'CPF';
  }
  return 'CUIT';
};
