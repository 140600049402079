import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { GDMContainer } from 'components';
import { DashboardMapMarkersTree } from '../dashboard-map-markers-tree';
import { mapContainerStyles, mapStyles } from './DashboardMap.styles';
import { DashboardMapComponent } from './DashboardMap.type';

const DashboardMap: DashboardMapComponent = ({ dataSource, handleNavigate, theme, ...rest }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <GDMContainer sx={mapContainerStyles}>
      <APIProvider apiKey={apiKey}>
        <Map
          {...rest}
          mapTypeId="terrain"
          mapId="49ae42fed52588c3"
          gestureHandling="cooperative"
          style={mapStyles}
        >
          {dataSource && (
            <DashboardMapMarkersTree
              data={dataSource}
              theme={theme}
              handleNavigate={handleNavigate}
            />
          )}
        </Map>
      </APIProvider>
    </GDMContainer>
  );
};

export default DashboardMap;
