/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const getSasToken = async (url: string): Promise<any> => {
  return Api.get({
    url: `/SasToken/?url=${url}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const SasTokenService = {
  getSasToken,
};
