import { SellerGDMService } from 'core/api/services/sellerGDM';

import { errorHandler } from 'utils/form/errorHandler';

import { accountRegisterPageCloseModal } from '../account-register-page-close-modal';
import {
  AccountRegisterModelData,
  AccountRegisterPageFormSubmitFunction,
} from './accountRegisterPageFormSubmit.type';

const accountRegisterPageFormSubmit: AccountRegisterPageFormSubmitFunction = async payload => {
  const { formData, isNew, accountId, openModal, navigate, setFieldErrors, getLabelTranslation } =
    payload;

  try {
    const sellerGDMData: AccountRegisterModelData = {
      name: formData.name,
      email: formData.contactEmail,
      phoneNumber: formData.phoneNumber,
      documentTypeId: formData.documentTypeId,
      documentNumber: formData.documentNumber,
      countryId: formData.countryId,
      fantasyName: formData.fantasyName,
      personInChargeName: formData.personInChargeName,
      regionId: formData.regionId,
      brandIds: formData.brandIds,
    };

    await SellerGDMService.upSertSellerGDM(
      isNew ? 'post' : 'put',
      { ...sellerGDMData, documentNumber: formData.documentNumber.replace('_', '') },
      accountId,
    );

    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_create_success'),
      onClose: () => accountRegisterPageCloseModal({ navigate }),
    });
  } catch (error) {
    errorHandler(error, setFieldErrors);

    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_create_error'),
      message: getLabelTranslation('modal_message_create_error'),
    });
  }
};

export default accountRegisterPageFormSubmit;
