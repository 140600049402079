import { Step, StepIconProps, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/system';
import { GDMTypography } from 'components/gdm-typography';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { FaCheck } from 'react-icons/fa6';
import { TbNumber1, TbNumber2, TbNumber3 } from 'react-icons/tb';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#0A3C56',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#0A3C56',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    background: '#0A3C56',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    background: '#06C270',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { icon, active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: !completed ? <TbNumber1 /> : <FaCheck />,
    2: !completed ? <TbNumber2 /> : <FaCheck />,
    3: !completed ? <TbNumber3 /> : <FaCheck />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

const GDMStepper = ({ activeStep }) => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  function breakWord(label, index) {
    const [firstPart, ...rest] = label.split(' ');
    return (
      <GDMTypography
        sx={{
          color: activeStep === index ? '#222' : '#A1A9B8',
          fontSize: isDesktop ? '16px' : '10px',
          fontWeight: 400,
        }}
      >
        {`${firstPart}`} <br /> {`${rest.join(' ')}`}
      </GDMTypography>
    );
  }

  const farmer = getLabelTranslation('add_farmer_information');
  const farm = getLabelTranslation('add_farm_information');
  const lot = getLabelTranslation('add_lot_information');
  const steps = [farmer, farm, lot];
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
      sx={{ width: !isDesktop ? '100%' : '40%' }}
    >
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              sx={{
                span: { span: { span: { width: '12ch', wordBreak: 'break-word' } } },
              }}
            >
              {breakWord(label, index)}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default GDMStepper;
