import { RolesServices } from 'core/api/services/roles';
import { loadRoles, loadRolesDone, loadRolesFail } from 'core/store/stores';

const execute = async (): Promise<void> => {
  const errorCallback = () => {
    loadRolesFail();
  };

  loadRoles();

  return RolesServices.getRolesUseCase()
    .then(({ data }) => {
      loadRolesDone(data);
    })
    .catch(errorCallback);
};

const LoadRolesServicesUseCase = {
  execute,
};

export default LoadRolesServicesUseCase;
