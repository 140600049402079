import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Drawer,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GDMButton, GDMTextField, GDMTypography } from 'components';
import GDMBox from 'components/gdm-box/GDMBox';
import GDMInputCheckbox from 'components/gdm-input-checkbox/GDMInputCheckbox';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { CiFilter } from 'react-icons/ci';
import { IoIosArrowDown } from 'react-icons/io';
import { IoClose, IoSearchOutline } from 'react-icons/io5';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FaCircle } from 'react-icons/fa6';

const GDMRecommendationFilter = ({
  filters,
  setFilter,
  filter,
  periods,
  setPeriods,
  prevFilters,
  handleChangeSearch,
  setPrevFilters,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const [copyFilter, setCopyFilter] = useState(filter);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [usePrevFilters, setUsePrevFilters] = useState(false);
  const [data, setData] = useState({
    periodFrom: periods.periodFrom,
    periodTo: periods.periodTo,
  });

  const [searchInputs, setSearchInputs] = useState({
    cityInput: '',
    stateInput: '',
    genotypeInput: '',
    role: '',
    multiplier: '',
    distributor: '',
    user: '',
    periodFrom: periods.periodFrom,
    periodTo: periods.periodTo,
  });

  const [searchFilters, setSearchFilters] = useState({
    cities: [],
    states: [],
    genotypes: [],
    roles: [],
    multipliers: [],
    distributors: [],
    users: [],
  });
  const [filtersApplied, setFiltersApplied] = useState([]);
  const [search, setSearch] = useState('');
  const [useFilterApplied, setUseFilterApplied] = useState(false);
  const currentYear = new Date().getFullYear();
  const initial = new Date(Date.UTC(currentYear, 0, 1)).toISOString();
  const end = new Date(Date.UTC(currentYear, 11, 31, 23, 59, 59, 999)).toISOString();

  const fromDate = dayjs(initial).startOf('day').add(1, 'day');
  const toDate = dayjs(end).endOf('day');

  const clearFilter = () => {
    setUseFilterApplied(false)
    setUsePrevFilters(false);
    setPeriods({ periodFrom: fromDate, periodTo: toDate });
    setFiltersApplied([]);
    setFilter(prev => ({
      ...prev,
      brand: [],
      culture: [],
      technology: [],
      genotype: [],
      status: [],
      state: [],
      city: [],
      role: [],
      multiplier: [],
      distributor: [],
      user: [],
      periodFrom: fromDate,
      periodTo: toDate,
    }));
    setCopyFilter(prev => ({
      ...prev,
      brand: [],
      culture: [],
      technology: [],
      genotype: [],
      status: [],
      state: [],
      city: [],
      role: [],
      multiplier: [],
      distributor: [],
      user: [],
      periodFrom: fromDate,
      periodTo: toDate,
    }));

    setData({
      periodFrom: fromDate,
      periodTo: toDate,
    });
    sessionStorage.removeItem('recommendationFilters');
  };

  const handleFilterSearch = ({
    query,
    type,
  }: {
    query: string;
    type: 'city' | 'state' | 'genotype' | 'role' | 'multiplier' | 'distributor' | 'user';
  }) => {
    if (query) {
      if (type === 'city') {
        const newArray = filters.city.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, cityInput: query }));
        setSearchFilters(prev => ({ ...prev, cities: newArray }));
      } else if (type === 'state') {
        const newArray = filters.state.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, stateInput: query }));
        setSearchFilters(prev => ({ ...prev, states: newArray }));
      } else if (type === 'genotype') {
        const newArray = filters.genotype.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, genotypeInput: query }));
        setSearchFilters(prev => ({ ...prev, genotypes: newArray }));
      } else if (type === 'role') {
        const newArray = filters.role.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, role: query }));
        setSearchFilters(prev => ({ ...prev, roles: newArray }));
      } else if (type === 'multiplier') {
        const newArray = filters.multiplier.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, multiplier: query }));
        setSearchFilters(prev => ({ ...prev, multipliers: newArray }));
      } else if (type === 'distributor') {
        const newArray = filters.distributor.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, distributor: query }));
        setSearchFilters(prev => ({ ...prev, distributors: newArray }));
      } else if (type === 'user') {
        const newArray = filters.user.filter(obj =>
          obj.name.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchInputs(prev => ({ ...prev, user: query }));
        setSearchFilters(prev => ({ ...prev, users: newArray }));
      }
    }
    if (!query) {
      if (type === 'city') {
        setSearchInputs(prev => ({ ...prev, cityInput: '' }));
        setSearchFilters(prev => ({ ...prev, cities: filters.city }));
      } else if (type === 'state') {
        setSearchInputs(prev => ({ ...prev, stateInput: '' }));
        setSearchFilters(prev => ({ ...prev, states: filters.state }));
      } else if (type === 'genotype') {
        setSearchInputs(prev => ({ ...prev, genotypeInput: '' }));
        setSearchFilters(prev => ({ ...prev, genotypes: filters.genotype }));
      } else if (type === 'role') {
        setSearchInputs(prev => ({ ...prev, role: '' }));
        setSearchFilters(prev => ({ ...prev, roles: filters.role }));
      } else if (type === 'multiplier') {
        setSearchInputs(prev => ({ ...prev, multiplier: '' }));
        setSearchFilters(prev => ({ ...prev, multipliers: filters.multiplier }));
      } else if (type === 'distributor') {
        setSearchInputs(prev => ({ ...prev, distributor: '' }));
        setSearchFilters(prev => ({ ...prev, distributors: filters.distributor }));
      } else if (type === 'user') {
        setSearchInputs(prev => ({ ...prev, user: '' }));
        setSearchFilters(prev => ({ ...prev, users: filters.user }));
      }
    }
  };

  const handleApplyFilters = () => {
    setUseFilterApplied(false)
    setCopyFilter(prev => ({ ...prev, periodFrom: data.periodFrom, periodTo: data.periodTo, page: 1}));
    setPeriods(prev => ({ ...prev, periodFrom: data.periodFrom, periodTo: data.periodTo }));
    setFilter(copyFilter);
    setOpenFilterDrawer(false);
  };
  const handleCheck = ({ obj, type }: { obj: any; type: string }) => {
    setUsePrevFilters(false);
    if (!filtersApplied.some(item => JSON.stringify(item.id) === JSON.stringify(obj.id))) {
      setFiltersApplied(prev => [...prev, { id: obj.id, name: obj.name, type }]);
      setCopyFilter(prev => ({ ...prev, [type]: [...prev[type], obj.id] }));
    } else {
      setUseFilterApplied(true);
      setFiltersApplied(filtersApplied.filter(filter => filter.id !== obj.id));
      setCopyFilter(prev => {
        const filter = { ...prev, [type]: prev[type].filter(v => v !== obj.id) };
        return filter;
      });
    }
  };

  const disableButton = () => {
    if (
      filtersApplied.length > 0 &&
      dayjs(data.periodFrom).isSame(fromDate) &&
      dayjs(data.periodTo).isSame(toDate)
    ) {
      return false;
    }
    if (
      filtersApplied.length === 0 && 
      (!dayjs(data.periodFrom).isSame(fromDate) || !dayjs(data.periodTo).isSame(toDate))
    ) {
      return false;
    }
    if(useFilterApplied){
      return false
    }

    if (
      filtersApplied.length > 0 &&
      (!dayjs(data.periodFrom).isSame(fromDate) || !dayjs(data.periodTo).isSame(toDate))
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (prevFilters.length > 0) {
      setFiltersApplied(
        prevFilters
          .filter(index => index.length !== 0)
          .slice(0, -2)
          .flat(),
      );
      if (
        prevFilters[prevFilters.length - 2] &&
        dayjs(prevFilters[prevFilters.length - 2]).isValid()
      ) {
        setData(prev => ({ ...prev, periodFrom: prevFilters[prevFilters.length - 2] }));
      }
      if (
        prevFilters[prevFilters.length - 1] &&
        dayjs(prevFilters[prevFilters.length - 1]).isValid()
      ) {
        setData(prev => ({ ...prev, periodTo: prevFilters[prevFilters.length - 1] }));
      }
      setPrevFilters([]);
      setCopyFilter(filter);
      setUsePrevFilters(true);
    }
  }, [prevFilters]);

  useEffect(() => {
    if (filters) {
      setSearchFilters({
        cities: filters.city,
        states: filters.state,
        genotypes: filters.genotype,
        roles: filters.role,
        multipliers: filters.multiplier,
        distributors: filters.distributor,
        users: filters.user,
      });
    }
  }, [filters]);

  return (
    <>
      <GDMBox
        sx={{ display: 'flex', flex: 1, gap: '24px', flexDirection: isDesktop ? 'row' : 'column' }}
      >
        <GDMTextField
          placeholder={getLabelTranslation('search_for')}
          value={search}
          onChange={({ target }) => {
            setSearch(target.value);
            handleChangeSearch(search);
          }}
          InputProps={
            search
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearch('');
                          handleChangeSearch('');
                        }}
                      >
                        <IoClose />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoSearchOutline />
                    </InputAdornment>
                  ),
                }
          }
          sx={{ flex: 1 }}
        />
        <GDMButton
          sx={{
            position: 'relative',
            color: '#222',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            border: '1px solid #A1A9B8',
            borderRadius: '6px',
            height: '58px',
            justifyContent: isDesktop ? 'center' : 'space-between',
            padding: '15.5px 24px 15.5px 14px',
          }}
          onClick={() => setOpenFilterDrawer(true)}
        >
          {getLabelTranslation('filter_for')} <CiFilter />
          {!disableButton() && (
            <FaCircle
              size={10}
              color="red"
              style={{ position: 'absolute', right: '20px', top: '18px' }}
            />
          )}
        </GDMButton>
      </GDMBox>

      <Drawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            marginRight: isDesktop && '40px',
            padding: '42px 20px',
            height: isDesktop ? '85vh' : '90%',
            top: '12vh',
            borderRadius: '12px',
            width: isDesktop ? '400px' : '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              paddingLeft: '8px',
              width: '8px',
              background: '#fafafa',
              border: '1px solid #cbcdd9',
              borderRadius: '24px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#cbcdd9',
              borderRadius: '24px',
            },
          },
        }}
      >
        <GDMBox>
          <GDMBox sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
            <IconButton onClick={() => setOpenFilterDrawer(false)}>
              <CgClose color="#222" />
            </IconButton>
          </GDMBox>
          <GDMBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <GDMTypography sx={{ fontSize: '16px', color: '#222', fontWeight: 700 }}>
              {getLabelTranslation('filters')}
            </GDMTypography>
            <GDMButton
              sx={{ fontSize: '12px', color: '#222', textDecoration: 'underline' }}
              onClick={clearFilter}
            >
              {getLabelTranslation('clear_filter')}
            </GDMButton>
          </GDMBox>
          <GDMBox sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginBottom: '16px' }}>
            {filtersApplied.length > 0 &&
              filtersApplied.map(filter => {
                if (filter.name) {
                  return (
                    <Chip
                      label={getLabelTranslation(filter.name)}
                      onDelete={() => handleCheck({ obj: filter, type: filter.type })}
                      deleteIcon={<CgClose color="#222" />}
                      variant="outlined"
                      sx={{
                        background: theme.palette.primary.light,
                        color: '#222',
                        border: 'none',
                      }}
                    />
                  );
                }
                return '';
              })}
          </GDMBox>
          <GDMBox>
            {filters !== null && (
              <GDMBox sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMTypography sx={{ fontSize: '16px', color: '#222', fontWeight: 700 }}>
                  {getLabelTranslation('for_filters')}
                </GDMTypography>
                <GDMBox
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <GDMTypography
                    sx={{ fontSize: '14px', color: '#222', fontWeight: 400, padding: '0 16px' }}
                  >
                    {getLabelTranslation('period')}
                  </GDMTypography>
                  {(!dayjs(data.periodFrom).isSame(fromDate) ||
                    !dayjs(data.periodTo).isSame(toDate)) && (
                    <GDMButton
                      sx={{ fontSize: '12px', color: '#222', textDecoration: 'underline' }}
                      onClick={() => {
                        setPeriods({ periodFrom: fromDate, periodTo: toDate });
                        setCopyFilter(prev => ({
                          ...prev,
                          periodFrom: fromDate,
                          periodTo: toDate,
                        }));
                        setUsePrevFilters(false);
                        setData(prev => ({ ...prev, periodFrom: fromDate, periodTo: toDate }));
                      }}
                    >
                      {getLabelTranslation('clear_date_period')}
                    </GDMButton>
                  )}
                </GDMBox>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <GDMBox sx={{ display: 'flex', padding: '0 16px', gap: '8px' }}>
                    <GDMBox>
                      <GDMTypography sx={{ fontSize: '12px', color: '#222', fontWeight: 400 }}>
                        {getLabelTranslation('from')}
                      </GDMTypography>
                      <DatePicker
                        defaultValue={dayjs(data.periodFrom)}
                        value={dayjs(data.periodFrom)}
                        onChange={data => {
                          if (dayjs(data).isValid()) {
                            setUsePrevFilters(false);
                            setCopyFilter(prev => ({ ...prev, periodFrom: data.toISOString() }));
                            setData(prev => ({ ...prev, periodFrom: data.toISOString() }));
                          }
                        }}
                        format="DD/MM/YYYY"
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '20px',
                          },
                        }}
                      />
                    </GDMBox>

                    <GDMBox>
                      <GDMTypography sx={{ fontSize: '12px', color: '#222', fontWeight: 400 }}>
                        {getLabelTranslation('to')}
                      </GDMTypography>
                      <DatePicker
                        defaultValue={dayjs(data.periodTo)}
                        value={dayjs(data.periodTo)}
                        onChange={data => {
                          if (dayjs(data).isValid()) {
                            setData(prev => ({ ...prev, periodTo: data.toISOString() }));
                            setCopyFilter(prev => ({ ...prev, periodTo: data.toISOString() }));
                            setUsePrevFilters(false);
                          }
                        }}
                        format="DD/MM/YYYY"
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '20px',
                          },
                        }}
                      />
                    </GDMBox>
                  </GDMBox>
                </LocalizationProvider>

                <Accordion
                  sx={{
                    margin: '0 !important',
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography
                      sx={{
                        fontSize: '14px',
                        color: '#222',
                      }}
                    >
                      {getLabelTranslation('brand')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      margin: '0 !important',
                    }}
                  >
                    {filters.brand.map(b => (
                      <GDMBox
                        key={b.id}
                        sx={{ display: 'flex', alignItems: 'center', margin: '0 !important' }}
                      >
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(b.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: b, type: 'brand' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{b.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('culture')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {filters.culture.map(c => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(c.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: c, type: 'culture' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>
                          {getLabelTranslation(c.name)}
                        </GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('tech')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {filters.technology.map(t => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={filtersApplied && filtersApplied.some(item => item.id === t)}
                          onClick={() =>
                            handleCheck({ obj: { id: t, name: t }, type: 'technology' })
                          }
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{t}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('variable')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder="Busque a variedade desejada"
                      value={searchInputs.genotypeInput}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'genotype' });
                      }}
                      InputProps={
                        searchInputs.genotypeInput
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleFilterSearch({ query: '', type: 'genotype' })
                                    }
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.genotypes.map(g => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(g.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: g, type: 'genotype' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{g.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>Status</GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {filters.status.map(s => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(s.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: s, type: 'status' })}
                          sx={{
                            '&.Mui-checked + .MuiSvgIcon-root': {
                              backgroundColor: 'green',
                            },
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>
                          {getLabelTranslation(s.name)}
                        </GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('state')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder="Busque a UF desejada"
                      value={searchInputs.stateInput}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'state' });
                      }}
                      InputProps={
                        searchInputs.stateInput
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleFilterSearch({ query: '', type: 'state' })}
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.states.map(s => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(s.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: s, type: 'state' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{s.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('city')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder="Busque o município desejado"
                      value={searchInputs.cityInput}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'city' });
                      }}
                      InputProps={
                        searchInputs.cityInput
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleFilterSearch({ query: '', type: 'city' })}
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.cities.map(c => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(c.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: c, type: 'city' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{c.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('account_profile_label')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder={getLabelTranslation('search_account_type')}
                      value={searchInputs.role}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'role' });
                      }}
                      InputProps={
                        searchInputs.role
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleFilterSearch({ query: '', type: 'role' })}
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.roles.map(g => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(g.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: g, type: 'role' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>
                          {getLabelTranslation(g.name)}
                        </GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('distributor')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder={getLabelTranslation('search_distributor')}
                      value={searchInputs.distributor}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'distributor' });
                      }}
                      InputProps={
                        searchInputs.distributor
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleFilterSearch({ query: '', type: 'distributor' })
                                    }
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.distributors.map(g => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(g.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: g, type: 'distributor' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{g.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('multiplier')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder={getLabelTranslation('search_multiplier')}
                      value={searchInputs.multiplier}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'multiplier' });
                      }}
                      InputProps={
                        searchInputs.multiplier
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleFilterSearch({ query: '', type: 'multiplier' })
                                    }
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.multipliers.map(g => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(g.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: g, type: 'multiplier' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{g.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                    '&.Mui-expanded': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="test"
                    id="test"
                    sx={{ '& .Mui-expanded': { margin: 0 } }}
                  >
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('recommended_by')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder={getLabelTranslation('search')}
                      value={searchInputs.user}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value, type: 'user' });
                      }}
                      InputProps={
                        searchInputs.user
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleFilterSearch({ query: '', type: 'user' })}
                                  >
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1, '& .MuiInputBase-root': { borderRadius: '12px' } }}
                    />
                    {searchFilters.users.map(g => (
                      <GDMBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          sx={{
                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              color: theme.palette.primary.light,
                            },
                          }}
                          checked={
                            filtersApplied &&
                            filtersApplied.some(
                              item => JSON.stringify(item.id) === JSON.stringify(g.id),
                            )
                          }
                          onClick={() => handleCheck({ obj: g, type: 'user' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{g.name}</GDMTypography>
                      </GDMBox>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </GDMBox>
            )}
          </GDMBox>
        </GDMBox>

        <GDMButton
          disabled={usePrevFilters || disableButton()}
          onClick={handleApplyFilters}
          sx={{
            marginTop: '24px',
            background: usePrevFilters || disableButton() ? '#A1A9B8' : theme.palette.primary.main,
            color: '#fafafa',
            '&:hover': { background: theme.palette.primary.main },
            justifySelf: 'end',
            '&.Mui-disabled': {
              color: '#fafafa',
            },
          }}
        >
          {getLabelTranslation('apply')}
        </GDMButton>
      </Drawer>
    </>
  );
};

export default GDMRecommendationFilter;
