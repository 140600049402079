/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const getSellerGDM = async ({
  currentPage = 1,
  itemsPerPage,
  search = '',
  searchColumn = null,
  orderDesc = false,
  orderColumn = null,
}): Promise<any> => {
  let url = `/UsersSellerGdm?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }
  if (searchColumn && searchColumn !== 'all') {
    url += `&SearchColumn=${searchColumn}`;
  }
  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const upSertSellerGDM = async (method, body: any, id = ''): Promise<any> => {
  return Api[method]({
    url: `/UsersSellerGdm/${id}`,
    body: JSON.stringify(body),
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const deleteSellerGDM = async (id: string): Promise<any> => {
  return Api.delete({
    url: `/UsersSellerGdm/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getSellerGDMId = async (id: string): Promise<any> => {
  return Api.get({
    url: `/UsersSellerGdm/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const SellerGDMService = {
  getSellerGDM,
  upSertSellerGDM,
  deleteSellerGDM,
  getSellerGDMId,
};
