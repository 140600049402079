import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  GDMContainer,
  GDMDialog,
  GDMDialogContent,
  GDMDialogTitle,
  GDMDivider,
  GDMFeedbackModalComponent,
  GDMIconButton,
  GDMTypography,
} from 'components';

import useModal, { ActionButton } from 'hooks/useModal/useModal';

import { GDMFeedbackModalActions, GDMFeedbackModalTypeIcon } from './components';
import gdmFeedbackModalStyles from './GDMFeedbackModal.styles';
import { gdmFeedbackModalSetOldActions } from './utils';

const GDMFeedbackModal: GDMFeedbackModalComponent = () => {
  const {
    modalState: { message, open, title, type, primaryAction, secondAction, actionButtons },
    closeModal,
  } = useModal();

  const [modalActionButtons, setModalActionButtons] = useState<Array<ActionButton>>(actionButtons);

  const {
    paperStyle,
    titleStyle,
    closeIconButtonStyle,
    typeIconContainerStyle,
    dialogContentStyle,
    dialogContentTitleStyle,
    dividerStyle,
    dialogContentTextStyle,
    dialogContentContainerStyle,
  } = gdmFeedbackModalStyles;

  useEffect(() => {
    /* TODO: Remove this function and 'primaryAction' and 'secondAction' dependencies in useModal hook */

    gdmFeedbackModalSetOldActions({
      modalActionButtons,
      primaryAction,
      secondAction,
      setModalActionButtons,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryAction, secondAction, actionButtons]);

  return (
    <GDMDialog
      sx={{ padding: 4 }}
      open={open}
      PaperProps={{
        sx: paperStyle,
      }}
      onClose={closeModal}
      maxWidth="xs"
      fullWidth
    >
      <GDMDialogTitle sx={titleStyle}>
        <GDMIconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
          sx={closeIconButtonStyle}
        >
          <CloseIcon />
        </GDMIconButton>
      </GDMDialogTitle>

      <GDMContainer sx={typeIconContainerStyle}>
        <GDMFeedbackModalTypeIcon type={type} />
      </GDMContainer>

      <GDMDialogContent sx={dialogContentStyle}>
        <GDMContainer sx={dialogContentContainerStyle}>
          <GDMTypography sx={dialogContentTitleStyle} gutterBottom>
            {title}
          </GDMTypography>
          <GDMDivider sx={dividerStyle} />
          {message ? (
            <GDMTypography
              sx={{
                ...dialogContentTextStyle,
                ...(type === 'success'
                  ? {
                      display: 'none',
                    }
                  : {}),
              }}
              color={type === 'delete' || type === 'error' ? 'error' : 'grey.700'}
            >
              {message}
            </GDMTypography>
          ) : (
            ''
          )}
        </GDMContainer>
      </GDMDialogContent>

      <GDMFeedbackModalActions actionButtons={modalActionButtons} />
    </GDMDialog>
  );
};

export default GDMFeedbackModal;
