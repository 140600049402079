import { SxProps } from '@mui/material';

export const appBarStyle: SxProps = {
  display: 'flex',
  height: '64px',
  width: '100%',
  gridArea: 'header',
  padding: '18px 30px',
  justifyContent: 'center',
  borderBottom: '1px solid #D9DBE4',
  background: '#FAFAFA',
};

export const appBarStyleMobile: SxProps = {
  display: 'flex',
  height: '64px',
  width: '100%',
  gridArea: 'header',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #D9DBE4',
  background: '#FAFAFA',
};

export const brandSelectorMobile = {};

export const actionsContainer: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const actionsContainerMobile: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
